import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';

import { ChevronDownIcon, ChevronRightIcon } from '.';

const transitionClassNames = {
  entering: 'collapsing',
  entered: 'collapse show',
  exiting: 'collapsing',
  exited: 'collapse',
};

const iconStyles = {
  width: '14px',
};

function AccordionItem({ item, onOpen, open, secondary }) {
  return (
    <Transition in={open} timeout={75}>
      {(state) =>
        secondary ? (
          <section className="mb-4" data-testid={item?.dataTestId}>
            <div
              className="d-flex align-items-center cursor-pointer"
              onClick={onOpen}
            >
              <div className="me-2" style={iconStyles}>
                {open ? <ChevronDownIcon /> : <ChevronRightIcon />}
              </div>
              <h5 className="mb-0">{item.title}</h5>
            </div>
            <div className={transitionClassNames[state]}>{item.body}</div>
          </section>
        ) : (
          <div
            className={`card ${open ? 'dropup' : ''}`}
            data-testid={item?.dataTestId}
          >
            <div className="card-header p-0 ">
              <h2 className="mb-0">
                <button
                  type="button"
                  className="btn btn-light w-100 text-start p-3 rounded-0 dropdown-toggle d-flex align-items-center justify-content-between"
                  onClick={onOpen}
                >
                  {item.title}
                </button>
              </h2>
            </div>

            <div className={transitionClassNames[state]}>
              <div className="card-body">{item.body}</div>
            </div>
          </div>
        )
      }
    </Transition>
  );
}

function Accordions({
  items,
  secondary,
  defaultOpenedItemIndex, // You should either choice between controlled mode (with onChange) or uncontrolled with only defaultOpenedItemIndex
  openedItemIndex, // for controlled input
  onChange, // for controlled input; Controlled input should provide onChange callback
}) {
  const [openedItem, setOpenedItem] = useState(defaultOpenedItemIndex);

  useEffect(() => {
    if (openedItemIndex) {
      setOpenedItem(openedItemIndex);
    }
  }, [openedItemIndex]);

  useEffect(() => {
    onChange(openedItem);
    // eslint-disable-next-line
  }, [openedItem]);

  return (
    <div className="accordion">
      {items.map(
        (item, i) =>
          item && (
            <AccordionItem
              item={item}
              data-testid={item?.dataTestId}
              key={i}
              open={openedItem === i}
              onOpen={() => setOpenedItem(openedItem === i ? null : i)}
              secondary={secondary}
            />
          ),
      )}
    </div>
  );
}

Accordions.propTypes = {
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      body: PropTypes.node.isRequired,
      dataTestId: PropTypes.string,
    }),
  ).isRequired,
  defaultOpenedItemIndex: PropTypes.number,
};

Accordions.defaultProps = {
  onChange: () => {},
};

export default Accordions;
