import cs from 'classnames';
import React, { useEffect, useState } from 'react';

import { LockIcon, LockOpenIcon } from '.';
import classes from './delayedButton.module.css';

function DelayedButton({
  className,
  label,
  onClick,
  isReady = false,
  ...rest
}) {
  const { button, progress, visible, text } = classes;
  const [clock, setClock] = useState(null);
  const [ready, setReady] = useState(isReady);
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    setReady(isReady);
    if (!isReady) {
      setClicked(false);
    }
  }, [isReady]);
  return (
    <button
      onMouseEnter={(e) => {
        if (!clicked) {
          if (clock) {
            clearTimeout(clock);
          }
          setClock(setTimeout(() => setReady(true), 1210));
        }
      }}
      onMouseLeave={() => {
        clearTimeout(clock);
        if (!clicked) {
          setReady(false);
        }
      }}
      onClick={(e) => {
        if (ready) {
          setClicked(true);
          onClick(e);
        } else {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      className={cs(className, button, { [visible]: ready })}
      {...rest}
    >
      <span className={cs(progress, { [visible]: ready })}>
        {ready ? <LockOpenIcon /> : <LockIcon />}
      </span>
      <span className={text}>{label}</span>
    </button>
  );
}

export default DelayedButton;
