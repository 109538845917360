import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import ErrorPage from '../pages/error.page';
import NavigationLayout from '../pages/layouts/navigation-layout';
import V2RedirectionPage from '../pages/login/v2Redirection.page';
import MemberPageRoutes from '../pages/members/member-page-routes/index';
import {
  accessUserAssemblies,
  accessUserSomething,
  and,
} from '../pills/gate/gate.constants';
import MZoomModal from '../pills/zoom/zoomModal';
import useSentry, { withProfiler } from '../useSentry.hook';
import ErrorBound from './errorBound';
import ModaledPageRoute from './ModaledPageRoute';
import PagedRoute from './PagedRoute';
import Whereby from '../pills/visio/Whereby';

const DataLoader = React.lazy(() =>
  import(
    /* webpackChunkName: "dataLoader.container" */
    '../pills/dataLoader/dataLoader.container'
  ),
);

const LoginPage = React.lazy(() =>
  import(
    /* webpackChunkName: "login.page" */
    '../pages/login/login.page'
  ),
);

const AssembliesPage = React.lazy(() =>
  import('../pages/assemblies/index/assemblies.page'),
);

const AssembliesDetailsPage = React.lazy(() =>
  import(
    /* webpackChunkName: "assembliesDetails.page" */
    '../pages/assemblies/assemblyDetails/assembliesDetails.page'
  ),
);

const AssembliesVPCPage = React.lazy(() =>
  import(
    /* webpackChunkName: "assembliesVPC.page" */
    '../pages/assemblies/assembliesVPC/assembliesVPC.page'
  ),
);

const AssembliesVPCPageCopro = React.lazy(() =>
  import(
    /* webpackChunkName: "assembliesVPC.page" */
    '../pages/assemblies/assembliesVPCCopro/assembliesVPCCopro.page'
  ),
);

const AssemblyMinutesPage = React.lazy(() =>
  import(
    /* webpackChunkName: "assemblyMinutes.page" */
    '../pages/assemblies/assemblyMinutes/assemblyMinutes.page'
  ),
);

const AssembliesClosedPage = React.lazy(() =>
  import(
    /* webpackChunkName: "assemblyMinutes.page" */
    '../pages/assemblies/shared-modals/assembliesClosed.page'
  ),
);

const AssemblyConvocationsPage = React.lazy(() =>
  import(
    /* webpackChunkName: "assemblyConvocations.page" */
    '../pages/assemblies/assemblyConvocation/assemblyConvocations.page'
  ),
);

const AssemblyFullPageLayout = React.lazy(() =>
  import(
    /* webpackChunkName: "assemblyFullPage.layout" */
    '../pages/layouts/assemblyFullPage.layout'
  ),
);

//#region Assembly Live
const AssemblyLiveFullPageLayout = React.lazy(() =>
  import(
    /* webpackChunkName: "assemblyLiveFullPage.layout" */
    '../pages/layouts/assemblyLiveFullPage.layout'
  ),
);

const ConvocationPageLayout = React.lazy(() =>
  import(
    /* webpackChunkName: "assemblyLiveFullPage.layout" */
    '../pages/layouts/convocation-page-layout/index'
  ),
);

const AssemblyLiveTimesheetPage = React.lazy(() =>
  import(
    /* webpackChunkName: "assemblyLiveTimesheet.page" */
    '../pages/assemblyLive/assemblyLiveTimesheet/assemblyLiveTimesheet.page'
  ),
);
const AssemblyLiveParticipatePage = React.lazy(() =>
  import(
    /* webpackChunkName: "assembliesParticipate.page" */
    '../pages/assemblyLive/assemblyLiveParticipate/assemblyLiveParticipate.page'
  ),
);
const AssemblyLiveResolutionDetailsPage = React.lazy(() =>
  import(
    /* webpackChunkName: "assemblyLiveResolutionDetails.page" */
    '../pages/assemblyLive/assemblyLiveResolutionDetails/assemblyLiveResolutionDetails.page'
  ),
);
const NoMatchPage = React.lazy(() =>
  import(
    /* webpackChunkName: "noMatch.page" */
    '../pages/noMatch.page'
  ),
);

const AssembliesAddModalPage = React.lazy(() =>
  import(
    /* webpackChunkName: "assembliesAdd.modal.page" */
    '../pages/assemblies/index/modals/assembliesAdd.modal.page'
  ),
);

const AssemblyConvocationRegenerateModalPage = React.lazy(() =>
  import(
    /* webpackChunkName: "assemblyConvocationRegenerate.modal.page" */
    '../pages/assemblies/assemblyConvocation/modals/assemblyConvocationRegenerate.modal.page'
  ),
);
const AssemblyConvocationQuoteParameterModalPage = React.lazy(() =>
  import(
    /* webpackChunkName: "assemblyConvocationRegenerate.modal.page" */
    '../pages/assemblies/assemblyConvocation/modals/assemblyConvocationQuoteParameter.modal.page'
  ),
);

const AssemblyConvocationSendModalPage = React.lazy(() =>
  import(
    /* webpackChunkName: "assemblyConvocationRegenerate.modal.page" */
    '../pages/assemblies/assemblyConvocation/modals/assemblyConvocationSend.modal.page'
  ),
);
//#endregion

const AppSwitch = ({ userToken }) => {
  // if user got redirected from V2 :
  // - we will pass that information to the v2RedirectionPage
  // - save in store the leave & finish urls (page V2 linked to)
  // - try to establish a connexion with the API using cookies

  let v2RedirectionParams = {};
  let isV2redirection = false; // indicate if user is redirected here from V2
  let requestedUrl = '';
  // we got a query string ?
  // likely to be a redirection from V2 front
  // TODO only activate redirection on assembly page
  if (window.location.search) {
    const searchParams = new URLSearchParams(window.location.search);
    const tokenParam = searchParams.get('token');
    const urlLeaveParam = searchParams.get('leave');
    const urlFinishParam = searchParams.get('finish');
    if (urlLeaveParam && urlFinishParam) {
      const pathname = window.location.pathname;
      isV2redirection = true;
      requestedUrl = pathname;
      v2RedirectionParams.leaveUrl = urlLeaveParam;
      v2RedirectionParams.finishUrl = urlFinishParam;
      v2RedirectionParams.tokenParam = tokenParam;
    } else {
      const fromSymentApp = searchParams.get('fromSymentApp');
      if (fromSymentApp) {
        const pathname = window.location.pathname;
        isV2redirection = true;
        requestedUrl = pathname;
      }
    }
  }

  return (
    <Switch>
      <Route
        path="/"
        component={() => <Redirect exact from="/" to="/login" />}
        exact
      />
      <Route // route to handle the zoom 'rejoindre' button
        path="/assemblies/:assemblyId/details/zoom/join-button"
      >
        <Whereby />
      </Route>
      <PagedRoute path="/login" page={LoginPage} />
      {isV2redirection ? (
        <Switch>
          <Route
            path="/(dashboard*|members*|residencies|calendar|publications|inbox|notifications|me|providers|plaints|assemblies|polls*|companies*|documents*)"
            component={() => (
              <Redirect
                push
                to={{
                  pathname: '/v2redirection',
                  state: {
                    requestedUrl,
                    leaveUrl: v2RedirectionParams.leaveUrl,
                    finishUrl: v2RedirectionParams.finishUrl,
                    tokenParam: v2RedirectionParams.tokenParam,
                  },
                }}
              />
            )}
          />
          <PagedRoute v2bridge path="/v2redirection" page={V2RedirectionPage} />
        </Switch>
      ) : userToken ? (
        <DataLoader renderFailure={() => <Redirect to="/login" />}>
          <Switch>
            <Route
              path="/"
              component={() => <Redirect exact from="/" to="/assemblies" />}
              exact
            />
            {/* TODO: create a proper component for this page */}
            <PagedRoute
              path="/unauthorized"
              page={() => <div>NOPE NOPE !</div>}
              isPrivate
            />
            {/* //#region Assembly Live */}
            <PagedRoute
              path="/assemblies/:id/live/timesheet"
              page={AssemblyLiveTimesheetPage}
              isPrivate
              exact={false}
              grant={and(accessUserSomething, accessUserAssemblies)}
              layout={AssemblyLiveFullPageLayout}
              downloadPresenceSheet
              fluid={true}
            />
            <PagedRoute
              path="/assemblies/:id/live/participate"
              page={AssemblyLiveParticipatePage}
              isPrivate
              exact={false}
              grant={and(accessUserSomething, accessUserAssemblies)}
              layout={AssemblyLiveFullPageLayout}
            />
            <PagedRoute
              path="/assemblies/:id/live/resolutions/:ongoingResolutionIndex"
              isPrivate
              page={AssemblyLiveResolutionDetailsPage}
              layout={AssemblyLiveFullPageLayout}
              exact={false}
            />
            {/* //#endregion */}

            <PagedRoute
              path={'/assemblies/:id/details/convocation'}
              page={AssemblyConvocationsPage}
              isPrivate
              grant={and(accessUserSomething, accessUserAssemblies)}
              exact={false}
              layout={ConvocationPageLayout}
              title="Convocations"
            />

            <PagedRoute
              path={'/assemblies/:id/details/vpc'}
              page={AssembliesVPCPage}
              isPrivate
              grant={and(accessUserSomething, accessUserAssemblies)}
              exact={false}
              layout={AssemblyFullPageLayout}
              title="Votes par correspondance"
            />
            <PagedRoute
              path={'/assemblies/:id/details/copro/vpc/add'}
              page={AssembliesVPCPageCopro}
              isPrivate
              grant={and(accessUserSomething, accessUserAssemblies)}
              exact={true}
              isAdded={true}
              layout={AssemblyFullPageLayout}
              title="Votes par correspondance"
              useOwnContainer
            />
            <PagedRoute
              path={'/assemblies/:id/details/copro/vpc/view'}
              page={AssembliesVPCPageCopro}
              isPrivate
              isView={true}
              grant={and(accessUserSomething, accessUserAssemblies)}
              exact={true}
              layout={AssemblyFullPageLayout}
              title="Votes par correspondance"
              useOwnContainer
            />
            <PagedRoute
              path={'/assemblies/:id/details/copro/vpc/edit'}
              page={AssembliesVPCPageCopro}
              isPrivate
              isEdited={true}
              grant={and(accessUserSomething, accessUserAssemblies)}
              exact={true}
              layout={AssemblyFullPageLayout}
              title="Votes par correspondance"
              useOwnContainer
            />
            <PagedRoute
              path="/assemblies/:id/minutes"
              page={AssemblyMinutesPage}
              isPrivate
              grant={and(accessUserSomething, accessUserAssemblies)}
              exact={false}
              layout={AssemblyFullPageLayout}
              title="Procès Verbal"
              useOwnContainer
              useOverflow
            />
            <PagedRoute
              path="/assemblies/closed"
              page={AssembliesClosedPage}
              isPrivate
              exact={false}
            />
            <PagedRoute
              path="/assemblies/:id/details"
              page={AssembliesDetailsPage}
              isPrivate
              grant={and(accessUserSomething, accessUserAssemblies)} // todo check can access plaints
              bgLight
              exact={false}
            />

            <PagedRoute
              path="/assemblies"
              page={AssembliesPage}
              isPrivate
              grant={and(accessUserSomething, accessUserAssemblies)} // todo check can access plaints
              exact={false}
              layout={NavigationLayout}
            />

            <MemberPageRoutes />

            <PagedRoute page={NoMatchPage} exact={false} isPrivate />
          </Switch>

          <ModaledPageRoute
            size="xl"
            parentPath="/dashboard"
            path="/dashboard/assemblies/add"
            modal={AssembliesAddModalPage}
            isPrivate
          />

          <ModaledPageRoute
            parentPath="/assemblies/:id/details/convocation?reload"
            path={`/assemblies/:id/details/convocation/regenerate`}
            modal={AssemblyConvocationRegenerateModalPage}
            isPrivate
          />

          <ModaledPageRoute
            parentPath="/assemblies/:id/details/convocation"
            path={`/assemblies/:id/details/convocation/quote/parameter`}
            modal={AssemblyConvocationQuoteParameterModalPage}
            isPrivate
          />

          <ModaledPageRoute
            parentPath="/assemblies/:id/details/convocation"
            path={`/assemblies/:id/details/convocation/confirmation`}
            modal={AssemblyConvocationSendModalPage}
            isPrivate
          />
        </DataLoader>
      ) : (
        !userToken && (
          <Route
            path="/(assemblies*)"
            component={() => <Redirect to="/login" />}
          />
        )
      )}
      <PagedRoute page={NoMatchPage} exact={false} />
    </Switch>
  );
};

const WithSentryProfilerAppSwitch = withProfiler(AppSwitch);

const AppSwitchWithSentry = (props) => {
  useSentry();
  return <WithSentryProfilerAppSwitch {...props} />;
};

const AppRouter = ({ layout, userToken }) => {
  return (
    <ErrorBound renderError={ErrorPage}>
      <React.Suspense fallback={null}>
        <Router>
          <AppSwitchWithSentry userToken={userToken} />
        </Router>
      </React.Suspense>
      <MZoomModal />
    </ErrorBound>
  );
};

export default AppRouter;
