import { Popover as BPopover } from 'bootstrap/dist/js/bootstrap.bundle';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef, useState } from 'react';

/**
 * @example
 * const Trigger = React.forwardRef(({ popover }, ref) => (
      <div ref={ref} />
  ));
  <Popover trigger={Trigger}... />
 * @param {Renderable} trigger - with forwarded ref - the node that will be used by bootsrap to mount the popover
 *
 */
function Popover({ trigger: Trigger, content, title }) {
  const $container = useRef();
  const $content = useRef();
  const $title = useRef();
  const [popover, setPopover] = useState();
  useLayoutEffect(() => {
    setPopover(
      new BPopover($container.current, {
        html: true,
        content: $content.current,
        title: $title.current,
        trigger: 'focus',
      }),
    );
  }, [content, title, Trigger, $container]);
  useLayoutEffect(() => {
    if (popover) {
      const hide = () => popover.hide();
      $container.current.addEventListener('show.bs.popover', () => {
        document.body.addEventListener('mousedown', hide);
        document.body.addEventListener('focusout', hide);
      });
      $container.current.addEventListener('hidden.bs.popover', () => {
        document.body.removeEventListener('mousedown', hide);
        document.body.removeEventListener('focusout', hide);
      });
    }
    return () => {
      if (popover) {
        popover.hide();
      }
    };
  }, [popover]);
  return (
    <>
      <Trigger popover={popover} ref={$container} />
      <div className="d-none">
        <span ref={$title}>{title}</span>
        <span ref={$content}>{content}</span>
      </div>
    </>
  );
}

Popover.propTypes = {
  trigger: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired,
};

export default Popover;
