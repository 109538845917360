import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { GREY_900 } from 'styles/constant';

const linkStyle = css`
  display: grid;
  grid-template-columns: 14px 1fr;
  align-items: center;
  grid-column-gap: 12px;
  cursor: pointer;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-decoration: none;
  &:hover {
    border-radius: 4px;
    opacity: 1;
    background-color: rgba(233, 236, 239, 1);
  }

  &.active {
    border-radius: 4px;
    opacity: 1;
    background-color: rgba(255, 235, 208, 1);
    & span {
      color: ${GREY_900};

      font-weight: 600;
    }
  }
`;

export const MenuLinkWrapper = styled(Link)<{ $hasIcon: boolean }>`
  ${linkStyle}
  grid-template-columns: ${({ $hasIcon }) => ($hasIcon ? '14px 1fr' : '1fr')};
`;

export const A = styled.a<{ $hasIcon: boolean }>`
  ${linkStyle}
  grid-template-columns: ${({ $hasIcon }) => ($hasIcon ? '14px 1fr' : '1fr')};
`;
export const MenuLabel = styled.span`
  opacity: 1;
  color: rgba(33, 37, 41, 1);

  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
`;

export const ImageWrapper = styled.div`
  & img {
    display: block;
    width: 100%;
  }
`;
