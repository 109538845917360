export const ASSEMBLY_PARTICIPANT_STATUS = {
  NONE: 'none',
  IN_PERSON: 'physical',
  REMOTE_CONTROL: 'remote_control',
  REMOTE: 'online',
  POWER_GIVEN: 'represented',
  MISSING: 'missing',
};

export const ASSEMBLY_PARTICIPANT_LEGACY_STATUS = {
  NONE: 0,
  IN_PERSON: 1,
  REMOTE: 2,
  POWER_GIVEN: 3,
  REMOTE_CONTROL: 4,
};

export function convertAssemblyParticipantStatusToLegacyStatus(
  status: string,
): number | null {
  switch (status) {
    case ASSEMBLY_PARTICIPANT_STATUS.NONE:
      return null;
    case ASSEMBLY_PARTICIPANT_STATUS.IN_PERSON:
      return ASSEMBLY_PARTICIPANT_LEGACY_STATUS.IN_PERSON;
    case ASSEMBLY_PARTICIPANT_STATUS.REMOTE_CONTROL:
      return ASSEMBLY_PARTICIPANT_LEGACY_STATUS.REMOTE_CONTROL;
    case ASSEMBLY_PARTICIPANT_STATUS.REMOTE:
      return ASSEMBLY_PARTICIPANT_LEGACY_STATUS.REMOTE;
    case ASSEMBLY_PARTICIPANT_STATUS.POWER_GIVEN:
      return ASSEMBLY_PARTICIPANT_LEGACY_STATUS.POWER_GIVEN;
    case ASSEMBLY_PARTICIPANT_STATUS.MISSING:
      return ASSEMBLY_PARTICIPANT_LEGACY_STATUS.NONE;
  }

  return null;
}

export function convertAssemblyParticipantLegacyStatusToStatus(
  status: number,
): string {
  switch (status) {
    case ASSEMBLY_PARTICIPANT_LEGACY_STATUS.NONE:
      return ASSEMBLY_PARTICIPANT_STATUS.MISSING;
    case ASSEMBLY_PARTICIPANT_LEGACY_STATUS.IN_PERSON:
      return ASSEMBLY_PARTICIPANT_STATUS.IN_PERSON;
    case ASSEMBLY_PARTICIPANT_LEGACY_STATUS.REMOTE:
      return ASSEMBLY_PARTICIPANT_STATUS.REMOTE;
    case ASSEMBLY_PARTICIPANT_LEGACY_STATUS.POWER_GIVEN:
      return ASSEMBLY_PARTICIPANT_STATUS.POWER_GIVEN;
  }

  return ASSEMBLY_PARTICIPANT_STATUS.MISSING;
}
