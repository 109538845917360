import React from 'react';
import { BG_GREY, BG_RED, DARK_TEXT, GREY_100, RED } from 'styles/constant';
import { Box, Span, StyledBadge, WarningIcon } from 'ui-kit';

import { IconWrapper, ProfileRendererWrapper } from './profile-renderer.style';

type ProfileRendererProps = {
  className?: string;
  is_owner: boolean;
  is_tenant: boolean;
};

const ProfileRenderer: React.FC<ProfileRendererProps> = ({
  className,
  is_owner,
  is_tenant,
}) => {
  const getBadgeProps = (): {
    label: string;
    bgColor: string;
    color: string;
    icon?: React.ReactNode;
  } => {
    if (is_owner) {
      return { label: 'Propriétaire', bgColor: BG_GREY, color: DARK_TEXT };
    }
    if (is_tenant) {
      return { label: 'Locataire', bgColor: GREY_100, color: DARK_TEXT };
    }
    return {
      label: 'Sans lot',
      bgColor: BG_RED,
      color: RED,
      icon: <WarningIcon className="text-danger ms-1 font-xs" size="xs" />,
    };
  };

  const { label, bgColor, color, icon } = getBadgeProps();
  return (
    <ProfileRendererWrapper className={`${className || ''}`}>
      <StyledBadge
        backgroundColor={bgColor}
        fontSize="10px"
        fontWeight="700"
        color={color}
        pt="1px"
        pr="6px"
        pb="1px"
        pl="5px"
      >
        {icon ? (
          <Box display="flex" gap="5px" alignItems="center">
            <IconWrapper>{icon}</IconWrapper>

            <Span fontSize="10px" fontWeight="700" color={color}>
              {label}
            </Span>
          </Box>
        ) : (
          <>{label}</>
        )}
      </StyledBadge>
    </ProfileRendererWrapper>
  );
};

export default React.memo(ProfileRenderer);
