import React from 'react';
import styled from 'styled-components';

import { ModalProps } from './modal.type';

/* used to render submit button of form - SEE: React.portal doc*/
const PORTAL_ID = 'modal-portal-action';

const Wrapper = styled.div`
  overflow: auto;
`;

function Modal({
  title,
  menu,
  footer,
  withFooter = true,
  onClose,
  className = '',
  customModalWrapper,
  children,
  style,
}: ModalProps) {
  const ModalWrapper = customModalWrapper || Wrapper;
  return (
    <ModalWrapper>
      <div
        className={`modal-header tw-border-none align-items-start ${className}`}
        style={style}
      >
        <div className="modal-title font-weight-semi-bold">{title}</div>
        <div className="mt-2 d-flex align-items-center">
          {menu && <div className=" d-flex flex-row-reverse me-2">{menu}</div>}
          <button
            onClick={onClose}
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div className="modal-body">{children}</div>
      {withFooter && (
        <div className={`modal-footer tw-border-none footer-${className}`}>
          {footer}
          {/* used to render submit button of form */}
          <div id={PORTAL_ID} className="w-100" />
        </div>
      )}
    </ModalWrapper>
  );
}

Modal.portalId = PORTAL_ID;

export default Modal;
