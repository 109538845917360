import { setMinutesStep } from 'pills/session/session.minutes.actions';
import {
  getCurrentStep,
  MinutesStepsById,
} from 'pills/session/session.minutes.type';
import { useDispatch } from 'react-redux';

const useInitMinutesSteps = ({
  assemblyId,
  steps,
  minutesStatus,
}: {
  assemblyId: string;
  steps: MinutesStepsById;
  minutesStatus: number | undefined;
}) => {
  const dispatch = useDispatch();
  if (minutesStatus !== undefined) {
    const currentStep = getCurrentStep(minutesStatus);
    if (!steps) {
      dispatch(
        setMinutesStep({
          step: currentStep,
          assemblyId,
        }),
      );
    }
  }
};

export const useSetMinutesStep = ({
  assemblyId,
  step,
}: {
  assemblyId: string;
  step: number;
}) => {
  const dispatch = useDispatch();

  const setStep = () => {
    dispatch(
      setMinutesStep({
        step,
        assemblyId,
      }),
    );
  };

  return setStep;
};

export default useInitMinutesSteps;
