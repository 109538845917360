import { Address as AddressType } from 'pills/members/member.type';
import React from 'react';

import Address from './address';
import Email from './email';
import { MemberCoordinateWrapper } from './member-coordinate.style';
import PhoneNumber from './phone-number';

type MemberCoordinateProps = {
  className?: string;
  address: AddressType;
  email: string | null;
  phone: string;
};

const MemberCoordinate: React.FC<MemberCoordinateProps> = ({
  className,
  address,
  email,
  phone,
}) => {
  return (
    <MemberCoordinateWrapper className={`${className || ''}`}>
      <PhoneNumber phone={phone} />
      <Email email={email} />
      <Address address={address} />
    </MemberCoordinateWrapper>
  );
};

export default MemberCoordinate;
