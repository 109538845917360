import { createSelector } from 'reselect';

import { entitiesSelector } from '../entities/entities.selectors';
import { ROLES } from '../users/users.constants';

export const authSelector = createSelector(entitiesSelector, ({ Auth }) =>
  Auth.getQuerySet().last(),
);

export const authUserSelector = createSelector(authSelector, (auth) =>
  auth.user ? auth.user : {},
);

export const isLoggedSelector = createSelector(authUserSelector, (user) => {
  if (!user.roles) return false;
  return Boolean(user.roles[0] !== ROLES.ANONYME);
});

export const isAuthError = createSelector(authSelector, (auth) => {
  return auth.error;
});

export const authTokenSelector = createSelector(
  authSelector,
  (auth) => auth.token,
);

export const leaveUrlSelector = createSelector(
  authSelector,
  (auth) => auth.leaveUrl,
);

export const finishUrlSelector = createSelector(
  authSelector,
  (auth) => auth.finishUrl,
);

export const authUserResidenciesSelector = createSelector(
  authUserSelector,
  ({ residencies }) => residencies,
);

export const authUserResidencyByIdSelector = (residencyId) =>
  createSelector(authUserResidenciesSelector, (residencies) =>
    residencies.find((residency) => residency.id === residencyId),
  );
