import styled from 'styled-components';

import { GREEN_400, GREY_500, RED_400, YELLOW } from '../../../styles/constant';

const RESO_NOT_PROCESSED = '#6C757D';
const CURRENT_RESO = YELLOW;
const ACCEPTED_RESO = GREEN_400;
const REJECTED_RESO = RED_400;
const NO_VOTE_RESO = GREY_500;
export const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 50px 1fr 172px;
  grid-column-gap: 30px;
  align-items: center;
`;

export const LiveResolutionCounterWrapper = styled.div``;

export const LiveResolutionsNavigationBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const LiveResolutionItemWrapper = styled.div<{ pr?: string }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border: 1px solid ${RESO_NOT_PROCESSED};
  color: ${RESO_NOT_PROCESSED};
  border-radius: 14px;
  font-size: 14px;
  line-height: 28px;
  padding-right: ${({ pr }) => pr || undefined};
`;

export const LiveCurrentResolutionItemWrapper = styled(
  LiveResolutionItemWrapper,
)`
  background-color: ${CURRENT_RESO};
  border: 1px solid ${CURRENT_RESO};
`;
export const LiveRejectedResolutionItemWrapper = styled(
  LiveResolutionItemWrapper,
)`
  background-color: ${REJECTED_RESO};
  border: 1px solid ${REJECTED_RESO};
  color: white;
`;
export const LiveAcceptedResolutionItemWrapper = styled(
  LiveResolutionItemWrapper,
)`
  background-color: ${ACCEPTED_RESO};
  border: 1px solid ${ACCEPTED_RESO};
  color: white;
`;
export const LiveNoVoteResolutionItemWrapper = styled(
  LiveResolutionItemWrapper,
)`
  background-color: ${NO_VOTE_RESO};
  border: 1px solid ${NO_VOTE_RESO};
  color: white;
`;

export const LiveQuorumThresholdWrapper = styled.div<{ threshold?: string }>`
  border-left: 4px solid gray;
  height: 14px;
  position: absolute;
  left: ${({ threshold }) => threshold || undefined};
  border-radius: 5px;
  top: -2px;
`;
