import cs from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import useGetUniqId from '../hooks/useGetUniqId';
import classes from './rTooltip.module.css';

function RTooltip({
  title,
  place,
  effect,
  delayShow,
  delayHide,
  children,
  zIndex,
  isOnCLick = false,
  ...rest
}) {
  const randomId = useGetUniqId('tooltip-');
  return title ? (
    <>
      {children.props && !children.props.disabled ? (
        React.cloneElement(children, {
          'data-tip': true,
          'data-for': randomId,
          style: { zIndex },
        })
      ) : (
        // <span /> fixes the problem of tooltip compatibility on disabled children
        <span data-tooltip-id={randomId} style={{ zIndex }}>
          {children}
        </span>
      )}
      <ReactTooltip
        id={randomId}
        place={place}
        effect={effect}
        delayShow={delayShow}
        delayHide={delayHide}
        isCapture
        className={cs(classes.rTooltip)}
        event={isOnCLick && 'click'}
        {...rest}
      >
        {title}
      </ReactTooltip>
    </>
  ) : (
    <>{children}</>
  );
}

RTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  place: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  effect: PropTypes.oneOf(['solid', 'float']),
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
};

RTooltip.defaultProps = {
  effect: 'solid',
  delayShow: 200,
  delayHide: 200,
};

export default RTooltip;
