import NavigationLayout from 'pages/layouts/navigation-layout';
import { accessUserSomething, and } from 'pills/gate/gate.constants';
import React from 'react';
import PagedRoute from 'router/PagedRoute';

import { IndexModalPath } from '../index/member.modal.routes';
import { DetailModalPath } from '../member-details/member-details.modal.routes';

const MembersPage = React.lazy(() => import('../index/members.page'));
const MemberEditPage = React.lazy(
  () => import('../member-forms/member-edit.page'),
);
const MemberDetailsPage = React.lazy(
  () => import('../member-details/member-details.page'),
);

const MemberPageRoutes: React.FC = () => {
  const AnyPageRoute = PagedRoute as any;
  return (
    <>
      <AnyPageRoute
        path={['/member', ...IndexModalPath]}
        page={MembersPage}
        isPrivate
        grant={and(accessUserSomething)} // todo check can access plaints
        exact={true}
        layout={NavigationLayout}
      />

      <AnyPageRoute
        path={'/member/details/:id/edit'}
        page={MemberEditPage}
        isPrivate
        grant={and(accessUserSomething)}
        exact={true}
        layout={NavigationLayout}
      />

      <AnyPageRoute
        path={['/member/details/:id', DetailModalPath]}
        page={MemberDetailsPage}
        isPrivate
        grant={and(accessUserSomething)}
        exact={true}
        layout={NavigationLayout}
      />
    </>
  );
};

export default MemberPageRoutes;
