import styled, { css } from 'styled-components';

import { COLUMNS_GAP } from '../../member-details.style';

export const DetailedOwnershipWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 60px;
`;

export const ListingsWrapper = styled.div`
  display: flex;
  gap: ${COLUMNS_GAP};
`;

const flexColStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
export const Col1 = styled.div`
  ${flexColStyle}
`;
export const Col2 = styled.div`
  ${flexColStyle}
`;
