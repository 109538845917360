import { Model } from 'redux-orm';

import COMPANY_DEFAULT_AVATAR from '../images/company-default-avatar.svg';

class Company extends Model {
  constructor(data) {
    super({
      ...data,
      managers: Array.isArray(data.companyManagers)
        ? data.companyManagers.map((companyManager) => ({
            ...companyManager,
            ...companyManager.member,
          }))
        : [],
      admins: Array.isArray(data.companyAdmins)
        ? data.companyAdmins.map((companyAdmin) => ({
            ...companyAdmin,
            ...companyAdmin.member,
          }))
        : [],
    });
  }
  getAvatar() {
    return this.ref.avatar?.url || COMPANY_DEFAULT_AVATAR;
  }
  toString() {
    return `Company: ${this.name}`;
  }
}

Company.modelName = 'Company';

export default Company;
