import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function Icon({ icon, size = '1x', className, disabled, ...rest }) {
  return (
    <FontAwesomeIcon
      icon={icon}
      size={size}
      className={cs(className, 'fa-w-16', { disabled })} // same width for all icons
      {...rest}
    />
  );
}

Icon.propTypes = {
  icon: PropTypes.any.isRequired,
  size: PropTypes.oneOf([
    '10x',
    '7x',
    '5x',
    '3x',
    '2x',
    '1x',
    'lg',
    'sm',
    'xs',
  ]),
};

export default Icon;
