import { ORM } from 'redux-orm';

import Assembly from './assembly.model';
import AssemblyConvocation from './assemblyConvocation.model';
import AssemblyLawArticle from './assemblyLawArticle.model';
import AssemblyMajorityMode from './assemblyMajorityMode.model';
import AssemblyParticipant from './assemblyParticipant.model';
import AssemblyResolution from './assemblyResolution.model';
import AssemblyResolutionComment from './assemblyResolutionComment.model';
import AssemblyResolutionTemplate from './assemblyResolutionTemplate.model';
import AssemblyResolutionVote from './assemblyResolutionVote.model';
import Auth from './auth.model';
import Comment from './comment.model';
import Company from './company.model';
import Council from './council.model';
import DistributionKey from './distributionKey.model';
import Document from './document.model';
import DocumentType from './documentType.model';
import File from './file.model';
import Member from './member.model';
import Parcel from './parcel.model';
import ParcelDistributionKey from './parcelDistributionKey.model';
import ParcelType from './parcelType.model';
import Proabono from './proabono.model';
import Residency from './residency.model';
import User from './user.model';

const orm = new ORM();

orm.register(User);
orm.register(Member);
orm.register(Residency);
orm.register(Parcel);
orm.register(ParcelType);

orm.register(Comment);
orm.register(Company);
orm.register(Council);
orm.register(Auth);
orm.register(Document);
orm.register(DocumentType);
orm.register(Assembly);
orm.register(AssemblyConvocation);
orm.register(AssemblyResolutionTemplate);
orm.register(AssemblyResolution);
orm.register(AssemblyResolutionVote);
orm.register(AssemblyResolutionComment);
orm.register(AssemblyLawArticle);
orm.register(AssemblyMajorityMode);
orm.register(AssemblyParticipant);
orm.register(File);
orm.register(DistributionKey);
orm.register(ParcelDistributionKey);
orm.register(Proabono);

export default orm;
