import React from 'react';

function BetaBadge({ soon, children }) {
  return (
    <div className="position-relative">
      <div className="d-flex justify-content-start align-items-start">
        {children}
        <span
          style={{ fontSize: 'xx-small' }}
          className="badge rounded-pill bg-warning me-2"
          title={soon ? 'Bientôt disponible ⌛' : 'beta'}
        >
          {soon ? 'S' : 'beta'}
        </span>
      </div>
    </div>
  );
}

export default BetaBadge;
