import { Model } from 'redux-orm';

class ParcelType extends Model {
  toString() {
    return `ParcelType: ${this.name}`;
  }
}

ParcelType.modelName = 'ParcelType';
export default ParcelType;
