import styled from 'styled-components';

export const AvatarWrapper = styled.div`
  position: relative;
  & div.avatar-concil-member {
    padding: 2px 16px 2px 10px;
    opacity: 1;

    font-size: 10px;
    font-weight: 500;

    letter-spacing: 0px;
  }
`;

export const Outline = styled.div<{ width: number; borderOffset: number }>`
  border: 2px solid rgba(44, 137, 219, 1);
  position: absolute;
  top: ${({ borderOffset }) => `-${borderOffset}px`};
  bottom: ${({ borderOffset }) => `-${borderOffset}px`};
  left: ${({ borderOffset }) => `-${borderOffset}px`};
  right: ${({ borderOffset }) => `-${borderOffset}px`};
  border-radius: 50%;
  width: ${({ width, borderOffset }) => `${width + 2 * borderOffset}px`};
  height: ${({ width, borderOffset }) => `${width + 2 * borderOffset}px`};
`;

export const Span = styled.span`
  color: #fff;

  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
`;

export const DefaultAvatarWrapper = styled.div<{
  backgroundColor: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
