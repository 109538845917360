import { createSelector } from 'reselect';

import { entitiesSelector } from '../entities/entities.selectors';

export const usersSelector = createSelector(entitiesSelector, ({ User }) =>
  User.all().toModelArray(),
);

export const userByIdSelector = (id) =>
  createSelector(entitiesSelector, ({ User }) => User.withId(id));
