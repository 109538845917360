import PropTypes from 'prop-types';
import React from 'react';

import { SpinnerIcon } from '.';
import Button from './button';

/**
 *
 * This component allows you to submit a form without having to be inside the form.
 * We can therefore create several buttons submitting a single form
 *
 * TODO: The modal closes on submission, not on success of the request
 * TODO2: Also, put the loader there as on the classic submit button on submitting
 */

function ButtonSubmitOutsideForm({ formId, text, onClick, ...props }) {
  const [clicked, setClicked] = React.useState(false);
  if (clicked) {
    return (
      <div className="btn btn-primary">
        <SpinnerIcon />
      </div>
    );
  }
  return (
    <Button
      type="submit"
      form={formId}
      {...props}
      onClick={() => {
        setClicked(true);
        document
          .getElementById(formId)
          .dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true }),
          );
      }}
    >
      {text}
    </Button>
  );
}

ButtonSubmitOutsideForm.propTypes = {
  formId: PropTypes.string,
  text: PropTypes.string,
};

ButtonSubmitOutsideForm.defaultProps = {
  text: 'Continuer',
};

export default ButtonSubmitOutsideForm;
