import { DATE_FORMATS, localizedFormat } from 'date.utils';
import React from 'react';
import { DARK_TEXT } from 'styles/constant';
import { Box, CrossCircleIcon, GreenCheckCircle, Span } from 'ui-kit';

import { MemberStatusWrapper } from './member-status.style';

type MemberStatusProps = {
  className?: string;
  userEnabled: boolean;
  lastLogin: string;
  registeredEmailConsent: boolean;
  passwordRequestedAt?: string;
};

const getDate = (timestamp: string) => {
  const date = new Date(timestamp);
  return localizedFormat(date, DATE_FORMATS.dayMonthYear);
};

const MemberStatus: React.FC<MemberStatusProps> = ({
  className,
  userEnabled,
  lastLogin,
  registeredEmailConsent,
  passwordRequestedAt,
}) => {
  return (
    <MemberStatusWrapper className={`${className || ''}`}>
      {userEnabled && lastLogin && (
        <Span
          fontSize="14px"
          color={DARK_TEXT}
        >{`Dernière connexion le ${getDate(lastLogin)}`}</Span>
      )}
      {!userEnabled && passwordRequestedAt && (
        <Span
          fontSize="14px"
          color={DARK_TEXT}
        >{`Dernier envoi d'email d'activation le ${getDate(
          passwordRequestedAt,
        )}`}</Span>
      )}
      <EREConsent registeredEmailConsent={registeredEmailConsent} />
    </MemberStatusWrapper>
  );
};

export const EREConsent = ({
  registeredEmailConsent,
}: {
  registeredEmailConsent: boolean;
}) => {
  return (
    <Box display="flex" gap="10px" className="ere-consent">
      <Span fontSize="14px" color={DARK_TEXT}>
        Consentement ERE
      </Span>
      {registeredEmailConsent ? (
        <>
          <GreenCheckCircle />
        </>
      ) : (
        <>
          <CrossCircleIcon />
        </>
      )}
    </Box>
  );
};

export const LastConnection = ({
  userEnabled,
  lastLogin,
  lastActivationEmail,
}: {
  userEnabled: boolean;
  lastLogin: string | null;
  lastActivationEmail: string | null;
}) => {
  const label =
    userEnabled && lastLogin ? (
      `Dernière connexion le ${getDate(lastLogin)}`
    ) : lastActivationEmail ? (
      `Email d’activation envoyé le ${getDate(lastActivationEmail || '')}`
    ) : (
      <span className="tw-text-sm tw-text-dark">Non renseigné</span>
    );
  return (
    <Span fontSize="14px" color={DARK_TEXT}>
      {label}
    </Span>
  );
};

export default MemberStatus;
