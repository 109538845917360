import { isBefore } from 'date-fns';
import { isValidPhoneNumber as isValidLibPhoneNumber } from 'libphonenumber-js';
import * as EmailValidator from 'email-validator';

import { flatten } from '../utils';

export const optional = () => undefined;

export const required = (value) =>
  value !== null && value !== undefined && _.trim(value).length !== 0
    ? undefined
    : 'required';

export const regexTest = (regex, error) => (value) => {
  return value === null ||
    value === '' ||
    value === undefined ||
    regex.test(value)
    ? undefined
    : error;
};

export const minLength = (len) =>
  regexTest(
    new RegExp(`(\\w|\\W){${len},}$`, 'i'),
    `Must not be less than ${len}`,
  );

export const maxLength = (len) => (value) =>
  value === null ||
  value === undefined ||
  new RegExp(`^(\\w|\\W){0,${len}}$`, 'i').test(value)
    ? undefined
    : `Must not be more than ${len}`;

export const withMinAlpha = (len) =>
  regexTest(
    new RegExp(`(.*[a-z]){${len},}.*`, 'i'),
    `Must contain at least ${len} alphabetic char`,
  );

export const withMinNumeric = (len) =>
  regexTest(
    new RegExp(`(.*\\d){${len},}.*`, 'i'),
    `Must contain at least ${len} numeric char`,
  );

export const withMinSpecial = (len) =>
  regexTest(
    new RegExp(`\\W{${len},}`, 'i'),
    `Must contain at least ${len} special char`,
  );

export const withMinUpper = (len) =>
  regexTest(
    new RegExp(`(.*[A-Z]){${len},}.*`),
    `Must contain at least ${len} uppercased char`,
  );

export const withMinLower = (len) =>
  regexTest(
    new RegExp(`(.*[a-z]){${len},}.*`),
    `Must contain at least ${len} lowercase char`,
  );

export const isValidEmail = (value) => {
  if (!value) {
    return undefined;
  }

  return EmailValidator.validate(value) ? undefined : 'Invalid Email';
};

export const isValidPhoneNumber = (value) => {
  if (!value) {
    return undefined;
  }

  return isValidLibPhoneNumber(value, 'FR')
    ? undefined
    : 'Invalid Phone Number';
};

export const combineValidators =
  (...validators) =>
  (values, allValues, meta) =>
    flatten([validators]).reduce(
      (acc, p) => p(values, allValues, meta) || acc,
      undefined,
    );

export const minDate = (date) => (value) =>
  isBefore(new Date(value), new Date(date))
    ? 'La date de début doit être postérieure à la date du jour.'
    : undefined;

export const notInList = (list) => (value, allValues, meta) => {
  if (value === undefined || value === '') {
    return undefined;
  }

  if (list.includes(value)) {
    return 'must be uniq';
  }

  return undefined;
};

export const disabledMajority = (assemblyMajority) => (value) => {
  return assemblyMajority?.enabled === false && assemblyMajority?.id === value
    ? 'required'
    : undefined;
};
