import PropTypes from 'prop-types';
import React from 'react';

/**
 * transform plain text (with \n) to html (with <br />)
 * One day this component will be a markdown render ?
 */
function PlainText({ text }) {
  return (
    <>
      {typeof text === 'string'
        ? text
            .split('\n\n')
            .map((paragraph, i) => (
              <p key={i}>
                {paragraph
                  .split('\n')
                  .reduce((total, line, j) => [total, <br key={j} />, line])}
              </p>
            ))
        : text}
    </>
  );
}

PlainText.propTypes = {
  text: PropTypes.any,
};

export default PlainText;
