import { usersApi } from 'api/base.api';
import { QUERY_KEYS } from 'api/queryKeys';
import { useQuery, useQueryClient } from 'react-query';

import { InvitableUser } from './type';

type UseInvitableUsersProps = {
  residencyId: string | null;
};

const getInvitableUsersById = (
  residencyId: string | null,
): Promise<InvitableUser[]> => {
  return usersApi.getInvitableUsersByResidencyId({ id: residencyId });
};

export const getInvitableUsersKey = (residencyId: string | null) => {
  return [QUERY_KEYS.invitableUsers, residencyId];
};

export function useInvitableUsers({ residencyId }: UseInvitableUsersProps) {
  const { data, isLoading, isFetching } = useQuery(
    getInvitableUsersKey(residencyId),
    () => getInvitableUsersById(residencyId),
  );

  return { data, isLoading, isFetching };
}

export const usePrefetchInvitableUsers = ({
  residencyId,
}: {
  residencyId: string;
}) => {
  const queryClient = useQueryClient();

  queryClient.prefetchQuery(getInvitableUsersKey(residencyId), () =>
    getInvitableUsersById(residencyId),
  );
};
