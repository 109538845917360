import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 30_000,
      retry(failureCount: number, error: any) {
        if (error.status === 404) return false;
        return failureCount < 2;
      },
    },
  },
});
