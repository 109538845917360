import { Toast } from 'bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';

export enum NotifyToastPosition {
  topRight = 'topRight',
  bottomLeft = 'bottomLeft',
}

type NotifyOptions = {
  closable: boolean;
  position: NotifyToastPosition;
  autoHide?: boolean;
};

let notficationCounter = 0;

const createNotification =
  (
    className: string,
    notifOptions: NotifyOptions = {
      closable: false,
      position: NotifyToastPosition.topRight,
    },
  ) =>
  (message: string | JSX.Element, title?: string) => {
    const notificationId = `notifications-${notifOptions.position}`;
    const $notifications = document.getElementById(notificationId);
    if (!$notifications) {
      return;
    }
    const $notification = document.createElement('div');
    const internalCounter: number = notficationCounter++;
    $notification.id = `${notificationId}-${internalCounter}`;
    $notifications.appendChild($notification);
    ReactDOM.render(
      <div
        id={`${notificationId}-${internalCounter}-toast`}
        className={`toast ${className}`}
        role="alert"
      >
        <div className="toast-body">
          {title && (
            <>
              <div className="d-flex justify-content-between">
                <b>{title}</b>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                ></button>
              </div>
              <br />
              {message}
            </>
          )}
          {!title && (
            <div className="d-flex justify-content-between">
              {message}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
              ></button>
            </div>
          )}
        </div>
      </div>,
      $notification,
      () => {
        const toastElement = document.getElementById(
          `${notificationId}-${internalCounter}-toast`,
        );
        if (toastElement) {
          if (notifOptions?.autoHide !== undefined) {
            const toast = new Toast(toastElement, {
              autohide: notifOptions.autoHide,
            });
            toast.show();
          } else {
            const toast = new Toast(toastElement, { autohide: true });
            toast.show();
          }
        }
      },
    );
  };

const Notify = {
  success: (
    text: string | JSX.Element,
    title: string | undefined = undefined,
    toastOptions: NotifyOptions = {
      closable: true,
      position: NotifyToastPosition.topRight,
    },
  ) => createNotification('bg-success text-white', toastOptions)(text, title),
  error: (
    text: string | JSX.Element,
    title: string | undefined = undefined,
    toastOptions: NotifyOptions = {
      closable: true,
      position: NotifyToastPosition.topRight,
    },
  ) => createNotification('bg-danger text-white', toastOptions)(text, title),
  closableInfoTopRight: (
    text: string | JSX.Element,
    title: string | undefined = undefined,
    toastOptions: NotifyOptions = {
      closable: true,
      position: NotifyToastPosition.topRight,
    },
  ) => createNotification('bg-info text-white', toastOptions)(text, title),
  closableInfoBottomLeft: (
    text: string | JSX.Element,
    title: string | undefined = undefined,
    toastOptions: NotifyOptions = {
      closable: true,
      position: NotifyToastPosition.bottomLeft,
      autoHide: false,
    },
  ) => createNotification('bg-info text-white', toastOptions)(text, title),
  primary: (
    text: string | JSX.Element,
    title: string | undefined = undefined,
    toastOptions: NotifyOptions = {
      closable: true,
      position: NotifyToastPosition.topRight,
    },
  ) => createNotification('bg-primary text-black', toastOptions)(text, title),
  warning: (
    text: string | JSX.Element,
    title: string | undefined = undefined,
    toastOptions: NotifyOptions = {
      closable: true,
      position: NotifyToastPosition.topRight,
      autoHide: false,
    },
  ) => createNotification('bg-warning text-white', toastOptions)(text, title),
};

export default Notify;
