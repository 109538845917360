import { companiesApi } from '../../api/base.api';

export async function getCurrentCompany() {
  const currentCompany = await companiesApi.getCurrent();
  currentCompany.current = true;
  return currentCompany;
}

export async function updateCompany(company) {
  await companiesApi.update(company);
  return companiesApi.getCurrent();
}

export async function switchCompany(company) {
  await companiesApi.switch({ newCompanyId: company });
  return companiesApi.getCurrent();
}

export async function revokeCurrentCompany(company) {
  await companiesApi.updateCurrent(company);
  return companiesApi.getCurrent();
}

export async function addManagerCurrentCompany(company) {
  await companiesApi.updateCurrent(company);
  return companiesApi.getCurrent();
}
