/* eslint-disable no-unused-labels */
/* eslint-disable no-label-var */
/* eslint-disable no-labels */

import config from './api/config';
import { LS_KEY } from './redux-utils/localState';
// Invalide the localstorage if the current version is greater than the old one
ver: {
  const ver = process.env.REACT_APP_VERSION;
  const sentryIsActive = process.env.REACT_APP_SENTRY_IS_ACTIVE;
  const verKey = 'SYMENT:version';
  if (ver) {
    console.log('VERSION : ', process.env.REACT_APP_VERSION);
    const currentVersion = parseInt(ver.split('.').join(''));
    const oldVersion = parseInt(
      (localStorage.getItem(verKey) || '0').split('.').join(''),
    );
    if (currentVersion > oldVersion) {
      localStorage.removeItem(LS_KEY);
      localStorage.setItem(verKey, ver);
    }
  }
  if (sentryIsActive) {
    console.log('SENTRY : ACTIVE');
  } else {
    console.log('SENTRY : INACTIVE');
  }
}
// display configuration for debugging purpose
config: {
  console.info('API_CONFIGURATION: ', config);
}

info: {
  console.info('CURRENT NAVIGATOR LANGUAGE:', navigator?.language);
}
