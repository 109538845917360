import styled from 'styled-components';

import { GREY_100 } from '../styles/constant';

export const StyledInput = styled.input`
  background-color: ${GREY_100};

  border: none;

  outline: none;
  &:focus,
  &:active {
  }
`;
