import AssemblyResolution from '../../redux-orm-models/assemblyResolution.model';
// // NOTE: As this reducer is 'integrated' o the orm reducer, it receive the session and not the entire state
import { ACTION_TYPES as EntitiesActionTypes } from '../entities/entities.actions';

const syncAssemblyResolutionParentAndChildrenAndReopened = ({
  assemblyResolution,
  AssemblyResolution,
}) => {
  if (assemblyResolution.secondVoteResolution?.id) {
    AssemblyResolution.upsert({
      ...assemblyResolution.secondVoteResolution,
      parentResolution: assemblyResolution,
      reopenedVoteResolution: assemblyResolution.reopenedVoteResolution,
      assemblyId: assemblyResolution.assemblyId,
    });
  }
  if (assemblyResolution.parentResolution?.id) {
    AssemblyResolution.upsert({
      ...assemblyResolution.parentResolution,
      secondVoteResolution: assemblyResolution,
      reopenedVoteResolution:
        assemblyResolution.parentResolution.reopenedVoteResolution,
      assemblyId: assemblyResolution.assemblyId,
    });
  }
  if (assemblyResolution.initialVoteResolution?.id) {
    AssemblyResolution.upsert({
      ...assemblyResolution.initialVoteResolution,
      reopenedVoteResolution: assemblyResolution,
      assemblyId: assemblyResolution.assemblyId,
    });
  }
  if (assemblyResolution.reopenedVoteResolution?.id) {
    AssemblyResolution.upsert({
      ...assemblyResolution.reopenedVoteResolution,
      initialVoteResolution: assemblyResolution,
      assemblyId: assemblyResolution.assemblyId,
    });
  }
  if (assemblyResolution.reopenedVoteResolution?.secondVoteResolution?.id) {
    AssemblyResolution.upsert({
      ...assemblyResolution.reopenedVoteResolution.secondVoteResolution,
      reopenedVoteResolution: assemblyResolution.reopenedVoteResolution,
      initialVoteResolution: assemblyResolution,
      assemblyId: assemblyResolution.assemblyId,
    });
  }
  if (assemblyResolution.reopenedVoteResolution?.parentResolution?.id) {
    AssemblyResolution.upsert({
      ...assemblyResolution.reopenedVoteResolution.parentResolution,
      secondVoteResolution: assemblyResolution.reopenedVoteResolution,
      initialVoteResolution: assemblyResolution,
      assemblyId: assemblyResolution.assemblyId,
    });
  }
};

const reducer = (orm) => (state, action) => {
  const { type, payload } = action;
  const session = orm.session(state);
  if (payload?.entity === AssemblyResolution.modelName) {
    const { attributes: assemblyResolution } = payload;
    const { attributes: assemblyResolutions } = payload;
    const { AssemblyResolution } = session;
    switch (type) {
      case EntitiesActionTypes.SYNC: {
        assemblyResolutions.forEach((assemblyResolution) =>
          syncAssemblyResolutionParentAndChildrenAndReopened({
            assemblyResolution,
            AssemblyResolution,
          }),
        );
        break;
      }
      case EntitiesActionTypes.UPSERT:
        if (!assemblyResolution.assemblyId) {
          console.info(
            "WHAAAT ? Why assemblyResolution hasn't an assemblyId ???? ",
          );
        }
        break;
      case EntitiesActionTypes.UPDATE:
        syncAssemblyResolutionParentAndChildrenAndReopened({
          assemblyResolution,
          AssemblyResolution,
        });
        break;
      default:
        break;
    }
  }
  return session.state;
};

export default reducer;
