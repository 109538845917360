import { Model, fk } from 'redux-orm';

import { getId } from '../utils';
import Assembly from './assembly.model';
import Member from './member.model';
import { ASSEMBLY_PARTICIPANT_LEGACY_STATUS } from '../pills/assemblyParticipants/assemblyParticipants.constants';

class AssemblyParticipant extends Model {
  constructor(data) {
    super({
      ...data,
      memberId: getId(data.member),
      powerGivenToMemberId: data.proxyParticipant
        ? getId(data.proxyParticipant)
        : null,
      powerGivenTo: data.proxyParticipant?.member ?? null,
    });
  }

  isRemote() {
    return this.ref.status === ASSEMBLY_PARTICIPANT_LEGACY_STATUS.REMOTE;
  }

  isEarlyVote() {
    return this.ref.hasEarlyVoted;
  }

  isNone() {
    return (
      this.ref.status === ASSEMBLY_PARTICIPANT_LEGACY_STATUS.NONE ||
      this.ref.status === null
    );
  }

  isInPerson() {
    return (
      this.ref.status === ASSEMBLY_PARTICIPANT_LEGACY_STATUS.IN_PERSON ||
      this.ref.status === ASSEMBLY_PARTICIPANT_LEGACY_STATUS.REMOTE_CONTROL
    );
  }

  isPowerGiven() {
    return this.ref.status === ASSEMBLY_PARTICIPANT_LEGACY_STATUS.POWER_GIVEN;
  }

  isReceivingPower() {
    return this.ref.proxyMetadata?.count > 0;
  }

  isOnline() {
    return this.isRemote();
  }

  get proxyParticipant() {
    return this.ref.proxyParticipant;
  }

  cannotVoteOnline() {
    return this.isInPerson() || this.isPowerGiven();
  }

  static getName(member) {
    return `${member?.lastName} ${member?.firstName}`;
  }
}

AssemblyParticipant.modelName = 'AssemblyParticipant';
AssemblyParticipant.fields = {
  assemblyId: fk({
    to: Assembly.modelName,
    as: 'assembly',
    relatedName: 'assemblieParticipantsSet',
  }),
  memberId: fk({
    to: Member.modelName,
    as: 'member',
    relatedName: '_assemblieParticipantsSet',
  }),
};
export default AssemblyParticipant;
