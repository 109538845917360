import styled from 'styled-components';

export const ModalWrapper = styled.div`
  & > div.modal-content > div.modal-header {
    border-bottom: 0px;
    padding-bottom: 8px;
    & > h5 {
      font-weight: bold;
    }
  }
  & > div.modal-content > div.modal-body {
    padding-bottom: 0px;
  }
  & > div.modal-content > div.modal-footer {
    border-top: 0px;
    padding-top: 8px;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
`;
