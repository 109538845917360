import { Model } from 'redux-orm';

class File extends Model {
  get extension() {
    return this.ref.name.split('.').pop() || '';
  }
  toString() {
    return `File: ${this.name}`;
  }
}

File.modelName = 'File';
export default File;
