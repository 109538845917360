import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import styled from 'styled-components';
import { RED } from 'styles/constant';

import Icon from './icon';

const ExclamationTriangleIcon = (props) => (
  <Icon icon={faExclamationTriangle} {...props} />
);
export default ExclamationTriangleIcon;

const SVGWrapper = styled.div`
  & svg {
    width: 14px;
    height: 14px;
    display: block;
    & g {
      fill: ${RED};
    }
  }
`;

export const RedWarning = (props) => {
  return (
    <SVGWrapper>
      <ExclamationTriangleIcon className="text-danger" {...props} />
    </SVGWrapper>
  );
};
