import React from 'react';
import styled from 'styled-components';
import { DARK_TEXT, GREY_700 } from 'styles/constant';
import { AtIcon, Span } from 'ui-kit';

type EmailProps = {
  className?: string;
  email: string | null;
};

const EmailWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Email: React.FC<EmailProps> = ({ className, email }) => {
  return (
    <EmailWrapper className={`${className || ''}`}>
      <AtIcon color={GREY_700} />
      <Span color={DARK_TEXT} fontSize="14px">
        {email || 'Non renseigné'}
      </Span>
    </EmailWrapper>
  );
};

export default Email;
