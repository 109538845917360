import useOutsideClick from 'hooks/useOutsideClick';
import React, { useRef, useState } from 'react';
import { GREY_400 } from 'styles/constant';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  SearchIcon,
  StyledInput,
} from 'ui-kit';

import { OptionResidencyType } from '../helper';
import OptionResidency from '../option-residency';
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
  SearchItemWrapper,
  SelectionOptionWrapper,
  Separator,
} from './dropdown.style';

type CustomSelectProps = {
  className?: string;
  selected: OptionResidencyType;
  options: Array<OptionResidencyType>;
  setSelected: (id: string | null) => void;
};

const CustomSelect = ({
  selected,
  options,
  setSelected,
  className,
}: CustomSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filterOption, setFilterOption] = useState('');
  const [selectedOption, setSelectedOption] =
    useState<OptionResidencyType>(selected);
  const ref = useRef(null);
  useOutsideClick(ref, () => setIsOpen(false));

  const toggling = () => {
    setFilterOption('');
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (value: OptionResidencyType) => () => {
    setSelectedOption(value);
    setSelected(value.id);
    setIsOpen(false);
  };

  const dofilter = (option: OptionResidencyType, match: string) => {
    if (!match) {
      return true;
    }
    if (option.displayName.toLowerCase().includes(match.toLowerCase())) {
      return true;
    }
    return false;
  };

  return (
    <DropDownContainer className={`${className || ''} dropdown-container`}>
      <DropDownHeader onClick={toggling} className="dropdown-header">
        <SelectionOptionWrapper className="selected-option-wrapper">
          <OptionResidency
            name={selectedOption.displayName}
            location={selectedOption.location}
            className="placeholder-residency"
          />
        </SelectionOptionWrapper>
        <Separator />
        {isOpen ? (
          <ChevronUpIcon color={GREY_400} />
        ) : (
          <ChevronDownIcon color={GREY_400} />
        )}
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer className="dropdown-list-container" ref={ref}>
          <DropDownList className="dropdown-list">
            <SearchItemWrapper>
              <SearchIcon size="1x" className="me-2" color="#929CA9" />
              <StyledInput
                value={filterOption || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFilterOption(e.target.value)
                }
                placeholder="Rechercher..."
              />
            </SearchItemWrapper>
            {options
              .filter((op) => dofilter(op, filterOption))
              .map((option) => (
                <ListItem
                  onClick={onOptionClicked(option)}
                  key={option.id || 'default'}
                  className="dropdown-list-item"
                >
                  <OptionResidency
                    name={option.displayName}
                    location={option.location}
                    className="placeholder-residency"
                  />
                </ListItem>
              ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

export default CustomSelect;
