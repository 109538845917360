export const mapCouncilRole = (role: string): string => {
  switch (role) {
    case 'member':
      return 'Membre';
    case 'president':
      return 'Président';
    default:
      return 'Unknown';
  }
};
