import { PER_PAGE } from '../../../api/base.api';

export const getNumberOfItemsFromPreviousPages = (currentPage: number) => {
  return currentPage === 1 ? 0 : (currentPage - 1) * PER_PAGE.MEMBERS;
};

export const isLastPage = (
  currentPage: number,
  currentNumberOfItems: number,
  numberOfItems: number,
) => {
  const numberOfItemsFromPreviousPages =
    getNumberOfItemsFromPreviousPages(currentPage);
  return (
    numberOfItemsFromPreviousPages + currentNumberOfItems === numberOfItems
  );
};
