import { STATUS } from 'redux-orm-models/assemblyConvocation.model';

export const ConvocationStep = {
  editConvocation: 0,
  checkAddress: 1,
  generatingConvocation: 2,
  convocationGenerated: 3,
  convocationSent: 4,
};

export type Step = {
  step: number;
  date: string;
  owner: string;
};

export type ConvocationStepsById = Array<Step>;

export const getCurrentStep = (status: string) => {
  switch (status) {
    case STATUS.NONE:
      return ConvocationStep.editConvocation;
    case STATUS.VALIDATED:
      return ConvocationStep.checkAddress;
    case STATUS.GENERATING:
    case STATUS.STARTING:
      return ConvocationStep.generatingConvocation;
    case STATUS.GENERATED:
      return ConvocationStep.convocationGenerated;
    case STATUS.ZIP_READY:
      return ConvocationStep.convocationGenerated;
    case STATUS.WAIT_SENDING:
    case STATUS.SENT:
      return ConvocationStep.convocationSent;
    default:
      return ConvocationStep.editConvocation;
  }
};
