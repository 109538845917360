import styled from 'styled-components';

import { GREY_100, GREY_200, GREY_900 } from '../../../../../styles/constant';

export const LISTING_PADDING_RIGHT = '26px';
const LISTING_COLUMNS_LAYOUT =
  '60px minmax(175px, 0.5fr) 145px minmax(175px, 1fr) 117px';
export const ListingWrapper = styled.div`
  align-self: start;
`;

export const Table = styled.div`
  border-radius: 6px;
  border: 1px solid ${GREY_200};
`;

export const THead = styled.div``;
export const TBody = styled.div``;
export const TRHead = styled.div`
  border-radius: 6px;
  opacity: 1;
  background-color: ${GREY_100};
  display: grid;
  grid-template-columns: ${LISTING_COLUMNS_LAYOUT};
  padding-top: 10px;
  padding-bottom: 11px;
  padding-right: ${LISTING_PADDING_RIGHT};
`;
export const THHead = styled.div`
  &:last-child {
    display: flex;
    justify-content: flex-end;
  }
  font-size: 12px;
  font-weight: 700;
  color: #9da6b1;
  & .sorted {
    color: ${GREY_900};

    font-size: 12px;
    font-weight: 700;
  }
`;
export const TRBody = styled.div`
  display: grid;
  grid-template-columns: ${LISTING_COLUMNS_LAYOUT};
  min-height: 64px;
  &:not(:last-child) {
    border-bottom: 1px solid ${GREY_200};
  }
  padding-right: ${LISTING_PADDING_RIGHT};
`;
export const TDBody = styled.div`
  display: flex;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 100%;
  min-height: 300px;
`;
