import { Model } from 'redux-orm';

class DocumentType extends Model {
  toString() {
    return `DocumentType: ${this.name}`;
  }
}

DocumentType.modelName = 'DocumentType';

export default DocumentType;
