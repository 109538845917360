import './before';
import './index.css';
import './styles/main.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import CacheProvider from './pills/entities/requestEntitiesCache.provider';
import store from './redux-utils/store';

const container = document.getElementById('root');

/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
String.prototype.replaceAll = function (search, replacement) {
  return this.split(search).join(replacement);
};

library.add(fas);

render(
  <>
    <Provider store={store}>
      <CacheProvider>
        <App />
      </CacheProvider>
    </Provider>
  </>,
  container,
);
