import { ACTION_TYPES as EntitiesActionTypes } from './entities.actions';

const entitiesReducer = (orm) => {
  return (state, action) => {
    const { type, payload = {} } = action;
    const { models, filter, entity, id, attributes, append } = payload;
    const session = orm.session(state);
    const Model = session[entity];
    switch (type) {
      case EntitiesActionTypes.FLUSH:
        models.forEach((model) => {
          if (!session[model.modelName])
            throw new Error(model.modelName + ' is not a registered model');
          return session[model.modelName]
            .getQuerySet()
            .filter((instance) =>
              filter(session[model.modelName].withId(instance.id)),
            )
            .delete();
        });
        break;
      case EntitiesActionTypes.FLUSH_ALL:
        break;
      case EntitiesActionTypes.SYNC:
        /*
        WARN: Dans le cas de la suppression d'element chez un autre client, celle ci ne sera pas 'visible' chez les autres client.
        La solution ici est de supprimer les anciennes data lors du SYNC.
        Buggy
      */
        // NOTE: Du a la différence d'object entre GET /assemblies et GET /assemblies/{id} (par exemple il n'y pas la props 'stats') et afin d'éviter des SYNC
        // foireux 'race condition'. LIST (pending) -> DETAILS (PENDING) -> DETAILS (FINISH) (il y a assembly.stats) -> LIST (finish) (pas de stats ici)
        // A la place de tout supprimer, on exclu avant ceux qui vont etre mis à jour, ainsi on garde l'instance la plus 'popularisé' possible.
        // De plus, lors de la suppression, certaine 'relations (fk)' sont détruites  (resolution.assemblyId était détruit quand on passe de details a index et qu'on revient a details)
        // En ne supprimant pas ceux qui vont être mis à jour, on conserve ainsi les relations et on évite ainsi de devoir les recostruires 'à la main'
        if (!append) {
          Model.getQuerySet()
            .exclude((instance) =>
              attributes.some((attribute) => attribute.id === instance.id),
            )
            .delete(); // can break the app as tests are not ready - maybe create a HARD_SYNC that remove old data before inserting new ones
          // NOTE: when deleting a resource, the associated ref is set to null !!
        }
        attributes.forEach((attr) => Model.upsert(attr));
        break;
      case EntitiesActionTypes.UPSERT:
        Model.upsert(attributes);
        break;
      case EntitiesActionTypes.CREATE:
        Model.create(attributes);
        break;
      case EntitiesActionTypes.UPDATE:
        Model?.idExists(id) && Model.withId(id).update(attributes);
        break;
      case EntitiesActionTypes.DELETE:
        Model.idExists(id) && Model.withId(id).delete();
        break;
      default:
    }
    return session.state;
  };
};

export default entitiesReducer;
