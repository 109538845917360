import { fk, Model } from 'redux-orm';

import { getId } from '../utils';
import Residency from './residency.model';

class DistributionKey extends Model {
  static getFullName(distributionKey) {
    return `${distributionKey.code} - ${distributionKey.name}`;
  }
  constructor(data) {
    super({
      ...data,
      rights: data.rights || {},
      residencyId: getId(data.residencyId),
    });
  }

  get fullName() {
    return DistributionKey.getFullName(this);
  }

  toString() {
    return `DistributionKey: ${this.name}`;
  }
}

DistributionKey.modelName = 'DistributionKey';
DistributionKey.fields = {
  residencyId: fk({
    to: Residency.modelName,
    as: 'residency',
    relatedName: '_distributionKeysSet',
  }),
};
export default DistributionKey;
