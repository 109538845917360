import { attr, fk, Model } from 'redux-orm';

import { getId } from '../utils';
import DocumentType from './documentType.model';

class Document extends Model {
  constructor(data) {
    super({
      ...data,
      residencyId: getId(data.residency),
      typeId: data.type,
    });
  }
  getFileUrl() {
    return this.ref.shareableLink;
  }

  toString() {
    return `Document: ${this.id}`;
  }
}

Document.modelName = 'Document';
Document.fields = {
  file: attr({ getDefault: () => ({ base64Content: 'NOPE NOPE TODO' }) }),
  typeId: fk({
    to: DocumentType.modelName,
    as: 'type',
    relatedName: '_documentsSet',
  }),
};

export default Document;
