import { CouncilMember, ManagedResidency } from 'pills/members/member.type';
import React from 'react';
import { DARK_TEXT, GREY_600 } from 'styles/constant';
import { Box, Span } from 'ui-kit';
import { ReactComponent as ResidencyLogo } from 'ui-kit/menu-link/residency.svg';

import { mapCouncilRole } from './helper';
import {
  DetailedSpan,
  ImageWrapper,
  MemberResidenciesWrapper,
  ResidencyWrapper,
} from './member-residencies.style';

type MemberResidenciesProps = {
  className?: string;
  residencies: ManagedResidency[];
};

const MAX_RESIDENCY = 3;

const MemberResidencies: React.FC<MemberResidenciesProps> = ({
  residencies,
  className,
}) => {
  const numberOfResidencies = residencies.length;

  const mappedResidencies = residencies.map((residency, index) => {
    const { displayName, id, councilMember } = residency;

    if (index === MAX_RESIDENCY - 1) {
      return (
        <Residency
          name={`+ ${numberOfResidencies - (MAX_RESIDENCY - 1)} autres`}
          key={id}
          councilMember={councilMember}
        />
      );
    }

    if (index > MAX_RESIDENCY - 1) {
      return null;
    }

    return (
      <Residency name={displayName} key={id} councilMember={councilMember} />
    );
  });

  return (
    <MemberResidenciesWrapper className={`${className || ''}`}>
      {mappedResidencies}
    </MemberResidenciesWrapper>
  );
};

type ResidencyProps = {
  name: string;
  councilMember: CouncilMember | null;
  variant?: 'detailed';
};

export const Residency: React.FC<ResidencyProps> = ({
  name,
  councilMember,
  variant,
}) => {
  const Wrapper = React.Fragment;
  const VariantSpan = variant === 'detailed' ? DetailedSpan : Span;
  return (
    <ResidencyWrapper>
      <ImageWrapper>
        <ResidencyLogo />
      </ImageWrapper>
      <Box display="flex" flexDirection="column" gap="2px">
        <Wrapper>
          <VariantSpan color={DARK_TEXT} fontSize="14px">
            {name}
          </VariantSpan>
        </Wrapper>
        {councilMember && (
          <Span color={GREY_600} fontSize="12px">
            {mapCouncilRole(councilMember.role)} du Conseil Syndical
          </Span>
        )}
      </Box>
    </ResidencyWrapper>
  );
};

export default React.memo(MemberResidencies);
