import React from 'react';
import { ReactComponent as ResidencyIcon } from 'ui-kit/menu-link/residency.svg';

import {
  IconWrapper,
  NameWrapper,
  OptionResidencyWrapper,
  ResidencyLocation,
  ResidencyName,
} from './filter-bar.style';

type OptionResidencyProps = {
  name: string | null;
  location: string | null;

  className?: string;
};

const OptionResidency: React.FC<OptionResidencyProps> = ({
  name,
  location,

  className,
}) => {
  if (!name) {
    return null;
  }
  return (
    <OptionResidencyWrapper className={`${className || ''}`}>
      <IconWrapper>
        <ResidencyIcon />
      </IconWrapper>
      <NameWrapper>
        <ResidencyName className="residency-name">{name}</ResidencyName>

        {location && <ResidencyLocation>{location}</ResidencyLocation>}
      </NameWrapper>
    </OptionResidencyWrapper>
  );
};

export default OptionResidency;
