import React from 'react';
import { CellProps } from 'react-table';
import { Avatar } from 'ui-kit';

import { ColumnNames } from '../../column';
import {
  AvatarRendererWrapper,
  DefaultRendererWrapper,
} from './cell-renderer.style';
import EnabledRenderer from './enabled-renderer';
import NameRenderer from './name-renderer';
import ProfileRenderer from './profile-renderer';

export const defaultRenderer = (
  table: React.PropsWithChildren<CellProps<any, any>>,
): JSX.Element => {
  const { value } = table;

  return <DefaultRendererWrapper>{value}</DefaultRendererWrapper>;
};

export const nameRenderer = (
  table: React.PropsWithChildren<CellProps<ColumnNames, string>>,
): JSX.Element => {
  const {
    cell: {
      row: {
        original: { id, companyName, firstName, lastName, email, ownedParcels },
      },
    },
  } = table;

  return (
    <NameRenderer
      id={id}
      companyName={companyName}
      firstName={firstName}
      lastName={lastName}
      email={email}
      ownedParcels={ownedParcels}
    />
  );
};

export const avatarRenderer = (
  table: React.PropsWithChildren<CellProps<ColumnNames, string>>,
): JSX.Element => {
  const {
    cell: {
      row: {
        original: { avatar, companyName, firstName, lastName, councilMember },
      },
    },
  } = table;

  return (
    <AvatarRendererWrapper className={`avatar-renderer`}>
      <Avatar
        src={avatar?.url}
        firstName={firstName}
        lastName={lastName}
        companyName={companyName}
        councilMember={councilMember}
        variant="base"
      />
    </AvatarRendererWrapper>
  );
};

export const enabledRenderer = (
  table: React.PropsWithChildren<CellProps<ColumnNames, boolean>>,
): JSX.Element => {
  const {
    cell: { row },
  } = table;

  return <EnabledRenderer row={row} className={`account-renderer`} />;
};

export const profileRenderer = (
  table: React.PropsWithChildren<CellProps<ColumnNames, string>>,
): JSX.Element => {
  const {
    cell: {
      row: {
        original: {
          rights: { is_tenant, is_owner },
        },
      },
    },
  } = table;

  return <ProfileRenderer is_tenant={is_tenant} is_owner={is_owner} />;
};
