import styled from 'styled-components';
import { DARK_TEXT, GREY_600 } from 'styles/constant';

export const NameRendererWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 13px 12px;
  padding-left: 0px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  & > a {
    text-decoration: none;
    color: ${DARK_TEXT};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Detail = styled.span`
  color: ${GREY_600};
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0px;
`;
