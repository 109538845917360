import styled from 'styled-components';

import { BG_GREY, GREY_400, GREY_700 } from '../../styles/constant';

export const StyledButtonWrapper = styled.div<{
  gap?: string;
  px?: string;
  py?: string;
}>`
  & button {
    height: 100%;
    display: flex;
    align-items: center !important;
    padding-left: ${(props) => props.px || '12px'};
    padding-right: ${(props) => props.px || '12px'};
    padding-top: ${(props) => props.py || '6px'};
    padding-bottom: ${(props) => props.py || '6px'};
    justify-content: space-between;
    gap: ${(props) => (props.gap ? props.gap : undefined)};
    background-color: #fff;
    width: 100%;
    align-items: baseline;
    border-radius: 6px;
    border: 1px solid ${GREY_400};

    &.btn-light {
      background-color: ${BG_GREY};
      border-color: ${BG_GREY};
    }

    &.btn-light:hover {
      background-color: ${GREY_400};
      border-color: ${GREY_400};
    }
    &:active {
      background-color: ${BG_GREY} !important;
    }

    &:hover {
      border: 1px solid ${GREY_400};
      background-color: ${BG_GREY};
    }

    & svg {
      width: 16px;
      height: 16px;
      display: block;
      & g {
        fill: ${GREY_700};
      }
    }
  }
`;
