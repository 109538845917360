import debounce from 'lodash.debounce';
import React, { useState } from 'react';
import { StyledInput } from 'ui-kit/custom-input';
import SearchIcon from 'ui-kit/search.icon';

import { SearchBarWrapper } from './search-bar.style';

type SearchBarProps = {
  className?: string;
  inputClassName?: string;
  filter: string | null;
  onChange: (value: string | null) => void;
};

const SearchBar: React.FC<SearchBarProps> = ({
  className,
  inputClassName,
  filter,
  onChange,
}) => {
  const [text, setText] = useState(filter);

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: nextValue } = e.target;
    setText(nextValue);
    const debouncedFilterByText = debounce(() => onChange(nextValue), 1000);
    debouncedFilterByText();
  };

  return (
    <SearchBarWrapper className={`${className || ''}`}>
      <SearchIcon size="1x" className="me-2" color="#929CA9" />
      <StyledInput
        value={text || ''}
        onChange={onTextChange}
        className={`${inputClassName || ''}`}
        placeholder="Rechercher..."
      />
    </SearchBarWrapper>
  );
};

export default SearchBar;
