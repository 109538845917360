import { ReportingObserver } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Axios from 'axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { authUserSelector } from './pills/auth/auth.selectors';
import User from './redux-orm-models/user.model';

const DSN =
  'https://617fc8b5afac402e868184f837cb5bf7@o439837.ingest.sentry.io/5716309';

function getId(user) {
  return `${user.email}:${User.getName(user)}:${user.id}`;
}

function useSentry() {
  const currentUser = useSelector(authUserSelector);
  const history = useHistory();

  useEffect(() => {
    if (process?.env?.REACT_APP_SENTRY_IS_ACTIVE === 'true') {
      Sentry.init({
        dsn: DSN,
        release: `app.syment.com@${process.env.REACT_APP_VERSION}`,
        environment: process.env.REACT_APP_SENTRY_ENV,
        normalizeDepth: 7, // store.entities.itemsById.xxx + 3
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation:
              Sentry.reactRouterV5Instrumentation(history),
          }),
          new ReportingObserver({
            levels: ['crash' | 'deprecation' | 'intervention'],
          }),
        ],
        tracesSampleRate: 1.0,
        // NOTE: remove all console.log and xhr stuff to avoid 'payload too large sentry error'
        beforeBreadcrumb: (breadcrumb) => {
          if (
            breadcrumb.level === Sentry.Severity.Error &&
            breadcrumb.category !== 'console' &&
            breadcrumb.category !== 'xhr'
          ) {
            return breadcrumb;
          }
          return null;
        },
        // NOTE: send only error event. Warns and logs are ignored
        beforeSend: (event) => {
          if (event.level === Sentry.Severity.Error) {
            // let's limit the number of breadcrumb sent (7max)
            if (event.breadcrumbs && event.breadcrumbs.length >= 0) {
              event.breadcrumbs = event.breadcrumbs.slice(-7);
            }
            // Remove redux state from payload
            if (event.contexts && event.contexts['redux.state']) {
              event.contexts['redux.state'] = undefined;
            }
            return event;
          }
          return null;
        },
      });
    }
  }, [history]);

  useEffect(() => {
    if (currentUser?.token) {
      Sentry.setUser({
        email: currentUser.email,
        username: User.getName(currentUser),
        id: getId(currentUser),
        extra: {
          roles: currentUser.roles,
          rights: currentUser.rights,
          currentProfile: currentUser.currentProfile,
        },
      });
    }
    // eslint-disable-next-line
  }, [history]);

  useEffect(() => {
    if (process?.env?.REACT_APP_SENTRY_IS_ACTIVE === 'true') {
      if (currentUser?.token) {
        Sentry.setUser({
          email: currentUser.email,
          username: User.getName(currentUser),
          id: getId(currentUser),
          extra: {
            roles: currentUser.roles,
            rights: currentUser.rights,
            currentProfile: currentUser.currentProfile,
          },
        });
      } else {
        Sentry.setUser(null);
      }
    }
  }, [currentUser]);
}

// NOTE : https://forum.sentry.io/t/api-auth-over-http/4795/7
export async function sendFeedback({ currentUser, comments }) {
  return await Axios.post(
    'https://sentry.io/api/0/projects/syment/front-react/user-feedback/',
    {
      comments,
      name: User.getName(currentUser),
      email: currentUser.email,
      event_id: getId(currentUser),
    },
    {
      headers: {
        Authorization: `DSN ${DSN}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  );
}

export const withProfiler = Sentry.withProfiler;

export default useSentry;
