import { useLayoutEffect } from 'react';

/**
 *
 * @param {*} textArea - useRef().current
 */
export default function useAutoResize(textAreaRef, initialHeight = 'auto') {
  const textArea = textAreaRef.current;
  const handleInput = (offset) => (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + offset + 'px';
  };
  useLayoutEffect(() => {
    if (textArea) {
      textArea.style.boxSizing = 'border-box';
      textArea.style.resize = 'none';
      let offset = textArea.offsetHeight - textArea.clientHeight;
      const listener = handleInput(offset);
      textArea.addEventListener('input', listener);
      textArea.addEventListener('focus', listener);
      return () => {
        textArea.removeEventListener('focus', listener);
        textArea.removeEventListener('input', listener);
      };
    }
    // eslint-disable-next-line
  }, [textArea]);
  useLayoutEffect(() => {
    if (
      textArea &&
      (textArea.value === '' ||
        textArea.value === undefined ||
        textArea.value === null)
    ) {
      textArea.style.height = initialHeight;
    }
  }, [initialHeight, textArea]);
}
