import {
  getMemberDetailsById,
  getMemberDetailsKey,
  getParcelsById,
  getParcelsKey,
} from 'pages/members/member-details/components';
import { Member } from 'pills/members/member.type';
import React from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import {
  DescriptionWrapper,
  Detail,
  NameRendererWrapper,
} from './name-renderer.style';

interface NameRendererProps
  extends Pick<
    Member,
    'id' | 'companyName' | 'firstName' | 'lastName' | 'email' | 'ownedParcels'
  > {
  className?: string;
}

export const capitalizeFirstLetter = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getFirstLine = (
  companyName?: string | null,
  lastName?: string,
  firstName?: string,
  email?: string | null,
): string => {
  if (companyName) return companyName;
  if (lastName || firstName) {
    return `${lastName ? lastName.toLocaleUpperCase() : ''} ${
      firstName ? capitalizeFirstLetter(firstName.toLowerCase()) : ''
    } `;
  }

  return email ?? '';
};

export const getSecondLine = (
  companyName?: string | null,
  lastName?: string,
  firstName?: string,
): string | null => {
  if (!companyName) {
    return null;
  }
  const last = lastName ? lastName.toLocaleUpperCase() : '';
  const first = firstName ? capitalizeFirstLetter(firstName.toLowerCase()) : '';
  return `${last} ${first}`;
};

const NameRenderer: React.FC<NameRendererProps> = ({
  className,
  id,
  companyName,
  firstName,
  lastName,
  email,
  ownedParcels,
}) => {
  const firstLine = getFirstLine(companyName, lastName, firstName, email);
  const secondLine = getSecondLine(companyName, lastName, firstName);
  const queryClient = useQueryClient();

  const prefetch = () => {
    queryClient.prefetchQuery(
      getMemberDetailsKey(id),
      () => getMemberDetailsById(id),
      { staleTime: 1000 * 60 * 5 },
    );
    ownedParcels.forEach((parcel) => {
      const parcelId = parcel.replace('/parcels/', '');
      queryClient.prefetchQuery(
        getParcelsKey(parcelId),
        () => getParcelsById(parcelId),
        { staleTime: 1000 * 60 * 5 },
      );
    });
  };
  return (
    <NameRendererWrapper
      className={`${className || ''} name-renderer`}
      onMouseEnter={prefetch}
    >
      <DescriptionWrapper>
        <Link to={`/member/details/${id}`}>{firstLine}</Link>
        {secondLine && <Detail>{secondLine}</Detail>}
      </DescriptionWrapper>
    </NameRendererWrapper>
  );
};

export default React.memo(NameRenderer);
