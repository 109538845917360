import styled from 'styled-components';
import { StyledButtonWrapper } from 'ui-kit/styled-button/styled-button.style';

export const NotesWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FieldWrapper = styled.div``;

export const FormWrapper = styled.form`
  display: flex;
  gap: 18px;

  & ${StyledButtonWrapper} {
    align-self: flex-end;
  }

  & ${FieldWrapper} {
    flex: 1;
    & > div > textarea {
      height: 125px !important;
    }
    & > div {
      margin-bottom: 0px;
    }
  }
`;
