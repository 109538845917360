import { assembliesApi, assemblyLiveApi } from '../../api/base.api';

export async function getAssemblyLiveById(id) {
  return await assemblyLiveApi.getById({ id });
}

export async function updateOnGoingManagerByAssemblyId(
  assemblyId,
  newManagerUserId,
) {
  return await assemblyLiveApi.updateOnGoingManagerByAssemblyId({
    assemblyId,
    ongoingManagerUser: newManagerUserId,
  });
}

export async function updateAssemblyLiveParticipantStatus({
  participantId,
  status,
}) {
  return await assemblyLiveApi.participants.updateParticipantStatus({
    id: participantId,
    status,
  });
}

export async function updateAssemblyLiveParticipantRepresentedParticipants({
  participantId,
  representedParticipants,
}) {
  return await assemblyLiveApi.updateAssemblyLiveParticipantRepresentedParticipants(
    {
      participantId,
      representedParticipants,
    },
  );
}

export async function signAssemblyLiveParticipant({
  participantId,
  checkedByManager,
  checkedByParticipant,
  signName,
}) {
  return await assemblyLiveApi.signParticipantByAssemblyId({
    participantId,
    checkedByManager,
    checkedByParticipant,
    signName,
  });
}

export async function openVotesAssemblyLiveResolution(
  assemblyResolutionId,
  assemblyQuorumOverride,
) {
  return await assemblyLiveApi.openVotesByAssemblyResolutionId({
    id: assemblyResolutionId,
    assemblyQuorumOverride: assemblyQuorumOverride,
  });
}

export async function closeVotesAssemblyLiveResolution(assemblyResolutionId) {
  return await assemblyLiveApi.closeVotesByAssemblyResolutionId({
    id: assemblyResolutionId,
  });
}

/**
 * Api broadcast an 'begin' event when this the first time we put ongoingResolutionIndex
 * Used in coordination with assembly.isBegan status.
 * @param {String} assemblyId
 */
export async function beginAssemblyLive(assemblyId, assemblyQuorumOverride) {
  return await assemblyLiveApi.nextResolutionByAssemblyId({
    id: assemblyId,
    ongoingResolutionIndex: 1,
    assemblyQuorumOverride: assemblyQuorumOverride,
  });
}

/**
 * used to update the ongoingResolutionIndex
 * @param {*} assemblyId
 * @param {*} nextOngoingAssemblyResolutionIndex
 */
export async function nextResolutionAssemblyLive(
  assemblyId,
  nextOngoingAssemblyResolutionIndex,
) {
  return await assemblyLiveApi.nextResolutionByAssemblyId({
    id: assemblyId,
    ongoingResolutionIndex: nextOngoingAssemblyResolutionIndex,
  });
}

export async function finishVotesResolutionAssemblyLive(assemblyResolutionId) {
  return await assemblyLiveApi.finishVotesByAssemblyResolutionId({
    id: assemblyResolutionId,
  });
}

export async function closeAssembly(assemblyId, assemblyQuorumFailed) {
  const updatedAssembly = await assembliesApi.finish({
    id: assemblyId,
    assemblyQuorumFailed: assemblyQuorumFailed,
  });
  return updatedAssembly;
}

export async function batchVotesByAssemblyResolutionId(
  assemblyResolutionId,
  votes,
) {
  return await assemblyLiveApi.batchVotesByAssemblyResolutionId({
    id: assemblyResolutionId,
    votes,
  });
}

export async function reopenVotesAssemblyLiveResolution(assemblyResolutionId) {
  return await assemblyLiveApi.reopenVotesByAssemblyResolutionId({
    id: assemblyResolutionId,
  });
}

export async function createExternalParticipantByAssemblyId(assemblyId, data) {
  const createdExternalRepresentative =
    await assemblyLiveApi.createExternalParticipantByAssemblyId({
      assemblyId,
      data,
    });
  return createdExternalRepresentative;
}

export async function updateExternalParticipantByAssemblyId(
  assemblyId,
  participantId,
  data,
) {
  const updatedExternalRepresentative =
    await assemblyLiveApi.updateExternalParticipantByAssemblyId({
      assemblyId,
      participantId,
      data,
    });
  return updatedExternalRepresentative;
}
