import React from 'react';
import { useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useCurrentCompany from '../../hooks/useCurrentCompany';
import { authTokenSelector } from '../../pills/auth/auth.selectors';
import { buildLinkStr } from '../menu-link/helper';
import {
  A,
  IconLinkWrapper,
  ImageWrapper,
  NotificationPoint,
} from './icon-link.style';
import notification from './notification.svg';
import settings from './settings.svg';

type IconLinkProps = {
  className?: string;
  name: MenuName;
  hasNotifications?: boolean;
};

const MENU_DEF = {
  settings: {
    icon: settings,
    path: 'residencies',
    queryParams:
      'action=COMPANY_INFORMATIONS_EDIT&options={"id":"[COMPANY_ID]"}',
    type: 'v4',
  },
  notification: {
    icon: notification,
    path: '',
    queryParams: undefined,
    type: 'v4',
  },
};

export type MenuName = keyof typeof MENU_DEF;

const IconLink: React.FC<IconLinkProps> = ({
  name,
  hasNotifications = false,
  className,
}) => {
  const token = authTokenSelector(useStore().getState());
  const currentCompany = useCurrentCompany();
  const icon = MENU_DEF[name].icon;
  const path = MENU_DEF[name].path;
  const type = MENU_DEF[name].type;
  const queryParams = MENU_DEF[name].queryParams;
  const location = useLocation();

  const link = buildLinkStr(
    type,
    path,
    undefined,
    token,
    queryParams?.replace('[COMPANY_ID]', currentCompany?.id),
  );

  if (type === 'v2') {
    return (
      <A
        href={link}
        className={`${className || ''} ${
          location.pathname.slice(1) === path ? 'active' : ''
        }`}
      >
        <ImageWrapper>
          {hasNotifications && <NotificationPoint />}
          <img src={icon} alt="" />
        </ImageWrapper>
      </A>
    );
  }

  if (type === 'v4') {
    return (
      <A href={link}>
        <ImageWrapper className={`${hasNotifications ? 'notification' : ''}`}>
          {hasNotifications && <NotificationPoint />}
          <img src={icon} alt="" />
        </ImageWrapper>
      </A>
    );
  }

  return (
    <IconLinkWrapper
      className={`${className || ''} ${
        location.pathname.slice(1) === path ? 'active' : ''
      }`}
      to={link}
    >
      <ImageWrapper className={`${hasNotifications ? 'notification' : ''}`}>
        {hasNotifications && <NotificationPoint />}
        <img src={icon} alt="" />
      </ImageWrapper>
    </IconLinkWrapper>
  );
};

export default IconLink;
