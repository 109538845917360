import { parcelsApi } from '../../api/base.api';

export async function getParcels() {
  return await parcelsApi.list();
}

export async function getParcelById(id) {
  return await parcelsApi.getById({ id });
}

export async function updateParcel(parcel) {
  return await parcelsApi.update(parcel);
}

export async function deleteParcel(parcel) {
  return await parcelsApi.delete(parcel);
}

export async function createParcel(parcel) {
  return await parcelsApi.create(parcel);
}
