import { faPen } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import styled from 'styled-components';
import { GREY_200, LG_GREY } from 'styles/constant';

import { IconProps } from './at.icon';
import Icon from './icon';

type PenIconProps = IconProps & { hasCircle?: boolean };

export const CircleWrapper = styled.div`
  border-radius: 50%;
  border: 1px solid ${LG_GREY};
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: ${GREY_200};
  }
`;
const PenIcon: React.FC<PenIconProps> = ({
  size = '1x',
  color,
  disabled = false,
  hasCircle = false,
  className,
}) => {
  const Wrapper = hasCircle ? CircleWrapper : React.Fragment;
  return (
    <Wrapper>
      <Icon
        icon={faPen}
        color={color}
        className={className}
        disabled={disabled}
        size={size}
      />
    </Wrapper>
  );
};

export default PenIcon;
