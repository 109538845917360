import styled from 'styled-components';
import { Span } from 'ui-kit';

export const MemberResidenciesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: -1;
`;

export const ResidencyWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const ImageWrapper = styled.div`
  width: 17px;
  height: 16px;

  transform: translateY(4px);
  & svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const CodeWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const DetailedSpan = styled(Span)`
  font-weight: 600;
  max-width: 268px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
