import { membersApi } from 'api/base.api';
import { useQuery } from 'react-query';

type UseSendActivationProps = {
  residencyId: string;
  userIds: Array<string> | null;
};

const getSendInvitation = (
  residencyId: string,
  userIds: Array<string> | null,
) => {
  const usersQuery = userIds
    ? userIds.map((userId) => `&users[]=${userId}`)
    : null;
  return membersApi.sendInvationByResidenceIdAndByUserIds({
    id: residencyId,
    usersQuery,
  });
};

export const getSendActivationKey = (
  residencyId: string,
  userIds: Array<string> | null,
) => {
  return ['send-invitation', residencyId, userIds];
};

export function useSendActivation({
  residencyId,
  userIds,
}: UseSendActivationProps) {
  const { data, isLoading, refetch } = useQuery(
    getSendActivationKey(residencyId, userIds),
    () => getSendInvitation(residencyId, userIds),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: false, // disable this query from automatically running
    },
  );

  return { data, isLoading, refetch };
}
