import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faSlash } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import Icon from './icon';

const CalendarSlashIcon = (props) => (
  <span className="fa-layers fa-fw">
    <Icon icon={faSlash} size="sm" {...props} />
    <Icon icon={faCalendar} {...props} />
  </span>
);
export default CalendarSlashIcon;
