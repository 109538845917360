import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import assembliesOrmReducer from '../pills/assemblies/assemblies.ormReducer';
import assemblyResolutionsOrmReducer from '../pills/assemblyResolutions/assemblyResolutions.ormReducer';
import authOrmReducer from '../pills/auth/auth.ormReducer';
import dataLoaderReducer from '../pills/dataLoader/dataLoader.reducer';
import entitiesReducer from '../pills/entities/entities.reducer';
import sessionReducer from '../pills/session/session.reducer';
import orm from '../redux-orm-models';

const userInitializer = ({ User, Auth }) => {
  User.create({ id: 0 });
  Auth.create({ userId: 0 });
};

const getInitialOrmState = (orm) => {
  const emptyState = orm.getEmptyState();
  const mutableSession = orm.mutableSession(emptyState);
  [userInitializer].forEach((init) => init(mutableSession));
  return mutableSession.state;
};

const ormInitialState = getInitialOrmState(orm);

const reduceReducers =
  (...reducers) =>
  (state = ormInitialState, action) => {
    return reducers.reduce((acc, reducer) => reducer(acc, action), state);
  };

export default combineReducers({
  dataLoader: dataLoaderReducer,
  session: sessionReducer,
  entities: reduceReducers(
    entitiesReducer(orm),
    authOrmReducer(orm),
    assembliesOrmReducer(orm),
    assemblyResolutionsOrmReducer(orm),
  ),
  form: formReducer,
});
