import { CKEditor as Editor } from 'ckeditor4-react';
import React from 'react';

const defaultEditorConfig = {
  allowedContent: true,
  versionCheck: false,
  toolbarGroups: [
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'editing', groups: ['find', 'selection'] },

    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'justify'],
    },
    { name: 'links' },
    { name: 'insert' },
    { name: 'styles' },
    { name: 'colors' },
    { name: 'about' },
  ],

  removeDialogTabs: 'link:advanced',
  removeButtons: 'Cut,Copy,Paste,Undo,Redo,Anchor,Strike,Subscript,Superscript',
  extraPlugins: 'find, pagebreak, justify',
};

export function CKEditor(props) {
  let config = defaultEditorConfig;

  return <Editor config={defaultEditorConfig} {...props} />;
}

export default CKEditor;
