import { ManagedResidency } from 'pills/members/member.type';
import React from 'react';
import styled from 'styled-components';
import { DARK_TEXT, GREY_200 } from 'styles/constant';
import { Span } from 'ui-kit';
import { ReactComponent as ResidencyIcon } from 'ui-kit/menu-link/residency.svg';

type ResidencyProps = {
  className?: string;
  residency: ManagedResidency | null;
};

const ResidencyWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  & img,
  & svg {
    width: 26px;
    height: 26px;
    object-fit: cover;
  }
`;

const IconWrapper = styled.div`
  border-radius: 4px;

  background-color: ${GREY_200};
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Img = styled.img``;

const Residency: React.FC<ResidencyProps> = ({ className, residency }) => {
  if (!residency) {
    return null;
  }

  const file = residency.file ? (
    <Img src={residency.file.url} />
  ) : (
    <IconWrapper>
      <ResidencyIcon />
    </IconWrapper>
  );
  const name = residency.displayName;

  return (
    <ResidencyWrapper className={`${className || ''}`}>
      {file}{' '}
      <Span fontSize="14px" fontWeight="600" color={DARK_TEXT}>
        {name}
      </Span>
    </ResidencyWrapper>
  );
};

export default Residency;
