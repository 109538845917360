import { isAfter, isBefore, isToday } from 'date-fns';
import { filter } from 'lodash';
import orderBy from 'lodash.orderby';
import {
  ASSEMBLY_MINUTES_STATUS,
  ASSEMBLY_MODE,
  STATUS_LEGACY,
  STATUS_NEW,
  TITLES,
} from 'pills/assemblies/assembly.constants';
import { fk, Model } from 'redux-orm';

import { VISIO_TYPE } from '../pills/zoom/visio.constants';
import { getId } from '../utils';
import DistributionKey from './distributionKey.model';
import Residency from './residency.model';

class Assembly extends Model {
  constructor(data) {
    super({
      ...data,
      residencyId: getId(data.residency),
      distributionKeyId:
        data.distributionKeyBackup || data.distributionKey
          ? getId(data.distributionKeyBackup || data.distributionKey)
          : null,
      resolutionIds: data.resolutions?.map(getId),
      checklist: data.checklist || {},
      ongoingManagerUserId: getId(data.ongoingManagerUser),
    });
  }
  toString() {
    return `Assembly: ${this.title}`;
  }

  hasLive() {
    return (
      this.mode !== ASSEMBLY_MODE.PHYSICAL &&
      this.mode !== ASSEMBLY_MODE.VPC_ONLY
    );
  }

  isFinished() {
    return this.status === STATUS_LEGACY.FINISHED;
  }

  isStarted() {
    return this.status === STATUS_LEGACY.STARTED;
  }

  isUnstarted() {
    return (
      this.status === STATUS_LEGACY.FUTURE &&
      isAfter(new Date(), new Date(this.ref.date))
    );
  }

  // TO DO : implement the new status step by step
  isNeverStarted() {
    return (
      this.status === STATUS_LEGACY.FUTURE || this.status === STATUS_NEW.FUTURE
    );
  }

  isFutureDate() {
    return (
      !this.ref.date ||
      (!isToday(new Date(this.ref.date)) &&
        isBefore(new Date(), new Date(this.ref.date)))
    );
  }

  isPassedDate() {
    return (
      this.ref.date &&
      !isToday(new Date(this.ref.date)) &&
      isAfter(new Date(), new Date(this.ref.date))
    );
  }

  isDate() {
    return this.ref.date;
  }

  isToday() {
    return this.ref.date ? isToday(new Date(this.ref.date)) : false;
  }

  isPublished() {
    return this.ref.publishDate != null;
  }

  isCouncilPublished() {
    return this.ref.publishedAtForCouncil != null;
  }

  get resolutionsProgressRate() {
    return 100 * (this.ref.ongoingResolution / this.numberOfResolution);
  }

  get numberOfResolution() {
    return this.ref.resolutionsCount;
  }

  get participantRatio() {
    return parseInt(this.ref.participationRate * 100);
  }

  get title() {
    return TITLES[this.ref.type] || 'Assemblée';
  }

  get progressChecklistPercent() {
    const checklistValues = Object.values(this.checklist);
    return checklistValues.length
      ? Math.trunc(
          (checklistValues.reduce(
            (score, clValue) => (clValue ? score + 1 : score),
            0,
          ) /
            checklistValues.length) *
            100,
        )
      : 0;
  }

  isMinutesUpdated() {
    return this.ref.minutesStatus === ASSEMBLY_MINUTES_STATUS.PDF_GENERATED;
  }

  isMinutesSigning() {
    return this.ref.minutesStatus === ASSEMBLY_MINUTES_STATUS.REQUEST_SENT;
  }

  isMinutesSigned() {
    return this.ref.minutesStatus === ASSEMBLY_MINUTES_STATUS.SIGNED;
  }

  isEarlyVoting() {
    if (this.ref.rights?.manage) {
      // {# SYM-1967 : manager can set early votes #}
      return this.isNeverStarted();
    }
    return (
      this.isNeverStarted() &&
      (!!this.ref.earlyVotingOpenedAt || !!this.ref.earlyVotingClosedAt)
    );
  }

  isEarlyVotesOpen() {
    return this.isNeverStarted() && !!this.ref.earlyVotingOpenedAt;
  }

  isEarlyVotesClose() {
    return this.isNeverStarted() && !!this.ref.earlyVotingClosedAt;
  }

  isBegan() {
    return !!this.ref.ongoingResolution;
  }

  getMinutesSignedUrl() {
    return this.ref.minutesSigned.url;
  }

  getMinutesUpdatedUrl() {
    return this.ref.minutesUpdated?.url;
  }

  getPresenceSheetUrl() {
    return this.ref?.presenceSheet?.url;
  }

  getPresenceSheetUpdatedUrl() {
    if (this.ref?.presenceSheetUpdated) {
      return this.ref.presenceSheetUpdated?.url;
    }
    return this.ref?.presenceSheet?.url;
  }

  getSignedPresenceSheetUrl() {
    return this.ref?.presenceSheetSigned?.url;
  }

  getPresenceSheetDownloadUrl() {
    if (this.isMinutesSigned()) {
      return this.getSignedPresenceSheetUrl();
    }
    return this.getPresenceSheetUpdatedUrl();
  }

  getMinutesCertificateOfSignatureUrl() {
    return this.ref.minutesSignedProof.url;
  }

  getMinutesDownloadUrl() {
    if (this.isMinutesSigned()) {
      return this.getMinutesSignedUrl();
    }
    return this.getMinutesUpdatedUrl();
  }

  getMinutesSignersWhoReceivedSignRequest() {
    /* NOTE: retrieving the last minutesSignRequest event allows us to NOT consider the cancelled signatures
    --> thanks to this unique list of signers into the last event, we can display the current signers
     and not the current + the previous ones (meaning the cancelled ones)
    */
    const signersByEmail = new Map();
    const lastMinutesSignRequestEvents =
      this.minutesSignRequestEvents &&
      this.minutesSignRequestEvents[this.minutesSignRequestEvents.length - 1];

    lastMinutesSignRequestEvents?.document.signrequest.signers
      // .filter((signer) => !signer.forwarded)
      .forEach((signer) => {
        switch (signer.signed) {
          case true:
            signersByEmail.set(signer.email, {
              firstName: signer.first_name,
              lastName: signer.last_name,
              email: signer.email,
              hasSignedEarlyVotes: true,
              forwarded: signer.forwarded,
            });
            break;
          default: {
            if (!signersByEmail.has(signer.email)) {
              signersByEmail.set(signer.email, {
                firstName: signer.first_name,
                lastName: signer.last_name,
                email: signer.email,
                hasSignedEarlyVotes: false,
                forwarded: signer.forwarded,
              });
            }
          }
        }
      });
    return orderBy(
      Array.from(signersByEmail.values()),
      ['hasSignedEarlyVotes', 'lastName'],
      ['desc', 'asc'],
    );
  }

  static getFullAddressForMinutes(address) {
    return `${address?.line1}, ${address?.zipcode} ${address?.city}`;
  }

  //#endregion

  get visioMetadata() {
    return this.ref.visioMetadata || {};
  }

  isVisioEnabled() {
    return this.ref.visioMetadata?.enabled;
  }

  isVisioCustom() {
    return this.ref.visioMetadata?.visioType === 'custom';
  }

  isVisioIntegrated() {
    return this.ref.visioMetadata?.visioType === VISIO_TYPE.WHEREBY;
  }

  getCategory() {
    return 'Assemblée';
  }

  get convocationSentAt() {
    return this.ref.date;
  }
  get invalidQuotityKeyIds() {
    let invalidQttKeyIds = [];
    if (this.ref.distributionKey?.invalidQuotity) {
      invalidQttKeyIds.push(this.ref.distributionKey.id);
    }

    const assemblyResolutionInvalidQttKeys = filter(
      this.ref.resolutions,
      (resolution) => resolution?.distributionKey?.invalidQuotity === true,
    ).map((distributionKey) => distributionKey.id);

    invalidQttKeyIds = invalidQttKeyIds.concat(
      assemblyResolutionInvalidQttKeys,
    );

    return invalidQttKeyIds;
  }
}

Assembly.modelName = 'Assembly';
Assembly.fields = {
  residencyId: fk({
    to: Residency.modelName,
    as: 'residency',
    relatedName: '_assembliesSet',
  }),
  distributionKeyId: fk({
    to: DistributionKey.modelName,
    as: 'distributionKey',
    relatedName: '_assembliesSet',
  }),
};
export default Assembly;
