import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

function DropDownMenu({
  position = 'end',
  triggerRender,
  menuRender,
  children,
  As = 'div',
  forceOpen = false,
  ...rest
}) {
  const menu = useRef(null);
  const [isOpen, setIsOpen] = useState(() => false);
  const close = useCallback(function (e) {
    if (e.target.type === 'checkbox') {
      // HACK: when clicking on a checkbox, target is the window, so dom element traversed. Just ignore the native action when clicking on a checkbox
      return;
    }
    setIsOpen(false);
  }, []);
  const open = useCallback(
    function (e) {
      setIsOpen(!isOpen);
      e.stopPropagation();
    },
    [isOpen],
  );
  useEffect(
    function () {
      if (isOpen) {
        document.addEventListener('click', close);
        return () => document.removeEventListener('click', close);
      }
    },
    [isOpen, close],
  );
  return (
    <As className="dropdown" onClick={open} {...rest}>
      {triggerRender}
      <ul
        ref={menu}
        className={`dropdown-menu dropdown-menu-${position} ${
          isOpen ? 'show' : ' '
        } ${forceOpen ? 'show' : ''}`}
      >
        {!menuRender
          ? React.Children.toArray(children).map((child, i) =>
              React.cloneElement(child, {
                className: `${child.props.className || ''} dropdown-item`,
              }),
            )
          : menuRender}
      </ul>
    </As>
  );
}

DropDownMenu.propTypes = {
  position: PropTypes.oneOf(['end', 'start']).isRequired,
};

DropDownMenu.defaultProps = {
  position: 'end',
};

export default DropDownMenu;
