import styled from 'styled-components';

import { GREY_100 } from '../../../../../styles/constant';

export const SearchBarWrapper = styled.div`
  padding: 8px 12px 9px 12px;
  border-radius: 6px;
  opacity: 1;
  background-color: ${GREY_100};
  display: flex;
`;
