import styled from 'styled-components';
import { GreenWrapper } from 'ui-kit/checkCircle.regular.icon';

export const MemberStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  & ${GreenWrapper} {
    margin-left: -5px;
    margin-top: -2px;
    width: 25px;
    height: 25px;
    display: flex;
    align-self: center;
    & svg {
      width: 100%;
      height: 100%;
    }
  }
`;
