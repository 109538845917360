import React from 'react';
import { useQueryClient } from 'react-query';
import { GREY_600 } from 'styles/constant';
import {
  CloudDownloadIcon,
  DropdownButton,
  DropdownButtonItemType,
} from 'ui-kit';
import { ItemType } from 'ui-kit/dropdown-button';
import { download } from 'utils';

import { getExportEREKey, useExportERE } from '../hooks/useExportERE';
import {
  getExportResidencyKey,
  useExportResidency,
} from '../hooks/useExportResidency';

type ExportButtonProps = {
  className?: string;
  residencyId: string;
};

const ExportButton: React.FC<ExportButtonProps> = ({ residencyId }) => {
  const [closeDropdown, setCloseDropdown] = React.useState(false);
  const queryClient = useQueryClient();
  const {
    data: dataExportResidency,
    isLoading: isLoadingExportResidency,
    refetch: refetchExportResidency,
  } = useExportResidency({ residencyId });

  const {
    data: dataExportERE,
    isLoading: isLoadingExportERE,
    refetch: refetchExportERE,
  } = useExportERE({ residencyId });

  if (dataExportResidency) {
    download(dataExportResidency.data, 'liste des résidents.pdf');
    queryClient.removeQueries(getExportResidencyKey(residencyId), {
      exact: true,
    });
    setCloseDropdown(true);
  }

  if (dataExportERE) {
    download(dataExportERE.data, 'résidents ERE.xlsx');
    queryClient.removeQueries(getExportEREKey(residencyId), {
      exact: true,
    });
    setCloseDropdown(true);
  }

  const items: Array<ItemType> = [
    {
      label: 'Liste des copropriétaires',
      type: DropdownButtonItemType.onClickHandler,
      icon: <CloudDownloadIcon color={GREY_600} />,

      onClick: () => refetchExportResidency(),
      loading: isLoadingExportResidency,
    },
    {
      label: 'Consentements ERE',
      type: DropdownButtonItemType.onClickHandler,
      icon: <CloudDownloadIcon color={GREY_600} />,
      onClick: () => refetchExportERE(),
      loading: isLoadingExportERE,
    },
  ];

  return (
    <DropdownButton
      label="Export"
      items={items}
      closeDropdown={closeDropdown}
    />
  );
};

export default ExportButton;
