import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAssembly } from '../../hooks/query/useAssembly';
import User from '../../redux-orm-models/user.model';
import { authUserSelector } from '../auth/auth.selectors';

const Whereby = () => {
  // @ts-ignore
  const { assemblyId } = useParams();

  const user = useSelector(authUserSelector);

  const { data: assembly, isLoading } = useAssembly({ assemblyId });

  if (isLoading) {
    return null;
  }

  return (
    <div className="tw-w-full">
      <iframe
        className="tw-w-full tw-h-full tw-rounded-md"
        src={`${
          assembly?.visioMetadata?.raw?.hostRoomUrl
        }?minimal&displayName=${User.getName(
          user,
        )}&chat=off&people=off&leaveButton=off&screenshare=on&precallCeremony=on&&precallReview=on&precallCeremonyCanSkip=on`}
        allow="camera; microphone; fullscreen; speaker; display-capture; autoplay"
      ></iframe>
    </div>
  );
};

export default Whereby;
