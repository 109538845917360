import { fk, Model } from 'redux-orm';
import { getId } from '../utils';
import Assembly from './assembly.model';
import AssemblyLawArticle from './assemblyLawArticle.model';
import AssemblyMajorityMode from './assemblyMajorityMode.model';
import DistributionKey from './distributionKey.model';

export const ASSEMBLY_RESOLUTIONS_VOTE_STATUS = {
  PRE_OPEN: 0,
  OPEN: 1,
  CLOSE: 2,
  FINISH: 3,
};

export const ASSEMBLY_RESOLUTIONS_TYPES = {
  RESOLUTION: 'resolution',
  SUGGESTION: 'suggestion',
};

export const ASSEMBLY_SUGGESTIONS_STATUS = {
  ACCEPTED: 1,
  PENDING: 0,
  REJECTED: -1,
};

export const ASSEMBLY_LAW_ARTICLE_FREE_INPUT_ID = -1;

export const ASSEMBLY_RESOLUTIONS_STATUS = {
  REJECTED: -1,
  PENDING: 0,
  ACCEPTED: 1,
  NEW_VOTE: 2,
};

const _VOTE_KEYS = {
  pour: 'pour',
  contre: 'contre',
  abstention: 'abstention',
  nonVoter: 'non-voter',
  other: 'other',
};

class AbstractVote {
  constructor({
    share,
    quotity,
    color,
    useless,
    proposition,
    votersPct,
    voters,
  }) {
    this.proposition = proposition;
    this.share = share;
    this.votersPct = votersPct;
    this.voters = voters;
    this.quotity = quotity;
    this._color = color;
    this._useless = useless;
  }
}

class VotePour extends AbstractVote {
  get value() {
    return _VOTE_KEYS.pour;
  }

  get title() {
    if (this.proposition) {
      // dirty fix: until API fixes issue related to keeping early votes while changing while changing propositions
      return AssemblyResolution.getPropositionName(this.proposition);
    } else {
      return null;
    }
  }

  get color() {
    return this._color || 'var(--bs-vote-pour)';
  }

  get useless() {
    return false;
  }
}
class VoteContre extends AbstractVote {
  get value() {
    return _VOTE_KEYS.contre;
  }

  get title() {
    if (this.proposition) {
      // dirty fix: until API fixes issue related to keeping early votes while changing while changing propositions
      return AssemblyResolution.getPropositionName(this.proposition);
    } else {
      return null;
    }
  }

  get color() {
    return this._color || 'var(--bs-vote-contre)';
  }
  get useless() {
    return false;
  }
}
class VoteAbstention extends AbstractVote {
  get value() {
    return _VOTE_KEYS.abstention;
  }
  get title() {
    return 'Abstention';
  }
  get color() {
    return this._color || 'var(--bs-vote-abstention)';
  }
  get useless() {
    return this._useless;
  }
}

class VoteNone extends AbstractVote {
  get value() {
    return _VOTE_KEYS.nonVoter;
  }
  get title() {
    return 'Non-votant';
  }
  get color() {
    return this._color || 'var(--bs-vote-none)';
  }
  get useless() {
    return this._useless;
  }
}
class VoteOther extends AbstractVote {
  constructor(vote) {
    super(vote);
    this._title = vote.title;
  }
  get title() {
    if (this.proposition) {
      // dirty fix: until API fixes issue related to keeping early votes while changing while changing propositions
      return AssemblyResolution.getPropositionName(this.proposition);
    } else {
      return null;
    }
  }
  get value() {
    return this.title;
  }
  get color() {
    return this._color || 'var(--bs-vote-other)';
  }
  get useless() {
    return false;
  }
}

const _VOTE_BY_TYPE = {
  [_VOTE_KEYS.pour]: VotePour,
  [_VOTE_KEYS.contre]: VoteContre,
  [_VOTE_KEYS.abstention]: VoteAbstention,
  [_VOTE_KEYS.nonVoter]: VoteNone,
  [_VOTE_KEYS.other]: VoteOther,
};

class AssemblyResolution extends Model {
  constructor(data) {
    if (!data.assemblyId) {
      console.info(
        '🚀 ~ file: assemblyResolution.model.js ~ line 125 ~ AssemblyResolution ~ constructor ~ data',
        data,
        'assemblyId field is MANDATORY !',
      );
    }
    super({
      ...data,
      assemblyId: data.assemblyId,
      assemblyMajorityModeId: data.assemblyMajorityMode
        ? getId(data.assemblyMajorityMode)
        : null,
      assemblyLawArticleId: data.assemblyLawArticle
        ? getId(data.assemblyLawArticle)
        : ASSEMBLY_LAW_ARTICLE_FREE_INPUT_ID,
      /**
       * [API_CURIOSITY#2](assemblyDistributionKeyBackup) When a resolution got a specific distributionKey API send it in: distributionKeyBackup field
       * Otherwise we have got to use distributionKeyBackup provide for the assembly
       *
       * dirty way to retreive the assembly distribution key
       * perhaps we should do this form AssemblyResolution model ?
       */
      distributionKeyId:
        data.distributionKeyBackup || data.distributionKey
          ? getId(data.distributionKeyBackup || data.distributionKey)
          : null,
      secondVoteResolutionId: data.secondVoteResolution
        ? getId(data.secondVoteResolution)
        : null,
      parentAssemblyResolutionId: data.parentResolution
        ? getId(data.parentResolution)
        : null,
      reopenedVoteResolutionId: data.reopenedVoteResolution
        ? getId(data.reopenedVoteResolution)
        : null,
      initialVoteResolutionId: data.initialVoteResolution
        ? getId(data.initialVoteResolution)
        : null,
      propositions: Array.isArray(data.propositions) ? data.propositions : [],
    });
  }

  get votes() {
    if (
      !this.ref.stats ||
      Array.isArray(this.ref.stats) ||
      typeof this.ref.stats !== 'object'
    ) {
      return [];
    }
    if (this.secondVoteResolutionId) {
      return this.secondVoteResolution.votes;
    }
    return Object.keys(this.ref.stats.votes).map((votesKey) => {
      const votesValue = this.ref.stats.votes[votesKey];
      let VoteType = _VOTE_BY_TYPE[votesKey] || _VOTE_BY_TYPE[_VOTE_KEYS.other];
      return new VoteType({
        useless: votesValue.ignored === true,
        share: votesValue.share,
        votersPct: votesValue.votersPct,
        voters: votesValue.voters,
        quotity: votesValue.quotity,
        title: votesKey,
        proposition: this.getPropositionFromName(votesKey),
      });
    });
  }

  get liveVotes() {
    // NOTE: [API_CURIOSITY#4] -- when stats is empty, it's an array ! but when it exists, its an object !
    /**
     * @exemple of .stats : {
	"stats": {
		"votes": {
			"pour": {
				"share": 0,
				"voters": 0,
				"quotity": 0,
				"proposition": "pour"
			},
			"contre": {
				"share": 0,
				"voters": 0,
				"quotity": 0,
				"proposition": "contre"
			},
			"non-voter": {
				"share": 0,
				"voters": 5,
				"quotity": 1000,
				"proposition": "non-voter"
			},
			"abstention": {
				"share": 0,
				"voters": 0,
				"quotity": 0,
				"proposition": "abstention"
			}
		},
		"voters": 0,
		"quotity": 0
	}
}
     */
    if (
      !this.ref.stats ||
      Array.isArray(this.ref.stats) ||
      typeof this.ref.stats !== 'object'
    ) {
      return [];
    }

    return Object.keys(this.ref.stats.votes)
      .filter((votesKey) => votesKey !== _VOTE_KEYS.nonVoter)
      .map((votesKey) => {
        const votesValue = this.ref.stats.votes[votesKey];
        let VoteType =
          _VOTE_BY_TYPE[votesKey] || _VOTE_BY_TYPE[_VOTE_KEYS.other];
        return new VoteType({
          useless: votesValue.ignored === true,
          share: votesValue.share,
          votersPct: votesValue.votersPct,
          voters: votesValue.voters,
          quotity: votesValue.quotity,
          title: votesKey,
          proposition: this.getPropositionFromName(votesKey),
        });
      })
      .concat(
        new _VOTE_BY_TYPE[_VOTE_KEYS.nonVoter]({
          ...this.ref.stats.votes[_VOTE_KEYS.nonVoter],
          useless: this.ref.stats.votes[_VOTE_KEYS.nonVoter].ignored === true,
          proposition: { name: _VOTE_KEYS.nonVoter },
        }),
      );
  }

  get livePropositions() {
    return this.propositions;
  }

  get assemblyResolutionPropositions() {
    return this.livePropositions.map(
      (proposition) =>
        new (_VOTE_BY_TYPE[proposition.name] ||
          _VOTE_BY_TYPE[_VOTE_KEYS.other])({
          useless: this.ref.ignored === true,
          title: AssemblyResolution.getPropositionName(proposition),
          proposition,
        }),
    );
  }

  getPropositionFromName(propositionName) {
    return this.livePropositions.find(({ name }) => name === propositionName);
  }

  get position() {
    // maybe this function should be removed
    if (this.parentAssemblyResolutionId && !this.subResolution) {
      return this.parentAssemblyResolution.position;
    }
    return this.ref.position;
  }

  resolveDeepestChildren() {
    if (this.secondVoteResolutionId) {
      return this.secondVoteResolution.resolveDeepestChildren();
    }
    return this;
  }

  resolveDeepestDescription() {
    return this.resolveDeepestChildren().description;
  }

  resolveDeepestId() {
    return this.resolveDeepestChildren().id;
  }

  isVotePending() {
    return (
      this.resolveDeepestChildren().finalVoteResult ===
      ASSEMBLY_RESOLUTIONS_STATUS.PENDING
    );
  }

  isVoteAccepted() {
    return (
      this.resolveDeepestChildren().finalVoteResult ===
      ASSEMBLY_RESOLUTIONS_STATUS.ACCEPTED
    );
  }

  isVoteRejected() {
    return (
      this.resolveDeepestChildren().finalVoteResult ===
      ASSEMBLY_RESOLUTIONS_STATUS.REJECTED
    );
  }

  isVoteOpen() {
    return (
      this.resolveDeepestChildren().voteStatus ===
      ASSEMBLY_RESOLUTIONS_VOTE_STATUS.OPEN
    );
  }

  isVoteCanBeOpened() {
    return this.ref.voteStatus === ASSEMBLY_RESOLUTIONS_VOTE_STATUS.PRE_OPEN;
  }

  isVotesEnabled() {
    return this.ref.isVoteEnabled === true || this.ref.votesEnabled === true;
  }

  isInformative() {
    return !this.isVotesEnabled() && !this.ref.isWithoutSubject;
  }

  isVotesFinished() {
    return this.ref.voteStatus === ASSEMBLY_RESOLUTIONS_VOTE_STATUS.FINISH;
  }

  isVotesClosed() {
    return this.ref.voteStatus === ASSEMBLY_RESOLUTIONS_VOTE_STATUS.CLOSE;
  }

  isAdditionalVote() {
    return this.ref.voteNumber > 1;
  }

  isReopenedSecondVote() {
    return this.ref.voteNumber === 3;
  }

  static getPropositionName({ name, price }) {
    return `${name} ${price ? price + ' €' : ''}`;
  }
}

AssemblyResolution.modelName = 'AssemblyResolution';
AssemblyResolution.fields = {
  assemblyId: fk({
    to: Assembly.modelName,
    as: 'assembly',
    relatedName: '_assemblieResolutionsSet',
  }),
  distributionKeyId: fk({
    to: DistributionKey.modelName,
    as: 'distributionKey',
    relatedName: '_assemblyResolutionsSet',
  }),
  assemblyLawArticleId: fk({
    to: AssemblyLawArticle.modelName,
    as: 'assemblyLawArticle',
    relatedName: '_assemblieResolutionsSet',
  }),
  assemblyMajorityModeId: fk({
    to: AssemblyMajorityMode.modelName,
    as: 'assemblyMajorityMode',
    relatedName: '_assemblieResolutionsSet',
  }),
  secondVoteResolutionId: fk({
    to: AssemblyResolution.modelName,
    as: 'secondVoteResolution',
    relatedName: '_parentAssemblieResolutionsSet',
  }),
  parentAssemblyResolutionId: fk({
    to: AssemblyResolution.modelName,
    as: 'parentAssemblyResolution',
    relatedName: '_secondVoteResolutionsSet',
  }),
  reopenedVoteResolutionId: fk({
    to: AssemblyResolution.modelName,
    as: 'reopenedVoteResolution',
    relatedName: '_secondReopenedVoteResolutionsSet',
  }),
  initialVoteResolutionId: fk({
    to: AssemblyResolution.modelName,
    as: 'initialVoteResolution',
    relatedName: '_reopenedVoteResolutionsSet',
  }),
};
export default AssemblyResolution;
