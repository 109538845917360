import React from 'react';
import { useSelector } from 'react-redux';

import { activeVisio } from '../session/session.selectors';
import ZoomCardView from './zoom.card.view';

const ZOOM_FRAME_ID = 'zoom-iframe';

const ZoomModal = () => {
  const currentVisio = useSelector(activeVisio);

  if (!currentVisio) {
    return null;
  }

  return (
    <ZoomCardView
      isVisible={currentVisio.show}
      className={currentVisio.className}
    >
      <iframe
        className="h-100 w-100"
        src={`/assemblies/${currentVisio.assemblyId}/details/zoom/join-button`}
        title="zoom"
        id={ZOOM_FRAME_ID}
      />
    </ZoomCardView>
  );
};

export default ZoomModal;
export const MZoomModal = React.memo(ZoomModal);
