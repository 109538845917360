import { Annexe } from 'redux-orm-models/assembly.model.type';

import { assembliesApi } from '../../api/base.api';

export async function getAssemblies(page = 1) {
  return await assembliesApi.list({ page });
}

export const getTodayAssemblies = (dateFilter: any) => async () => {
  return await assembliesApi.today({ dateFilter });
};

export const getFutureAssemblies = (dateFilter: any) => async () => {
  return await assembliesApi.future({ dateFilter });
};

export const getAnomalyAssemblies = async () => {
  return await assembliesApi.anomaly();
};

export const getClosedAssemblies =
  ({ usePagination, itemsPerPage }: any) =>
  async () => {
    return await assembliesApi.closed({ usePagination, itemsPerPage });
  };

export async function createAssembly(assembly: any) {
  return await assembliesApi.create(assembly);
}

export async function getAssemblyById(id: any) {
  return await assembliesApi.getById({ id });
}

export async function startAssembly(assembly: any) {
  return await assembliesApi.start(assembly);
}

export async function updateAssembly(assembly: any) {
  return await assembliesApi.update(assembly);
}

export async function updateAssemblyLegacy(
  assembly: any,
  afterOpenAssembly = false,
) {
  return await assembliesApi.updateLegacy(assembly, afterOpenAssembly);
}

export async function enableVisio(assemblyId: any) {
  return await assembliesApi.enableVisio({ id: assemblyId });
}

export async function disableVisio(assemblyId: any) {
  return await assembliesApi.disableVisio({ id: assemblyId });
}

export async function updateAssemblyWithoutNormalizingData(assembly: any) {
  return await assembliesApi.updateWithoutNormalizingData(assembly);
}

export async function archiveAssembly(assembly: any) {
  return await assembliesApi.archive(assembly);
}

export async function duplicateAssembly(assembly: any) {
  return await assembliesApi.duplicate(assembly);
}

export async function addSymentResolution(assemblyId: any) {
  return await assembliesApi.addSymentResolution({ id: assemblyId });
}

export async function publish(publishDate: any) {
  return await assembliesApi.publishV3(publishDate);
}

export async function publishToOwners(publishDate: any) {
  return await assembliesApi.publish(publishDate);
}

export async function publishToCouncil(id: any) {
  return await assembliesApi.publishCouncil({ id });
}

export async function recallToCouncil(id: any) {
  return await assembliesApi.recallCouncil({ id });
}

export async function getPresenceSheetByAssemblyId(id: any) {
  return await assembliesApi.getPresenceSheet({ id });
}

export async function listAnnexes(
  assemblyId: string,
  type?: AnnexeType,
): Promise<Annexe[]> {
  return await assembliesApi.listAnnexes(assemblyId, type);
}

export async function getAnnexe(
  assemblyId: string,
  annexeId: string,
): Promise<Annexe> {
  return await assembliesApi.getAnnexe(assemblyId, annexeId);
}

export async function createAnnexes(
  assemblyId: string,
  annexesPayload: CreateAnnexePayload[],
) {
  const formData = new FormData();
  let i = 0;
  for (const annexe of annexesPayload) {
    formData.append(`file[${i}]`, annexe.file);
    formData.append(`title[${i}]`, annexe.title);
    if (annexe.resolutionId) {
      formData.append(`resolutionId[${i}]`, annexe.resolutionId);
    }
    if (annexe.participantId) {
      formData.append(`participantId[${i}]`, annexe.participantId);
    }
    if (annexe.type) {
      formData.append(`type[${i}]`, annexe.type);
    }
    i++;
  }
  return await assembliesApi.createAnnexes(assemblyId, formData);
}

export async function deleteAnnexe(assemblyId: string, annexeId: string) {
  return await assembliesApi.deleteAnnexe(assemblyId, annexeId);
}

export async function updateAnnexe(
  assemblyId: string,
  annexeId: string,
  annexePayload: UpdateAnnexePayload,
) {
  return await assembliesApi.updateAnnexe(assemblyId, annexeId, annexePayload);
}
export interface CreateAnnexePayload {
  file: File;
  title: string;
  resolutionId?: string;
  participantId?: string;
  type?: AnnexeType;
}

export interface UpdateAnnexePayload {
  title: string;
  resolutionId?: string | null;
  participantId?: string | null;
}

export type AnnexeType = 'minutes' | 'invitation';
