import React from 'react';

import { DATE_FORMATS, localizedFormat } from '../../date.utils';

const AssemblyMinutesSendToSignersHTMLView = ({ assembly }) => {
  const signaturesStyle = { fontSize: 64, paddingBottom: 30, color: 'white' };

  let scrutineers = assembly.minutesSigners.scrutineers;
  if (scrutineers && scrutineers.constructor.name !== 'Array') {
    scrutineers = Object.values(assembly.minutesSigners.scrutineers);
  }
  const today = Date.now();

  return (
    <>
      <html>
        <head>
          <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
          <link
            rel="stylesheet"
            href="https://media.syment.com/ckeditor/contents.css"
          />
        </head>
        <body>
          <div dangerouslySetInnerHTML={{ __html: assembly.minutesHtml }} />
          <table style={{ width: '100%' }}>
            <tr>
              <td>Fait à {assembly.minutesSigners.address}</td>
              <td align="right">
                Le {localizedFormat(today, DATE_FORMATS.dayMonthYearSlash)}
              </td>
            </tr>
          </table>
          <br />
          <br />
          <table style={{ width: '100%' }}>
            <tr>
              <td valign="top">
                <p>Le président de séance</p>
                {assembly.minutesSigners.president.firstname}{' '}
                {assembly.minutesSigners.president.lastname}
                <p style={signaturesStyle}>[[s|0]]</p>
              </td>
              <td valign="top">
                <p>Le secrétaire de séance</p>
                {assembly.minutesSigners.secretary.firstname}{' '}
                {assembly.minutesSigners.secretary.lastname}
                <p style={signaturesStyle}>[[s|1]]</p>
              </td>
              {scrutineers && (
                <td>
                  <p>
                    {scrutineers.length > 1
                      ? 'Les scrutateurs'
                      : 'Le scrutateur'}
                  </p>

                  {scrutineers?.map((scrutineer, i) => (
                    <span key={i}>
                      {scrutineer.firstname} {scrutineer.lastname}
                      <p style={signaturesStyle}>[[s|{i + 2}]]</p>
                    </span>
                  ))}
                </td>
              )}
            </tr>
          </table>
        </body>
      </html>
    </>
  );
};

export default AssemblyMinutesSendToSignersHTMLView;
