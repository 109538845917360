import React from 'react';
import { FlexRow, PageTitle } from 'ui-kit';

type HeaderProps = {
  className?: string;
};

const Header: React.FC<HeaderProps> = () => {
  return (
    <FlexRow>
      <PageTitle>Usagers</PageTitle>
    </FlexRow>
  );
};

export default Header;
