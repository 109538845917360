import { assemblyResolutionVotesApi } from '../../api/base.api';

export async function getAssemblyResolutionVotesByAssemblyResolutionId(
  assemblyId,
  assemblyResolutionId,
  mineAndRepresented = false,
) {
  let assemblyResolutionVotes = [];
  if (mineAndRepresented) {
    assemblyResolutionVotes =
      await assemblyResolutionVotesApi.listMineAndRepresentedByAssemblyResolutionId(
        {
          id: assemblyResolutionId,
        },
      );
  } else {
    assemblyResolutionVotes =
      await assemblyResolutionVotesApi.listByAssemblyResolutionId({
        id: assemblyResolutionId,
      });
  }
  return assemblyResolutionVotes.map((assemblyResolutionVote) => ({
    ...assemblyResolutionVote,
    assemblyId,
    assemblyResolutionId,
  }));
}

/**
 * update an assemblyResolutionVote
 * NOTE: the api wait for an id resolution=xxx;member=xxx
 * @param {Object} assemblyResolutionVote
 * @param {String} assemblyResolutionVote.id - @see  AssemblyResolutionVote.getIdForTheApi
 * @param {String} assemblyResolutionVote.vote - the 'vote' (aka proposition name) that will be sent
 * @param {Boolean} assemblyResolutionVote.earlyVotedByManager - is the manager that vote for somes
 * @param {Boolean} assemblyResolutionVote.keepForSecondVote - (API NOT YET READY - SO IT'S ALWAYS TRUE) - pass it down when api is ready
 */
export async function updateAssemblyResolutionVote(assemblyResolutionVote) {
  let payload = {
    id: assemblyResolutionVote.id,
    vote: assemblyResolutionVote.vote,
    keepForSecondVote: assemblyResolutionVote.keepForSecondVote,
    secondVote: assemblyResolutionVote.secondVote,
  };

  if (assemblyResolutionVote.earlyVotedByManager !== undefined) {
    payload.earlyVotedByManager = assemblyResolutionVote.earlyVotedByManager;
  }

  return await assemblyResolutionVotesApi.update(payload);
}

export async function voteByAssemblyResolutionId(assemblyResolutionVote) {
  return await assemblyResolutionVotesApi.voteByAssemblyResolutionId(
    assemblyResolutionVote,
  );
}

export async function voteByAssemblyId(
  id,
  { votes, checkedByParticipant, signName },
) {
  return await assemblyResolutionVotesApi.voteByAssemblyId({
    id,
    votes,
    checkedByParticipant,
    signName,
  });
}

export async function deleteAssemblyResolutionVote(assemblyResolutionVote) {
  return await assemblyResolutionVotesApi.delete(assemblyResolutionVote);
}
