import React, { Suspense } from 'react';

import { Loader } from '../../../ui-kit';
import {
  LoaderWrapper,
  Main,
  NavigationContentLayout,
  NavigationLayoutWrapper,
} from './navigation-layout.style';

type NavigationLayoutProps = {
  className?: string;
};

const NavigationLayout: React.FC<NavigationLayoutProps> = ({
  className,
  children,
}) => {
  const LoaderAny = Loader as any;
  return (
    <NavigationLayoutWrapper className={`${className || ''}`}>
      <Main>
        <Suspense
          fallback={
            <LoaderWrapper>
              <LoaderAny />
            </LoaderWrapper>
          }
        >
          <NavigationContentLayout className="nav-content">
            {children}
          </NavigationContentLayout>
        </Suspense>
      </Main>
    </NavigationLayoutWrapper>
  );
};

export default NavigationLayout;
