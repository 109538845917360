import {
  ActivationStatusTag,
  getBagdeVariant,
} from 'pages/members/index/components/listing/renderer/cell/enabled-renderer';
import {
  getFirstLine,
  getSecondLine,
} from 'pages/members/index/components/listing/renderer/cell/name-renderer';
import { File } from 'pills/members/member.type';
import React from 'react';
import { LG_GREY } from 'styles/constant';
import {
  Avatar,
  Box,
  PageSubTitle,
  PageTitle,
  PenIcon,
  UnstyledLink,
} from 'ui-kit';

import { HeaderWrapper } from './header.style';

type HeaderProps = {
  email: string | null;
  memberId: string;
  userEnabled: boolean;
  className?: string;
  avatar?: File;
  firstName?: string;
  lastName?: string;
  companyName?: string | null;
  councilMember?: boolean;
  disabled: boolean;
};

const Header: React.FC<HeaderProps> = ({
  email,
  memberId,
  userEnabled,
  avatar,
  firstName,
  lastName,
  companyName,
  councilMember,
  disabled,
}) => {
  const variant = getBagdeVariant(email, userEnabled);
  const firstLine = getFirstLine(companyName, lastName, firstName, email);
  const secondLine = getSecondLine(companyName, lastName, firstName);
  return (
    <HeaderWrapper spanTitle={!secondLine}>
      <Avatar
        src={avatar?.url}
        firstName={firstName}
        lastName={lastName}
        companyName={companyName}
        councilMember={councilMember}
        className="avatar"
        variant="xl"
      />
      <Box
        display="flex"
        gap="15px"
        alignItems="baseline"
        className="page-title"
      >
        <PageTitle>{firstLine}</PageTitle>
        <ActivationStatusTag
          color={variant.color}
          bgColor={variant.bgColor}
          content={variant.content}
          className="activation-status"
        />
      </Box>
      <PageSubTitle>{secondLine}</PageSubTitle>

      {!disabled && (
        <UnstyledLink to={`/member/details/${memberId}/edit`}>
          <PenIcon color={LG_GREY} size="xs" hasCircle />
        </UnstyledLink>
      )}
    </HeaderWrapper>
  );
};

export default Header;
