import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from './icon';

const CheckSquareIcon = ({ size, background, className, disabled = false }) => (
  <Icon icon={faCheckSquare} size={size} className={className} />
);

CheckSquareIcon.propTypes = {
  size: PropTypes.oneOf(['10x', '7x', '5x', '3x', '2x', 'lg', 'sm', 'xs']),
};

export default CheckSquareIcon;
