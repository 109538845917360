export const RESOURCE_NAMES = {
  CALENDAR: 'calendar',
  PLAINTS: 'plaints',
  ASSEMBLIES: 'assemblies',
  POLLS: 'polls',
  RESIDENCIES: 'residencies',
  PARCELS: 'parcels',
  MEMBERS: 'members',
  PROVIDERS: 'providers',
  NOTIFICATIONS: 'notifications',
  DOCUMENTS: 'documents',
  DOCUMENT_TYPES: 'document types',
  COMPANY: 'company',
  OTHERS: 'others',
};
