import { Model } from 'redux-orm';

class Council extends Model {
  constructor(data) {
    super({
      ...data,
    });
  }

  /**
   * Checks if councilMembers match requirements : one president and (at least) one member
   */
  isCouncilOk() {
    if (this.ref.members < 2) {
      return false;
    }

    return true;
  }
}

Council.modelName = 'Council';
Council.fields = {};
export default Council;
