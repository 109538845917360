import { FormApi } from 'final-form';
import React, { Fragment, useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { GroupInputLabel } from 'redux-form-utils/fieldComponents';
import { maxLength, required } from 'redux-form-utils/fieldValidators';
import { GREY_700 } from 'styles/constant';
import { Box, MapMarkerAltIcon } from 'ui-kit';

import { ContactFieldWrapper, LinkNoAddressWrapper } from './address.style';
import AutoCompleteField from './autocomplete-field';
import { AddressVariant, PlaceType, PlaceTypeType } from './type';

type AddressProps = {
  className?: string;
  values: any;
  form: FormApi<any, Partial<any>>;
  setIsAddressValid: React.Dispatch<React.SetStateAction<boolean>>;
  variant?: AddressVariant;
  showErrorOnLoad?: boolean;
  withIcon?: boolean;
  isAddressRequired?: boolean;
};

const Address: React.FC<AddressProps> = ({
  values,
  form,
  setIsAddressValid,
  variant = 'fullwidth',
  showErrorOnLoad = false,
  withIcon = true,
  isAddressRequired = true,
}) => {
  const [addressMode, setAddressMode] = useState<PlaceTypeType>(
    values.placeType ?? PlaceType.google,
  );
  const [isValidating, setIsValidating] = useState(false);

  const _required = isAddressRequired ? required : undefined;
  const customClass = variant === 'fullwidth' ? 'tw-w-full' : '';

  useEffect(() => {
    form.mutators.setValue('placeType', addressMode);
    if (addressMode === PlaceType.custom) {
      setIsAddressValid(true);
    }
  }, [form.mutators, addressMode, setIsAddressValid]);

  useEffect(() => {
    if (!isAddressRequired) setIsAddressValid(true);
    setIsValidating(isAddressRequired);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddressRequired]);

  return (
    <Fragment
      key={
        isAddressRequired
          ? 'addresss_form_required_key'
          : 'addresss_form_unrequired_key'
      }
    >
      <Field
        className={`tw-hidden ${customClass}`}
        name={`placeType`}
        component={GroupInputLabel}
        label={``}
      />
      <Field
        className={`tw-hidden ${customClass}`}
        name={`placeId`}
        component={GroupInputLabel}
        label={``}
      />
      {addressMode === PlaceType.google && (
        <ContactFieldWrapper variant={variant}>
          {withIcon && (
            <MapMarkerAltIcon
              color={GREY_700}
              size="lg"
              className="address-marker"
            />
          )}
          <AutoCompleteField
            line1={values.line1}
            form={form}
            setIsAddressValid={setIsAddressValid}
            className={`line1-autocomplete ${customClass}`}
            variant={variant}
            showErrorOnLoad={showErrorOnLoad}
            isValidating={isValidating}
            setIsValidating={setIsValidating}
            required={isAddressRequired !== false}
          />
        </ContactFieldWrapper>
      )}
      <LinkNoAddressWrapper
        className={`mode-switch mode-switch-${addressMode} tw-mb-2`}
        variant={variant}
        withIcon={withIcon}
      >
        <Link
          to={{}}
          className="text-info font-xs"
          onClick={() =>
            addressMode === PlaceType.google
              ? setAddressMode(PlaceType.custom)
              : setAddressMode(PlaceType.google)
          }
        >
          {addressMode === PlaceType.google
            ? "Je ne trouve pas l'adresse"
            : 'Réessayer les suggestions'}
        </Link>
      </LinkNoAddressWrapper>

      <ContactFieldWrapper
        className={addressMode === PlaceType.google ? 'tw-hidden' : ''}
        variant={variant}
      >
        {withIcon && (
          <MapMarkerAltIcon
            color={GREY_700}
            size="lg"
            className={
              addressMode === PlaceType.custom ? '-tw-translate-y-2' : ''
            }
          />
        )}
        <Field
          name="line1"
          label={`Adresse${_required ? '*' : ''}`}
          component={GroupInputLabel}
          validate={addressMode === PlaceType.google ? undefined : _required}
          className={`line1-manual ${customClass} tw-mb-4 ${
            showErrorOnLoad && !values.line1 && values.line1 !== null
              ? 'is-invalid'
              : ''
          }`}
        />
      </ContactFieldWrapper>

      <Box
        ml={withIcon ? '27px' : ''}
        display="flex"
        flexDirection="column"
        gap="3px"
        maxWidth={variant === 'fullwidth' ? '100%' : '300px'}
        className="address-detail"
      >
        <Field
          name="line2"
          label="Complément d'adresse"
          component={GroupInputLabel}
          validate={maxLength(255)}
          className={`${customClass} mb-0`}
        />
        <div className="d-flex">
          <div className="me-2">
            <Field
              name="zipcode"
              label={`Code postal${_required ? '*' : ''}`}
              component={GroupInputLabel}
              validate={maxLength(30) && _required}
              disabled={addressMode === PlaceType.google}
              className={`${customClass} ${
                (showErrorOnLoad || isValidating) &&
                !values.zipcode &&
                values.zipcode !== null
                  ? 'is-invalid'
                  : ''
              }`}
            />
          </div>
          <Field
            name="city"
            label={`Ville${_required ? '*' : ''}`}
            component={GroupInputLabel}
            validate={maxLength(255) && _required}
            disabled={addressMode === PlaceType.google}
            className={`${customClass} ${
              (showErrorOnLoad || isValidating) &&
              !values.city &&
              values.city !== null
                ? 'is-invalid'
                : ''
            }`}
          />
        </div>
        <Field
          name="country"
          label={`Pays${_required ? '*' : ''}`}
          component={GroupInputLabel}
          validate={maxLength(255) && _required}
          disabled={addressMode === PlaceType.google}
          className={`${customClass} mb-0 ${
            (showErrorOnLoad || isValidating) &&
            !values.country &&
            values.country !== null
              ? 'is-invalid'
              : ''
          }`}
        />
      </Box>
    </Fragment>
  );
};
export default Address;
