import { fk, Model } from 'redux-orm';

import { getId } from '../utils';
import DistributionKey from './distributionKey.model';
import Parcel from './parcel.model';

class ParcelDistributionKey extends Model {
  constructor(data) {
    super({
      ...data,
      parcelId: getId(data.parcel),
      distributionKeyId: getId(data.distributionKey),
    });
  }

  toString() {
    return `ParcelDistributionKey: ${this.name}`;
  }
}

ParcelDistributionKey.modelName = 'ParcelDistributionKey';
ParcelDistributionKey.fields = {
  parcelId: fk({
    to: Parcel.modelName,
    as: 'parcel',
    relatedName: '_parcelDistributionKeysSet',
  }),
  distributionKeyId: fk({
    to: DistributionKey.modelName,
    as: 'distributionKey',
    relatedName: '_parcelDistributionKeysSet',
  }),
};

export default ParcelDistributionKey;
