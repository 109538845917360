export const SET_MINUTES_STEP = 'SESSION/SET_MINUTES_STEP';

export type SetMinutesStepProps = {
  step: number;
  assemblyId: string;
};
export const setMinutesStep = ({ step, assemblyId }: SetMinutesStepProps) => {
  return {
    type: SET_MINUTES_STEP,
    payload: {
      step,
      assemblyId,
    },
  };
};
