import finalFormTryCatch from '../../final-form-utils/tryCatchWrapper.final-form';
import { getClassNameFromLocation } from './session.actions.helper';

export const SET_FILTER = 'SESSION/SET_FILTER';
export const CLEAR_FILTER = 'SESSION/CLEAR_FILTER';
export const SET_SEARCH_LISTING = 'SESSION/SET_SEARCH_LISTING';
export const SET_FILTER_LISTING = 'SESSION/SET_FILTER_LISTING';
export const CLEAR_SEARCH_LISTING = 'SESSION/CLEAR_SEARCH_LISTING';
export const CLEAR_FILTER_LISTING = 'SESSION/CLEAR_FILTER_LISTING';

export const SHOW_VISIO = 'SESSION/SHOW_VISIO';
export const HIDE_VISIO = 'SESSION/HIDE_VISIO';
export const CLEAR_VISIO = 'SESSION/CLEAR_VISIO';
export const CREATE_VISIO = 'SESSION/CREATE_VISIO';
export const CLOSE_VISIO = 'SESSION/CLOSE_VISIO';

export function setFilter({
  inPersonChecked,
  onlineChecked,
  powerGivenChecked,
  noneChecked,
  votedChecked,
  notVotedChecked,
}) {
  return {
    type: SET_FILTER,
    payload: {
      inPersonChecked,
      onlineChecked,
      powerGivenChecked,
      noneChecked,
      votedChecked,
      notVotedChecked,
    },
  };
}

export const clearFilter = () => {
  return {
    type: CLEAR_FILTER,
  };
};

export const showVisio = ({ assemblyId, defaultClassName }) => {
  let className = defaultClassName;
  if (!className) {
    className = getClassNameFromLocation();
  }
  return {
    type: SHOW_VISIO,
    payload: {
      assemblyId,
      className,
    },
  };
};
export const createVisio = ({ assemblyId, defaultClassName }) => {
  let className = defaultClassName;
  if (!className) {
    className = getClassNameFromLocation();
  }
  return {
    type: CREATE_VISIO,
    payload: {
      assemblyId,
      className,
    },
  };
};
export const closeVisio = () => {
  return {
    type: CLOSE_VISIO,
  };
};

export const showVisioAction =
  (assemblyId, className) => (_, onSubmitSuccess) => (dispatch) =>
    finalFormTryCatch(async () => {
      dispatch(showVisio({ assemblyId, className }));
      return;
    });

export const hideVisio = ({ assemblyId }) => {
  return {
    type: HIDE_VISIO,
    payload: {
      assemblyId,
    },
  };
};

export const hideVisioAction =
  (assemblyId) => (_, onSubmitSuccess) => (dispatch) =>
    finalFormTryCatch(async () => {
      dispatch(hideVisio({ assemblyId }));
      return;
    });

export const clearVisio = ({ userId }) => {
  return {
    type: CLEAR_VISIO,
    payload: {
      userId,
    },
  };
};

export function setFilterListing({ id }) {
  return {
    type: SET_FILTER_LISTING,
    payload: {
      id,
    },
  };
}

export const clearFilterListing = () => {
  return {
    type: CLEAR_FILTER_LISTING,
  };
};

export function setSearchListing({ match }) {
  return {
    type: SET_SEARCH_LISTING,
    payload: {
      match,
    },
  };
}

export const clearSearchListing = () => {
  return {
    type: CLEAR_SEARCH_LISTING,
  };
};
