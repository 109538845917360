import styled from 'styled-components';
import { StyledButtonWrapper } from 'ui-kit/styled-button/styled-button.style';

export const MemberSummaryRendererWrapper = styled.div`
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 70px;
  grid-row-gap: 27px;

  padding-left: 35px;
  padding-right: 35px;
  padding-top: 20px;
  padding-bottom: 23px;

  & ${StyledButtonWrapper} {
    grid-column: 3/4;
    width: min-content;
    justify-self: end;
  }
`;

export const ButtonsWrapper = styled.div`
  grid-column: 1/-1;
  justify-self: end;
  display: flex;
  gap: 10px;
`;
