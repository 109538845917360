import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GREY_900 } from 'styles/constant';

type TextLinkProps = {
  className?: string;
  label: string;
  to: string;
};

const TextLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  & a {
    text-decoration: none;
    color ${GREY_900};
    font-size: 14px;
  }
  gap: 10px;
  cursor:pointer;
`;

const LinkBodyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const TextLink: React.FC<TextLinkProps> = ({
  className,
  label,
  to,
  children,
}) => {
  return (
    <TextLinkWrapper className={`${className || ''}`}>
      <Link to={to} className="text">
        <LinkBodyWrapper>
          {children}
          {label}
        </LinkBodyWrapper>
      </Link>
    </TextLinkWrapper>
  );
};

type ProxyTextLinkProps = {
  className?: string;
  label: string;

  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const ProxyTextLink: React.FC<ProxyTextLinkProps> = ({
  className,
  label,

  onClick,
  children,
}) => {
  return (
    <TextLinkWrapper className={`${className || ''}`} onClick={onClick}>
      <LinkBodyWrapper>
        {children}
        {label}
      </LinkBodyWrapper>
    </TextLinkWrapper>
  );
};

export default TextLink;
