import { fk, Model } from 'redux-orm';

import { getId } from '../utils';
import AssemblyMajorityMode from './assemblyMajorityMode.model';

class AssemblyLawArticle extends Model {
  static getFullName(assemblyLawArticle) {
    return `${assemblyLawArticle.article} ${
      assemblyLawArticle.paragraph ? assemblyLawArticle.paragraph + ')' : ''
    } : ${assemblyLawArticle.title} ${
      assemblyLawArticle.subtitle ? '- ' + assemblyLawArticle.subtitle : ''
    }`;
  }
  constructor(data) {
    super({
      ...data,
      assemblyMajorityModeId: data.assemblyMajorityMode
        ? getId(data.assemblyMajorityMode)
        : null,
    });
  }
}

AssemblyLawArticle.modelName = 'AssemblyLawArticle';
AssemblyLawArticle.fields = {
  assemblyMajorityModeId: fk({
    to: AssemblyMajorityMode.modelName,
    as: 'assemblyMajorityMode',
    relatedName: '_assemblieLawArticlesSet',
  }),
};

export default AssemblyLawArticle;
