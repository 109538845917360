import React from 'react';

const cardBodyMinimizedStyle = {
  height: '0px',
  padding: '0px',
};
const cardMinimizedStyle = {
  display: 'none',
};
const cardBaseStyle = {};

function ZoomCardView({ children, isVisible, className, customStyle = {} }) {
  let cardStyle = isVisible
    ? { ...cardBaseStyle, ...customStyle }
    : { ...cardBaseStyle, ...cardMinimizedStyle, ...customStyle };
  return (
    <div className={`card shadow ${className}`} style={cardStyle}>
      <div
        className="card-body"
        style={isVisible ? {} : cardBodyMinimizedStyle}
      >
        {children}
      </div>
    </div>
  );
}
export default ZoomCardView;
