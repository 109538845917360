import { attr, fk, Model } from 'redux-orm';

import { getId } from '../utils';
import ParcelType from './parcelType.model';
// import Member from './member.model';
import Residency from './residency.model';
import User from './user.model';

class Parcel extends Model {
  constructor(data) {
    /**
     * @see https://redux-orm.github.io/redux-orm/basics/fields#one-to-many
     * All we need to do is pass an ownerId when creating each parcel.
     */
    super({
      ...data,
      ownerId: getId(data.owner),
      tenantId: getId(data.tenant),
      residencyId: getId(data.residency),
      typeId: data.type,
    });
  }

  static getFullName(parcel) {
    const companyName = parcel.ref.owner.companyName
      ? parcel.ref.owner.companyName + ' - '
      : '';
    return `${companyName}${User.getName(parcel.ref.owner)} - ${
      parcel.type.name
    } ${parcel.number}`;
  }

  get building() {
    return this.ref.building?.id;
  }

  get fullName() {
    return Parcel.getFullName(this);
  }

  toString() {
    return `Parcel: ${this.name}`;
  }
}

Parcel.modelName = 'Parcel';
Parcel.fields = {
  ownerChange: attr({ getDefault: () => false }),
  tenantChange: attr({ getDefault: () => false }),
  // tenantId: fk({
  //   to: Member.modelName,
  //   as: 'tenant',
  //   relatedName: '_tenantedParcelsSet', // not tenantedParcels as the associations are 'selectors (must be used with the redux-store.entities)
  // }),
  // ownerId: fk({
  //   to: Member.modelName,
  //   as: 'owner',
  //   relatedName: '_ownedParcelsSet',
  // }),
  residencyId: fk({
    to: Residency.modelName,
    as: 'residency',
    relatedName: '_parcelsSet',
  }),
  typeId: fk({
    to: ParcelType.modelName,
    as: 'type',
    relatedName: '_parcelsSet',
  }),
};
export default Parcel;
