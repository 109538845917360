import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { authUserSelector } from '../auth/auth.selectors';

function Gate({ user, grant, children, ...rest }) {
  if (Array.isArray(grant)) {
    return children({
      granted: grant.map((access) => access({ user })),
      ...rest,
    });
  }
  return children({ granted: grant({ user }), ...rest });
}

function mapStateToProps(state) {
  return {
    user: authUserSelector(state),
  };
}
Gate.propTypes = {
  /**
   * Function (or Array of function) that receive the current user, and the current user role
   * ({ user, userRole }) => any
   */
  grant: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]).isRequired,
  /**
   * Funciton that receive ({ granted : any }) => component
   */
  children: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Gate);
