import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Icon from './icon';

const CheckCircleIcon = ({ size, background }) => (
  <Icon icon={faCheckCircle} size={size} />
);

CheckCircleIcon.propTypes = {
  size: PropTypes.oneOf(['10x', '7x', '5x', '3x', '2x', 'lg', 'sm', 'xs']),
  className: PropTypes.string,
};

export default CheckCircleIcon;

export const GreenCheckCircle = ({ className, size = '' }) => {
  return (
    <GreenWrapper className={className}>
      <svg
        width="28"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="14"
          cy="14.5"
          r={size === 'sm' ? '7' : '8'}
          fill="#2BC952"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3703 11.7929C17.9798 11.4024 17.3467 11.4024 16.9561 11.7929L12.3628 16.3862L10.7071 14.7306C10.3166 14.34 9.68342 14.34 9.29289 14.7306C8.90237 15.1211 8.90237 15.7542 9.29289 16.1448L11.6557 18.5076C12.0462 18.8981 12.6794 18.8981 13.0699 18.5076L18.3703 13.2071C18.7609 12.8166 18.7609 12.1834 18.3703 11.7929Z"
          fill="#36383A"
        />
      </svg>
    </GreenWrapper>
  );
};

GreenCheckCircle.propTypes = {
  size: PropTypes.oneOf(['10x', '7x', '5x', '3x', '2x', 'lg', 'sm', 'xs']),
  className: PropTypes.string,
};

export const GreenWrapper = styled.div`
  margin-left: -10px;
  margin-top: -2px;
`;
