import React from 'react';
import ModaledPageRoute from 'router/ModaledPageRoute';

import ActivationUsersModal from './components/activation-users-modal';

export const IndexModalPath = [
  '/member/:residencyId/activation',
  '/member/:residencyId/activation/:userId',
];

const MemberModalPageRoutes: React.FC = () => {
  return (
    <>
      <ModaledPageRoute
        parentPath="/member"
        path={IndexModalPath}
        modal={ActivationUsersModal}
        isPrivate
        minWidth="800px"
        className="activation-users-modal"
        exact={true}
      />
    </>
  );
};

export default MemberModalPageRoutes;
