import PropTypes from 'prop-types';
import React from 'react';

function ImageLabel({ src, alt, height, width, label }) {
  return (
    <div className="d-flex rounded bg-light text-muted font-10 align-items-center mt-3 mb-3">
      <img
        src={src}
        alt={alt || label}
        height={height}
        width={width}
        className="img-thumbnail small me-2"
      />
      {label}
    </div>
  );
}

ImageLabel.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  label: PropTypes.string,
};

ImageLabel.defaultProps = {
  height: '24',
  width: '24',
};

export default ImageLabel;
