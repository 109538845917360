import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from './icon';

const CheckIcon = ({ size, background, className, disabled = false }) => (
  <Icon icon={faCheck} size={size} className={className} />
);

CheckIcon.propTypes = {
  size: PropTypes.oneOf(['10x', '7x', '5x', '3x', '2x', 'lg', 'sm', 'xs']),
};

export default CheckIcon;
