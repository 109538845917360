export type AutoCompleteAddress = {
  line1: string;
  zipcode: string;
  city: string;
  country: string;
  placeId: string;
};

export const PlaceType = {
  google: 'google',
  custom: 'custom',
};

export type PlaceTypeType = (typeof PlaceType)[keyof typeof PlaceType];

export type AddressVariant = 'fullwidth' | 'fixedwidth';
