import { membersApi } from 'api/base.api';
import { QUERY_KEYS } from 'api/queryKeys';
import { RecipientsFields } from 'pills/assemblyRecipients/assemblyRecipients.api.type';
import { Member } from 'pills/members/member.type';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from 'react-query';

type UseMemberDetailsProps = {
  memberId: string;
  enabled?: boolean;
};

export const getMemberDetailsById = (memberId: string): Promise<Member> => {
  return membersApi.getById({ id: memberId });
};

export const getMemberDetailsKey = (memberId: string) => {
  return [QUERY_KEYS.memberDetails, memberId];
};

export const getAssemblyRecipientsByAssemblyIdKey = (assemblyId: string) => {
  return `/assemblies/${assemblyId}/recipients`;
};

export function useMemberDetails({
  memberId,
  enabled = true,
}: UseMemberDetailsProps) {
  const { data, refetch, isLoading, isFetching } = useQuery(
    getMemberDetailsKey(memberId),
    () => getMemberDetailsById(memberId),
    { enabled },
  );
  return { data, refetch, isLoading, isFetching };
}

async function updateMember(member: Partial<Member>): Promise<Member> {
  return await membersApi.updateV3(member as any);
}

export function useMemberUpdate({
  memberId,
}: UseMemberDetailsProps): UseMutationResult<Member, unknown, Partial<Member>> {
  const queryClient = useQueryClient();

  return useMutation(updateMember, {
    onSuccess: (newMember: Member) => {
      queryClient.setQueryData<Member | undefined>(
        getMemberDetailsKey(memberId),

        (oldData: Member | undefined) => {
          return oldData !== undefined
            ? {
                ...oldData,
                companyName: newMember.companyName,
                firstName: newMember.firstName,
                lastName: newMember.lastName,
                phone: newMember.phone,
                email: newMember.email,
                address: newMember.address,
              }
            : oldData;
        },
      );
      queryClient.invalidateQueries(getMemberDetailsKey(memberId));
    },
  });
}

//RECIPIENTS
type UseRecipientDetailsProps = {
  recipientId: string;
  enabled?: boolean;
};

async function generateRecipients({
  id,
  payload,
}: {
  id: string;
  payload: { status: string };
}): Promise<any> {
  return await membersApi.generateRecipients({ id, data: payload });
}

export const useGenerateRecipients = () => {
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    { id: string; payload: { status: string } }
  >(({ id, payload }) => generateRecipients({ id, payload }), {
    onSuccess: (res, req) => {
      void queryClient.invalidateQueries(
        getAssemblyRecipientsByAssemblyIdKey(req.id),
      );
    },
  });
};

export const getRecipientDetailsById = (
  recipientId: string,
): Promise<RecipientsFields> => {
  return membersApi.getRecipientById({ id: recipientId });
};

export const getRecipientDetailsKey = (recipientId: string) => {
  return [QUERY_KEYS.recipientDetails, recipientId];
};

export function useRecipientDetails({
  recipientId,
  enabled = true,
}: UseRecipientDetailsProps) {
  const { data, refetch, isLoading, isFetching } = useQuery(
    getRecipientDetailsKey(recipientId),
    () => getRecipientDetailsById(recipientId),
    { enabled },
  );
  return { data, refetch, isLoading, isFetching };
}

async function updateRecipient({
  id,
  assemblyId,
  payload,
}: {
  id: string;
  assemblyId: string;
  payload: Partial<RecipientsFields>;
}): Promise<any> {
  return await membersApi.recipientUpdate({ id, assemblyId, data: payload });
}

export const useRecipientUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    { id: string; assemblyId: string; payload: Partial<RecipientsFields> }
  >(
    ({ id, assemblyId, payload }) =>
      updateRecipient({ id, assemblyId, payload }),
    {
      onSuccess: (res, req) => {
        void queryClient.invalidateQueries(
          getAssemblyRecipientsByAssemblyIdKey(req.assemblyId),
        );
      },
    },
  );
};

async function addCoRecipient({
  id,
  assemblyId,
  payload,
}: {
  id: string;
  assemblyId: string;
  payload: Partial<RecipientsFields>;
}): Promise<any> {
  return await membersApi.coRecipientAdd({ id, assemblyId, data: payload });
}

export const useCoRecipientAdd = () => {
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    { id: string; assemblyId: string; payload: Partial<RecipientsFields> }
  >(
    ({ id, assemblyId, payload }) =>
      addCoRecipient({ id, assemblyId, payload }),
    {
      onSuccess: (res, req) => {
        void queryClient.invalidateQueries(
          getAssemblyRecipientsByAssemblyIdKey(req.assemblyId),
        );
      },
    },
  );
};

async function removeCoRecipient({
  id,
  assemblyId,
}: {
  id: string;
  assemblyId: string;
}): Promise<any> {
  return await membersApi.coRecipientRemove({ id, assemblyId });
}

export const useCoRecipientRemove = () => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, { id: string; assemblyId: string }>(
    ({ id, assemblyId }) => removeCoRecipient({ id, assemblyId }),
    {
      onSuccess: (res, req) => {
        void queryClient.invalidateQueries(
          getAssemblyRecipientsByAssemblyIdKey(req.assemblyId),
        );
      },
    },
  );
};
