import cs from 'classnames';
import React from 'react';

const Badge = ({
  primary,
  secondary,
  info,
  danger,
  success,
  light,
  warning,
  bgPrimary,
  rounded,
  className,
  children,
  ...rest
}: any) => (
  <span
    {...rest}
    className={cs('badge', className, {
      'badge-primary': primary,
      'badge-secondary': secondary,
      'badge-info': info,
      'badge-danger': danger,
      'badge-success': success,
      'bg-light text-dark': light,
      'badge-warning text-dark': warning,
      'bg-primary text-dark': bgPrimary,
      'rounded-pill': rounded,
    })}
  >
    {children}
  </span>
);

export default Badge;
