import { Model, attr } from 'redux-orm';
import { ROLES } from '../pills/users/users.constants';

// const TYPE_UNKNOWN = 0;
const TYPE_PRO = 1;
// const TYPE_COUNCIL = 2;
// const TYPE_SCI = 3;
const TYPE_INDIVIDUAL = 4; // A company created by an owner or tenant.
const PROFILE_MANAGER = 'PROFILE_MANAGER';

class User extends Model {
  static getName(
    { companyName, firstName, lastName, name } = {},
    capitalize = false,
  ) {
    let ret = '';
    if (companyName) {
      return companyName;
    } else if (firstName || lastName) {
      ret = `${(lastName && lastName.toUpperCase()) || ''} ${firstName || ''}`;
    } else {
      ret = name;
    }
    return (
      (capitalize
        ? ret.replace(/\b\w/g, (c) => c.toUpperCase())
        : ret
      )?.trim() || ''
    );
  }

  static getAvatar(member) {
    if (!member) {
      return null;
    }
    const { file, avatar } = member;
    const res = file?.url || avatar?.url;
    if (typeof avatar === 'string') return avatar;
    return res || null;
  }
  // PROVIDERS
  isGrantedToAccessProviders() {
    return this.ref.rights?.access?.provider;
  }
  isGrantedToAddProvider() {
    return this.ref.rights?.add?.provider;
  }
  // ASSEMBLIES
  isGrantedToAccessAssemblies() {
    return this.ref.rights?.access?.assembly;
  }
  isGrantedToAddAssembly() {
    return this.ref.rights?.add?.assembly;
  }
  // POLLS
  isGrantedToAccessPolls() {
    return this.ref.rights?.access?.poll;
  }
  isGrantedToAddPoll() {
    return this.ref.rights?.add?.poll;
  }
  // PLAINTS
  isGrantedToAccessPlaints() {
    return this.ref.rights?.access?.plaint;
  }
  isGrantedToAddPlaint() {
    return this.ref.rights?.add?.plaint;
  }
  // RESIDENCIES
  isGrantedToAccessResidencies() {
    return this.ref.rights?.access?.residency;
  }
  isGrantedToAddResidency() {
    return this.ref.rights?.add?.residency;
  }
  // ROLES
  isSomething() {
    return this.ref.rights?.is_something;
  }
  isTenant() {
    return this.ref.rights?.is_tenant;
  }
  isIndividualCompany() {
    return this.ref.currentCompany?.type === TYPE_INDIVIDUAL;
  }
  isProfessionalCompany() {
    return this.ref.currentCompany?.type === TYPE_PRO;
  }
  isAdmin() {
    return this.ref.rights?.is_admin;
  }
  isManager() {
    return this.ref.rights?.is_manager;
  }
  isProvider() {
    return this.ref.rights?.is_provider;
  }
  isModerator() {
    return (
      this.ref.currentProfile === PROFILE_MANAGER &&
      (this.isAdmin() || this.isManager())
    );
  }
  isOwner() {
    return this.ref.rights?.is_owner;
  }

  // DEBT - WE have to delete them as they are confusing
  isGrantedToViewResidency() {
    return this.ref.rights?.access?.residency;
  }
  isGrantedToEditResidency() {
    return this.ref.rights?.add?.residency;
  }
  // --------------------

  hasDiscussionActivated() {
    // when not defined, discussion default is true
    return this.normalizedParameters.discussion === false ? false : true;
  }
  hasCompany() {
    return !!this.ref.currentCompany;
  }
  get avatar() {
    return User.getAvatar(this.ref);
  }
  get name() {
    return User.getName(this.ref);
  }
  /**
   * From something like :
   * parameters: [
            {
              name: 'showFirstConnectionProfileModal',
              metadata: {
                value: 'false'
              }
            }
          ]
      to
      normalizedParameters : { showFirstConnectionProfileModal : true }
   */
  get normalizedParameters() {
    return this.ref.parameters.reduce(
      (acc, { name, metadata: { value } }) => ({
        ...acc,
        [name]: Boolean(value),
      }),
      {},
    );
  }

  toString() {
    return `User: ${this.token}`;
  }
}
User.modelName = 'User';
User.fields = {
  token: attr({ getDefault: () => null }),
  refresh_token: attr({ getDefault: () => null }),
  roles: attr({ getDefault: () => [ROLES.ANONYME] }),
};
export default User;
