import React, { useEffect, useState } from 'react';
import { GREY_500 } from 'styles/constant';
import { Box, InfoCircleIcon, RTooltip, Span } from 'ui-kit';
import SortIcon from 'ui-kit/table/sort-icon';

import { NormalizedParcels } from '../helper';
import {
  BodyWrapper,
  HeaderWrapper,
  ParcelsListingWrapper,
  Row,
} from './parcels-listing.style';

type ParcelsListingProps = {
  className?: string;
  parcels: NormalizedParcels[];
};

const ParcelsListing: React.FC<ParcelsListingProps> = ({
  className,
  parcels,
}) => {
  const [isSortedDesc, setIsSortedDesc] = useState(true);
  const [sortedParcels, setSortedParcels] = useState(parcels);

  useEffect(() => {
    if (isSortedDesc) {
      setSortedParcels(parcels.sort((a, b) => a.ref.localeCompare(b.ref)));
    } else {
      setSortedParcels(parcels.sort((a, b) => b.ref.localeCompare(a.ref)));
    }
  }, [isSortedDesc, parcels]);

  return (
    <ParcelsListingWrapper className={`${className || ''}`}>
      <HeaderWrapper>
        <Box
          onClick={() => setIsSortedDesc((sorted) => !sorted)}
          display="flex"
          gap="5px"
        >
          REF <SortIcon isSorted={true} isSortedDesc={isSortedDesc} />
        </Box>
        <Span>TYPE DE LOTS</Span>
        <Box display="flex" gap="5px">
          <Span>TANTIÈMES </Span>
          <RTooltip
            title="Clé générale"
            place="right"
            delayHide={undefined}
            delayShow={undefined}
            effect={undefined}
            zIndex={undefined}
          >
            <InfoCircleIcon color={GREY_500} />
          </RTooltip>
        </Box>
      </HeaderWrapper>
      <BodyWrapper>
        {sortedParcels.map((parcel) => (
          <Row key={parcel.ref}>
            <Span>{parcel.ref}</Span>
            <Span>{parcel.type}</Span>
            <Span>{parcel.tantieme || ''}</Span>
          </Row>
        ))}
      </BodyWrapper>
    </ParcelsListingWrapper>
  );
};

export default ParcelsListing;
