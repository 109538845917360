import PropTypes from 'prop-types';
import React from 'react';

import ModalLayout from '../pages/layouts/modal.layout';
import PagedRoute from './PagedRoute';

const ModaledPageRoute = ({ modal, ...rest }) => (
  <PagedRoute page={modal} layout={ModalLayout} {...rest} />
);

ModaledPageRoute.propTypes = {
  modal: PropTypes.elementType.isRequired,
  parentPath: PropTypes.string.isRequired,
};

export default ModaledPageRoute;
