import styled from 'styled-components';
import { PageSubTitle, StyledBadge } from 'ui-kit';

export const HeaderWrapper = styled.div<{ spanTitle: boolean }>`
  margin-top: 26px;
  display: grid;
  grid-template-columns: 90px auto 1fr 26px;
  grid-template-rows: 40px 40px;

  justify-content: center;
  align-items: center;
  grid-column-gap: 15px;

  & > div.avatar {
    grid-row: 1 / 3;
  }
  & ${StyledBadge} {
    height: 22px;
    align-self: end;
    transform: translateY(-7px);
  }

  & > div.page-title {
    grid-row: ${({ spanTitle }) => (spanTitle ? '1 / 3' : '1 / 2')};
    grid-column: 2 / 3;
  }
  & > ${PageSubTitle} {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }

  & > a {
    grid-column: 4/5;
  }
`;
