import { DATE_FORMATS, localizedFormat } from './date.utils';
import t from './i18n';

export function groupBy(arr, fn) {
  return arr.reduce((acc, current) => ({ ...acc, [fn(current)]: current }), {});
}

export function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function noop() {}

/**
 * Flat recursively the given array
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat#reduce_and_concat
 * @param {Array} - Array to flat
 * @returns {Array} - Flattened array
 */
export function flatten(arr) {
  return arr.reduce(
    (acc, val) =>
      Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val),
    [],
  );
}

/**
 * Give the delta as a string between the given number and now()
 * @param {Number} time
 * @returns {String} - the relative string time
 */
export function fromNow(time) {
  const parsedTime = parseInt(time);
  if (Number.isNaN(parsedTime)) {
    return t('utils.from_now.wait');
  }
  const deltaByDayFromNow = Math.trunc(
    (Date.now() - time) / (1000 * 60 * 60 * 24),
  );
  switch (true) {
    case deltaByDayFromNow === 0:
      return t('utils.from_now.today');
    case deltaByDayFromNow === 1:
      return t('utils.from_now.yesterday');
    case deltaByDayFromNow === 2:
      return t('utils.from_now.twoDays');
    case deltaByDayFromNow >= 3 && deltaByDayFromNow <= 7:
      return t('utils.from_now.thisWeek');
    case deltaByDayFromNow >= 8 && deltaByDayFromNow <= 14:
      return t('utils.from_now.lastWeek');
    case deltaByDayFromNow >= 15 && deltaByDayFromNow <= 22:
      return t('utils.from_now.lastLastWeek');
    case deltaByDayFromNow >= 23 && deltaByDayFromNow <= 30:
      return t('utils.from_now.lastLastLastWeek');
    case deltaByDayFromNow >= 31 && deltaByDayFromNow <= 60:
      return t('utils.from_now.month');
    case deltaByDayFromNow >= 61:
      return t('utils.from_now.more');
    default:
      return '';
  }
}

/**
 * format a given date to dd/mm/yyyy
 * @param {Date|String|Number} date - the date to be formatted (@see https://www.w3schools.com/js/js_date_formats.asp)
 * @returns {String} the date representation in the format dd/mm/yyyy as a string
 */
export function formatDate(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}

export function nMonthsAgo(n) {
  var d = new Date();
  d.setMonth(d.getMonth() - n);
  return localizedFormat(d, DATE_FORMATS.YearMonthDay);
}

/**
 * make the sum of the given entries
 * @param  {...any} numbers - list of numbers. It will be flattened and parsed
 * @returns {Number} the sum
 * @example
 *   sum(1,2,3)
 *   sum([1,2,3])
 *   sum(['1',2],3)
 */
export function sum(...numbers) {
  return flatten(numbers).reduce((acc, nb) => acc + parseFloat(nb), 0);
}

/**
 * Creates an object composed of the picked object properties.
 * @param {Object} object - The source object.
 * @param {String} keys - The properties paths to pick.
 */
export function pick(object, ...keys) {
  return keys.reduce((acc, key) => {
    return { ...acc, [key]: object[key] };
  }, {});
}

/**
 * Creates an object composed of object properties.
 * @param {Object} object - The source object.
 * @param {String} keys - The properties paths to omit.
 */
export function omit(object, ...keys) {
  return pick(
    object,
    ...Object.keys(object).filter(
      (key) => !keys.find((omittedKey) => omittedKey === key),
    ),
  );
}

export function getId(resource) {
  return resource && typeof resource === 'object'
    ? resource.id
    : resource?.split('/').pop();
}

function toUTC(date) {
  const d = new Date(date);
  return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 12));
}

export function iso(date) {
  return toUTC(date).toISOString().split('T')[0];
}

/**
 * Format hour date to HH:mm
 * > format(new Date(date), 'HH:mm') @see https://date-fns.org/v2.11.1/docs/format
 * @param  {Date} date - the date where to pick the hour
 * @returns {String} formatted date as HH:mm
 */
export function formatHour(date) {
  return localizedFormat(new Date(date), 'HH:mm');
}

export function today() {
  return localizedFormat(new Date(), DATE_FORMATS.YearMonthDay);
}

export function tomorrow() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return localizedFormat(tomorrow, DATE_FORMATS.YearMonthDay);
}

/**
 * Transform given file into base64content
 * @param {File}
 */
export function createBase64(file) {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => resolve(e.target.result);
    fileReader.readAsDataURL(file);
  });
}

export function capitalize(text) {
  return text?.length ? text[0].toUpperCase() + text.slice(1) : '';
}

export function capitalizeEachWord(text) {
  return text.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
}

export function printHTML(html, title) {
  let mywindow = window.open(
    '',
    'PRINT',
    'height=650,width=900,top=100,left=150',
  );
  mywindow.document.write(`
  <html>
    <head>
      <title>${title}</title>
    </head>
    <body>
    ${html}
    </body>
  </html>
  `);
  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/

  mywindow.print();
  mywindow.close();

  return true;
}

export const download = (data, docTitle) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', docTitle);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const pluralize = (name, nbItems) => {
  return `${name}${nbItems > 1 ? 's' : ''}`;
};
