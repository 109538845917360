import cs from 'classnames';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

import { Loader } from '../../ui-kit';
import classes from './mainLayout.module.css';

const { main, content } = classes;

export default function MainLayout({
  withMenu,
  bgWhite,
  children,
  breadcrumb,
}) {
  return (
    <div className="w-100 d-flex">
      <div className="d-flex flex-grow-1">
        <main
          className={cs(
            main,
            { 'bg-light': !bgWhite, 'bg-white': bgWhite },
            'overflow-auto vh-100',
          )}
        >
          <Suspense
            fallback={
              <div className={content}>
                <Loader />
              </div>
            }
          >
            <div className={content}>
              {breadcrumb && (
                <div className="container flex-grow-0">{breadcrumb}</div>
              )}
              {children}
            </div>
          </Suspense>
        </main>
      </div>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
