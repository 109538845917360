import classNames from 'classnames';
import React from 'react';

import RTooltip from '../rTooltip';
import { AvatarWrapper, DefaultAvatarWrapper, Span } from './avatar.style';

type AvatarProps = {
  src?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string | null;
  label?: string;
  councilMember?: boolean;
  className?: string;
  variant: 'sm' | 'base' | 'lg' | 'xl';
};

const DEFAUT_AVATAR_BACKGROUND_COLOR = [
  '#FDE1BA',
  '#FFEBD0',
  '#FDF2BA',
  '#FFF7D0',
  '#FDBDBA',
  '#FFD2D0',
];

const randomColor = (): string => {
  const randomIndex = Math.floor(
    Math.random() * DEFAUT_AVATAR_BACKGROUND_COLOR.length,
  );
  return DEFAUT_AVATAR_BACKGROUND_COLOR[randomIndex];
};

const Avatar = ({
  src,
  firstName,
  lastName,
  companyName,
  label,
  councilMember,
  className,
  variant,
}: AvatarProps) => {
  const variants = {
    sm: 'tw-h-7 tw-w-7',
    base: 'tw-h-8 tw-w-8',
    lg: 'tw-h-9 tw-w-9',
    xl: 'tw-h-20 tw-w-20',
  };

  const getAvatar = () => {
    if (src) {
      return (
        <img
          src={src}
          alt="avatar"
          className={classNames(
            'tw-rounded-full tw-object-cover',
            variants[variant],
            {
              'tw-outline tw-outline-blue-400 tw-outline-2 tw-outline-offset-2':
                councilMember,
            },
          )}
        />
      );
    }

    return (
      <DefaultAvatarWrapper
        backgroundColor={randomColor()}
        className={classNames('defaut-avatar', variants[variant], {
          'tw-outline tw-outline-blue-400 tw-outline-2 tw-outline-offset-2':
            councilMember,
        })}
      >
        {companyName ? (
          <Span>{companyName.charAt(0).toUpperCase()}</Span>
        ) : (
          <>
            {firstName && <Span>{firstName.charAt(0).toUpperCase()}</Span>}
            {lastName && <Span>{lastName.charAt(0).toUpperCase()}</Span>}
          </>
        )}
      </DefaultAvatarWrapper>
    );
  };

  return (
    <AvatarWrapper className={`${className ? className : ''} d-flex`}>
      {councilMember ? (
        <RTooltip
          title={'Conseil Syndical'}
          place="right"
          effect="solid"
          delayShow={200}
          delayHide={200}
          zIndex={1}
          className="avatar-concil-member"
        >
          {getAvatar()}
        </RTooltip>
      ) : (
        getAvatar()
      )}
      &nbsp;
      {label}
    </AvatarWrapper>
  );
};

export default React.memo(Avatar);
