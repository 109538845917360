import styled from 'styled-components';

const Divider = styled.div<{ mt?: string; mb?: string }>`
  height: 1px;
  width: 100%;
  background-color: #e2e6e9;
  margin-top: ${(props) => props.mt || '0px'};
  margin-bottom: ${(props) => props.mb || '0px'};
`;

export default Divider;
