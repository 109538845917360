import { useCallback, useEffect, useState } from 'react';

/**
 * React hooks that 
 * @example   
 * const { data: user, error } = useRequest(() =>
    authApi.accountToActivate({ token }),
  );
 * @param {Function} request - function that return a promise
 * @returns {Object} {data, error}
 */
function useRequest(request) {
  const [state, setState] = useState({ data: null, error: null });
  const doRequest = useCallback(
    function () {
      request()
        .then((data) => setState({ data, error: null }))
        .catch((error) => setState({ data: null, error }));
    },
    [request],
  );
  // eslint-disable-next-line
  useEffect(doRequest, []);
  return state;
}

export default useRequest;
