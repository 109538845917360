import { distributionKeysApi } from '../../api/base.api';

export async function getDistributionKeysByResidencyId(residencyId) {
  return await distributionKeysApi
    .listByResidencyId({ id: residencyId })
    .then((distributionKeys) =>
      distributionKeys.map((distributionKey) => ({
        ...distributionKey,
        residencyId,
      })),
    );
}

export async function getDistributionKeyById(id) {
  return await distributionKeysApi.getById({ id });
}

export async function createDistributionKeyByResidencyId(distributionKey) {
  const createdDistributionKey = await distributionKeysApi.createByResidencyId(
    distributionKey,
  );
  return createdDistributionKey;
}

export async function updateDistributionKey(distributionKey) {
  const updatedDistributionKey = await distributionKeysApi.update(
    distributionKey,
  );
  return updatedDistributionKey;
}

export async function deleteDistributionKey(distributionKey) {
  return await distributionKeysApi.delete(distributionKey);
}
