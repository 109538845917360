import { useInvitableUsers } from 'pages/members/index/components/hooks/useInvitableUsers';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { GREY_600 } from 'styles/constant';
import { PaperPlaneIcon, Span, SpinnerIcon, StyledButton } from 'ui-kit';

export type Origin = 'index' | 'detail';
type ActivationButtonProps = {
  className?: string;
  residencyId: string;
  userId: string;
  origin?: Origin;
  memberId?: string;
};

const ActivationButton: React.FC<ActivationButtonProps> = ({
  residencyId,
  userId,
  memberId,
  origin = 'index',
}) => {
  const history = useHistory();
  const { data, isLoading } = useInvitableUsers({ residencyId });

  const currentUser = data?.find((user) => user.id === userId);

  const onClick = () => {
    history.push(
      origin === 'index'
        ? `/member/${residencyId}/activation/${currentUser?.id}`
        : `/member/details/${memberId}/${residencyId}/activation/${userId}`,
    );
  };
  return (
    <StyledButton gap="10px" px="22px" onClick={onClick}>
      {isLoading && currentUser ? (
        <SpinnerIcon />
      ) : (
        <>
          <PaperPlaneIcon color={GREY_600} />
          <Span fontWeight="700" fontSize="14px" color={GREY_600}>
            {currentUser && currentUser.passwordRequestedAt
              ? `Renvoyer l'email d'activation de compte`
              : `Envoyer l'email d'activation de compte`}
          </Span>
        </>
      )}
    </StyledButton>
  );
};

export default ActivationButton;
