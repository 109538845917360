export const QUERY_KEYS = {
  assembly: 'assembly',
  assemblyParticipants: 'assembly-participants',
  assemblyParticipantAnnouncedStatus: 'assembly-participant-announced-status',
  assemblyTemplates: 'assembly-templates',
  company: 'company',
  convocation: 'convocation',
  convocationFiles: 'convocation-files',
  councilMembers: 'council-members',
  exportResidentsEre: 'export-residents-ere',
  exportResidentsList: 'export-residents-list',
  invitableUsers: 'invitable-users',
  members: 'members',
  memberDetails: 'member-details',
  recipientDetails: 'recipient-details',
  minute: 'minute',
  minuteFiles: 'minute-files',
  notifications: 'notifications',
  parcels: 'parcels',
  parcelTypes: 'parcel-types',
  postageQuote: 'postage-quote',
  postageStatus: 'postage-status',
  sendInvitation: 'send-invitation',
  user: 'user',
  users: 'users',
  annexes: 'assembly-annexes',
  annexe: 'assembly-annexe',
  resolutions: 'assembly-resolutions',
  participants: 'assembly-participants',
  resolutionTemplates: 'resolutionTemplates',
};
