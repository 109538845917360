import { assemblyParticipantsApi } from '../../api/base.api';
import { convertAssemblyParticipantStatusToLegacyStatus } from './assemblyParticipants.constants';

export async function getAssemblyParticipantsByAssemblyId(
  assemblyId,
  noExternal,
) {
  const response = await assemblyParticipantsApi.listByAssemblyId({
    id: assemblyId,
    noExternal,
  });

  return response.data.map((assemblyParticipant) => ({
    ...assemblyParticipant,
    status: convertAssemblyParticipantStatusToLegacyStatus(
      assemblyParticipant.status,
    ),
    announcedStatus: convertAssemblyParticipantStatusToLegacyStatus(
      assemblyParticipant.announcedStatus,
    ),
    assemblyId,
  }));
}

export async function updateAssemblyParticipantStatus(
  assemblyId,
  announcedStatus,
) {
  return assemblyParticipantsApi.sendAssemblyParticipantAnnouncedStatusByAssemblyId(
    {
      id: assemblyId,
      announcedStatus,
    },
  );
}

export async function getAssemblyParticipantAnnouncedStatusByAssemblyId(
  assemblyId,
) {
  return assemblyParticipantsApi.getAssemblyParticipantAnnouncedStatusByAssemblyId(
    assemblyId,
  );
}

export async function updateAssemblyParticipantEarlyVotesReceivedAt({
  participantId,
  earlyVotesReceivedAt,
}) {
  return assemblyParticipantsApi.updateAssemblyParticipantEarlyVotesReceivedAt({
    participantId,
    earlyVotesReceivedAt,
  });
}

export async function deleteAssemblyParticipantEarlyVotes(
  assemblyId,
  participantId,
) {
  await assemblyParticipantsApi.deleteEarlyVotes({
    participantId,
  });
  return getAssemblyParticipantsByAssemblyId(assemblyId);
}
