import React from 'react';

function Switch({ label, ...inputRest }) {
  return (
    <div className="form-check form-switch">
      <label className="form-check-label">
        <input type="checkbox" className="form-check-input" {...inputRest} />
        {label}
      </label>
    </div>
  );
}

export default Switch;
