import Assembly from '../../redux-orm-models/assembly.model';
import { ACTION_TYPES as EntitiesActionTypes } from '../entities/entities.actions';

const reducer = (orm) => (state, action) => {
  const { type, payload } = action;
  const session = orm.session(state);
  if (payload?.entity === Assembly.modelName) {
    const { Residency, AssemblyParticipant, AssemblyResolution, Member } =
      session;
    switch (type) {
      case EntitiesActionTypes.SYNC: {
        const { attributes: assemblies } = payload;
        assemblies
          .filter((assembly) => assembly.residency?.id)
          .forEach((assembly) => {
            Residency.upsert(assembly.residency);
          });
        break;
      }
      case EntitiesActionTypes.UPSERT:
      case EntitiesActionTypes.UPDATE: {
        const { attributes: assembly } = payload;
        if (assembly.residency?.id) {
          Residency.upsert(assembly.residency);
        }
        assembly.participants?.forEach((participant) => {
          if (typeof participant.member === 'object') {
            Member.upsert(participant.member);
          }
          AssemblyParticipant.upsert({
            ...participant,
            assemblyId: assembly.id,
          });
        });
        // Parent resolution first - then upsert the children. if not, ReduxOrm can't make the foreign key and crash
        const resolutions = assembly.resolutions?.filter(
          (resolution) => typeof resolution === 'object',
        );

        resolutions
          ?.filter((resolution) => resolution.position)
          .forEach((resolution) =>
            AssemblyResolution.upsert({
              ...resolution,
              secondVoteResolution: null,
              reopenedVoteResolution: null,
              assemblyId: assembly.id,
            }),
          );
        resolutions
          ?.filter((resolution) => !resolution.position)
          .forEach((resolution) =>
            AssemblyResolution.upsert({
              ...resolution,
              assemblyId: assembly.id,
              parentResolution: null,
              initialVoteResolution: null,
              secondVoteResolution: null,
              reopenedVoteResolution: null,
            }),
          );
        resolutions?.forEach((resolution) =>
          AssemblyResolution.upsert(resolution),
        );
        break;
      }
      default:
        break;
    }
  }
  return session.state;
};

export default reducer;
