import styled from 'styled-components';

import { Span } from '../../typography';

export const Wrapper = styled.div`
  background-color: #e9e9eb;
  border-radius: 2px;

  height: ${(props) => props.height || '4px'};
  width: 100%;
`;

export const BarWrapper = styled.div`
  border-radius: 8px;
  height: 100%;
  overflow: hidden;
  display: flex;
  border: 0.5px solid #ced4da;
`;

export const Bar = styled.div`
  width: ${(props) => props.value}%;
  height: 100%;
  background-color: ${(props) => props.color};
  border-radius: 0 0 0 0;
`;

export const StartBar = styled(Bar)`
  border-radius: 2px 0 0 2px;
`;
export const EndBar = styled(Bar)`
  border-radius: 0 2px 2px 0;
`;

export const VpcProgressionVoteWrapper = styled.div``;

export const LegendWrapper = styled.div`
  margin-top: 6px;
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
`;

export const Legend = styled(Span)`
  font-size: 10px;
  color: #6c757d;
`;
