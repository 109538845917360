import { removeApiHeaderToken } from '../../api/base.api';
import config from '../../api/config';
import Assembly from '../../redux-orm-models/assembly.model';
import AssemblyConvocation from '../../redux-orm-models/assemblyConvocation.model';
import AssemblyLawArticle from '../../redux-orm-models/assemblyLawArticle.model';
import AssemblyMajorityMode from '../../redux-orm-models/assemblyMajorityMode.model';
import AssemblyParticipant from '../../redux-orm-models/assemblyParticipant.model';
import AssemblyResolution from '../../redux-orm-models/assemblyResolution.model';
import AssemblyResolutionComment from '../../redux-orm-models/assemblyResolutionComment.model';
import AssemblyResolutionTemplate from '../../redux-orm-models/assemblyResolutionTemplate.model';
import AssemblyResolutionVote from '../../redux-orm-models/assemblyResolutionVote.model';
import Auth from '../../redux-orm-models/auth.model';
import Comment from '../../redux-orm-models/comment.model';
import Company from '../../redux-orm-models/company.model';
import DistributionKey from '../../redux-orm-models/distributionKey.model';
import Document from '../../redux-orm-models/document.model';
import DocumentType from '../../redux-orm-models/documentType.model';
import File from '../../redux-orm-models/file.model';
import Member from '../../redux-orm-models/member.model';
import Parcel from '../../redux-orm-models/parcel.model';
import ParcelDistributionKey from '../../redux-orm-models/parcelDistributionKey.model';
import ParcelType from '../../redux-orm-models/parcelType.model';
import Proabono from '../../redux-orm-models/proabono.model';
import Residency from '../../redux-orm-models/residency.model';
import User from '../../redux-orm-models/user.model';
import { LS_KEY } from '../../redux-utils/localState';
import { clearCache } from '../entities/requestEntitiesCache.provider';
import { ACTION_TYPES as AuthActionTypes } from './auth.actions';

export const restoreDefaultStore = (ormSession, removeAPIHeader = true) => {
  // be carefull other seems to matter.
  // because of relationship. For instance: AssemblyResolution has a foreign key to Assembly,
  // therefore AssemblyResolution should be deleted AFTER Assembly
  [
    AssemblyResolution,
    AssemblyLawArticle,
    AssemblyMajorityMode,
    AssemblyConvocation,
    AssemblyParticipant,
    AssemblyResolutionVote,
    AssemblyResolutionComment,
    AssemblyResolutionTemplate,
    Assembly,
    Company,
    Comment,
    Document,
    DocumentType,
    DistributionKey,
    File,
    Member,
    Parcel,
    ParcelType,
    Proabono,
    ParcelDistributionKey,
    Residency,
    Auth,
    User,
  ].forEach((modelClass) => {
    ormSession[modelClass.modelName].getQuerySet().delete();
  });
  // let's clear react-query cache
  clearCache();
  // restore default state
  ormSession.User.create({ id: 0 });
  ormSession.Auth.create({ userId: 0, token: null });
  if (removeAPIHeader) removeApiHeaderToken();
};

// // NOTE: As this reducer is 'integrated' o the orm reducer, it receive the session and note the entire state
const reducer = (orm) => (state, action) => {
  const { type, payload } = action;
  const session = orm.session(state);
  switch (type) {
    case AuthActionTypes.LOGIN_SUCCESS:
      restoreDefaultStore(session);
      session.User.create(payload);
      session.Auth.create({
        token: payload.token,
        refreshToken: payload.refreshToken,
        userId: payload.id,
        error: null,
      });
      break;
    case AuthActionTypes.LOGIN_ERROR:
      localStorage.removeItem(LS_KEY);
      session.Auth.create({
        error: payload.message,
      });
      break;
    case AuthActionTypes.LOGIN_WITH_COOKIES_SUCCESS: {
      restoreDefaultStore(session, false);
      session.User.create(payload);
      session.Auth.create({
        token: payload.token,
        refreshToken: payload.refreshToken,
        userId: payload.id,
        finishUrl: payload?.finishUrl,
        leaveUrl: payload?.leaveUrl,
        error: null,
      });
      break;
    }
    case AuthActionTypes.LOGOUT: {
      localStorage.removeItem(LS_KEY);

      break;
    }
    case AuthActionTypes.LOGOUT_THEN_V2: {
      localStorage.removeItem(LS_KEY);
      window.location = config.v4Url;
      break;
    }
    default:
      break;
  }
  // increase robustness to avoid 'no user in the current state' - it must be always a 'auth' (even if anonyme)
  // If somes call dispatch(logout()) and remove the only one anonyme user it will break the rest of the app
  // So we ensure to create a user if (by mistake ?) we delete the anon user
  // TODO : find an onterway to ensure that the 'anonymous' user can't be deleted
  // Maybe by overriding : https://redux-orm.github.io/redux-orm/api/Model#-delete⇒-undefined
  if (session.Auth.getQuerySet().count() === 0) {
    session.Auth.create({});
  }
  return session.state;
};
export default reducer;
