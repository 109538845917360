import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const linkStyle = css`
  cursor: pointer;
`;

export const IconLinkWrapper = styled(Link)`
  ${linkStyle}
`;

export const A = styled.a`
  ${linkStyle}
`;
export const IconLabel = styled.span`
  opacity: 1;
  color: rgba(33, 37, 41, 1);

  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
`;

export const ImageWrapper = styled.div`
  postion: relative;
  & img {
    display: block;
    width: 100%;
  }
`;

export const NotificationPoint = styled.div`
  position: absolute;
  opacity: 1;
  background-color: rgba(255, 0, 50, 1);
  width: 10px;
  height: 10px;
  border-radius: 10px;
  transform: translate(25px, 13px);
`;
