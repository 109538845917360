import { batch } from 'react-redux';
import { createAction } from 'redux-actions';

import Assembly from '../../redux-orm-models/assembly.model';
import AssemblyConvocation from '../../redux-orm-models/assemblyConvocation.model';
import AssemblyLawArticle from '../../redux-orm-models/assemblyLawArticle.model';
import AssemblyMajorityMode from '../../redux-orm-models/assemblyMajorityMode.model';
import AssemblyParticipant from '../../redux-orm-models/assemblyParticipant.model';
import AssemblyResolution from '../../redux-orm-models/assemblyResolution.model';
import AssemblyResolutionComment from '../../redux-orm-models/assemblyResolutionComment.model';
import AssemblyResolutionTemplate from '../../redux-orm-models/assemblyResolutionTemplate.model';
import AssemblyResolutionVote from '../../redux-orm-models/assemblyResolutionVote.model';
import Comment from '../../redux-orm-models/comment.model';
import Company from '../../redux-orm-models/company.model';
import Council from '../../redux-orm-models/council.model';
import DistributionKey from '../../redux-orm-models/distributionKey.model';
import Document from '../../redux-orm-models/document.model';
import DocumentType from '../../redux-orm-models/documentType.model';
import File from '../../redux-orm-models/file.model';
import Member from '../../redux-orm-models/member.model';
import Parcel from '../../redux-orm-models/parcel.model';
import ParcelDistributionKey from '../../redux-orm-models/parcelDistributionKey.model';
import ParcelType from '../../redux-orm-models/parcelType.model';
import Proabono from '../../redux-orm-models/proabono.model';
import Residency from '../../redux-orm-models/residency.model';
import User from '../../redux-orm-models/user.model';
import { addPrefixToActionTypes } from '../../redux-utils/utils';
import { logout } from '../auth/auth.actions';
import { authUserSelector } from '../auth/auth.selectors';
import {
  createEntity,
  flushEntities,
  syncEntities,
} from '../entities/entities.actions';
import {
  accessUserAdminOrManager,
  accessUserAssemblies,
  accessUserNotifications,
  accessUserSomething,
} from '../gate/gate.constants';
import { RESOURCE_NAMES } from './dataLoader.constants';
import { dataLoaderShouldRevalidateSelector } from './dataLoader.selectors';

export const ACTION_TYPES = addPrefixToActionTypes(
  {
    RETRY: 'RETRY',
    GET_ALL_SUCCESS: 'GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'GET_ALL_FAILURE',
    GET_ALL_PENDING: 'GET_ALL_PENDING',
    UPDATE_FETCHING_RESOURCE: 'UPDATE_FETCHING_RESOURCE',
  },
  'dataloader',
);

export const getAllSuccess = createAction(ACTION_TYPES.GET_ALL_SUCCESS);
export const getAllFailure = createAction(ACTION_TYPES.GET_ALL_FAILURE);
export const getAllPending = createAction(ACTION_TYPES.GET_ALL_PENDING);
export const retry = createAction(ACTION_TYPES.RETRY);
export const updateFetchingResource = createAction(
  ACTION_TYPES.UPDATE_FETCHING_RESOURCE,
);

export function load({ onPending, onSuccess, onFailure }) {
  return async (dispatch, getState, { api }) => {
    const store = getState();
    const shouldRevalidate = dataLoaderShouldRevalidateSelector(store);
    if (!shouldRevalidate) {
      return onSuccess();
    }
    const user = authUserSelector(store);
    dispatch(getAllPending());
    dispatch(
      flushEntities({
        models: [
          AssemblyResolution,
          AssemblyLawArticle,
          AssemblyMajorityMode,
          AssemblyConvocation,
          AssemblyParticipant,
          AssemblyResolutionVote,
          AssemblyResolutionComment,
          AssemblyResolutionTemplate,
          Assembly,
          Company,
          Comment,
          Council,
          Document,
          DocumentType,
          DistributionKey,
          File,
          Member,
          Parcel,
          ParcelType,
          Proabono,
          ParcelDistributionKey,
          Residency,
          User,
        ],
        filter: (instance) =>
          instance.getClass().modelName === User.modelName
            ? instance.id !== 0 && instance.id !== user.id
            : true,
      }),
    );
    onPending();
    try {
      const resources = [];

      if (accessUserAssemblies({ user })) {
      }

      if (accessUserSomething({ user })) {
      }
      if (accessUserNotifications({ user })) {
      }
      if (accessUserAdminOrManager({ user })) {
      }
      const actions = [];
      let step = 1;
      for await (let { model, request, resourceName } of resources) {
        dispatch(
          updateFetchingResource({
            fetchingResource: resourceName || RESOURCE_NAMES.OTHERS,
            step: step++,
            maxStep: resources.length,
          }),
        );
        const data = await request();
        if (Array.isArray(data)) {
          actions.push(syncEntities(model.modelName, data));
        } else {
          actions.push(createEntity(model.modelName, data));
        }
      }
      actions.push(getAllSuccess());
      batch(() => actions.map(dispatch));
      onSuccess();
    } catch (error) {
      dispatch(getAllFailure(error));

      dispatch(logout());
      onFailure();
    }
  };
}
