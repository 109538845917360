import React from 'react';
import ModaledPageRoute from 'router/ModaledPageRoute';

import ActivationUsersModal from '../index/components/activation-users-modal';

export const DetailModalPath =
  '/member/details/:id/:residencyId/activation/:userId';
const DetailMemberModalPageRoutes: React.FC = () => {
  return (
    <>
      <ModaledPageRoute
        parentPath="/member/details/:id"
        path={'/member/details/:id/:residencyId/activation/:userId'}
        modal={ActivationUsersModal}
        isPrivate
        minWidth="800px"
        className="activation-users-modal"
      />
    </>
  );
};

export default DetailMemberModalPageRoutes;
