import cs from 'classnames';
import React from 'react';

import { Button } from '.';

const ButtonLink = ({ className, secondary, ...props }) => (
  <Button
    type="button"
    className={cs(className, 'btn-link', { 'text-secondary': secondary })}
    {...props}
  />
);
export default ButtonLink;
