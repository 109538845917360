import { documentsApi } from '../../api/base.api';

export async function getDocuments(page = 1, { residencyId }) {
  return await documentsApi.list({ page, where: { residencyId } });
}

export async function createDocument(document) {
  return await documentsApi.create(document);
}

export async function deleteDocument(document) {
  return await documentsApi.delete(document);
}

export async function getDocumentFileUrlById(id) {
  const document = await documentsApi.getFileUrlById({ id });
  return { id, ...document };
}

export async function getDocumentById(id) {
  return await documentsApi.getById({ id });
}

export async function updateDocument(document) {
  return await documentsApi.update(document);
}
