import styled from 'styled-components';

export const EnabledRendererWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Tag = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: ${({ color }) => color};
  border-radius: 10px;

  padding: 1px 5px;
  padding-right: 6px;
`;

export const Dot = styled.div<{ color: string }>`
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: ${({ color }) => color};
`;

export const ChevronWrapper = styled.div``;
