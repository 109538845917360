import React from 'react';
import { Row } from 'react-table';
import { BG_GREEN, BG_GREY, DARK_TEXT, GREEN } from 'styles/constant';
import { ChevronDownIcon, ChevronRightIcon, Span, StyledBadge } from 'ui-kit';

import { ColumnNames } from '../../../column';
import {
  ChevronWrapper,
  Dot,
  EnabledRendererWrapper,
} from './enabled-renderer.style';
import { variant, variants } from './variant.type';

type EnabledRendererProps = {
  className?: string;
  row: Row<ColumnNames>;
};

export const ActivationStatusTag: React.FC<{
  className?: string;
  bgColor: string;
  color: string;
  content: string;
}> = ({ className, color, bgColor, content }) => {
  return (
    <StyledBadge
      className={`${className || ''}`}
      backgroundColor={bgColor}
      gap="6px"
      pt="1px"
      pr="6px"
      pb="1px"
      pl="5px"
    >
      <Dot color={color} />
      <Span color={color} fontSize="10px" fontWeight="bold">
        {content}
      </Span>
    </StyledBadge>
  );
};

const VARIANTS: variants = {
  activated: {
    bgColor: BG_GREEN,
    color: GREEN,
    content: 'activé',
  },
  deactivated: {
    bgColor: BG_GREY,
    color: DARK_TEXT,
    content: 'non activé',
  },
};
export const getBagdeVariant = (
  email: string | null,
  userEnabled: boolean,
): variant => {
  return email && userEnabled ? VARIANTS.activated : VARIANTS.deactivated;
};

export const EnabledRenderer: React.FC<EnabledRendererProps> = ({
  className,
  row,
}) => {
  const { userEnabled, email } = row.original;
  const variant = getBagdeVariant(email, userEnabled);
  return (
    <EnabledRendererWrapper className={`${className || ''}`}>
      <ActivationStatusTag
        color={variant.color}
        bgColor={variant.bgColor}
        content={variant.content}
      />
      <ChevronWrapper {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
      </ChevronWrapper>
    </EnabledRendererWrapper>
  );
};

export default EnabledRenderer;
