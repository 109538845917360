import Notify, { NotifyToastPosition } from 'notification.utils';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { DARK_TEXT } from 'styles/constant';
import {
  Box,
  Button,
  Divider,
  Loader,
  Modal,
  PaperPlaneIcon,
  Span,
  SpinnerIcon,
  StyledButton,
} from 'ui-kit';

import {
  getInvitableUsersKey,
  useInvitableUsers,
} from '../hooks/useInvitableUsers';
import { useAllMembers } from '../hooks/useMembers';
import {
  getSendActivationKey,
  useSendActivation,
} from '../hooks/useSendActivation';
import {
  ActivationUsersModalWrapper,
  GridHeader,
  GridWrapper,
  TitleWrapper,
} from './activation-users-modal.style';
import GridInvitableItems from './grid-invitable-items';
import {
  getActivationMessage,
  getCurrentResidency,
  getInvitableItems,
  getMembersWithoutEmail,
  getNonInvitableItems,
  getSendMailLabel,
  getTitle,
  getToastMessage,
  getToastTitle,
  getWarningMessage,
} from './helper';
import Residency from './residency';

type ActivationUsersModalProps = {
  className?: string;
  modal: { close: () => void };
};

const ActivationUsersModal: React.FC<ActivationUsersModalProps> = ({
  modal: { close },
}) => {
  const queryClient = useQueryClient();
  const { residencyId, userId } = useParams<{
    residencyId: string;
    userId?: string;
  }>();

  const { data: membersData, isLoading: isLoadingMembers } = useAllMembers({
    residencyId,
  });

  const { data: usersData, isLoading: isLoadingUsers } = useInvitableUsers({
    residencyId,
  });
  const inactiveUsersData = usersData?.filter((user) => !user.enabled);
  const userIds = userId
    ? [userId]
    : inactiveUsersData?.map((inactiveUserData) => inactiveUserData.id);

  const { refetch, isLoading } = useSendActivation({
    residencyId,
    userIds: userIds || null,
  });

  const membersWithoutEmail = getMembersWithoutEmail(membersData);
  const currentResidency = getCurrentResidency(residencyId, membersData);
  const invitableItems = getInvitableItems(inactiveUsersData, userId);
  const nonInvitableItems = userId
    ? []
    : getNonInvitableItems(membersWithoutEmail);

  const title = (
    <TitleWrapper>
      <Span fontSize="17px" fontWeight="700" color={DARK_TEXT}>
        {getTitle(userId)}
      </Span>
    </TitleWrapper>
  );

  const ModalAny = Modal as any;

  const activationMessage = getActivationMessage(inactiveUsersData, userId);
  const warningMessage = getWarningMessage(membersWithoutEmail, userId);
  const sendMailLabel = getSendMailLabel(inactiveUsersData, userId);

  const onSendEmail = async () => {
    await refetch();
    queryClient.removeQueries(getSendActivationKey(residencyId, null), {
      exact: true,
    });
    queryClient.removeQueries(getInvitableUsersKey(residencyId), {
      exact: true,
    });

    Notify.success(
      getToastMessage(userId ? 1 : invitableItems.length),
      getToastTitle(userId ? 1 : invitableItems.length),

      {
        closable: true,
        position: NotifyToastPosition.topRight,
      },
    );
    close();
  };
  return (
    <ModalAny
      title={title}
      onClose={close}
      customModalWrapper={ActivationUsersModalWrapper}
      className="activation-users-modal"
    >
      <Box mt="5px" mb="40px">
        <Box display="flex" flexDirection="column" gap="10px">
          {activationMessage && (
            <Span fontSize="14px" color={DARK_TEXT}>
              {activationMessage}
            </Span>
          )}
          {warningMessage && (
            <Span fontSize="14px" color={DARK_TEXT}>
              {warningMessage}
            </Span>
          )}

          <Box mt="20px" mb="14px">
            <Residency residency={currentResidency} />
          </Box>

          <Box>
            <GridHeader>
              <Span></Span>
              <Span>NOM</Span>
              <Span>EMAIL</Span>
              <Span>DERNIER ENVOI</Span>
            </GridHeader>
            <GridWrapper>
              <GridInvitableItems items={invitableItems} type="invitable" />
              <GridInvitableItems
                items={nonInvitableItems}
                type="notinvitable"
              />
              {userId && <Divider />}
            </GridWrapper>
          </Box>
        </Box>
      </Box>

      {isLoadingMembers && isLoadingUsers ? (
        <Loader size="xs" />
      ) : (
        <Box className="d-flex justify-content-between">
          <Button onClick={() => close()} className="btn btn-light">
            Annuler
          </Button>
          <StyledButton
            onClick={onSendEmail}
            primary
            gap="10px"
            disabled={!inactiveUsersData || inactiveUsersData.length === 0}
          >
            {isLoading ? (
              <SpinnerIcon />
            ) : (
              <>
                <PaperPlaneIcon />
                {sendMailLabel}
              </>
            )}
          </StyledButton>
        </Box>
      )}
    </ModalAny>
  );
};

export default ActivationUsersModal;
