import styled from 'styled-components';
import { GREY_400, GREY_700, LIGHTER_GREY } from 'styles/constant';
import { Span } from 'ui-kit/typography';

import { AddressVariant } from './type';

export const DefaultAddressWrapper = styled.div`
  max-width: 325px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const AutoCompleteAddressWrapper = styled.div`
  margin-top: 8px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${GREY_400};

  padding: 20px 29px 20px 22px;
  display: grid;
  grid-template-columns: 39px 250px 1fr 130px;
  grid-template-rows: 39px 39px;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  & ${Span} {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const FormLabelGroup = styled.div`
  position: relative;
  & .form-label-group input:not(:placeholder-shown) ~ label,
  .form-label-group textarea:not(:placeholder-shown) ~ label {
    font-size: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  & .form-label-group input:not(:placeholder-shown),
  .form-label-group textarea:not(:placeholder-shown) {
    padding-top: 12px;
    padding-bottom: 4px;
  }

  & .form-label-group > input {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  & .form-label-group > label {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;
export const AutoCompleteFieldWrapper = styled.div``;

export const ContactFieldWrapper = styled.div<{ variant: AddressVariant }>`
  max-width: ${({ variant }) => (variant === 'fullwidth' ? '100%' : '325px')};
  display: flex;
  gap: 13px;
  align-items: center;
  width: 100%;
  position: relative;
  & div.form-label-group {
    width: ${({ variant }) => (variant === 'fullwidth' ? '100%' : undefined)};
  }
`;

export const LinkNoAddressWrapper = styled.div<{
  variant: AddressVariant;
  withIcon: boolean;
}>`
  max-width: ${({ variant }) => (variant === 'fullwidth' ? '100%' : '325px')};
  display: flex;
  gap: 13px;
  align-items: center;
  justify-content: between;
  width: 100%;
  position: relative;
  margin-left: ${({ withIcon }) => (withIcon === true ? '30px' : '')};
`;

export const DropDownSuggestionsContainer = styled('div')`
  z-index: 10;
  position: absolute;
  background: #fff;
  left: 0;
  right: 0;
  width: 440px;
`;

export const DropDownSuggestions = styled('ul')`
  border: 1px solid ${LIGHTER_GREY};
  border-radius: 6px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0px;
  border-radius: 6px;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.166821);

  & li:not(:last-child) {
    margin-bottom: 3px;
  }
`;

export const Suggestion = styled('li')`
  display: flex;
  gap: 7px;
  padding: 9px 12px;
  border-bottom: 0.5px solid ${GREY_400};
  color: ${GREY_700};
  font-size: 14px;
  font-weight: 400;

  list-style: none;
  cursor: pointer;

  &:hover {
    background-color: HSLA(209, 72%, 82%, 0.28);

    border-radius: 0px;
  }
`;
