import React from 'react';

export function HTMLViewer({ html, ...rest }) {
  return (
    <div
      className="border shadow rounded-2 position-absolute overflow-auto h-100"
      {...rest}
    >
      <div className="p-4" dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
}

export default HTMLViewer;
