import styled from 'styled-components';

export const ContactWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const MailWrapper = styled.div`
  & > div.ere-consent {
    margin-left: 28px;
  }
`;

export const LastConnectionWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
