import { ASSEMBLY_MINUTES_STATUS } from 'pills/assemblies/assembly.constants';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

import { assemblyMinutesApi } from '../../api/base.api';
import { DATE_FORMATS, localizedFormat } from '../../date.utils';
import { pick } from '../../utils';
import AssemblyMinutesSendToSignersHTMLView from './assemblyMinutesSendToSignersHTML.view';

/**
 * When getting the assembly with minutes properties, it erased some attributes of the assembly.
 * We chose to adapt the api response to our front needs by picking up only the minutes props from the assembly.
 * @param {Object} response
 * @returns {Object} custom assembly with minutes properties
 */
const assemblyMinutesNormalizer = (response) =>
  pick(
    response,
    'id',
    'minutesHtml',
    'minutesSigners',
    'minutesUpdated',
    'minutesGenerated',
    'minutesSignRequestEvents',
    'minutesHtmlSavedAt',
    'minutesStatus',
    'minutesSigned',
    'minutesSent',
    'minutesSignedProof',
    'presenceSheetUpdated',
    'finalSigners',
  );

// #region Lambda Service PDF parameters
const withCSS = ({ minutesHtml }) => {
  return `<head>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <link
        rel="stylesheet"
        href="https://media.syment.com/ckeditor/contents.css"
      />
    </head>
    ${minutesHtml}
    `;
};

const headerHTML = ({ residency, date }) => {
  return `
<div class="styled header">
  <span class="text">P.V - AG Résidence « ${
    residency.displayName
  } » du ${localizedFormat(date, DATE_FORMATS.DayMonthYear)}
  </span>
</div>`;
};
const footerHTML = `
            <div class="styled footer">
                <img height="14" width="14" alt="logo" src="https://media.syment.com/assets/images/logo/syment-square-logo-300x300.png" />
                <span class="text">Procès verbal généré par Syment - <a href="https://www.syment.com" target="blank">www.syment.com</a></span>
                <span class="counter"><span class="page"></span>&nbsp;/&nbsp;<span class="topage"></span></span>
            </div>
        `;
const headerAndFooterCSS = `
            .styled { font-size: 0.5em; color: #999; padding: 30px 10px 30px 10px; }
            .styled a, .styled a:visited, .styled a:hover { color: #5e8ac7; }
            .header { text-align: center }
            .footer span.counter { float: right; }
            .footer img { margin-top: -2px; margin-right: 4px; float: left; }
        `;
const wkOptions = '--zoom 1.3';

function getFullGeneratedAssemblyMinuteHTMLMarkUp(assembly) {
  return ReactDOMServer.renderToStaticMarkup(
    <AssemblyMinutesSendToSignersHTMLView assembly={assembly} />,
  );
}
// #endregion

// #region PDF registration format: Y-m-d_H-i-s
const getDate = () => Date.now();

// #endregion

async function __generateAssemblyMinutePDFByAssemblyId(assemblyId) {
  const updatedAssemblyMinutesHtml = await assemblyMinutesApi.update({
    id: assemblyId,
    minutesHtml: assemblyId.minutesHtml,
  });
  const generatedAssemblyMinutePDFFile =
    await assemblyMinutesApi.lambdaPDFService({
      html: withCSS(updatedAssemblyMinutesHtml),
      options: {
        header_html: headerHTML(updatedAssemblyMinutesHtml),
        footer_html: footerHTML,
        header_and_footer_css: headerAndFooterCSS,
      },
      'wk-options': wkOptions,
    });
  return await assemblyMinutesApi.update({
    id: assemblyId,
    base64Content: generatedAssemblyMinutePDFFile.b64Pdf,
    originalFilename: `PV_modifie_${getDate()}.pdf`,
  });
}

/**
 * Retrieve the minutes associated to the given assemblyId and if the minutes PDF is not generated, it generate a minute in PDF
 * @param {String} assemblyId
 * @returns {Object} an assembly
 */
export async function getAssemblyMinutesByAssemblyId(assemblyId, residency) {
  const assembly = await assemblyMinutesApi
    .getByAssemblyId({
      id: assemblyId,
    })
    .then((response) => {
      return assemblyMinutesNormalizer(response);
    });
  if (assembly.minutesStatus === ASSEMBLY_MINUTES_STATUS.DOCX_GENERATED) {
    return await __generateAssemblyMinutePDFByAssemblyId(assemblyId, residency);
  }
  return assembly;
}

/**
 * Generate an assembly minutes PDF from a minutes HTML and an assembly id
 * @param {Object} assembly
 * @returns {Object} the updated assembly
 */
export async function generateAssemblyMinutesPDF(assembly) {
  const generatedAssemblyMinutesPDFFile =
    await assemblyMinutesApi.lambdaPDFService({
      html: withCSS(assembly),
      options: {
        header_html: headerHTML(assembly),
        footer_html: footerHTML,
        header_and_footer_css: headerAndFooterCSS,
      },
      'wk-options': wkOptions,
    });

  await assemblyMinutesApi.update({
    id: assembly.id,
    minutesHtml: assembly.minutesHtml,
  });

  return await assemblyMinutesApi.update({
    id: assembly.id,
    base64Content: generatedAssemblyMinutesPDFFile.b64Pdf,
    originalFilename: `PV_modifie_${getDate()}.pdf`,
  });
}

export async function resetInitialMinute(assembly) {
  await assemblyMinutesApi.cancelMinutesPdf({
    id: assembly.id,
  });
  return await assemblyMinutesApi.generate({
    id: assembly.id,
  });
}

export async function updateMinutesSignerEmail(
  assembly,
  signerFromEmail,
  signerForwardEmail,
) {
  return await assemblyMinutesApi.minutesSignerForward({
    id: assembly.id,
    signerFromEmail,
    signerForwardEmail,
  });
}

/**
 * Execute 3 required requests to sign an assembly minute
 * @param {Object} assembly form values from assemblyMinutes.form
 * @param {String} assembly.id
 * @param {Object} assembly.minutesSigners
 * @param {Object} assembly.minutesSigners.president { lastname, firstname, email}
 * @param {Object} assembly.minutesSigners.secretary { lastname, firstname, email}
 * @param {Array<Object>} assembly.minutesSigners.scrutineers list of { lastname, firstname, email}
 * @returns {Object} the updated assembly
 * }}
 */
export async function signAssemblyMinutes(assembly) {
  // STEP 1: Generate minutes in PDF format
  const generatedAssemblyMinutesPDFFile =
    await assemblyMinutesApi.lambdaPDFService({
      html: getFullGeneratedAssemblyMinuteHTMLMarkUp(assembly),
      options: {
        header_html: headerHTML(assembly),
        footer_html: footerHTML,
        header_and_footer_css: headerAndFooterCSS,
      },
      'wk-options': wkOptions,
    });
  // STEP 2: Reupdate assemblyMinute with the previous PDF generated
  await assemblyMinutesApi.update({
    id: assembly.id,
    minutesSigners: assembly.minutesSigners,
    base64Content: generatedAssemblyMinutesPDFFile.b64Pdf,
    originalFilename: `PV_modifie_${getDate()}.pdf`,
  });

  // STEP 3: Send signatures by e-mail
  await assemblyMinutesApi.sendSignRequest({
    id: assembly.id,
    minutesSigners: assembly.minutesSigners,
  });

  // STEP 4: retriee AGAIN the minutes BECAUSE the PUT request does not send us the last minutesSendRequestEvents as it is managed by signrequest.com

  return assemblyMinutesApi.getByAssemblyId({ id: assembly.id });
}

export async function cancelAssemblyMinutesSignRequest(id) {
  return await assemblyMinutesApi.cancelSignRequest({ id });
}

export const sendSignRequest = async ({ id, minutesSigners }) => {
  return await assemblyMinutesApi.sendSignRequest({
    id,
    minutesSigners,
  });
};

export const getMinutesFailing = async ({ id }) => {
  return await assemblyMinutesApi.getMinutesFailing({
    id,
  });
};

export async function getAssemblyMinuteFilesByAssemblyId(assemblyId) {
  const files = await assemblyMinutesApi.getFilesByAssemblyId({
    id: assemblyId,
  });
  return files.map((file) => ({ ...file, id: file.file.id }));
}
