import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import config from '../api/config';
import AuthLayout from '../pages/layouts/auth.layout';
import MainLayout from '../pages/layouts/main.layout';
import Auth from '../pills/auth/auth.container';
import { accessPulic } from '../pills/gate/gate.constants';
import Gate from '../pills/gate/gate.container';

const PagedRoute = ({
  layout,
  isPrivate,
  page: Page,
  grant,
  v2bridge = false,
  isAdded = false,
  isView = false,
  isEdited = false,
  ...rest
}) => {
  return (
    <Auth>
      {({ isLogged, user }) => {
        let Layout = layout;

        if (v2bridge) {
          return (
            <Route
              {...rest}
              render={(props) => (
                <Layout withMenu={false} {...props} {...rest}>
                  <Page {...props} {...rest} />)
                </Layout>
              )}
            />
          );
        }
        if (!isPrivate && isLogged) {
          return <Redirect to="/assemblies" />;
        }
        if (isPrivate && !isLogged) {
          if (process.env.NODE_ENV === 'development') {
            return <Redirect to="/login" />;
          }
          window.location = config.v4Url;
          return;
        }
        if (!isPrivate && !isLogged) {
          // used replace Layout component
          Layout = AuthLayout;
        }

        return (
          <Route
            {...rest}
            render={(props) => (
              <Gate grant={grant}>
                {({ granted, ...whenGrantIsClonedByModalLayoutProps }) => {
                  if (!granted) {
                    if (process.env.NODE_ENV === 'development') {
                      return <Redirect to="/dashboard" />;
                    } else {
                      window.location.href = process.env.REACT_APP_V4_URL || '';
                      return <div />;
                    }
                  }
                  return (
                    <Layout withMenu={true} {...props} {...rest}>
                      <Page
                        {...props}
                        {...rest}
                        {...whenGrantIsClonedByModalLayoutProps}
                        isAdded={isAdded}
                        isView={isView}
                        isEdited={isEdited}
                      />
                    </Layout>
                  );
                }}
              </Gate>
            )}
          />
        );
      }}
    </Auth>
  );
};

PagedRoute.propTypes = {
  page: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  isPrivate: PropTypes.bool,
  grant: PropTypes.func,
  exact: PropTypes.bool,
  layout: PropTypes.elementType,
};

PagedRoute.defaultProps = {
  grant: accessPulic,
  exact: true,
  isPrivate: false,
  layout: MainLayout,
};

export default PagedRoute;
