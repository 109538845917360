import React from 'react';

import { FileAltIcon } from '.';

const FILE_CARD_WIDTH = 109;
const FILE_CARD_HEIGHT = 108;
const cardSize = { height: FILE_CARD_HEIGHT, width: FILE_CARD_WIDTH };
const cardStyle = { ...cardSize, borderRadius: '1.2rem' };
const imgStyle = { borderRadius: cardStyle.borderRadius };
const getFileExtension = (filename) => {
  return filename?.split('.').pop() || '';
};

function FileCard({ url, name, preview }) {
  const fileExt = getFileExtension(name).toLowerCase();
  const isImage = fileExt === 'jpg' || fileExt === 'png' || fileExt === 'jpeg';
  return (
    <div className="text-muted">
      <div className="card" style={cardStyle}>
        {url && (
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="w-100 h-100 d-flex align-items-center justify-content-center"
          >
            {isImage ? (
              <img
                src={url}
                alt=""
                className="img-thumbnail mh-100 border-0"
                style={imgStyle}
              />
            ) : (
              <FileAltIcon className="w-50 h-50" />
            )}
          </a>
        )}
        {preview && (
          <div className="w-100 h-100 d-flex align-items-center justify-content-center">
            {isImage ? (
              <img
                src={preview}
                alt=""
                className="img-thumbnail mh-100 border-0"
                style={imgStyle}
              />
            ) : (
              <FileAltIcon className="w-50 h-50" />
            )}
          </div>
        )}
      </div>
      <small
        style={{ maxWidth: FILE_CARD_WIDTH }}
        title={name}
        className="text-truncate text-center w-100 d-block"
      >
        {name}
      </small>
    </div>
  );
}

FileCard.cardSize = cardSize;

export default FileCard;
