import { ACTION_TYPES as AuthActionTypes } from '../auth/auth.actions';
import { ACTION_TYPES as DataLoaderActionTypes } from './dataLoader.actions';

const initialState = {
  lastValidationTime: null,
  fetchingResource: null,
  step: 0,
  maxStep: 0,
};

const dataLoaderReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case DataLoaderActionTypes.RETRY:
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        lastValidationTime: null,
        fetchingResource: null,
        step: 0,
        maxStep: 0,
      };
    case DataLoaderActionTypes.GET_ALL_SUCCESS:
      return {
        ...state,
        lastValidationTime: Date.now(),
        fetchingResource: null,
        step: 0,
        maxStep: 0,
      };
    case DataLoaderActionTypes.UPDATE_FETCHING_RESOURCE:
      return {
        ...state,
        fetchingResource: action.payload.fetchingResource,
        step: action.payload.step,
        maxStep: action.payload.maxStep,
      };
    default:
      return state;
  }
};

export default dataLoaderReducer;
