import * as Sentry from '@sentry/react';
import axios from 'axios';
import omitBy from 'lodash.omitby';

import { ASSEMBLY_LAW_ARTICLE_FREE_INPUT_ID } from '../redux-orm-models/assemblyResolution.model';
import config from './config';

const api = axios.create({
  baseURL: config.apiEndpoint,
  headers: {
    'Content-Type': 'application/json',
    'Syment-Client': 'front-v3',
    'Syment-App-Base-Url': config.v4Url, // This header is used on API for emails (base url). For now, we must send the v2 URL (v2 handles email callbacks)
  },
  withCredentials: true,
});

const lambdaPDFServiceApi = axios.create({
  baseURL: 'https://1x4sp6uy1a.execute-api.eu-west-3.amazonaws.com',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const PER_PAGE = {
  DEFAULT: 30,
  ASSEMBLY_RESOLUTION_TEMPLATES: 100,
  MEMBERS: 10,
  ALL: 99999999,
};

const withSearch =
  (getPaginatedEndpoint) =>
  (page = 1, itemsPerPage = PER_PAGE.DEFAULT) =>
  ({ residencyId, targets, match, order }) => {
    const urlSearchParam = new URLSearchParams();
    if (residencyId) {
      urlSearchParam.set('residency', residencyId);
      urlSearchParam.set('filterAssociations', true);
    }
    if (match) urlSearchParam.set('match', match);
    if (order) urlSearchParam.set(`order[${order.key}]`, order.type);
    if (targets) {
      targets.forEach((target) => {
        urlSearchParam.append('target[]', target); // to filter invitableUsers
        urlSearchParam.append('companyRole[]', target); // to filter members
      });
    }
    return (
      getPaginatedEndpoint(page, itemsPerPage) + '&' + urlSearchParam.toString()
    );
  };

const withPagination =
  (endpoint) =>
  (page = 1, itemsPerPage = PER_PAGE.DEFAULT) =>
    `${endpoint}.jsonld?pagination=true&itemsPerPage=${itemsPerPage}&page=${page}`;

const withPaginationV3 =
  (endpoint) =>
  (page = 1, itemsPerPage = PER_PAGE.DEFAULT) =>
    `${endpoint}?itemsPerPage=${itemsPerPage}&page=${page}`;

/*
 JSON-LD middleware - use to construct the pagination hook into requestEntities.container
*/
api.interceptors.response.use(
  (response) => {
    if (
      response.headers['content-type'] &&
      response.headers['content-type'].indexOf('application/ld+json') !== -1
    ) {
      const {
        ['hydra:member']: data, // eslint-disable-line
        ['hydra:totalItems']: totalItems, // eslint-disable-line
      } = response.data;
      data.totalItems = totalItems;
      response.data = data;
    }
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export const VERBS = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const ENDPOINTS_V3 = {
  ASSEMBLIES: '/v3/assemblies',
  ASSEMBLY_PARTICIPANTS: '/v3/assembly-participants',
  ASSEMBLY_RESOLUTIONS: '/v3/resolutions',
  POSTAGE_STATUS: '/v3/postage-status',
  MEMBERS: '/v3/members',
  COMPANIES: '/v3/companies',
  RECIPIENTS: '/v3/recipients',
};

export const ENDPOINTS = {
  LOGIN: '/login',
  ME: '/me',
  ROUTES: '/routes',
  ME_COMPANIES: '/me/companies',
  ME_RESIDENCIES: '/me/residencies',
  REGISTER: '/account/register',
  PASSWORD_REQUEST: '/password/request',
  PASSWORD_RESET: '/password/reset',
  PASSWORD_CHANGE: '/me/password_change',
  ACCOUNT_ACTIVATE: '/account/activate',
  ACCOUNT_TOACTIVATE: '/account/toactivate',
  VISIO_ENABLED: '/me/assemblies/visio_enabled',
  DASHBOARD: '/dashboard',
  MEMBERS: '/members',
  RESIDENCIES: '/residencies',
  RESIDENCY_MESSAGES: '/residency_messages',
  COUNCIL_MEMBERS: '/residency_council_members',
  PARCELS: '/parcels',
  PARCEL_TYPES: '/parcel_types',
  NOTIFICATIONAPPS: '/notification_apps',
  NOTIFICATION_PREFERENCES: '/notification_preferences',
  PARAMETERS: '/parameters',
  PLAINTS: '/plaints',
  PLAINT_FILES: '/plaint_files',
  PLAINT_DOMAINS: '/domains',
  PLAINT_COMMENTS: '/plaint_comments',
  PROVIDERS: '/providers',
  PROVIDERS_SWITCH: '/me/provider_switch',
  PROFILE_SWITCH: '/me/profile_switch',
  NEWS: '/news',
  COMMENTS: '/comments',
  USERS: '/users',
  COMPANIES: '/companies',
  COMPANY_SWITCH: '/me/company_switch',
  DOCUMENTS: '/documents',
  DOCUMENT_TYPES: '/document_types',
  EVENTS: '/events',
  POLLS: '/polls',
  POLL_VOTES: '/votes',
  POLL_COMMENTS: '/poll_comments',
  CALENDAR: '/calendar',
  ASSEMBLIES: '/assemblies',
  ASSEMBLY_RESOLUTIONS: '/resolutions',
  ASSEMBLY_RESOLUTION_VOTES: '/resolution_votes',
  ASSEMBLY_RESOLUTION_TEMPLATES: '/resolution_templates',
  ASSEMBLY_RESOLUTION_TEMPLATES_FILES: '/resolution_template_files',
  ASSEMBLY_RESOLUTION_COMMENTS: '/resolution_comments',
  ASSEMBLY_LAW_ARTICLES: '/assembly_law_articles',
  ASSEMBLY_MAJORITY_MODES: '/assembly_majority_modes',
  ASSEMBLY_PARTICIPANTS: '/assembly_participants',
  INBOX_MESSAGES: '/inbox_messages',
  INBOX_SUBJECTS: '/inbox_subjects',
  INBOX_RECIPIENTS: '/inbox_recipients',
  INVITABLE_USERS: '/users/invitable_users',
  INVITATION_EMAIL: '/users/invitation_email',
  DISTRIBUTION_KEYS: '/distribution_keys',
  PARCEL_DISTRIBUTION_KEYS: '/parcel_distribution_keys',
  USER_EMAILS: '/user_emails',
};

const PAGINATIONS = {
  DOCUMENTS: withPagination(ENDPOINTS.DOCUMENTS),
  ASSEMBLIES: withPagination(ENDPOINTS.ASSEMBLIES),
  ASSEMBLY_RESOLUTION_TEMPLATES: withPagination(
    ENDPOINTS.ASSEMBLY_RESOLUTION_TEMPLATES,
  ),
  RESIDENCIES: withPagination(ENDPOINTS.RESIDENCIES),
  MEMBERS: withPagination(ENDPOINTS.MEMBERS),
};

const SEARCHS = {
  ASSEMBLY_RESOLUTION_TEMPLATES: withSearch(
    PAGINATIONS.ASSEMBLY_RESOLUTION_TEMPLATES,
  ),
  DOCUMENTS: withSearch(PAGINATIONS.DOCUMENTS),
  MEMBERS: withSearch(PAGINATIONS.MEMBERS),
  INVITABLE_USERS: withSearch(() => ENDPOINTS.INVITABLE_USERS + '?'),
};

const toIri =
  (resource) =>
  ({ id }) =>
    id ? `/${resource}/${id}` : null;
const toMembersIri = toIri('members');
const toBuildingsIri = toIri('buildings');
const toProvidersIri = toIri('providers');
const toResidenciesIri = toIri('residencies');
const toUsersIri = toIri('users');
const toParcelsIri = toIri('parcels');
const toDistributionKeysIri = toIri('distribution_keys');
const toParcelDistributionKeysIri = toIri('parcel_distribution_keys');
const toAssembliesIri = toIri('assemblies');
const toAssemblyResolutionsIri = toIri('resolutions');
const toAssemblyResolutionTemplatesIri = toIri('resolution_templates');
const toAssemblyMajorityModesIri = toIri('assembly_majority_modes');
const toAssemblyLawArticlesIri = toIri('assembly_law_articles');
const toAssemblyParticipantIri = toIri('assembly_participants');

// #region Normalized Functions
// --- Parcel Distribution Keys
function normalizeParcelDistributionKey(parcelDistributionKey) {
  return omitBy(
    {
      ...parcelDistributionKey,
      _fields: null,
      id: parcelDistributionKey.id
        ? toParcelDistributionKeysIri({ id: parcelDistributionKey.id })
        : null,
      parcel: toParcelsIri({ id: parcelDistributionKey.parcelId }),
      distributionKey: parcelDistributionKey.distributionKeyId
        ? toDistributionKeysIri({
            id: parcelDistributionKey.distributionKeyId,
          })
        : null,
    },
    (value) => value === null,
  );
}

// --- Distribution Keys
function normalizeDistributionKey(distributionKey) {
  return omitBy(
    {
      ...distributionKey,
      _fields: null,
      residency: toResidenciesIri({ id: distributionKey.residencyId }),
      parcelDistributionKeys: distributionKey.parcelDistributionKeys.map(
        normalizeParcelDistributionKey,
      ),
    },
    (value) => value === null,
  );
}

// --- Company
function normalizeCompany(company) {
  let result = { ...company };
  if (Array.isArray(company.managersToRemove)) {
    result.managersToRemove = company.managersToRemove.map(toMembersIri);
  }
  if (Array.isArray(company.managersToAdd)) {
    result.managersToAdd = company.managersToAdd.map(toMembersIri);
  }
  if (Array.isArray(company.adminsToRemove)) {
    result.adminsToRemove = company.adminsToRemove.map(toMembersIri);
  }
  if (Array.isArray(company.adminsToAdd)) {
    result.adminsToAdd = company.adminsToAdd.map(toMembersIri);
  }
  result.base64Content = company.base64Content?.split(',').pop();
  return result;
}

// -- Document
function normalizeDocument(document) {
  return {
    residency: toResidenciesIri({ id: document.residencyId }),
    base64Content: document.base64Content?.split(',').pop(),
    originalFilename: document.originalFilename,
    title: document.title,
  };
}

// --- Member
function normalizeMember(member) {
  let result = { ...member };
  if (Array.isArray(member.managedResidencies)) {
    result.managedResidencies = member.managedResidencies.map(toResidenciesIri);
  }
  return result;
}

// --- Me
function normalizeMe(me) {
  return omitBy(
    {
      ...me,
      base64Content: me.base64Content?.split(',').pop(),
      file: null,
      _fields: null,
    },
    (value) => value === null,
  );
}

// --- Assemblies
function normalizeAssembly(assembly) {
  return omitBy(
    {
      ...assembly,
      residency: assembly.residencyId
        ? toResidenciesIri({ id: assembly.residencyId })
        : null,
      distributionKey: assembly.distributionKeyId
        ? toDistributionKeysIri({
            id: assembly.distributionKeyId,
          })
        : null,
      _fields: null,
    },
    // WARNING: null fields are not sent
    (value) => value === null,
  );
}

function normalizeAssemblyWithNullValues(assembly) {
  let result = { ...assembly };
  if (assembly.distributionKeyId) {
    result.distributionKey = toDistributionKeysIri({
      id: assembly.distributionKeyId,
    });
  }
  if (assembly.residencyId) {
    result.residency = toResidenciesIri({
      id: assembly.residencyId,
    });
  }
  return result;
}

function normalizeAssemblyResolutionTemplate(assemblyResolutionTemplate) {
  let normalizedAssemblyResolutionTemplate = omitBy(
    {
      ...assemblyResolutionTemplate,
      assemblyMajorityMode: toAssemblyMajorityModesIri({
        id: assemblyResolutionTemplate.assemblyMajorityModeId,
      }),

      _fields: null,
    },
    (value) => value === null,
  );

  if (
    !assemblyResolutionTemplate.isVoteEnabled &&
    assemblyResolutionTemplate.votesEnabled
  ) {
    normalizedAssemblyResolutionTemplate.multiplePropositions = false;
    normalizedAssemblyResolutionTemplate.propositions = null;
    normalizedAssemblyResolutionTemplate.assemblyLawArticle = null;
    normalizedAssemblyResolutionTemplate.assemblyMajorityMode = null;
  } else if (assemblyResolutionTemplate.assemblyMajorityModeId !== 1) {
    normalizedAssemblyResolutionTemplate.multiplePropositions = false;
    normalizedAssemblyResolutionTemplate.propositions = null;
  } else if (!assemblyResolutionTemplate.multiplePropositions) {
    normalizedAssemblyResolutionTemplate.propositions = null;
  }

  if (assemblyResolutionTemplate.hasOwnProperty('assemblyLawArticleId')) {
    normalizedAssemblyResolutionTemplate.assemblyLawArticle =
      assemblyResolutionTemplate.assemblyLawArticleId ===
      ASSEMBLY_LAW_ARTICLE_FREE_INPUT_ID
        ? null
        : toAssemblyLawArticlesIri({
            id: assemblyResolutionTemplate.assemblyLawArticleId,
          });
  }
  return normalizedAssemblyResolutionTemplate;
}

function normalizeAssemblyResolution(assemblyResolution) {
  let normalizedAssemblyResolution = omitBy(
    {
      ...assemblyResolution,
      assembly: toAssembliesIri({ id: assemblyResolution.assemblyId }),
      distributionKey: toDistributionKeysIri({
        id: assemblyResolution.distributionKeyId,
      }),
      assemblyMajorityMode: toAssemblyMajorityModesIri({
        id: assemblyResolution.assemblyMajorityModeId,
      }),
      withoutSubject: assemblyResolution.isWithoutSubject,
      files: null,
      _fields: null,
    },
    (value) => value === null,
  );

  if (
    assemblyResolution.assemblyMajorityModeId &&
    assemblyResolution.assemblyMajorityModeId !== 1
  ) {
    normalizedAssemblyResolution.propositions = null;
  } else if (
    assemblyResolution.assemblyMajorityModeId &&
    !assemblyResolution.multiplePropositions
  ) {
    normalizedAssemblyResolution.propositions = null;
  } else if (normalizedAssemblyResolution.propositions === null) {
    delete normalizedAssemblyResolution.propositions;
  }

  if (assemblyResolution.hasOwnProperty('assemblyLawArticleId')) {
    normalizedAssemblyResolution.assemblyLawArticle =
      assemblyResolution.assemblyLawArticleId ===
      ASSEMBLY_LAW_ARTICLE_FREE_INPUT_ID
        ? null
        : toAssemblyLawArticlesIri({
            id: assemblyResolution.assemblyLawArticleId,
          });
  }
  if (!assemblyResolution.hasOwnProperty('distributionKeyId')) {
    normalizedAssemblyResolution.distributionKey =
      normalizedAssemblyResolution.distributionKey || null;
  }

  return normalizedAssemblyResolution;
}

function normalizeAssemblyResolutionFile(assemblyResolutionFile) {
  return {
    ...assemblyResolutionFile,
    resolution: toAssemblyResolutionsIri({
      id: assemblyResolutionFile.assemblyResolutionId,
    }),
    base64Content: assemblyResolutionFile.base64Content?.split(',').pop(),
  };
}

function normalizeAssemblyResolutionTemplateFile(
  assemblyResolutionTemplateFile,
) {
  return {
    ...assemblyResolutionTemplateFile,
    resolutionTemplate: toAssemblyResolutionTemplatesIri({
      id: assemblyResolutionTemplateFile.resolutionTemplateId,
    }),
    base64Content: assemblyResolutionTemplateFile.base64Content
      ?.split(',')
      .pop(),
  };
}

function normalizeAssemblyResolutionComment(comment) {
  return {
    resolution: toAssemblyResolutionsIri({ id: comment.assemblyResolutionId }),
    comment: comment.comment,
  };
}

function normalizeSendUserEmail({ userId, assemblyId, type }) {
  return {
    user: toUsersIri({ id: userId }),
    assembly: toAssembliesIri({ id: assemblyId }),
    type,
  };
}

function normalizeAssemblyConvocation(assemblyConvocation) {
  return assemblyConvocation;
}

function normalizeAssemblyMinute(assemblyMinute) {
  return omitBy(
    {
      ...assemblyMinute,
    },
    (value) => value === null,
  );
}

// --- Parcels
function normalizeParcel(parcel) {
  return {
    ...parcel,
    residency: toResidenciesIri({ id: parcel.residencyId }),
    building: parcel.building ? toBuildingsIri({ id: parcel.building }) : null,
    owner: toMembersIri({ id: parcel.ownerId }),
    tenant: parcel?.tenantId ? toMembersIri({ id: parcel.tenantId }) : null,
    type: typeof parcel.type === 'number' ? parcel.type : parcel.typeId,
    _fields: null,
  };
}

// --- Residencies
function normalizeResidency(residency) {
  return omitBy(
    {
      ...residency,
      base64Content: residency.base64Content?.split(',').pop(),
      managers: residency.managers?.map(toMembersIri),
      buildings: residency.buildings?.map((building) =>
        building.id ? { ...building, id: toBuildingsIri(building) } : building,
      ),
      quantities: residency.parcelTypeQuantities
        ?.filter((ptq) => ptq.quantity > 0)
        .map((ptq) => ({
          quantity: ptq.quantity,
          parcelType: ptq.id,
        })),
      developer: residency.developer
        ? toProvidersIri({ id: residency.developer })
        : null,
      parcelTypeQuantities: null,
      _fields: null,
    },
    (value) => value === null,
  );
}

// --- MessageErrors
function normalizeApiMessageError(msg) {
  return msg
    ? msg.replaceAll('.', ' ').trim().replaceAll(' ', '_').toLowerCase()
    : 'defaultError';
}
// #endregion

/**
 * Serves as a bottleneck of all requests.
 * Implenting cach service or request middleware will be more easier
 * Do not forget to return API(...args) to execute the request if no cach provided
 * Inspired by :
 * @see https://github.com/agraboso/redux-api-middleware#rsaafetch
 */
export const apiCreator =
  (method) =>
  (url) =>
  async (data = {}, opts = {}) => {
    try {
      const res = await api({
        method,
        url,
        data,
        ...opts,
      });
      return res.data;
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') console.error(e);
      if (process.env.REACT_APP_SENTRY_IS_ACTIVE === 'true') {
        Sentry.captureException(
          new Error(
            `Method: ${method}; Status: ${
              e?.response?.status
            }; Endpoint: ${url}; Error: ${e}; Data: ${JSON.stringify(data)}`,
          ),
        );
      }
      switch (e?.response?.status) {
        case 404:
        case 401:
        case 400:
          throw new Error(
            `api.${normalizeApiMessageError(e.response.data.message)}`,
          );
        case 403:
        case 500:
        default:
          throw new Error('api.defaultError');
      }
    }
  };

const lambdaPDFServiceApiCreator =
  (method) =>
  (url) =>
  async (data = {}, opts = {}) => {
    try {
      const res = await lambdaPDFServiceApi({
        method,
        url,
        data,
        ...opts,
      });
      if (!res.data.b64Pdf) {
        throw new Error('Missing b64Pdf in response');
      }
      return res.data;
    } catch (e) {
      switch (e?.response?.status) {
        case 404:
        case 401:
        case 400:
          throw new Error(
            `lambdaPDFServiceApi.${normalizeApiMessageError(
              e.response.data.message,
            )}`,
          );
        case 403:
        case 500:
        default:
          throw new Error('lambdaPDFServiceApi.defaultError');
      }
    }
  };

/**
 * (unpure function) unset the default header to the api
 * @summary api.defaults.headers.common.Authorization = undefined
 */
export function updateApiHeaderToken(token) {
  // when being redirected from V2 we use cookie auth
  // so we don't have a token
  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

/**
 * (unpure function) unset the default header to the api
 */
export function removeApiHeaderToken() {
  delete api.defaults.headers.common.Authorization;
}

//#region Api Models
// --- Auth
export const authApi = {
  login: async (data) => {
    const res = await apiCreator(VERBS.POST)(ENDPOINTS.LOGIN)(data);
    updateApiHeaderToken(res.token);
    return res;
  },
  me: apiCreator(VERBS.GET)(ENDPOINTS.ME),
  meWithToken: async (tokenUrl) => {
    updateApiHeaderToken(tokenUrl);
    const res = await apiCreator(VERBS.GET)(ENDPOINTS.ME)();
    return res;
  },
  residencies: apiCreator(VERBS.GET)(ENDPOINTS.ME_RESIDENCIES),
  companies: apiCreator(VERBS.GET)(ENDPOINTS.ME_COMPANIES),
  profiles: apiCreator(VERBS.GET)(ENDPOINTS.ME_COMPANIES),
  updateMe: async (data) =>
    apiCreator(VERBS.PUT)(ENDPOINTS.ME)(normalizeMe(data)),
  switchProfile: async ({ newProfile }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.PROFILE_SWITCH}`)({ newProfile }),
  routes: async (routeKey) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.ROUTES}/${routeKey}`)(),
};
// --- Members
export const membersApi = {
  list: async ({ page, where = {}, membersPerPage = PER_PAGE.MEMBERS }) =>
    apiCreator(VERBS.GET)(SEARCHS.MEMBERS(page, membersPerPage)(where))(),
  listAll: async () => apiCreator(VERBS.GET)(ENDPOINTS.MEMBERS)(),
  getById: async ({ id }) => {
    return apiCreator(VERBS.GET)(`${ENDPOINTS.MEMBERS}/${id}`)();
  },
  sendInvationByResidenceIdAndByUserIds: async ({ id, usersQuery }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS.INVITATION_EMAIL}?residency=${id}${
        !usersQuery ? '&target[]=owner' : usersQuery.join('')
      }`,
    )(),
  getEmailConsentsByResendyId: async ({ id }) =>
    api.get(
      `${ENDPOINTS.MEMBERS}/registered_email_consent_export.xlsx?residencyId=${id}`,
      {
        responseType: 'blob',
      },
    ),
  updateEre: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS_V3.MEMBERS}/${id}/email-consent`)(data),
  getBySearch: async (search) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.MEMBERS}?match=${search}`)(),
  update: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.MEMBERS}/${id}`)(normalizeMember(data)),
  updateV3: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS_V3.MEMBERS}/${id}`)(data),
  create: apiCreator(VERBS.POST)(ENDPOINTS.MEMBERS),
  generateRecipients: async ({ id, data }) =>
    apiCreator(VERBS.PUT)(`/assembly_invitations/${id}`)(data),
  getRecipientById: async ({ id }) => {
    return apiCreator(VERBS.GET)(`${ENDPOINTS_V3.RECIPIENTS}/${id}`)();
  },
  recipientUpdate: async ({ id, assemblyId, data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS_V3.RECIPIENTS}/${id}`)(data),
  coRecipientAdd: async ({ id, assemblyId, data }) =>
    apiCreator(VERBS.POST)(`${ENDPOINTS_V3.RECIPIENTS}/${id}/co-recipients`)(
      data,
    ),
  coRecipientRemove: async ({ id, assemblyId }) =>
    apiCreator(VERBS.DELETE)(`${ENDPOINTS_V3.RECIPIENTS}/${id}`)(),
};
// --- Residencies
export const residenciesApi = {
  list: async ({ page = 1 }) =>
    apiCreator(VERBS.GET)(PAGINATIONS.RESIDENCIES(page))(),
  create: async (data) =>
    apiCreator(VERBS.POST)(ENDPOINTS.RESIDENCIES)(normalizeResidency(data)),
  update: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.RESIDENCIES}/${id}`)(
      normalizeResidency(data),
    ),
  getById: async ({ id, ...data }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.RESIDENCIES}/${id}`)(data),
  getResidentsListById: async ({ id }) =>
    api.get(`${ENDPOINTS.RESIDENCIES}/${id}/residents_list`, {
      responseType: 'blob',
    }),
  getProabonoByRecidencyId: async ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.RESIDENCIES}/${id}/proabono`)(),
  getCouncilMemberByRecidencyId: async ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.RESIDENCIES}/${id}/council_members`)(),
  setConcilMembers: async (id, concilMembers) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.RESIDENCIES}/${id}/council_members`)(
      concilMembers,
    ),
};

// --- Council
export const councilApi = {
  create: async (data) =>
    apiCreator(VERBS.POST)(ENDPOINTS.COUNCIL_MEMBERS)(data),
  deleteCouncilMembers: async (residencyId, memberId) =>
    apiCreator(VERBS.DELETE)(
      `${ENDPOINTS.COUNCIL_MEMBERS}/residency=${residencyId};member=${memberId}`,
    )(),
};

// --- Parcels
export const parcelsApi = {
  list: apiCreator(VERBS.GET)(ENDPOINTS.PARCELS),
  create: async (data) =>
    apiCreator(VERBS.POST)(`${ENDPOINTS.PARCELS}`)(normalizeParcel(data)),
  getById: async ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.PARCELS}/${id}`)(),
  update: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.PARCELS}/${id}`)(normalizeParcel(data)),
  delete: async ({ id }) =>
    apiCreator(VERBS.DELETE)(`${ENDPOINTS.PARCELS}/${id}`)(),
};
export const parcelTypesApi = {
  list: apiCreator(VERBS.GET)(ENDPOINTS.PARCEL_TYPES),
};
// --- Users
export const usersApi = {
  getById: async ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.USERS}/${id}`)(),
  getInvitableUsersByResidencyId: async ({ id }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS.INVITABLE_USERS}?residency=${id}&target[]=owner`,
    )(),
};
// --- Companies
export const companiesApi = {
  getCurrent: apiCreator(VERBS.GET)(`${ENDPOINTS.COMPANIES}/current`),
  getCompanyById: ({ id }) => api.get(`${ENDPOINTS.COMPANIES}/${id}`),
  updateCurrent: async (data) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.COMPANIES}/current`)(
      normalizeCompany(data),
    ),
  update: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.COMPANIES}/${id}`)(
      normalizeCompany(data),
    ),
  switch: async ({ newCompanyId }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.COMPANY_SWITCH}`)(
      normalizeCompany({ newCompanyId }),
    ),
};

export const providersApi = {
  switch: async ({ newProviderId }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.PROVIDERS_SWITCH}`)({ newProviderId }),
};
// --- Assemblies
export const assembliesApi = {
  update: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS_V3.ASSEMBLIES}/${id}`)(
      normalizeAssembly(data),
    ),
  updateLegacy: async ({ id, ...data }, afterOpenAssembly) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS.ASSEMBLIES}/${id}?afterOpenAssembly=${afterOpenAssembly}`,
    )(normalizeAssembly(data)),
  updateWithoutNormalizingData: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS_V3.ASSEMBLIES}/${id}`)(
      normalizeAssemblyWithNullValues(data),
    ),
  publishV3: async ({ id }) =>
    apiCreator(VERBS.POST)(`${ENDPOINTS_V3.ASSEMBLIES}/${id}/publish`)(),
  publish: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.ASSEMBLIES}/${id}`)(data),
  publishCouncil: async ({ id }) =>
    apiCreator(VERBS.POST)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${id}/publish-for-council`,
    )(),
  recallCouncil: async ({ id }) =>
    apiCreator(VERBS.DELETE)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${id}/publish-for-council`,
    )(),
  list: async ({ page }) =>
    apiCreator(VERBS.GET)(PAGINATIONS.ASSEMBLIES(page))(),
  today: async ({ dateFilter }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS.ASSEMBLIES}.jsonld?pagination=false&${dateFilter}&status[]=0&status[]=1`,
    )(),
  listAnnexes: async (assemblyId, type) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${assemblyId}/annexes${
        type ? `?type=${type}` : ''
      }`,
    )(),
  getAnnexe: async (assemblyId, annexeId) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${assemblyId}/annexes/${annexeId}`,
    )(),
  createAnnexes: async (assemblyId, data) =>
    apiCreator(VERBS.POST)(`${ENDPOINTS_V3.ASSEMBLIES}/${assemblyId}/annexes`)(
      data,
    ),
  deleteAnnexe: async (assemblyId, annexeId) =>
    apiCreator(VERBS.DELETE)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${assemblyId}/annexes/${annexeId}`,
    )(),
  updateAnnexe: async (assemblyId, annexeId, data) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${assemblyId}/annexes/${annexeId}`,
    )(data),
  future: async ({ dateFilter }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS.ASSEMBLIES}.jsonld?pagination=false&${dateFilter}&status=0`,
    )(),
  anomaly: async () =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS.ASSEMBLIES}.jsonld?anomaly=true&pagination=true`,
    )(),
  closed: async ({ usePagination, itemsPerPage = 3 }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS.ASSEMBLIES}.jsonld?&status=2${
        usePagination
          ? `&pagination=true&itemsPerPage=${itemsPerPage}&order[date]=desc`
          : '&pagination=false'
      }`,
    )(),
  create: async (data) =>
    apiCreator(VERBS.POST)(ENDPOINTS_V3.ASSEMBLIES)(normalizeAssembly(data)),
  getById: async ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.ASSEMBLIES}/${id}`)(),
  archive: async ({ id }) =>
    apiCreator(VERBS.DELETE)(`${ENDPOINTS_V3.ASSEMBLIES}/${id}`)(),
  duplicate: async ({ id, assembly }) =>
    apiCreator(VERBS.POST)(`${ENDPOINTS_V3.ASSEMBLIES}/${id}/duplicate`)(
      normalizeAssembly(assembly),
    ),
  start: async ({ id }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.ASSEMBLIES}/${id}/start`)(),
  startV3: async ({ id }) =>
    apiCreator(VERBS.POST)(`${ENDPOINTS_V3.ASSEMBLIES}/${id}/start`)(),

  enableVisio: async ({ id }) =>
    apiCreator(VERBS.POST)(`${ENDPOINTS_V3.ASSEMBLIES}/${id}/enable-visio`)(),
  disableVisio: async ({ id }) =>
    apiCreator(VERBS.POST)(`${ENDPOINTS_V3.ASSEMBLIES}/${id}/disable-visio`)(),

  finish: async ({ id, assemblyQuorumFailed }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.ASSEMBLIES}/${id}/finish`)({
      assemblyQuorumFailed: assemblyQuorumFailed,
    }),
  addSymentResolution: async ({ id }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS.ASSEMBLIES}/${id}/add_syment_resolution`,
    )(),
  getPresenceSheet: async ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.ASSEMBLIES}/${id}/presence_sheet`)(
      {},
      { responseType: 'blob' },
    ),
};

export const assemblyLiveApi = {
  participants: {
    updateParticipantStatus: async ({ id, status }) =>
      apiCreator(VERBS.PUT)(
        `${ENDPOINTS_V3.ASSEMBLY_PARTICIPANTS}/${id}/status`,
      )({ status }),
  },
  getById: async ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.ASSEMBLIES}/${id}/ongoing`)(),
  batchVotesByAssemblyResolutionId: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS_V3.ASSEMBLY_RESOLUTIONS}/${id}/votes/batch`,
    )(data),
  finishVotesByAssemblyResolutionId: async ({
    id,
    ...data /* TODO: shall we keep voteInfos ? */
  }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS_V3.ASSEMBLY_RESOLUTIONS}/${id}/votes/finish`,
    )(data),
  closeVotesByAssemblyResolutionId: async ({ id }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS_V3.ASSEMBLY_RESOLUTIONS}/${id}/votes/close`,
    )(),
  openVotesByAssemblyResolutionId: async ({ id, assemblyQuorumOverride }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS_V3.ASSEMBLY_RESOLUTIONS}/${id}/votes/open`,
    )({ assemblyQuorumOverride: assemblyQuorumOverride }),
  nextResolutionByAssemblyId: async ({
    id,
    ongoingResolutionIndex,
    assemblyQuorumOverride,
  }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS.ASSEMBLIES}/${id}/ongoing/resolution_next`,
    )({
      ongoingResolution: ongoingResolutionIndex.toString(),
      assemblyQuorumOverride: assemblyQuorumOverride,
    }),
  updateAssemblyLiveParticipantRepresentedParticipants: async ({
    participantId,
    representedParticipants,
  }) => {
    return apiCreator(VERBS.PUT)(
      `${ENDPOINTS.ASSEMBLY_PARTICIPANTS}/${participantId}`,
    )({
      representedParticipants: representedParticipants.map((id) =>
        toAssemblyParticipantIri({ id }),
      ),
    });
  },
  updateOnGoingManagerByAssemblyId: async ({
    assemblyId,
    ongoingManagerUser,
  }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS.ASSEMBLIES}/${assemblyId}/ongoing/manager`,
    )({
      ongoingManagerUser: `/users/${ongoingManagerUser}`,
    }),
  signParticipantByAssemblyId: async ({
    participantId,
    checkedByManager,
    checkedByParticipant,
    signName,
  }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS.ASSEMBLY_PARTICIPANTS}/${participantId}/signature`,
    )({
      checkedByManager,
      checkedByParticipant,
      signName,
    }),
  reopenVotesByAssemblyResolutionId: async ({ id }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS_V3.ASSEMBLY_RESOLUTIONS}/${id}/votes/re-open`,
    )(),
  createExternalParticipantByAssemblyId: async ({ assemblyId, data }) =>
    apiCreator(VERBS.POST)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${assemblyId}/external-participants`,
    )(data),
  updateExternalParticipantByAssemblyId: async ({
    assemblyId,
    participantId,
    data,
  }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${assemblyId}/external-participants/${participantId}`,
    )(data),
};

export const assemblyParticipantsApi = {
  listByAssemblyId: async ({ id, noExternal }) =>
    noExternal
      ? apiCreator(VERBS.GET)(
          `${ENDPOINTS_V3.ASSEMBLIES}/${id}/participants?external=false`,
        )()
      : apiCreator(VERBS.GET)(
          `${ENDPOINTS_V3.ASSEMBLIES}/${id}/participants`,
        )(),
  listRepresentedByAssemblyId: async ({ id }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS.ASSEMBLIES}/${id}/represented_participants`,
    )(),
  deleteEarlyVotes: async ({ participantId }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS.ASSEMBLY_PARTICIPANTS}/${participantId}/delete_early_votes`,
    )({}),
  updateAssemblyParticipantEarlyVotesReceivedAt: async ({
    participantId,
    earlyVotesReceivedAt,
  }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS.ASSEMBLY_PARTICIPANTS}/${participantId}/early_vote`,
    )({
      earlyVotesReceivedAt,
    }),
  sendAssemblyParticipantAnnouncedStatusByAssemblyId: async ({
    id,
    announcedStatus,
  }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${id}/participant-announced-status`,
    )({
      announcedStatus,
    }),
  getAssemblyParticipantAnnouncedStatusByAssemblyId: async (id) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${id}/participant-announced-status`,
    ),
};

export const assemblyResolutionsApi = {
  create: async (data) =>
    apiCreator(VERBS.POST)(ENDPOINTS.ASSEMBLY_RESOLUTIONS)(
      normalizeAssemblyResolution(data),
    ),
  createFromResolutionTemplates: async (id, data) => {
    return apiCreator(VERBS.POST)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${id}${ENDPOINTS.ASSEMBLY_RESOLUTIONS}?compatibility=true`,
    )(data);
  },
  listByAssemblyId: async ({ id }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${id}${ENDPOINTS.ASSEMBLY_RESOLUTIONS}?compatibility=true`,
    )(),
  listAnnexes: async (assemblyId, resolutionId) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${assemblyId}${ENDPOINTS.ASSEMBLY_RESOLUTIONS}/${resolutionId}/annexes`,
    )(),
  getById: async ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS_V3.ASSEMBLY_RESOLUTIONS}/${id}`)(),
  update: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.ASSEMBLY_RESOLUTIONS}/${id}`)(
      normalizeAssemblyResolution(data),
    ),
  updateOrder: async ({ id, position }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS_V3.ASSEMBLY_RESOLUTIONS}/${id}/position`,
    )({
      position,
    }),
  updateSubResolutionOrder: async ({ id, resolutions }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS_V3.ASSEMBLY_RESOLUTIONS}/${id}/sub-resolutions`,
    )({ resolutions }),
  updateOnGoing: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.ASSEMBLY_RESOLUTIONS}/${id}/ongoing`)(
      normalizeAssemblyResolution(data),
    ),
  updateOnGoingWithoutSubject: async ({ id, assemblyQuorumFailed }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.ASSEMBLY_RESOLUTIONS}/${id}/ongoing`)({
      withoutSubject: assemblyQuorumFailed,
      votesEnabled: false,
    }),
  updateOnGoingSymentResolution: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.ASSEMBLY_RESOLUTIONS}/${id}/ongoing`)({
      withoutSubject: data.isWithoutSubject,
      votesEnabled: false,
    }),
  delete: async ({ id }) =>
    apiCreator(VERBS.DELETE)(`${ENDPOINTS_V3.ASSEMBLY_RESOLUTIONS}/${id}`)(),
  duplicate: async ({ id }) =>
    apiCreator(VERBS.POST)(
      `${ENDPOINTS_V3.ASSEMBLY_RESOLUTIONS}/${id}/duplicate`,
    )(),
};

export const assemblyResolutionTemplatesApi = {
  create: async (data) =>
    apiCreator(VERBS.POST)(ENDPOINTS.ASSEMBLY_RESOLUTION_TEMPLATES)(
      normalizeAssemblyResolutionTemplate(data),
    ),
  list: async (companyId, { page, where = {}, itemsPerPage }) =>
    apiCreator(VERBS.GET)(
      withSearch(
        withPaginationV3(
          `${ENDPOINTS_V3.COMPANIES}/${companyId}/resolution-templates`,
        ),
      )(
        page,
        itemsPerPage,
      )(where),
    )(),
  getById: async ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.ASSEMBLY_RESOLUTION_TEMPLATES}/${id}`)(),
  update: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.ASSEMBLY_RESOLUTION_TEMPLATES}/${id}`)(
      normalizeAssemblyResolutionTemplate(data),
    ),
  updateOrder: async ({ id, orderNumber }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS.ASSEMBLY_RESOLUTION_TEMPLATES}/${id}/move`,
    )({
      orderNumber,
    }),
  delete: async ({ id }) =>
    apiCreator(VERBS.DELETE)(
      `${ENDPOINTS.ASSEMBLY_RESOLUTION_TEMPLATES}/${id}`,
    )(),
};

export const assemblyResolutionTemplatesFilesApi = {
  create: async (data) =>
    apiCreator(VERBS.POST)(ENDPOINTS.ASSEMBLY_RESOLUTION_TEMPLATES_FILES)(
      normalizeAssemblyResolutionTemplateFile(data),
    ),
  delete: async ({ id }) =>
    apiCreator(VERBS.DELETE)(
      `${ENDPOINTS.ASSEMBLY_RESOLUTION_TEMPLATES_FILES}/${id}`,
    )(),
};

export const assemblyResolutionVotesApi = {
  listByAssemblyResolutionId: async ({ id }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS.ASSEMBLY_RESOLUTIONS}/${id}/resolution_votes`,
    )(),
  voteByAssemblyResolutionId: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS_V3.ASSEMBLY_RESOLUTIONS}/${id}/votes`)(
      data,
    ),
  voteByAssemblyId: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.ASSEMBLIES}/${id}/early_votes`)(data),

  listMineAndRepresentedByAssemblyResolutionId: async ({ id }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS.ASSEMBLY_RESOLUTIONS}/${id}/resolution_votes/mine_and_represented`,
    )(),
  update: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.ASSEMBLY_RESOLUTION_VOTES}/${id}`)(data),
  delete: async ({ id }) =>
    apiCreator(VERBS.DELETE)(`${ENDPOINTS.ASSEMBLY_RESOLUTION_VOTES}/${id}`),
};

export const assemblyResolutionCommentsApi = {
  listByAssemblyResolutionId: async ({ id, ...data }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.ASSEMBLY_RESOLUTIONS}/${id}/comments`)(
      data,
    ),
  createByAssemblyResolutionId: async (data) =>
    apiCreator(VERBS.POST)(`${ENDPOINTS.ASSEMBLY_RESOLUTION_COMMENTS}`)(
      normalizeAssemblyResolutionComment(data),
    ),
};

export const assemblyTemplatesApi = {
  listAssemblyTemplatesByCompanyId: async ({
    companyId,
    itemsPerPage = 20,
    page = 1,
    search = '',
  }) => {
    let url = `${ENDPOINTS_V3.COMPANIES}/${companyId}/assembly-templates?itemsPerPage=${itemsPerPage}&page=${page}`;
    if (search && search.length >= 2) url += `&text=${search}`;

    return apiCreator(VERBS.GET)(url)();
  },
};

export const assemblyLawArticlesApi = {
  list: apiCreator(VERBS.GET)(ENDPOINTS.ASSEMBLY_LAW_ARTICLES),
};

export const assemblyMajorityModesApi = {
  listByAssemblyId: async ({ id }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS.ASSEMBLY_MAJORITY_MODES}?assembly=${id}`,
    )(),
  list: apiCreator(VERBS.GET)(ENDPOINTS.ASSEMBLY_MAJORITY_MODES),
};

export const assemblyConvocationApi = {
  getByAssemblyId: async ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.ASSEMBLIES}/${id}/invitation`)(),
  getAssemblyPostageQuoteByAssemblyId: async ({ id, type }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${id}/quote?type=${type}`,
    )(),
  updatePostageQuoteByAssemblyId: async ({
    id,
    color,
    ereOnly = false,
    everyone = false,
    type = 'invitation',
  }) =>
    apiCreator(VERBS.POST)(`${ENDPOINTS_V3.ASSEMBLIES}/${id}/quote`)({
      color,
      ereOnly,
      everyone,
      type,
    }),
  cancelInvitation: async ({ id }) =>
    apiCreator(VERBS.POST)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${id}/cancel-invitation`,
    )({}),
  sendConvocationByAssemblyId: async ({ id }) =>
    apiCreator(VERBS.POST)(`${ENDPOINTS_V3.ASSEMBLIES}/${id}/send-invitations`)(
      {},
    ),
  getFilesByAssemblyId: async ({ id }) =>
    apiCreator(VERBS.GET)(`/assembly_invitations/${id}/files`)(),
  getBlankByAssemblyId: async ({ id, docType }) =>
    api.get(`/assembly_invitations/${id}/generate/${docType}`, {
      responseType: 'blob',
    }),
  updateByAssemblyId: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`/assembly_invitations/${id}`)(
      normalizeAssemblyConvocation(data),
    ),
  generateByAssemblyId: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`/assembly_invitations/${id}/generate`)(
      normalizeAssemblyConvocation(data),
    ),
  updateTemplateByAssemblyId: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`/assembly_invitations/${id}/template`)(
      normalizeAssemblyConvocation(data),
    ),
  getConvocationRegisteredEmailConsent: async ({ id }) =>
    api.get(
      `${ENDPOINTS.ASSEMBLIES}/${id}/participants/registered_email_consent_invitation`,
      {
        responseType: 'blob',
      },
    ),
  getMonitoringByAssemblyId: async ({ id, doc }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS_V3.POSTAGE_STATUS}/${doc}/${id}`)(),
  listRecipientsByAssemblyId: async ({ id }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${id}/invitation/recipients`,
    )(),
};

export const assemblyMinutesApi = {
  getByAssemblyId: async ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.ASSEMBLIES}/${id}/minutes`)(),
  getFilesByAssemblyId: async ({ id }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${id}/minutes/participants`,
    )(),
  sendMinuteByAssemblyId: async ({ id }) =>
    apiCreator(VERBS.POST)(`${ENDPOINTS_V3.ASSEMBLIES}/${id}/send-minutes`)({}),
  update: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.ASSEMBLIES}/${id}/minutes`)(
      normalizeAssemblyMinute(data),
    ),
  generateParticipants: async ({ id }) =>
    apiCreator(VERBS.POST)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${id}/minutes/generate-participants`,
    )(),
  cancelParticipantByAssemblyId: async ({ id }) =>
    apiCreator(VERBS.POST)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${id}/minutes/cancel-participants`,
    )(),
  lambdaPDFService: async (data) =>
    lambdaPDFServiceApiCreator(VERBS.POST)('/dev/pdf')(
      normalizeAssemblyMinute(data),
    ),
  generate: async ({ id }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.ASSEMBLIES}/${id}/minutes_generate`)(),
  cancelMinutes: async ({ id }) =>
    apiCreator(VERBS.POST)(`${ENDPOINTS_V3.ASSEMBLIES}/${id}/cancel-minutes`)(
      {},
    ),
  sendSignRequest: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS.ASSEMBLIES}/${id}/minutes_send_sign_request`,
    )(data),
  cancelSignRequest: async ({ id }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS.ASSEMBLIES}/${id}/minutes_cancel_sign_request`,
    )(),
  minutesSignerForward: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS.ASSEMBLIES}/${id}/minutes_signer_forward`,
    )(data),
  getMinutesFailing: async ({ id }) =>
    api.get(`${ENDPOINTS.ASSEMBLIES}/${id}/participants/minutes_failing`, {
      responseType: 'blob',
    }),
  cancelMinutesPdf: async ({ id }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.ASSEMBLIES}/${id}/minutes_cancel_pdf`)(),
  listRecipientsByAssemblyId: async ({ id }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS_V3.ASSEMBLIES}/${id}/minutes/recipients`,
    )(),
};

// --- Comments
export const commentsApi = {
  listByResidencyId: ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.COMMENTS}?residency=${id}`)(),
  moderate: ({ id }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.COMMENTS}/${id}/moderate`)(),
  unmoderate: ({ id }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.COMMENTS}/${id}/unmoderate`)(),
  report: ({ id }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.COMMENTS}/${id}/report`)(),
  unreport: ({ id }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.COMMENTS}/${id}/unreport`)(),
  delete: async ({ id }) =>
    apiCreator(VERBS.DELETE)(`${ENDPOINTS.COMMENTS}/${id}`)(),
  update: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.COMMENTS}/${id}`)(data),
};
// --- Parameters
export const parametersApi = {
  update: ({ type, sourceId, name, ...data }) =>
    apiCreator(VERBS.PUT)(
      `${ENDPOINTS.PARAMETERS}/${type}/${sourceId}/${name}`,
    )(data),
};
// --- Documents
export const documentsApi = {
  list: async ({ page = 1, where = {} }) =>
    apiCreator(VERBS.GET)(SEARCHS.DOCUMENTS(page)(where))(),
  create: async (data) =>
    apiCreator(VERBS.POST)(ENDPOINTS.DOCUMENTS)(normalizeDocument(data)),
  update: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.DOCUMENTS}/${id}`)(
      normalizeDocument(data),
    ),
  getById: async ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.DOCUMENTS}/${id}`)(),
  delete: async ({ id }) =>
    apiCreator(VERBS.DELETE)(`${ENDPOINTS.DOCUMENTS}/${id}`)(),
  getFileUrlById: ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.DOCUMENTS}/${id}/share`)(),
};
// --- Document types
export const documentTypesApi = {
  list: apiCreator(VERBS.GET)(ENDPOINTS.DOCUMENT_TYPES),
};
// --- Distribution Keys
export const distributionKeysApi = {
  getById: async ({ id }) =>
    apiCreator(VERBS.GET)(`${ENDPOINTS.DISTRIBUTION_KEYS}/${id}`)(),
  listByResidencyId: async ({ id }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS.RESIDENCIES}/${id}${ENDPOINTS.DISTRIBUTION_KEYS}`,
    )(),
  createByResidencyId: async ({ ...data }) =>
    apiCreator(VERBS.POST)(ENDPOINTS.DISTRIBUTION_KEYS)(
      normalizeDistributionKey(data),
    ),
  update: async ({ id, ...data }) =>
    apiCreator(VERBS.PUT)(`${ENDPOINTS.DISTRIBUTION_KEYS}/${id}`)(
      normalizeDistributionKey(data),
    ),
  delete: async ({ id }) =>
    apiCreator(VERBS.DELETE)(`${ENDPOINTS.DISTRIBUTION_KEYS}/${id}`)(),
};
// --- Parcel Distribution Keys
export const parcelDistributionKeysApi = {
  listByDistributionKeyId: async ({ id }) =>
    apiCreator(VERBS.GET)(
      `${ENDPOINTS.DISTRIBUTION_KEYS}/${id}${ENDPOINTS.PARCEL_DISTRIBUTION_KEYS}`,
    )(),
};

// --- User Email
export const userEmails = {
  send: async (data) =>
    apiCreator(VERBS.POST)(`${ENDPOINTS.USER_EMAILS}`)(
      normalizeSendUserEmail(data),
    ),
};

// #endregion

export default apiCreator;
