export const SET_CONVOCATION_STEP = 'SESSION/SET_CONVOCATION_STEP';
export const RESET_CONVOCATION_STEP = 'SESSION/RESET_CONVOCATION_STEP';

export type SetConvocationStepProps = {
  step: number;
  date: string;
  owner: string;
  assemblyId: string;
};
export const setConvocationStep = ({
  step,
  date,
  owner,
  assemblyId,
}: SetConvocationStepProps) => {
  return {
    type: SET_CONVOCATION_STEP,
    payload: {
      step,
      date,
      owner,
      assemblyId,
    },
  };
};

export type ResetConvocationStepProps = {
  step: number;
  assemblyId: string;
};

export const resetConvocationStep = ({
  step,
  assemblyId,
}: ResetConvocationStepProps) => {
  return {
    type: RESET_CONVOCATION_STEP,
    payload: {
      step,
      assemblyId,
    },
  };
};
