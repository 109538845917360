import { Model } from 'redux-orm';
class Member extends Model {
  hasDiscussionActivated() {
    return this.normalizedParameters.discussion ?? true;
  }
  /**
   * From something like :
   * parameters: [
            {
              name: 'showFirstConnectionProfileModal',
              metadata: {
                value: 'false'
              }
            }
          ]
      to
      normalizedParameters : { showFirstConnectionProfileModal : true }
   */
  get normalizedParameters() {
    return this.ref.parameters.reduce(
      (acc, { name, metadata: { value } }) => ({
        ...acc,
        [name]: Boolean(value),
      }),
      {},
    );
  }
  get avatar() {
    return (this.users && this.users[0]?.file?.url) || null;
  }
  get name() {
    return `${this.firstName} ${this.lastName}`;
  }
  get user() {
    return this.users[0];
  }

  toString() {
    return `Member: ${this.name}`;
  }

  isSomething() {
    return (
      this.ref.rights?.is_tenant ||
      this.ref.rights?.is_admin ||
      this.ref.rights?.is_manager ||
      this.ref.rights?.is_provider ||
      this.ref.rights?.is_provider_admin ||
      this.ref.rights?.is_owner
    );
  }
}

Member.modelName = 'Member';

export default Member;
