import * as Sentry from '@sentry/react';
import debounce from 'lodash.debounce';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import * as assemblies from '../pills/assemblies/assemblies.api';
import * as proabonoApi from '../pills/assembliesProabono/assembliesProabono.api';
import * as assemblyConvocation from '../pills/assemblyConvocation/assemblyConvocation.api';
import * as assemblyLawArticles from '../pills/assemblyLawArticles/assemblyLawArticles.api';
import * as assemblyLive from '../pills/assemblyLive/assemblyLive.api';
import * as assemblyMajorityModes from '../pills/assemblyMajorityModes/assemblyMajorityModes.api';
import * as assemblyMinutes from '../pills/assemblyMinutes/assemblyMinutes.api';
import * as assemblyParticipants from '../pills/assemblyParticipants/assemblyParticipants.api';
import * as assemblyResolutions from '../pills/assemblyResolutions/assemblyResolutions.api';
import * as assemblyResolutionTemplates from '../pills/assemblyResolutionTemplates/assemblyResolutionTemplates.api';
import * as assemblyResolutionVotes from '../pills/assemblyResolutionVotes/assemblyResolutionVotes.api';
import * as auth from '../pills/auth/auth.api';
import * as comments from '../pills/comments/comments.api';
import * as companies from '../pills/companies/companies.api';
import * as distributionKeys from '../pills/distributionKeys/distributionKeys.api';
import * as documents from '../pills/documents/documents.api';
import * as documentTypes from '../pills/documentTypes/documentTypes.api';
import * as members from '../pills/members/members.api';
import * as parameters from '../pills/parameters/parameters.api';
import * as parcelDistributionKeys from '../pills/parcelDistributionKeys/parcelDistributionKeys.api';
import * as parcels from '../pills/parcels/parcels.api';
import * as parcelTypes from '../pills/parcelTypes/parcelTypes.api';
import * as residencies from '../pills/residencies/residencies.api';
import * as userEmails from '../pills/userEmails/userEmails.api';
import * as users from '../pills/users/users.api';
import { loadState, saveState } from './localState';
import reducers from './reducers';

const unhandledExceptionMiddleware = (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (e) {
    const error = e?.toString();
    if (error === 'TypeError: branch is undefined') {
      console.error(
        `Cette erreur est dû a un problème avec le local storage et redux orm. Probablement l'ajout d'un nouveau model.
        En effet, lors de l'ajout d'un nouveu model, le localStorage n'est plus en phase avec redux orm et crée cette erreur.
        Si vous venez d'ajouter un model, pensez à bump la version (avec le fix 1), sinon essayez le fix 2. Sinon, bonne chance ;)
        Fix 1: npm version patch --no-git-tag-version && npm start
        Fix 2: vider le localStorage`,
      );
    } else {
      console.error(e);
    }
    throw e;
  }
};

const cleanState = (state) => {
  if (!state) return undefined;

  const { entities, form, dataLoader } = state;
  if (!entities) return state;

  const { Auth, User, ...restEntities } = entities;
  if (!restEntities) return state;

  const cleanedEntities = { Auth, User };
  Object.keys(restEntities).forEach((key) => {
    const cleanedEntity = {};
    Object.keys(restEntities[key]).forEach((innerKey) => {
      if (Array.isArray(restEntities[key][innerKey])) {
        cleanedEntity[innerKey] = [];
      } else if (typeof restEntities[key][innerKey] === 'object') {
        cleanedEntity[innerKey] = {};
      } else {
        cleanedEntity[innerKey] = undefined;
      }
    });
    cleanedEntities[key] = cleanedEntity;
  });

  return { entities: cleanedEntities, form, dataLoader };
};

const api = {
  auth,
  members,
  residencies,
  parcels,
  parcelTypes,
  parameters,
  comments,
  companies,
  users,
  userEmails,
  documents,
  documentTypes,
  assemblies,
  assemblyResolutions,
  assemblyResolutionVotes,
  assemblyResolutionTemplates,
  assemblyLawArticles,
  assemblyMajorityModes,
  assemblyParticipants,
  assemblyLive,
  assemblyMinutes,
  assemblyConvocation,
  distributionKeys,
  parcelDistributionKeys,
  proabonoApi,
};
let middlewares = [
  thunk.withExtraArgument({
    api,
  }),
  unhandledExceptionMiddleware,
];

let composeEnhancers = compose;
if (process.env.NODE_ENV !== 'production') {
  const { isFSA } = require('flux-standard-action');
  const reduxImmutable = require('redux-immutable-state-invariant').default();
  const fsaChecker = (store) => (next) => (action) => {
    if (isFSA(action)) {
      return next(action);
    } else {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Dispatching a non FSA action => ', action);
      }
      if (process.env.REACT_APP_SENTRY_IS_ACTIVE === 'true') {
        Sentry.captureException('Dispatching a non FSA action => ', action);
      }
      throw new Error(
        `Dispatching a non FSA action => ${JSON.stringify(action)}`,
      );
    }
  };

  middlewares.push(fsaChecker);
  middlewares.push(reduxImmutable);
  composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeEnhancers;
}

export const storeFactory = (initialState = {}) => {
  if (process.env.REACT_APP_SENTRY_IS_ACTIVE === 'true') {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer();
    return createStore(
      reducers,
      initialState,
      composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer),
    );
  }
  return createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
};

const persistedState = loadState();
const store = storeFactory(cleanState(persistedState));

store.subscribe(
  debounce(() => {
    const { entities, form, dataLoader } = store.getState();
    saveState({ entities, form, dataLoader });
  }, 250),
);

if (process.env.NODE_ENV !== 'production') {
  window.__STORE = store;

  window.__ACTIONS = {
    residencies: require('../pills/residencies/residencies.actions'),
  };
  store.subscribe(() => {
    window.__STATE = store.getState();
  });
}

export default store;
