import useOutsideClick from 'hooks/useOutsideClick';
import React, { useState } from 'react';
import { GREY_400, GREY_600 } from 'styles/constant';

import ChevronDownIcon from '../chevronDown.icon';
import ChevronUpIcon from '../chevronUp.icon';
import { Span } from '../typography';
import {
  DropdownButtonHeader,
  DropdownButtonItems,
  DropdownButtonItemsContainer,
  DropdownButtonWrapper,
} from './dropdown-button.style';
import DropdownButtonItem from './dropdown-button-item';

export const DropdownButtonItemType = {
  directDownloadHandler: 'directDownloadHandler',
  onClickHandler: 'onClickHandler',
} as const;
export type ItemType = {
  label: string;
  onClick?: () => void;
  type: keyof typeof DropdownButtonItemType;
  icon?: React.ReactNode;
  target?: string;
  fallback?: string;
  loading?: boolean;
};

type DropdownButtonProps = {
  className?: string;
  label: string;
  items: Array<ItemType>;
  closeDropdown?: boolean;
  icon?: React.ReactNode;
};

const DropdownButton: React.FC<DropdownButtonProps> = ({
  className,
  label,
  items,
  icon,
  closeDropdown = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = React.useRef(null);
  useOutsideClick(ref, () => setIsOpen(false));
  const toggling = () => {
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    if (closeDropdown) {
      setIsOpen(false);
    }
  }, [closeDropdown]);
  return (
    <DropdownButtonWrapper className={`${className || ''}`} ref={ref}>
      <DropdownButtonHeader onClick={toggling} className="dropdown-header">
        {icon}
        <Span color={GREY_600} fontSize="14px" fontWeight="700">
          {label}
        </Span>
        {isOpen ? (
          <ChevronUpIcon color={GREY_400} />
        ) : (
          <ChevronDownIcon color={GREY_400} />
        )}
      </DropdownButtonHeader>
      {isOpen && (
        <DropdownButtonItemsContainer className="dropdown-list-container">
          <DropdownButtonItems className="dropdown-list">
            {items.map((item) => {
              return (
                <DropdownButtonItem
                  key={item.label}
                  onClick={item.onClick}
                  icon={item.icon}
                  label={item.label}
                  type={item.type}
                  target={item.target}
                  loading={item.loading}
                  fallback={item.fallback}
                />
              );
            })}
          </DropdownButtonItems>
        </DropdownButtonItemsContainer>
      )}
    </DropdownButtonWrapper>
  );
};

export default DropdownButton;
