import * as Sentry from '@sentry/react';
import { createAction } from 'redux-actions';

import { removeApiHeaderToken } from '../../api/base.api';
import finalFormTryCatch from '../../final-form-utils/tryCatchWrapper.final-form';
import User from '../../redux-orm-models/user.model';
import { addPrefixToActionTypes } from '../../redux-utils/utils';
import { updateEntity } from '../entities/entities.actions';

export const ACTION_TYPES = addPrefixToActionTypes(
  {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    PASSWORD_REQUEST_SUCCESS: 'PASSWORD_REQUEST_SUCCESS',
    PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
    ACCOUNT_ACTIVATE_SUCCESS: 'ACCOUNT_ACTIVATE_SUCCESS',
    LOGIN_WITH_COOKIES_SUCCESS: 'LOGIN_SUCCESS_WITH_COOKIES',
    LOGOUT_THEN_V2: 'LOGOUT_THEN_V2',
    LOGOUT: 'LOGOUT',
  },
  'auth',
);

export const loginSuccess = createAction(ACTION_TYPES.LOGIN_SUCCESS);
export const loginError = createAction(ACTION_TYPES.LOGIN_ERROR);
export const loginWithCookiesSuccess = createAction(
  ACTION_TYPES.LOGIN_WITH_COOKIES_SUCCESS,
);
export const registerSuccess = createAction(ACTION_TYPES.REGISTER_SUCCESS);
export const passwordRequestSuccess = createAction(
  ACTION_TYPES.PASSWORD_REQUEST_SUCCESS,
);
export const passwordResetSuccess = createAction(
  ACTION_TYPES.PASSWORD_RESET_SUCCESS,
);
export const accountActivateSuccess = createAction(
  ACTION_TYPES.ACCOUNT_ACTIVATE_SUCCESS,
);
export const logout = createAction(ACTION_TYPES.LOGOUT);

export const logoutThenV2 = createAction(ACTION_TYPES.LOGOUT_THEN_V2);

export const loginWithCookiesThenRedirect =
  (referrer, leaveUrl, finishUrl, tokenUrl, history, errorCallback) =>
  (dispatch, getState, { api }) =>
    finalFormTryCatch(async () => {
      removeApiHeaderToken();
      try {
        localStorage.removeItem('SYMENT:state');
        const user = !!tokenUrl?.length
          ? await api.auth.meWithToken(tokenUrl)
          : await api.auth.me();
        // fetching additional information separetly (root user only)
        // see: https://syment.atlassian.net/browse/SYM-2789
        if (user.roles?.includes('ROLE_SUPER_ADMIN')) {
          console.log('user has role: ROLE_SUPER_ADMIN');
          // better capture the exception
          // we don't want to let user retry to load in case of failure
          // just log it
          try {
            user.residencies = await api.auth.residencies();
            user.companies = await api.auth.companies();
          } catch (e) {
            Sentry.captureException(e);
            console.log(
              "error while trying to fetch root's companies & residencies: ",
              e,
            );
          }
        }

        dispatch(
          loginWithCookiesSuccess({
            ...user,
            token: tokenUrl || user.token,
            refresh_token: 'fake_token_we_are_using_cookies', // right now we don't get a refresh token
            redirecting: true,
            leaveUrl,
            finishUrl,
          }),
        );
        history.push(referrer);
      } catch (e) {
        // let redirect user
        errorCallback();
      }
    });

export const reload =
  () =>
  (dispatch, getState, { api }) =>
    finalFormTryCatch(async () => {
      try {
        const user = await api.auth.me();
        if (user.roles?.includes('ROLE_SUPER_ADMIN')) {
          try {
            user.residencies = await api.auth.residencies();
            user.companies = await api.auth.companies();
          } catch (e) {
            Sentry.captureException(e);
            console.log(
              "error while trying to fetch root's companies & residencies: ",
              e,
            );
          }
        }
        dispatch(updateEntity(User.modelName, user.id, user));
      } catch (e) {}
    });

export const login =
  ({ username, password }) =>
  (dispatch, getState, { api }) =>
    finalFormTryCatch(async () => {
      try {
        const { token, refresh_token } = await api.auth.login({
          username,
          password,
        });
        const user = await api.auth.me();

        if (user.roles?.includes('ROLE_SUPER_ADMIN')) {
          console.log('user has role: ROLE_SUPER_ADMIN');

          try {
            user.residencies = await api.auth.residencies();
            user.companies = await api.auth.companies();
          } catch (e) {
            Sentry.captureException(e);
            console.log(
              "error while trying to fetch root's companies & residencies: ",
              e,
            );
          }
        }
        dispatch(loginSuccess({ ...user, token, refresh_token }));
      } catch (e) {
        dispatch(loginError({ message: e.message }));
      }
    });
