import { faCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Icon from './icon';

const CircleIcon = ({ color, size, width, height, className }) => {
  return (
    <Wrapper w={width} h={height} color={color}>
      <Icon
        icon={faCircle}
        style={{ color: `var(--bs-${color})` }}
        className={className}
        size={size}
      />
    </Wrapper>
  );
};

CircleIcon.propTypes = {
  size: PropTypes.oneOf(['10x', '7x', '5x', '3x', '2x', 'lg', 'sm', 'xs']),
  color: PropTypes.oneOf(['primary', 'orange', 'pink', 'cyan', 'dark']),
};

export const Wrapper = styled.div`
  color: ${(props) => props.color || undefined};
  & > svg.custom {
    display: block;
    width: ${(props) => props.w};
    height: ${(props) => props.h};
  }
`;

export default CircleIcon;
