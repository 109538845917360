import debounce from 'lodash.debounce';
import React, { useState } from 'react';

import { SearchIcon, StyledInput } from '../../../../../ui-kit';
import { SearchBarWrapper } from './search-bar.style';

type SearchBarProps = {
  className?: string;
  filter: string | null;
  setMatch: React.Dispatch<React.SetStateAction<string | null>>;
};

const SearchBar: React.FC<SearchBarProps> = ({
  className,
  filter,
  setMatch,
}) => {
  const [text, setText] = useState(filter);

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: nextValue } = e.target;
    setText(nextValue);
    const debouncedFilterByText = debounce(() => setMatch(nextValue), 1000);
    debouncedFilterByText();
  };

  return (
    <SearchBarWrapper className={`${className || ''}`}>
      <SearchIcon size="1x" className="me-2" color="#929CA9" />
      <StyledInput
        value={text || ''}
        onChange={onTextChange}
        placeholder="Rechercher..."
      />
    </SearchBarWrapper>
  );
};

export default SearchBar;
