import { faPen, faUser } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import styled from 'styled-components';

import Icon from './icon';

export const GroupedSvgWrapper = styled.div`
  position: relative;
  width: 12px;
`;
export const BigSvg = styled.div`
  position: absolute;
`;
export const SmallSvg = styled.div`
  position: absolute;

  top: 8px;
  left: 16px;
  & > svg {
    display: block;
    width: 8px !important;
  }
`;
const UserEditIcon = (props) => (
  <GroupedSvgWrapper>
    <BigSvg>
      <Icon icon={faUser} />
    </BigSvg>
    <SmallSvg>
      <Icon icon={faPen} />
    </SmallSvg>
  </GroupedSvgWrapper>
);
export default UserEditIcon;
