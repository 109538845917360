import cs from 'classnames';
import React from 'react';

const Card = ({ title, children, className, isFlex = false, ...props }) => {
  return (
    <div
      className={
        isFlex
          ? cs('card', className)
          : cs('card', className, 'd-flex', 'flex-column')
      }
      {...props}
    >
      <div className="card-body d-flex flex-column" style={{ height: '100%' }}>
        {title && <h5 className="card-title">{title}</h5>}
        {children}
      </div>
    </div>
  );
};

export default Card;
