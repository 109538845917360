import { fk, Model } from 'redux-orm';

import { getId } from '../utils';
import Assembly from './assembly.model';

export const STATUS = {
  NONE: 'none',
  VALIDATED: 'validated',
  STARTING: 'starting',
  VALIDATED: 'validated',
  GENERATING: 'generating',
  GENERATED: 'generated',
  ZIP_READY: 'zip_ready',
  WAIT_SENDING: 'wait_sending',
  SENT: 'sent',
};

class AssemblyConvocation extends Model {
  constructor(data) {
    super({
      ...data,
      assemblyId: getId(data.assembly),
    });
  }

  isNone() {
    return this.ref.status === STATUS.NONE;
  }

  isValidated() {
    return this.ref.status === STATUS.VALIDATED;
  }

  isStarting() {
    return this.ref.status === STATUS.STARTING;
  }

  isValidated() {
    return this.ref.status === STATUS.VALIDATED;
  }

  isGenerating() {
    return this.ref.status === STATUS.GENERATING;
  }

  isGenerated() {
    return this.ref.status === STATUS.GENERATED;
  }

  isZipReady() {
    return this.ref.status === STATUS.ZIP_READY;
  }

  getZipFileUrl() {
    return this.ref.zipFile?.url;
  }
}

AssemblyConvocation.modelName = 'AssemblyConvocation';
AssemblyConvocation.fields = {
  assemblyId: fk({
    to: Assembly.modelName,
    as: 'assembly',
    relatedName: '_assemblyConvocation',
  }),
};
export default AssemblyConvocation;
