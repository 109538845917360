import { createSelector } from 'reselect';

const lastValidationTimeSelector = (store) =>
  store.dataLoader.lastValidationTime;

export const dataLoaderShouldRevalidateSelector = createSelector(
  lastValidationTimeSelector,
  (lastValidationTime) => {
    return process.env.NODE_ENV !== 'production' && lastValidationTime
      ? Date.now() - lastValidationTime > 1000 * 60 * 30 * 8 // 4h
      : true;
  },
);

export const dataLoaderFetchingResourceSelector = (store) =>
  store.dataLoader.fetchingResource;

export const dataLoaderStepSelector = (store) => store.dataLoader.step;
export const dataLoaderMaxStepSelector = (store) => store.dataLoader.maxStep;
