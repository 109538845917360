import useLoggedInUser from './useLoggedInUser';

const useCurrentCompany = () => {
  const currentUser = useLoggedInUser();
  if (!currentUser) {
    throw new Error(`No user logged in`);
  }
  return currentUser.currentCompany;
};

export default useCurrentCompany;
