import {
  CLEAR_FILTER,
  CLEAR_FILTER_LISTING,
  CLEAR_SEARCH_LISTING,
  CLOSE_VISIO,
  CREATE_VISIO,
  HIDE_VISIO,
  SET_FILTER,
  SET_FILTER_LISTING,
  SET_SEARCH_LISTING,
  SHOW_VISIO,
} from './session.actions';
import {
  RESET_CONVOCATION_STEP,
  SET_CONVOCATION_STEP,
} from './session.convocation.actions';
import { SET_MINUTES_STEP } from './session.minutes.actions';

const initialState = {
  filters: {
    inPersonChecked: true,
    onlineChecked: true,
    powerGivenChecked: true,
    noneChecked: false,
    votedChecked: true,
    notVotedChecked: true,
  },
  activeVisios: [],
  convocationSteps: [],
  minutesSteps: [],
  listing: {
    filter: { id: null },
    search: { match: null },
  },
};

const sessionReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SET_FILTER:
      return {
        ...state,
        filters: { ...action.payload },
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filters: { ...initialState.filters },
      };

    case SHOW_VISIO: {
      const { assemblyId, className } = action.payload;

      if (!assemblyId) {
        const currentVisio = state.activeVisios[0];
        return {
          ...state,
          activeVisios: [{ ...currentVisio, show: true, className }],
        };
      }

      const currentVisio = state.activeVisios.find(
        (visio) => visio.assemblyId === assemblyId,
      );
      const otherVisio =
        state.activeVisios.find((visio) => visio.assemblyId !== assemblyId) ||
        [];

      if (!currentVisio) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        activeVisios: [
          ...otherVisio,
          { ...currentVisio, show: true, className },
        ],
      };
    }
    case HIDE_VISIO: {
      const { assemblyId } = action.payload;

      if (!assemblyId) {
        const currentVisio = state.activeVisios[0];
        return {
          ...state,
          activeVisios: [{ ...currentVisio, show: false }],
        };
      }

      const currentVisio = state.activeVisios.find(
        (visio) => visio.assemblyId === assemblyId,
      );
      if (!currentVisio) {
        return {
          ...state,
        };
      }

      const otherVisio =
        state.activeVisios.find((visio) => visio.assemblyId !== assemblyId) ||
        [];

      return {
        ...state,
        activeVisios: [...otherVisio, { ...currentVisio, show: false }],
      };
    }

    case CREATE_VISIO: {
      const { assemblyId, className } = action.payload;
      return {
        ...state,
        activeVisios: [{ show: true, assemblyId, className }],
      };
    }

    case CLOSE_VISIO: {
      return {
        ...state,
        activeVisios: [],
      };
    }

    case SET_CONVOCATION_STEP: {
      const { assemblyId, step, date, owner } = action.payload;
      if (!state.convocationSteps[assemblyId]) {
        const newState = Array.from({ length: step + 1 }, (v, k) => {
          return { step: k, date, owner };
        });

        return {
          ...state,
          convocationSteps: {
            ...state.convocationSteps,
            [assemblyId]: newState,
          },
        };
      }
      return {
        ...state,
        convocationSteps: {
          ...state.convocationSteps,
          [assemblyId]: [
            ...state.convocationSteps[assemblyId],
            { step, date, owner },
          ],
        },
      };
    }

    case RESET_CONVOCATION_STEP: {
      const { assemblyId, step } = action.payload;
      const currentSteps = state.convocationSteps[assemblyId];
      const newSteps = currentSteps.filter((s) => s.step <= step);

      return {
        ...state,
        convocationSteps: {
          ...state.convocationSteps,
          [assemblyId]: [...newSteps],
        },
      };
    }

    case SET_MINUTES_STEP: {
      const { assemblyId, step } = action.payload;
      if (!state.minutesSteps[assemblyId]) {
        const newState = Array.from({ length: step + 1 }, (v, k) => {
          return { step: k };
        });

        return {
          ...state,
          minutesSteps: {
            ...state.minutesSteps,
            [assemblyId]: newState,
          },
        };
      }
      return {
        ...state,
        minutesSteps: {
          ...state.minutesSteps,
          [assemblyId]: [...state.minutesSteps[assemblyId], { step }],
        },
      };
    }

    case SET_FILTER_LISTING:
      return {
        ...state,
        listing: {
          search: { ...state.listing.search },
          filter: { ...action.payload },
        },
      };
    case CLEAR_FILTER_LISTING:
      return {
        ...state,
        listing: {
          search: { ...state.listing.search },
          filter: { ...initialState.listing.filter },
        },
      };

    case SET_SEARCH_LISTING:
      return {
        ...state,
        listing: {
          search: { ...action.payload },
          filter: { ...state.listing.filter },
        },
      };
    case CLEAR_SEARCH_LISTING:
      return {
        ...state,
        listing: {
          search: { ...initialState.listing.search },
          filter: { ...state.listing.filter },
        },
      };

    default:
      return state;
  }
};

export default sessionReducer;
