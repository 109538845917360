import { fk, Model } from 'redux-orm';

import Residency from './residency.model';

class Proabono extends Model {
  constructor(data) {
    super({
      ...data,
      id: data.residencyId,
      residencyId: data.residencyId,
    });
  }

  getIframeUrl() {
    return this.ref.customer?.links['hosted-register'];
  }

  toString() {
    return `Proabono: ${this.id}`;
  }
}

Proabono.modelName = 'Proabono';
Proabono.fields = {
  residencyId: fk({
    to: Residency.modelName,
    as: 'residency',
    relatedName: '_proabonoSet',
  }),
};
export default Proabono;
