import styled, { css } from 'styled-components';
import { DARK_TEXT, GREY_200, GREY_600 } from 'styles/constant';

export const DropdownButtonWrapper = styled.div`
  position: relative;
`;
export const DropdownButtonHeader = styled.div`
  border-radius: 6px;
  border: 1px solid ${GREY_200};
  height: 100%;

  padding: 8px 13px 9px 20px;
  display: flex;
  align-items: center;
  gap: 13px;

  cursor: pointer;

  & svg {
    transform: translateY(2px);
    cursor: pointer;
    color: ${GREY_600} !important;
  }
`;

export const DropdownButtonItemsContainer = styled('div')`
  position: absolute;
  width: fit-content;
  background: #fff;
  left: 0;
  right: 0;
  z-index: 10;
`;

export const DropdownButtonItems = styled('ul')`
  border-radius: 6px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;

  & li:not(:last-child) {
    margin-bottom: 3px;
  }
`;

const linkLayout = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DropdownButtonItemWrapper = styled.li`
  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9px 17px;

  &:hover {
    background-color: #afd2f2;
  }

  & span {
    ${linkLayout}
    white-space: pre;
  }

  & svg {
    font-size: 0.8em;
  }

  &:first-child {
    padding-top: 10px;
  }
  &:last-child {
    padding-bottom: 10px;
  }
`;

export const IconWrapper = styled.div``;

export const A = styled.a`
  ${linkLayout}
  text-decoration: none;
`;

export const LabelWrapper = styled.span`
  ${linkLayout}
  white-space: pre;

  color: ${DARK_TEXT};

  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
`;

export const SpinnerWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
`;
