import cs from 'classnames';
import React from 'react';
import styled from 'styled-components';

const StyledCard = ({
  title,
  children,
  className,
  isFlex = false,
  maxWidth = '29rem',
  ...props
}) => {
  return (
    <div
      className={
        isFlex
          ? cs('card', className)
          : cs('card', className, 'd-flex', 'flex-column')
      }
      {...props}
    >
      <Wrapper
        className="card-body d-flex flex-column"
        style={{ height: '100%' }}
        maxWidth={maxWidth}
      >
        {title && <h5 className="card-title">{title}</h5>}
        {children}
      </Wrapper>
    </div>
  );
};

export const Wrapper = styled.div`
  max-width: ${(props) => props.maxWidth};

  margin-left: auto;
  margin-right: auto;
`;

export default StyledCard;
