// import useSWR from 'swr';
import { residenciesApi } from '../../api/base.api';

export async function getResidencies(page) {
  return await residenciesApi.list({ page });
}

export async function createResidency(residency) {
  return await residenciesApi.create(residency);
}

export async function updateResidency(residency) {
  return await residenciesApi.update(residency);
}

export async function setConcilMembers(residencyId, csMembers) {
  return await residenciesApi.setConcilMembers(residencyId, {
    councilMembers: csMembers,
  });
}

export async function getResidencyById(id) {
  return await residenciesApi.getById({ id });
}
