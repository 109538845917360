import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SortingRule, useExpanded, useSortBy, useTable } from 'react-table';

import { MembersAPI } from '../../../../../pills/members/member.type';
import { Loader, THRenderer } from '../../../../../ui-kit';
import { ColumnNames, COLUMNS } from './column';
import {
  ListingWrapper,
  LoaderWrapper,
  Table,
  TBody,
  TDBody,
  THead,
  THHead,
  TRBody,
  TRHead,
} from './listing.style';
import { normalize } from './mapper';
import MemberSummaryRenderer from './renderer/cell/member-summary-renderer';

type ListingProps = {
  className?: string;
  isLoading: boolean;
  isError?: boolean;
  data?: MembersAPI;
  sort: SortingRule<ColumnNames>;
  setSort: Dispatch<SetStateAction<SortingRule<ColumnNames>>>;
  residencyId: string | null;
};

const Listing = ({
  className,
  data,
  sort,
  setSort,
  isLoading,
  isError,
  residencyId,
}: ListingProps) => {
  const columns: any = useMemo(() => COLUMNS, []);
  const [tableData, setTableData] = useState<ColumnNames[]>(
    normalize(data || []),
  );

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      manualSortBy: true,
      disableSortRemove: true,
      sortDescFirst: true,
      initialState: {
        sortBy: [sort],
      },
    },
    useSortBy,
    useExpanded,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = tableInstance;

  useEffect(() => {
    setSort(sortBy[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSort, JSON.stringify(sortBy)]);

  useEffect(() => {
    setTableData(normalize(data || []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  const getFallback = () => {
    if (isLoading) {
      return (
        <LoaderWrapper>
          <Loader size="sm" />
        </LoaderWrapper>
      );
    }
    return (
      <LoaderWrapper>
        <div>error from backend, to be designed</div>
      </LoaderWrapper>
    );
  };
  return (
    <ListingWrapper className={`${className || ''}`}>
      <>
        <Table {...getTableProps()} className="listing-table">
          <THead className="thead">
            {headerGroups.map((headerGroup) => {
              return (
                <TRHead
                  {...headerGroup.getHeaderGroupProps()}
                  className="trhead"
                  key={`thead-tr-${headerGroup.id}`}
                >
                  {headerGroup.headers.map((column) => {
                    return (
                      <THHead {...column.getHeaderProps()} className="thhead">
                        <THRenderer<ColumnNames>
                          column={column as any}
                          key={`thead-th-${column.id}`}
                        />
                      </THHead>
                    );
                  })}
                </TRHead>
              );
            })}
          </THead>
          <TBody {...getTableBodyProps()} className="tbody">
            {isLoading || isError ? (
              getFallback()
            ) : (
              <>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <TRBody {...row.getRowProps()} className="trbody">
                      {row.cells.map((cell) => {
                        return (
                          <TDBody {...cell.getCellProps()} className="tdbody">
                            {cell.render('Cell')}
                          </TDBody>
                        );
                      })}
                      {row.isExpanded ? (
                        <MemberSummaryRenderer
                          row={rows[i] as any}
                          residencyId={residencyId}
                        />
                      ) : null}
                    </TRBody>
                  );
                })}
              </>
            )}
          </TBody>
        </Table>
      </>
    </ListingWrapper>
  );
};

export default Listing;
