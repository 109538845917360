import { Annexe } from 'redux-orm-models/assembly.model.type';

import { assemblyResolutionsApi } from '../../api/base.api';

const flattenResolutions = (resolutionsArray: any) => {
  const flatArray: any = [];
  resolutionsArray.map((resolution: any) => {
    const { subResolutions } = resolution;
    flatArray.push({
      ...resolution,
      initialResolution: null,
      subResolution: false,
    });
    if (subResolutions?.length > 0) {
      subResolutions.map((subResolution: any) => {
        const { parentResolution, ...currentSubResolution } = subResolution;
        const position = `${resolution.position}.${subResolution.position}`;
        flatArray.push({
          ...currentSubResolution,
          position,
          rights: resolution.rights,
          reopenedVoteResolution: currentSubResolution.reopenedVoteResolution
            ? {
                ...currentSubResolution.reopenedVoteResolution,
                position,
                subResolution: true,
              }
            : null,
          initialResolution: parentResolution,
          subResolution: true,
        });
      });
    }
  });
  return flatArray;
};

export async function getAssemblyResolutionsByAssemblyId(assemblyId: string) {
  const resolutions = await assemblyResolutionsApi.listByAssemblyId({
    id: assemblyId,
  });
  const flattenAssemblyResolutions = flattenResolutions(resolutions);
  return flattenAssemblyResolutions.map((assemblyResolution: any) => ({
    ...assemblyResolution,
    assemblyId,
    isResolution: assemblyResolution.type === 'resolution',
    isSuggestion: assemblyResolution.type === 'suggestion',
  }));
}

export async function getAssemblyResolutionById(
  id: string,
  assemblyId: string,
) {
  return await assemblyResolutionsApi
    .getById({ id })
    .then((ar) => ({ ...ar, assemblyId }));
}

export async function createAssemblyResolution(assemblyResolution: string) {
  return await assemblyResolutionsApi.create(assemblyResolution);
}

export async function createAssemblyResolutionsFromTemplate(
  assemblyId: string,
  assemblyResolutionTemplateIds: any,
) {
  const createdBulkAssemblyResolution =
    await assemblyResolutionsApi.createFromResolutionTemplates(assemblyId, {
      templates: assemblyResolutionTemplateIds,
    });
  return createdBulkAssemblyResolution;
}

export async function updateAssemblyResolution(assemblyResolution: any) {
  return await assemblyResolutionsApi.update(assemblyResolution);
}

export async function updateOrder({ id, position }: any) {
  const updatedAssemblyResolution = await assemblyResolutionsApi.updateOrder({
    id,
    position,
  });
  return updatedAssemblyResolution;
}

export async function updateSubResolutionOrder({ id, resolutions }: any) {
  const updatedAssemblyResolution =
    await assemblyResolutionsApi.updateSubResolutionOrder({
      id,
      resolutions,
    });
  return updatedAssemblyResolution;
}

export async function updateAssemblyOnGoingResolution(
  assemblyResolution: any,
  isSymentResolution: boolean,
) {
  if (isSymentResolution) {
    return await assemblyResolutionsApi.updateOnGoingSymentResolution(
      assemblyResolution,
    );
  }

  const updatedAssemblyResolution = await assemblyResolutionsApi.updateOnGoing(
    assemblyResolution,
  );
  return updatedAssemblyResolution;
}

export async function updateAssemblyOnGoingResolutionWithoutSubject(
  assemblyQuorumFailed: any,
  assemblyResolution: any,
) {
  const updatedAssemblyResolution =
    await assemblyResolutionsApi.updateOnGoingWithoutSubject({
      id: assemblyResolution.id,
      assemblyQuorumFailed: assemblyQuorumFailed,
    });
  return updatedAssemblyResolution;
}

export async function deleteAssemblyResolution(assemblyResolution: any) {
  const deletedAssemblyResolution = await assemblyResolutionsApi.delete(
    assemblyResolution,
  );
  return deletedAssemblyResolution;
}

export async function duplicateAssemblyResolution(assemblyResolution: any) {
  const duplicatedResolution = await assemblyResolutionsApi.duplicate(
    assemblyResolution,
  );
  return { ...duplicatedResolution, assemblyId: assemblyResolution.assemblyId };
}

export async function listAnnexes(
  assemblyId: string,
  resolutionId: string,
): Promise<Annexe[]> {
  return await assemblyResolutionsApi.listAnnexes(assemblyId, resolutionId);
}
