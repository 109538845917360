import { DATE_FORMATS, localizedFormat } from 'date.utils';
import {
  resetConvocationStep,
  setConvocationStep,
} from 'pills/session/session.convocation.actions';
import {
  ConvocationStepsById,
  getCurrentStep,
} from 'pills/session/session.convocation.type';
import { useDispatch } from 'react-redux';
import { capitalize } from 'utils';

import useLoggedInUser from './useLoggedInUser';

const useInitConvocationSteps = ({
  assemblyId,
  steps,
  invitationStatus,
}: {
  assemblyId: string;
  steps: ConvocationStepsById;
  invitationStatus: string | undefined;
}) => {
  const user = useLoggedInUser();
  const dispatch = useDispatch();
  if (invitationStatus !== undefined) {
    const currentStep = getCurrentStep(invitationStatus);
    if (!steps) {
      dispatch(
        setConvocationStep({
          step: currentStep,
          assemblyId,
          owner: `${capitalize(user?.lastName)} ${capitalize(user?.firstName)}`,
          date: localizedFormat(new Date(), DATE_FORMATS.dayMonthYearSlash),
        }),
      );
    }
  }
};

export const useSetConvocationStep = ({
  assemblyId,
  step,
}: {
  assemblyId: string;
  step: number;
}) => {
  const user = useLoggedInUser();

  const dispatch = useDispatch();

  return () => {
    dispatch(
      setConvocationStep({
        step,
        assemblyId,
        owner: `${capitalize(user?.lastName)} ${capitalize(user?.firstName)}`,
        date: localizedFormat(new Date(), DATE_FORMATS.dayMonthYearSlash),
      }),
    );
  };
};

export const useResetConvocationStep = ({
  assemblyId,
  step,
}: {
  assemblyId: string;
  step: number;
}) => {
  const dispatch = useDispatch();

  return () => {
    dispatch(
      resetConvocationStep({
        step,
        assemblyId,
      }),
    );
  };
};

export default useInitConvocationSteps;
