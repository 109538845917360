import { File } from 'pills/members/member.type';

import { ParcelDetailType, ParcelType } from '../hooks/type';

export const getTypeLabel = (type: number, parcelTypes: ParcelDetailType[]) => {
  const filteredType = parcelTypes.filter(
    (parcelType) => parcelType.id === type,
  );
  if (filteredType.length === 0) {
    throw Error(`Unknow parcel type ${type}`);
  }

  return filteredType[0].name;
};

export type NormalizeParcelsProps = {
  parcels: (ParcelType | undefined)[];
  parcelTypes: ParcelDetailType[];
};

export type NormalizedParcels = {
  ref: string;
  type: string;
  tantieme: number;
  residency: string;
  residencyId: string;
  residencyCode: string;
  residencyFile: File;
};

export const normalizeParcels = ({
  parcels,
  parcelTypes,
}: NormalizeParcelsProps): NormalizedParcels[][] => {
  const knownParcels = parcels.filter(
    (parcel) => parcel !== undefined || parcel !== null,
  ) as ParcelType[];
  const ownedParcels = knownParcels.filter(
    (parcel) => parcel && parcel?.parcelDistributionKeys?.length > 0,
  );

  const formattedParcels = ownedParcels.map((parcel) => {
    const hasPrimaryKey = parcel?.parcelDistributionKeys.some(
      (parcelDistributionKey) => parcelDistributionKey.distributionKey.primary,
    );

    let quotity = 0;

    if (hasPrimaryKey) {
      const primaryDistributionKey = parcel.parcelDistributionKeys.filter(
        (parcelDistributionKey) =>
          parcelDistributionKey.distributionKey.primary,
      );
      quotity = primaryDistributionKey[0].quotity;
    }

    return {
      ref: parcel?.number,
      type: getTypeLabel(parcel.type, parcelTypes),
      tantieme: quotity,
      residency: parcel.residency?.displayName,
      residencyFile: parcel.residency?.file,
      residencyCode: parcel.residency?.code,
      residencyId: parcel.residency?.id,
    };
  });
  return groupByResidencyName(formattedParcels);
};

export const groupByResidencyName = (
  formattedParcels: NormalizedParcels[],
): NormalizedParcels[][] => {
  const groupedBy: {
    [key: string]: NormalizedParcels[];
  } = formattedParcels.reduce((r, a) => {
    r[a.residency] = r[a.residency] || [];
    r[a.residency].push(a);
    return r;
  }, Object.create(null));

  const formatted: NormalizedParcels[][] = [];

  Object.entries(groupedBy).forEach(([, value]) => {
    formatted.push(value);
  });
  return formatted;
};
