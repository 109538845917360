import { AssemblyType } from 'pages/assemblies/components/hooks/type';
import { Residency } from 'pages/members/index/components';
import { ManagedResidency } from 'pills/members/member.type';
import React from 'react';
import { DARK_TEXT } from 'styles/constant';
import { Box, Span } from 'ui-kit';
import { pluralize } from 'utils';

import AssembliesByResidency from './assemblies-by-residency';
import { BuildingWrapper } from './building.style';

type BuildingProps = {
  className?: string;
  residencies: ManagedResidency[];
  assemblies: AssemblyType[];
};

const Building: React.FC<BuildingProps> = ({
  className,
  residencies,
  assemblies,
}) => {
  return (
    <BuildingWrapper className={`${className || ''}`}>
      <Span color={DARK_TEXT} fontSize="24px">
        {pluralize('Immeuble', residencies.length)}
      </Span>
      {residencies.map((residency) => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            gap="7px"
            key={residency.id}
          >
            <Residency
              name={residency.displayName}
              key={residency.id}
              councilMember={residency.councilMember}
              variant="detailed"
            />
            <AssembliesByResidency
              assemblies={assemblies}
              residencyId={residency.id}
            />
          </Box>
        );
      })}
    </BuildingWrapper>
  );
};

export default Building;
