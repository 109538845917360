import { useLoggedInUser } from 'hooks';
import React from 'react';

import CustomSelect from './custom-select';
import { FilterBarWrapper } from './filter-bar.style';
import { getSelectParameters } from './helper';

type FilterBarProps = {
  className?: string;
  residencyId: string | null;
  setResidencyId: React.Dispatch<React.SetStateAction<string | null>>;
};

const FilterBar = ({
  residencyId,
  setResidencyId,
  className,
}: FilterBarProps) => {
  const loggedInUser = useLoggedInUser();

  const { options, selected } = getSelectParameters(
    loggedInUser.residencies,
    residencyId,
  );

  const setSelected = (id: string | null) => {
    setResidencyId(id);
  };

  return (
    <FilterBarWrapper className={`${className || ''} filter-bar`}>
      <CustomSelect
        selected={selected}
        options={options}
        setSelected={setSelected}
      />
    </FilterBarWrapper>
  );
};

export default FilterBar;
