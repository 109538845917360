import { assemblyConvocationApi } from '../../api/base.api';
import { STATUS } from '../../redux-orm-models/assemblyConvocation.model';

export async function getAssemblyConvocationByAssemblyId(assemblyId) {
  let convocation = await assemblyConvocationApi.getByAssemblyId({
    id: assemblyId,
  });
  convocation.id = assemblyId;
  return convocation;
}

export async function updateAssemblyConvocationByAssemblyId(
  assemblyId,
  assemblyConvocation,
) {
  const updatedAssemblyConvocation =
    await assemblyConvocationApi.updateByAssemblyId({
      id: assemblyId,
      ...assemblyConvocation,
    });
  updatedAssemblyConvocation.id = assemblyId;
  return updatedAssemblyConvocation;
}

export async function updateTemplateAssemblyConvocationByAssemblyId(
  assemblyId,
  assemblyConvocation,
) {
  const updatedAssemblyConvocationTemplate =
    await assemblyConvocationApi.updateTemplateByAssemblyId({
      id: assemblyId,
      ...assemblyConvocation,
    });
  updatedAssemblyConvocationTemplate.id = assemblyId;
  return updatedAssemblyConvocationTemplate;
}

export async function getAssemblyConvocationFilesByAssemblyId(assemblyId) {
  const files = await assemblyConvocationApi.getFilesByAssemblyId({
    id: assemblyId,
  });
  return files.map((file) => ({ ...file, id: file.file.id }));
  // NOTE: api return composite object where the id of the file is stored in 'file' property
  // redux-orm need a 'fixed' id on the ressource to avoid creating new model and therefor force a complete render
  // associated with a cacheTime at 0, the container loop req->createmodel->render->req...
}

export async function generateAssemblyConvocationByAssemblyId(
  assemblyId,
  assemblyConvocation,
) {
  // NOTE: We have to cancel generating docuements before trying to generate new
  // see : https://netick-dev.atlassian.net/browse/SYF-844
  // the following request cancel the current generation taks.
  const updatedAssemblyConvocation =
    await assemblyConvocationApi.generateByAssemblyId({
      id: assemblyId,
      html: assemblyConvocation.html,
      status: STATUS.GENERATING,
    });
  updatedAssemblyConvocation.id = assemblyId;
  return updatedAssemblyConvocation;
}

export const getConvocationRegisteredEmailConsent = async ({ id }) => {
  return await assemblyConvocationApi.getConvocationRegisteredEmailConsent({
    id,
  });
};

export const getMonitoringByAssemblyId = async ({ id, doc }) => {
  return await assemblyConvocationApi.getMonitoringByAssemblyId({
    id,
    doc,
  });
};
