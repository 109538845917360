import { parametersApi } from '../../api/base.api';

export async function updateDiscussionById({ id, type, discussion }) {
  return await parametersApi.update({
    type,
    sourceId: id,
    name: 'discussion',
    metadata: { value: discussion },
  });
}
