import { Address as AddressType } from 'pills/members/member.type';
import React from 'react';
import styled from 'styled-components';
import { DARK_TEXT, GREY_700 } from 'styles/constant';
import { MapMarkerAltIcon, Span } from 'ui-kit';

type AddressProps = {
  className?: string;
  address: AddressType;
  size?: 'sm' | 'lg';
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  align-self: flex-start;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const AddressWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Address: React.FC<AddressProps> = ({
  className,
  address,
  size = 'sm',
}) => {
  const hasAddress =
    address?.line1 || address?.line2 || address?.city || address?.zipcode;

  return (
    <AddressWrapper className={`${className || ''}`}>
      <IconWrapper className="icon-wrapper">
        <MapMarkerAltIcon color={GREY_700} size={size} />
      </IconWrapper>
      <Content>
        {hasAddress ? (
          <>
            {address?.line1 && <Span>{address?.line1}</Span>}
            {address?.line2 && <Span>{address?.line2}</Span>}
            {(address?.zipcode || address?.city) && (
              <Span>
                {address?.zipcode} {address?.city}
              </Span>
            )}
          </>
        ) : (
          <Span fontSize="14px" color={DARK_TEXT}>
            Non renseigné
          </Span>
        )}
      </Content>
    </AddressWrapper>
  );
};

export default Address;
