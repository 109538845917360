import styled, { css } from 'styled-components';
import { DARK_TEXT, GREY_100, GREY_200, GREY_600 } from 'styles/constant';
import { Box, Span } from 'ui-kit';

const gridStyle = css`
  display: grid;
  grid-template-columns: 1fr 3fr 2fr;
  grid-column-gap: 52px;
  align-items: center;
`;

export const ParcelsListingWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
`;
export const HeaderWrapper = styled.div`
  padding: 10px 70px 10px 30px;
  ${gridStyle}
  border-radius-: 6px;
  background-color: ${GREY_100};
  & ${Span}, & ${Box} {
    color: #9da6b1;
    letter-spacing: 0px;
    font-size: 10px;
    font-weight: 700;
  }

  & ${Box} {
    color: ${GREY_600};
    cursor: pointer;
  }
`;
export const BodyWrapper = styled.div`
  & ${Span} {
    color: ${DARK_TEXT};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
  }
`;

export const Row = styled.div`
  padding: 22px 0px;
  padding-left: 30px;
  padding-right: 70px;
  ${gridStyle};
  border-bottom: 1px solid ${GREY_200};
`;
