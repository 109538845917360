import React from 'react';
import { DARK_TEXT } from 'styles/constant';
import { Box, Loader, Span } from 'ui-kit';

import { pluralize, sum } from '../../../../../utils';
import { useParcels, useParcelTypes } from '..';
import {
  Col1,
  Col2,
  DetailedOwnershipWrapper,
  ListingsWrapper,
} from './detailed-ownership.style';
import { normalizeParcels } from './helper';
import ParcelsByResidency from './parcels-by-residency';

type DetailedOwnershipProps = {
  className?: string;

  ownedParcels: string[];
};

const DetailedOwnership: React.FC<DetailedOwnershipProps> = ({
  className,

  ownedParcels,
}) => {
  const ownedParcelIds = ownedParcels.map((parcel) =>
    parcel.replace('/parcels/', ''),
  );
  const { data: parcels, isLoading } = useParcels({
    parcelIds: ownedParcelIds,
  });

  const { data: parcelTypes, isLoading: isParcelTypeLoading } =
    useParcelTypes();

  if (isLoading || isParcelTypeLoading || parcelTypes === undefined) {
    return (
      <DetailedOwnershipWrapper className={`${className || ''}`}>
        <Box mt="50px">
          <Loader size="xs" />
        </Box>
      </DetailedOwnershipWrapper>
    );
  }

  const formattedParcels = normalizeParcels({ parcels, parcelTypes });

  const evenParcels = formattedParcels
    .filter((_, index) => index % 2 === 0)
    .map((parcel) => (
      <ParcelsByResidency key={parcel[0].residencyId} parcels={parcel} />
    ));
  const oddParcels = formattedParcels
    .filter((_, index) => index % 2 !== 0)
    .map((parcel) => (
      <ParcelsByResidency key={parcel[0].residencyId} parcels={parcel} />
    ));

  const numberOfEvenParcels =
    evenParcels.length !== 0
      ? sum(evenParcels.map((evenParcel) => evenParcel.props.parcels.length))
      : 0;
  const numberOfOddParcels =
    oddParcels.length !== 0
      ? sum(oddParcels.map((oddParcel) => oddParcel.props.parcels.length))
      : 0;

  const numberOfOwnedParcels = numberOfEvenParcels + numberOfOddParcels;

  return (
    <DetailedOwnershipWrapper className={`${className || ''}`}>
      <Span color={DARK_TEXT} fontSize="24px">
        {`Propriétaire de ${numberOfOwnedParcels} ${pluralize(
          'lot',
          numberOfOwnedParcels,
        )}`}
      </Span>
      <ListingsWrapper>
        <Col1>{evenParcels}</Col1>
        <Col2>{oddParcels}</Col2>
      </ListingsWrapper>
    </DetailedOwnershipWrapper>
  );
};

export default DetailedOwnership;
