import { Model } from 'redux-orm';

import DEFAULT_AVATAR from '../images/residency-default-avatar.svg';

class Residency extends Model {
  constructor(data) {
    super({ ...data, managers: data?.managers || [] });
  }
  hasDiscussionActivated() {
    return this.normalizedParameters.discussion ?? true;
  }
  get normalizedParameters() {
    return this.ref.parameters.reduce(
      (acc, { name, metadata: { value } }) => ({
        ...acc,
        [name]: Boolean(value),
      }),
      {},
    );
  }
  get news() {
    return this.ref.news.map((newItem) => newItem.split('/').pop());
  }
  /**
   * @return Array of {id : parcelTypeId, quanity: quantity in the residence, count: parcel count, name: displayed name}
   */
  get parcelTypes() {
    return this.ref.parcelTypeQuantities.map((pqt) => {
      const parcelTypeCount = this.ref.stats.parcelTypeCount;
      const count =
        parcelTypeCount && parcelTypeCount[pqt.parcelType.id]
          ? parcelTypeCount[pqt.parcelType.id]
          : 0;

      return {
        ...pqt,
        ...pqt.parcelType,
        count,
      };
    });
  }
  get developer() {
    return this.ref.developer?.split('/').pop();
  }
  get avatar() {
    return this?.file?.url || DEFAULT_AVATAR;
  }
  get companyId() {
    return this.company.split('/').pop();
  }
  get companyName() {
    return this.ref.company?.name;
  }
  toString() {
    return `Residency: ${this.displayName}`;
  }
}

Residency.modelName = 'Residency';

export default Residency;
