import { authApi } from '../../api/base.api';
import { USER_STATUS } from './auth.constants';

const { INDIVIDUAL, PROFESSIONAL } = USER_STATUS;

// Should be the first request as it set the header Bearer token. See base.api.authApi.login
/**
 *
 * @param {Object} credentials
 * @param {String} credentials.username the email to send
 * @param {String} credentials.password the password to send
 * @return {Promise.resolve(Object)} Resolve an objeect within {token, refresh_token} - it used to populate the header.
 * Usually followed by GET /me (aka authApi.me())
 */
export async function login({ username, password }) {
  return await authApi.login({ username, password });
}

export async function me() {
  return await authApi.me();
}

export async function meWithToken(tokenUrl) {
  return await authApi.meWithToken(tokenUrl);
}

export async function companies() {
  return await authApi.companies();
}

export async function switchProfile({ newProfile }) {
  return await authApi.switchProfile({ newProfile });
}

export async function residencies() {
  return await authApi.residencies();
}

export async function updateMe(data) {
  return await authApi.updateMe(data);
}

export async function register({ status, ...data }) {
  const _data = { ...data };
  if (status === PROFESSIONAL) {
    _data.registrationType = PROFESSIONAL;
    delete _data.registrationCouncilName;
    delete _data.registrationCouncilPhoneNumber;
  } else if (status === INDIVIDUAL) {
    delete _data.registrationCompanyName;
    delete _data.registrationCompanySiret;
    delete _data.registrationCompanyAddress;
    delete _data.registrationCompanyType;
  }
  return await authApi.register(data);
}

export async function passwordRequest({ email }) {
  return await authApi.passwordRequest({ email });
}

export async function passwordReset({ password, passwordConfirm, token }) {
  return await authApi.passwordReset({
    password,
    passwordConfirm,
    token,
  });
}

export async function passwordChange({ password }) {
  return await authApi.passwordChange({
    plainPassword: password,
    passwordConfirm: password,
  });
}

export async function accountActivate({
  email,
  firstName,
  lastName,
  password,
  passwordConfirm,
  token,
}) {
  return await authApi.accountActivate({
    email,
    firstName,
    lastName,
    password,
    passwordConfirm,
    token,
  });
}

export async function routes(routeKey) {
  try {
    return await authApi.routes(routeKey);
  } catch (err) {
    console.error(err);
    return null;
  }
}
