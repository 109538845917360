import cs from 'classnames';
import React from 'react';

import { LiveQuorumThresholdWrapper } from '../pages/assemblyLive/shared-components/assemblyLive.footer.style';

const PROGRESS_BAR_SIZES = {
  tiny: { height: '4px' },
  md: {},
  lg: { height: '9px' },
};

type ProgressBarProps = {
  progress: number;
  color: string;
  size?: 'tiny' | 'md' | 'lg';
  className?: string;
  threshold?: string;
  showPercent?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const ProgressBar = ({
  progress,
  className,
  size = 'md',
  color,
  threshold,
  showPercent = false,
  ...props
}: ProgressBarProps) => {
  const tableCell = progress !== 0 ? 'd-table-cell' : '';
  return (
    <div className={className}>
      {showPercent && (
        <div className="tw-text-center tw-font-bold tw-pb-5">{progress}%</div>
      )}
      <div
        className={`progress ${className}`}
        style={PROGRESS_BAR_SIZES[size]}
        {...props}
      >
        <div
          className={cs('progress-bar', `${tableCell}`, `bg-${color}`)}
          style={{ width: `${progress}%` }}
          aria-valuenow={progress}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
        {threshold && (
          <LiveQuorumThresholdWrapper
            threshold={threshold}
          ></LiveQuorumThresholdWrapper>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
