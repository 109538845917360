import React from 'react';
import styled from 'styled-components';

import { GREY_200, GREY_900 } from '../../../styles/constant';
import ChevronLeftIcon from '../../chevronLeft.icon';
import ChevronRightIcon from '../../chevronRight.icon';
import { getNumberOfItemsFromPreviousPages, isLastPage } from './helper';

type PaginationProps = {
  className?: string;
  currentNumberOfItems: number;
  numberOfItems: number;

  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

const PaginationWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 54px;

  & svg {
    cursor: pointer;
  }
  & svg.disabled {
    cursor: not-allowed;
  }
`;

const Content = styled.div``;

const Pagination: React.FC<PaginationProps> = ({
  currentNumberOfItems,
  numberOfItems,

  currentPage,
  setCurrentPage,
  className,
}) => {
  const isLast = isLastPage(currentPage, currentNumberOfItems, numberOfItems);

  const numberOfItemsFromPreviousPages =
    getNumberOfItemsFromPreviousPages(currentPage);

  const nextPage = () => {
    if (!isLast) {
      setCurrentPage((page) => page + 1);
    }
  };

  const previousPage = () => {
    if (!isFirstPage()) {
      setCurrentPage((page) => page - 1);
    }
  };

  const isFirstPage = () => {
    return currentPage === 1;
  };

  const firstItemInCurrentPage = () => {
    if (numberOfItems === 0) {
      return 0;
    }
    return numberOfItemsFromPreviousPages + 1;
  };

  const lastItemInCurrentPage = () => {
    return numberOfItemsFromPreviousPages + currentNumberOfItems;
  };

  return (
    <PaginationWrapper className={`${className || ''}`}>
      <ChevronLeftIcon
        className={isFirstPage() ? 'disabled' : ''}
        color={isFirstPage() ? GREY_200 : GREY_900}
        onClick={previousPage}
      />
      <Content>{`${firstItemInCurrentPage()}-${lastItemInCurrentPage()} sur ${numberOfItems}`}</Content>
      <ChevronRightIcon
        className={isLast ? 'disabled' : ''}
        color={isLast ? GREY_200 : GREY_900}
        onClick={nextPage}
      />
    </PaginationWrapper>
  );
};

export default Pagination;
