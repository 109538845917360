import { Residency } from 'redux-orm-models/residency.type';

export type OptionResidencyType = {
  name: string;
  displayName: string;
  id: string | null;
  location: string | null;
};

export const normalizeResidencies = (
  residencies: Array<Residency>,
): Array<OptionResidencyType> => {
  return residencies.map((residency) => {
    return {
      name: residency.name,
      displayName: residency.displayName,
      id: residency.id,
      location: residency.address ? residency.address.city : '',
    };
  });
};

const defaultResidency = {
  name: 'Toutes les résidences',
  displayName: 'Toutes les résidences',
  id: null,
  location: null,
  value: null,
};

export const getSelectParameters = (
  residencies: Array<Residency>,
  residencyId: string | null,
): {
  options: Array<OptionResidencyType>;
  selected: OptionResidencyType;
} => {
  const normalizedResidencies = normalizeResidencies(residencies);
  const remainingItem = normalizedResidencies;

  remainingItem.sort((a, b) => a.displayName.localeCompare(b.displayName));

  const options = remainingItem.map((item) => ({
    name: item.name,
    displayName: item.displayName,
    id: item.id,
    location: item.location,
    value: item.id,
  }));

  options.unshift(defaultResidency);

  const optionsLeft = options.filter((item) => item.id !== residencyId);
  const selected = options.filter((item) => item.id === residencyId)[0];
  return {
    options: optionsLeft,
    selected,
  };
};
