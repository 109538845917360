import { commentsApi } from '../../api/base.api';

export async function getCommentsByResidencyId(residencyId) {
  return await commentsApi.listByResidencyId({ id: residencyId });
}

export async function moderateComment(id) {
  return await commentsApi.moderate({ id });
}

export async function unmoderateComment(id) {
  return await commentsApi.unmoderate({ id });
}

export async function deleteComment(id) {
  return await commentsApi.delete({ id });
}

export async function updateComment(comment) {
  return await commentsApi.update(comment);
}

export async function reportComment(id) {
  return await commentsApi.report({ id });
}

export async function unreportComment(id) {
  return await commentsApi.unreport({ id });
}
