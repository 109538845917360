import React from 'react';

import { Button, SpinnerIcon } from '.';

interface FormSubmitButtonProps {
  invalid: boolean;
  submitting: boolean;
  loader?: boolean;
  disabled?: boolean;
  className?: string;
  lg?: boolean;
  sm?: boolean;
  primary?: boolean;
  secondary?: boolean;
  success?: boolean;
  light?: boolean;
  danger?: boolean;
  block?: boolean;
  active?: boolean;
}

const FormSubmitButton: React.FC<FormSubmitButtonProps> = ({
  invalid,
  submitting,
  loader = true,
  disabled,
  className,
  lg,
  sm,
  primary,
  secondary,
  success,
  light,
  danger,
  block,
  active,
  children,

  ...rest
}) => {
  if (!submitting && !children) {
    return null;
  }
  return (
    <Button
      className={className || ''}
      lg={lg}
      sm={sm}
      primary={primary}
      secondary={secondary}
      success={success}
      light={light}
      danger={danger}
      block={block}
      active={active}
      type="submit"
      disabled={invalid || submitting || disabled}
      {...rest}
    >
      {(loader && submitting && <SpinnerIcon />) || children}
    </Button>
  );
};

export default FormSubmitButton;
