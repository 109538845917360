import * as Sentry from '@sentry/react';
import { FORM_ERROR } from 'final-form';

/**
 * Simple wrapper to throw a FORM_ERROR
 * @see: https://final-form.org/docs/react-final-form/examples/submission-errors
 * @param {Function} fn
 * @param {Function?} errorFn
 */

async function finalFormTryCatch(fn, errorFn) {
  try {
    return await fn();
  } catch (_error) {
    if (process.env.NODE_ENV !== 'production') console.error(_error);
    if (process.env.REACT_APP_SENTRY_IS_ACTIVE === 'true') {
      Sentry.captureException(_error);
    }

    if (errorFn) {
      errorFn();
    }

    return { [FORM_ERROR]: _error };
  }
}

export default finalFormTryCatch;
