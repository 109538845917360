import cs from 'classnames';
import { formatISO, roundToNearestMinutes } from 'date-fns';
import jstz from 'jstz';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Field } from 'react-final-form';
import Select from 'react-select';
import { listTimeZones } from 'timezone-support';

import { localizedFormat } from '../../date.utils';
import useAutoResize from '../../hooks/useAutoResize';
import useGetUniqId from '../../hooks/useGetUniqId';
import {
  CameraIcon,
  CKEditor,
  Col,
  EyeIcon,
  EyeSlashIcon,
  Feedback,
  FormGroup,
  FormLabelGroup,
  Input as BInput,
  Loader,
  RegularCheckCircleIcon,
  Row,
  Span,
  Switch as BSwitch,
  TimesIcon,
} from '../../ui-kit';
import { createBase64 } from '../../utils';
import { required } from '../fieldValidators';
import classes from './fieldComponent.module.css';

const {
  dateTimeInput,
  dateTimeInputOpen,
  fileInputImg,
  fileInputText,
  switchControl,
  switchLabel,
} = classes;

/**
 * Used to fix : https://github.com/final-form/react-final-form/issues/387
 * @param {Object} finalFormInput - the input given by final form
 * @param {*} defaultValueIfNoInitial - the true default value if no intiale is given
 * Call onChange with this true default value - can submit form if autosubmit is used
 */
const noDefaultValue = Symbol('noDefaultValue');
const useDefaultValue = (
  { input, meta },
  defaultValueIfNoInitial = noDefaultValue,
) => {
  useEffect(() => {
    if (
      meta.touched === false &&
      defaultValueIfNoInitial !== noDefaultValue &&
      (input.value === null || input.value === undefined || input.value === '')
    ) {
      input.onChange(defaultValueIfNoInitial);
    }
  }, [defaultValueIfNoInitial, input, meta.touched]);
};

export function Input({
  className,
  input,
  meta: { error, invalid, touched },
  ...rest
}) {
  const isInvalid = error && invalid && touched;
  return (
    <>
      <BInput
        className={cs(className, { 'is-invalid': isInvalid })}
        {...input}
        {...rest}
      />
    </>
  );
}

export const GroupInputLabel = ({
  input,
  meta,
  label,
  more: More,
  icon,
  eyeIcon,
  block,
  ...rest
}) => {
  const [type, setType] = useState(input.type);
  const { error, invalid, pristine } = meta;

  const uniqId = useGetUniqId(input.name);

  return (
    <FormLabelGroup className={cs({ 'w-100': block })}>
      <Input
        id={uniqId}
        placeholder={label}
        input={{ ...input, type }}
        meta={meta}
        {...rest}
      />
      {icon && !pristine && !error && !invalid && (
        <div
          style={{
            position: 'absolute',
            top: '14px',
            right: '10px',
          }}
        >
          <RegularCheckCircleIcon size="sm" />
        </div>
      )}
      {eyeIcon && input.value && input.type === 'password' && (
        <div
          style={{
            position: 'absolute',
            top: '15px',
            right: '-25px',
          }}
          onMouseDown={() => setType('text')}
          onMouseUp={() => setType(input.type)}
        >
          {type === 'text' ? <EyeSlashIcon /> : <EyeIcon />}
        </div>
      )}
      <label htmlFor={uniqId}>{label}</label>
      {More && <More />}
    </FormLabelGroup>
  );
};

export function RadioGroup({
  input,
  meta: { error, invalid, touched, submitting },
  options,
  labelRender: LabelRender, // a function that will receive destructured option as props
  className,
  cancelable,
  autoSubmit,
  isDisable,
  disabled,
  ...rest
}) {
  const isInvalid = error && invalid && touched;
  const handleCancelClick = useCallback(
    (e) => {
      if (cancelable && e.target.value === input.value) {
        input.onChange(null);
      }
    },
    [cancelable, input],
  );

  return (
    <>
      {options.map((option) => {
        return (
          <LabelRender
            key={option.id || option.value}
            {...option}
            disabled={disabled}
            value={option.value}
          >
            <input
              className={`${cs(className, {
                'is-invalid': isInvalid,
                'is-disabled': disabled,
              })} label-renderer`}
              type="radio"
              onChange={(e) => input.onChange(option.value)}
              onClick={handleCancelClick}
              value={option.value}
              checked={option.value === input.value}
              id={
                option.id ||
                option.value?.toString() /* enable input when clicking on the label */
              }
              name={input.name}
              disabled={disabled || (autoSubmit ? submitting : false)}
              {...rest}
            />
          </LabelRender>
        );
      })}
      {isInvalid && <Feedback.Invalid>{error}</Feedback.Invalid>}
    </>
  );
}

RadioGroup.defaultProps = {
  className: 'form-check-input',
  labelRender: ({ children, label, value }) => (
    <div className="form-check">
      {children}
      <label className="form-check-label" htmlFor={value ? 1 : 0}>
        {label}
      </label>
    </div>
  ),
};

export function Checkbox({ input: { value, ...input }, ...rest }) {
  return <input type="checkbox" checked={value} {...input} {...rest} />;
}

export function CheckboxInversedLabel({
  input: { value, ...input },
  meta,
  label,
  ...rest
}) {
  return (
    <div className="form-check">
      <span className="inverse">{label}</span>
      <label className="form-check-label">
        <input
          className="form-check-input"
          type="checkbox"
          checked={value}
          {...input}
          {...rest}
        />
      </label>
    </div>
  );
}

export function Switch({
  input,
  meta,
  label,
  defaultValueIfNoInitial,
  autoSubmit,
  ...rest
}) {
  const { name, value, ...restInput } = input;
  const { submitting } = meta;
  const uniqId = useGetUniqId(name);
  useDefaultValue({ input, meta }, defaultValueIfNoInitial);
  return (
    <div className={cs(switchControl, 'form-check form-switch mb-3 ')}>
      <input
        id={uniqId}
        type="checkbox"
        className="form-check-input me-2"
        checked={value}
        name={name}
        disabled={autoSubmit ? submitting : false}
        {...restInput}
        {...rest}
      />
      <label className={cs('form-check-label', switchLabel)} htmlFor={uniqId}>
        {label}
      </label>
    </div>
  );
}

export function Switch2({ input, meta, defaultValueIfNoInitial, ...rest }) {
  const uniqId = useGetUniqId(input.name);
  useDefaultValue({ input, meta }, defaultValueIfNoInitial);
  return <BSwitch id={uniqId} {...input} {...rest} />;
}

/**
 * A simple toggle switch using a link element
 * @param {disableActionLabel} (props) label when the field is true (call to action to disable)
 * @param {enableActionLabel} (props) label when the field is fale (call to action to enable)
 * @example
 * <Field
 *   name="visioMetadata.enabled"
 *   enableActionLabel="Lancer la Visioconférence"
 *   disableActionLabel="Arrêter la Visioconférence"
 *   component={VisioSwitcher}
 *   type="a"
 * />
 */
export const ToggleSwitchLink = ({
  disableActionLabel,
  enableActionLabel,
  dontDisplayDisabled,
  input,
}) => {
  const DEFAULT_FONT_SIZE = '16px';
  const DEFAULT_FONT_WEIGHT = '500';
  const uniqId = useGetUniqId(input.name);
  const { onChange, value } = input;

  if (dontDisplayDisabled && !!value) {
    return null;
  }
  return (
    <a
      id={uniqId}
      className={!value ? 'dropdown-item btn-visio' : 'dropdown-item'}
      onClick={() => {
        onChange(!value);
      }}
    >
      {!!value && (
        <button className="btn w-100">
          <Span
            fontSize={DEFAULT_FONT_SIZE}
            fontWeight={DEFAULT_FONT_WEIGHT}
            ml="-26px"
          >
            {disableActionLabel}
          </Span>
        </button>
      )}
      {!value && enableActionLabel}
    </a>
  );
};

export function TextAreaInput({
  input: { value, onChange, name },
  label,
  meta: { submitting },
  autoSubmit,
  initialHeight,
  ...rest
}) {
  const uniqId = useGetUniqId(name);
  const textArea = useRef();
  useAutoResize(textArea, initialHeight);
  const textAreaProps = autoSubmit
    ? {
        onBlur: onChange,
        defaultValue: value,
        disabled: submitting,
      }
    : {
        onChange,
        value,
        disabled: false,
      };
  return (
    <FormLabelGroup>
      <textarea
        ref={textArea}
        id={uniqId}
        name={name}
        className="form-control"
        placeholder={label}
        {...textAreaProps}
        {...rest}
      />
      {label ? (
        <label className="form-check-label" htmlFor={uniqId}>
          {' '}
          {label}{' '}
        </label>
      ) : null}
      {autoSubmit && submitting ? (
        <Loader
          size="xxs"
          className=""
          style={{ position: 'absolute', bottom: '10px', right: '10px' }}
        />
      ) : null}
    </FormLabelGroup>
  );
}

export const FileInput = ({
  names,
  label,
  title,
  defaultValue,
  batch = (fn) => fn(), // avoid useless render with react final form
  rounded,
  ...props
}) => {
  const [file, base64, filename] = names;
  const {
    [file]: fileField,
    [base64]: base64Field,
    [filename]: filenameField,
  } = props;
  return (
    <>
      <Dropzone
        noDrag
        multiple={false}
        onDrop={([file]) =>
          createBase64(file).then((base64url) => {
            batch(() => {
              base64Field.input.onChange(base64url);
              filenameField.input.onChange(file.name);
            });
          })
        }
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {label && <label className="w-100">{label}</label>}
            <div className="mb-3 d-flex justify-content-center position-relative">
              <div className={fileInputText}>
                <span className="text-light font-weight-bold add-avatar">
                  <CameraIcon /> <br />
                  {title}
                </span>
              </div>
              <img
                className={fileInputImg}
                src={
                  base64Field.input?.value ||
                  fileField.input?.value?.url ||
                  defaultValue
                }
                alt="fichier"
              />
            </div>
          </div>
        )}
      </Dropzone>
    </>
  );
};

export function Range({ input, label, ...rest }) {
  return (
    <FormGroup>
      <label>
        {label} : {input.value}
      </label>
      <input type="range" className="range" {...input} {...rest} />
    </FormGroup>
  );
}

export function TimeZone({
  name = 'timezone',
  label = 'Fuseau horaire',
  ...rest
}) {
  const formatedTimeZones = listTimeZones().map((tmpTimeZone) => ({
    label: tmpTimeZone,
    value: tmpTimeZone,
  }));
  const currentTimeZone = jstz.determine();

  return (
    <Field
      name={name}
      label={label}
      component={DropDownSelect}
      options={formatedTimeZones}
      defaultValue={currentTimeZone.name()}
      {...rest}
    />
  );
}

export function DateTimeInput({
  dateLabel,
  timeLabel,
  input,
  meta,
  noDefault,
  className,
  ...rest
}) {
  const uniqId = useGetUniqId(input.name);
  const { onChange, value, name } = input;
  const isInvalid = meta.error && meta.invalid && meta.dirty;
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minutes = 0; minutes < 60; minutes += 15) {
      const d = new Date();
      d.setHours(hour, minutes);
      options.push(localizedFormat(d, 'HH:mm'));
    }
  }
  const [currentDate, setCurrentDate] = useState(() =>
    value
      ? formatISO(new Date(value), { representation: 'date' })
      : !noDefault
      ? formatISO(new Date(), { representation: 'date' })
      : '',
  );
  const [currentTime, setCurrentTime] = useState(() =>
    value
      ? localizedFormat(
          roundToNearestMinutes(new Date(value), { nearestTo: 15 }),
          'HH:mm',
        )
      : localizedFormat(
          roundToNearestMinutes(new Date(), { nearestTo: 15 }),
          'HH:mm',
        ),
  );
  useEffect(() => {
    let date = '';
    if (currentDate) {
      date =
        new Date(currentDate).toISOString().split('T')[0] + 'T' + currentTime;
    }
    onChange(date ? new Date(date).toISOString() : '');
    // eslint-disable-next-line
  }, [currentDate, currentTime]);

  const [size, setSize] = useState(0);
  return (
    <>
      <Row className={currentDate ? 'row-cols-2' : ''}>
        <Col>
          <FormLabelGroup>
            <BInput
              name={name}
              value={currentDate}
              type="date"
              onChange={(e) => setCurrentDate(e.target.value)}
              className={cs(className, { 'is-invalid': isInvalid })}
              style={{ paddingRight: 0 }}
              {...rest}
            />
            <label className="text-truncate" htmlFor={input.name}>
              {dateLabel}
            </label>
          </FormLabelGroup>
        </Col>
        {currentDate && (
          <Col>
            <FormLabelGroup
              className={size ? dateTimeInputOpen : dateTimeInput}
            >
              <select
                name={`${input.name}:time`}
                className={
                  size ? 'border-0 w-100 d-block p-0 mb-3' : 'form-control'
                }
                id={`${uniqId}:time`}
                placeholder={timeLabel}
                onChange={(e) => {
                  setSize(0);
                  setCurrentTime(e.target.value);
                  document.activeElement.blur();
                }}
                value={currentTime}
                onMouseDown={() => setSize(5)}
                onBlur={() => setSize(0)}
                size={size}
                validate={required}
              >
                {options.map((option, i) => (
                  <option value={option} key={i}>
                    {option}
                  </option>
                ))}
              </select>
              <label
                className="text-truncate font-xs p-1 ms-2 text-black-50"
                htmlFor={`${uniqId}:time`}
              >
                {timeLabel}
              </label>
            </FormLabelGroup>

            {/* Better, but compatibility browser to fix */}
            {/* <FormLabelGroup>
              <BInput
                name={`${input.name}:time`}
                type="time"
                value={currentTime}
                pattern="[0-9]{2}:[0-9]{2}"
                required
              />
              <label className="text-truncate" htmlFor={`${uniqId}:time`}>
                {timeLabel}
              </label>
            </FormLabelGroup> */}
          </Col>
        )}
      </Row>
      {isInvalid && <p className="text-danger font-10">{meta.error}</p>}
      {isInvalid && <Feedback.Invalid>{meta.error}</Feedback.Invalid>}
    </>
  );
}

export function DateInput({
  dateLabel,
  input,
  meta,
  noDefault,
  className,
  ...rest
}) {
  const { onChange, value, name } = input;
  const isInvalid = meta.error && meta.invalid && meta.dirty;
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minutes = 0; minutes < 60; minutes += 15) {
      const d = new Date();
      d.setHours(hour, minutes);
      options.push(localizedFormat(d, 'HH:mm'));
    }
  }
  const [currentDate, setCurrentDate] = useState(() =>
    value
      ? formatISO(new Date(value), { representation: 'date' })
      : !noDefault
      ? formatISO(new Date(), { representation: 'date' })
      : '',
  );

  useEffect(() => {
    let date = '';
    if (currentDate) {
      date =
        new Date(currentDate).toISOString().split('T')[0] +
        'T' +
        localizedFormat(
          roundToNearestMinutes(new Date(), { nearestTo: 15 }),
          'HH:mm',
        );
    }
    onChange(date ? new Date(date).toISOString() : '');
    // eslint-disable-next-line
  }, [currentDate]);

  return (
    <>
      <Row>
        <Col>
          <FormLabelGroup>
            <BInput
              name={name}
              value={currentDate}
              type="date"
              onChange={(e) => setCurrentDate(e.target.value)}
              className={cs(className, { 'is-invalid': isInvalid })}
              style={{ paddingRight: 0 }}
              {...rest}
            />
            <label className="text-truncate" htmlFor={input.name}>
              {dateLabel}
            </label>
          </FormLabelGroup>
        </Col>
      </Row>
      {isInvalid && <p className="text-danger font-10">{meta.error}</p>}
      {isInvalid && <Feedback.Invalid>{meta.error}</Feedback.Invalid>}
    </>
  );
}

export const formatGroupLabel = (data) => (
  <div>
    <span className="fw-bold fst-italic">{data.label}</span>
  </div>
);

// NOTE: New version of the DropDown.
// We have to test it, use it, and see if we can spread it ito the app
// Until the moment, keep both
export function DropDownGroupedSelect2({
  input,
  meta,
  options,
  label,
  placeholder,
  defaultGroupIfNoInitial = 0,
  defaultValueIfNoInitial = 0,
  disabled,
  className = '',
  multi,
  currentValue, // controled field
}) {
  const uniqId = useGetUniqId(input.name);

  const findValue = (groups, value) => {
    const found = groups
      .map((group) => {
        return group.options.find((option) => option.value === value);
      })
      .filter((group) => group !== undefined);

    if (found.length === 0) {
      return options[defaultGroupIfNoInitial].options[defaultValueIfNoInitial];
    }
    return found[0];
  };

  const [selectedOption, setSelectedOption] = useState(() => {
    return findValue(options, input.value);
  });
  useEffect(() => {
    input.onChange(selectedOption ? selectedOption.value : null);
  }, [input, selectedOption]);
  useEffect(() => {
    if (typeof currentValue !== 'undefined') {
      const option = findValue(options, currentValue);
      setSelectedOption(option);
    }
    // eslint-disable-next-line
  }, [currentValue, options]);

  return (
    <div className="form-select-group">
      <Select
        inputId={uniqId}
        classNamePrefix="react-select"
        className={`${className} grouped react-select-container`}
        isDisabled={disabled}
        isOptionDisabled={(option) => !option.enabled}
        options={options}
        isSearchable
        isClearable
        isMulti={multi}
        placeholder={placeholder || 'Séléctionner'}
        menuPosition="fixed"
        onChange={setSelectedOption}
        value={selectedOption}
        name={input.name}
        formatGroupLabel={formatGroupLabel}
      />
      <label htmlFor={uniqId}>{label}</label>
    </div>
  );
}

// NOTE: New version of the DropDown.
// We have to test it, use it, and see if we can spread it ito the app
// Until the moment, keep both
export function DropDownSelect2({
  input,
  options,
  label,
  placeholder,
  defaultValueIfNoInitial,
  disabled,
  className = '',
  multi,
  onClear,
  currentValue, // controled field
  isClearable = true,
  ...rest
}) {
  const uniqId = useGetUniqId(input.name);

  const [selectedOption, setSelectedOption] = useState(() =>
    options.find((option) => {
      return input.value
        ? option.value === parseInt(input.value) || option.value === input.value
        : option.value === defaultValueIfNoInitial;
    }),
  );

  useEffect(() => {
    input.onChange(selectedOption ? selectedOption.value : null);
  }, [input, selectedOption]);
  useEffect(() => {
    if (typeof currentValue !== 'undefined') {
      setSelectedOption(
        options.find((option) => option.value === currentValue),
      );
    }
  }, [currentValue, options]);

  const onChange = (selectedOption, triggeredAction) => {
    if (triggeredAction.action === 'clear') {
      onClear && onClear();
      setSelectedOption(null);

      return;
    }
    setSelectedOption(selectedOption);
  };

  return (
    <div className="form-select-group">
      <Select
        inputId={uniqId}
        classNamePrefix="react-select"
        className={`${className} react-select-container`}
        isDisabled={disabled}
        options={options}
        isSearchable
        isClearable={isClearable}
        isMulti={multi}
        placeholder={placeholder || 'Sélectionner'}
        menuPosition="fixed"
        onChange={onChange}
        value={selectedOption}
        name={input.name}
        {...rest}
      />
      <label htmlFor={uniqId}>{label}</label>
    </div>
  );
}

export function DropDownSelect({
  input,
  meta,
  options,
  label,
  placeholder,
  defaultValueIfNoInitial,
  cancelable,
  disabled,
  ...rest
}) {
  const uniqId = useGetUniqId(input.name);
  useDefaultValue({ input, meta }, defaultValueIfNoInitial);
  return (
    <div className="form-select-group">
      <label htmlFor={uniqId}>
        {label}{' '}
        {cancelable && !disabled && (
          <span
            onClick={() => input.onChange(null)}
            title="Annuler"
            data-testid="cancel-power"
          >
            <TimesIcon />
          </span>
        )}
      </label>
      <select
        id={uniqId}
        className="form-select"
        disabled={disabled}
        {...input}
        {...rest}
      >
        {placeholder && (
          <option value="" disabled selected>
            {placeholder}
          </option>
        )}
        {options.map((option, i) => (
          <option value={option.value} key={i}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

/**
 *
 * @param {*} resetLabel - the label to be display as 'Recharger le contenu'
 * @param {*} resetContent - the  content to be used to 'reset' the editor
 */
export function RichTextEditor({
  input,
  resetLabel,
  resetContent,
  initialValue,
  autoSubmit,
  autoSubmitDelay,
  onSubmit,
  css,
  ...rest
}) {
  const debouncedSubmit = autoSubmit
    ? debounce((data) => onSubmit({ html: data }), autoSubmitDelay)
    : undefined;

  const onChange = ({ editor }) => {
    input.onChange(editor.getData());
    if (autoSubmit) {
      debouncedSubmit(editor.getData());
    }
  };

  return (
    <>
      {resetLabel && (
        <div
          id="reloadData"
          onClick={() => {
            input.onChange(resetContent);
          }}
        >
          <small className="text-muted btn btn-link font-italic ">
            {resetLabel}
          </small>
        </div>
      )}

      <CKEditor
        initData={input.value}
        onChange={onChange}
        initialValue={initialValue}
        customStyles={css}
      />
    </>
  );
}
