import { fk, Model } from 'redux-orm';

import { getId } from '../utils';
import Assembly from './assembly.model';

class AssemblyResolutionVote extends Model {
  static getIdForTheApi(assemblyResolutionVote) {
    return `resolution=${assemblyResolutionVote.assemblyResolutionId};member=${assemblyResolutionVote.memberId}`;
  }
  constructor(data) {
    if (!data.assemblyId) {
      console.info(
        '🚀 ~ file: assemblyResolutionVote.model.js ~ line 19 ~ AssemblyResolutionVote ~ constructor ~ data',
        data,
        'assemblyId field is MANDATORY !',
      );
    }
    if (!data.assemblyResolutionId) {
      console.info(
        '🚀 ~ file: assemblyResolutionVote.model.js ~ line 19 ~ AssemblyResolutionVote ~ constructor ~ data',
        data,
        'assemblyResolutionId field is MANDATORY !',
      );
    }
    super({
      ...data,
      assemblyId: data.assemblyId,
      assemblyResolutionId: data.assemblyResolutionId,
      memberId: data.member ? getId(data.member) : data.memberId ?? null,
      userId: data.user ? getId(data.user) : data.userId ?? null,
      keepForSecondVote: data.vote ? data.keepForSecondVote : true,
    });
  }
}

AssemblyResolutionVote.modelName = 'AssemblyResolutionVote';
AssemblyResolutionVote.fields = {
  assemblyId: fk({
    to: Assembly.modelName,
    as: 'assembly',
    relatedName: '_assemblyResolutionVotesSet',
  }),
};
export default AssemblyResolutionVote;
