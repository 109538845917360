import styled from 'styled-components';

export const CustomModalWrapper = styled.div`
  overflow: auto;
  & > div.modal-header {
    & > h5.modal-title {
      font-weight: 700;
      font-size: 18px;
    }
    border-bottom: none;
  }
  & > div.modal-body {
  }
  & > div.modal-footer {
    border-top: none;
  }
`;

export const CustomModalWrapperWithAddBtn = styled(CustomModalWrapper)`
  overflow: auto;
  & > div.modal-header {
    & > h5.modal-title {
      font-weight: 700;
    }
    border-bottom: none;
  }
  & > div.modal-body {
  }
  & > div.modal-footer {
    border-top: none;
    padding: 12px 25px;
  }
`;

export const Title = styled.h5`
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 28px;
`;
