import React from 'react';
import styled from 'styled-components';

import ArrowDownIcon from '../../arrowDown.icon';
import ArrowUpIcon from '../../arrowUp.icon';

type SortIconProps = {
  isSorted: boolean;
  isSortedDesc: boolean;
};

const Wrapper = styled.div``;
const SortIcon: React.FC<SortIconProps> = ({ isSorted, isSortedDesc }) => {
  if (!isSorted) {
    return (
      <Wrapper>
        <ArrowDownIcon />
      </Wrapper>
    );
  }

  return (
    <Wrapper>{isSortedDesc ? <ArrowDownIcon /> : <ArrowUpIcon />}</Wrapper>
  );
};

export default SortIcon;
