import {
  assemblyResolutionTemplatesApi,
  assemblyResolutionTemplatesFilesApi,
  PER_PAGE,
} from '../../api/base.api';
import { createBase64 } from '../../utils';

export async function getAssemblyResolutionTemplates(
  companyId,
  { page = 1, where = {}, itemsPerPage = PER_PAGE.DEFAULT },
) {
  return await assemblyResolutionTemplatesApi.list(companyId, {
    page,
    where,
    itemsPerPage,
  });
}

export async function updateAssemblyResolutionTemplate(
  assemblyResolutionTemplate,
  files = { filesToCreate: [], filesToDelete: [] },
) {
  const { filesToCreate, filesToDelete } = files;

  for (const file of filesToCreate) {
    const base64Content = await createBase64(file);
    await assemblyResolutionTemplatesFilesApi.create({
      resolutionTemplateId: assemblyResolutionTemplate.id,
      base64Content,
      originalFilename: file.name,
    });
  }
  for (const file of filesToDelete) {
    await assemblyResolutionTemplatesFilesApi.delete(file);
  }
  return await assemblyResolutionTemplatesApi.update(
    assemblyResolutionTemplate,
  );
}

export async function updateAssemblyResolutionTemplateOrder({
  id,
  orderNumber,
}) {
  const updatedAssemblyResolutionTemplates =
    await assemblyResolutionTemplatesApi.updateOrder({
      id,
      orderNumber,
    });
  return updatedAssemblyResolutionTemplates;
}

export async function createAssemblyResolutionTemplate(
  assemblyResolutionTemplate,
) {
  const createdAssemblyResolutionTemplate =
    await assemblyResolutionTemplatesApi.create(assemblyResolutionTemplate);
  const files = assemblyResolutionTemplate.files;
  if (files) {
    for (const file of files) {
      const base64Content = await createBase64(file);
      await assemblyResolutionTemplatesFilesApi.create({
        resolutionTemplateId: createdAssemblyResolutionTemplate.id,
        base64Content,
        originalFilename: file.name,
      });
    }
  }
  return createdAssemblyResolutionTemplate;
}

export async function getAssemblyResolutionTemplateById(id) {
  return await assemblyResolutionTemplatesApi.getById({ id });
}

export async function deleteAssemblyResolutionTemplate(
  assemblyResolutionTemplate,
) {
  return await assemblyResolutionTemplatesApi.delete(
    assemblyResolutionTemplate,
  );
}
