import { Member } from '../../../../../pills/members/member.type';
import { ColumnId, ColumnNames } from './column';

export const normalize = (members: Array<Member>): Array<ColumnNames> => {
  return members.map((member) => {
    return {
      ...member,
      profile: '',
      richavatar: '',
    };
  });
};

export const mapSortKey = (key: ColumnId): string => {
  switch (key) {
    case ColumnId.NAME:
      return 'fullName';
    case ColumnId.PROFILE:
      return 'companyRole';
    case ColumnId.USER_ENABLED:
      return 'activationStatus';
    case ColumnId.EMAIL:
      return 'email';
    default:
      return '';
  }
};
