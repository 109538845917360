import { parcelsApi, parcelTypesApi } from 'api/base.api';
import { QUERY_KEYS } from 'api/queryKeys';
import { useQueries, useQuery } from 'react-query';

import { ParcelDetailType, ParcelType } from './type';

type UseParcelsProps = {
  parcelIds: string[];
};

export const getParcelsById = (parcelId: string): Promise<ParcelType> => {
  return parcelsApi.getById({ id: parcelId });
};

export const getParcelsKey = (parcelId: string) => {
  return [QUERY_KEYS.parcels, parcelId];
};

export function useParcels({ parcelIds }: UseParcelsProps) {
  const queries = parcelIds.map((parcelId) => {
    return {
      queryKey: [QUERY_KEYS.parcels, parcelId],
      queryFn: () => getParcelsById(parcelId),
    };
  });

  const queryResult = useQueries(queries);
  const data =
    queryResult.map((result) => {
      return result.data;
    }) || [];

  const isLoading = queryResult.some((result) => {
    return result.isLoading;
  });

  return { data, isLoading };
}

const getParcelTypes = (): Promise<ParcelDetailType[]> => {
  return parcelTypesApi.list();
};

export const useParcelTypes = () => {
  const { data, isLoading, isFetching } = useQuery(
    [QUERY_KEYS.parcelTypes],
    () => getParcelTypes(),
    { cacheTime: Infinity, staleTime: Infinity },
  );
  return { data, isLoading, isFetching };
};
