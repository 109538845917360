import PropTypes from 'prop-types';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Loader } from '.';
import Sortable from 'sortablejs';
import { PlusSquareIcon, MinusSquareIcon, RTooltip } from '../ui-kit';

const DraggableItem = ({
  commonProps,
  getDraggableId,
  getIsDragDisabled,
  renderItem,
  nested,
  item,
  _items,
  onDrop,
}) => {
  const [openDragZone, setOpenDragZone] = useState(false);

  return (
    <li style={{ position: 'relative' }}>
      {renderItem({ ...commonProps })}
      {!nested && (openDragZone || item?.subResolutions?.length) ? (
        <DraggableList
          items={item?.subResolutions?.map((subRes) => {
            const mainRes = _items.find((res) => res.id === subRes.id);
            return mainRes || subRes;
          })}
          renderItem={renderItem}
          getDraggableId={getDraggableId}
          getIsDragDisabled={getIsDragDisabled}
          filtered={false}
          nested={true}
          onDrop={onDrop}
          parentId={item?.id}
          openDragZone={openDragZone}
        />
      ) : null}
      {!nested && !item?.subResolutions?.length && (
        <div
          style={{
            position: 'absolute',
            width: '1.5rem',
            height: '1.5rem',
            top: '5px',
            right: '-35px',
          }}
        >
          <RTooltip
            title={
              !openDragZone
                ? 'Ouvrir la zone des sous-résolutions'
                : 'Fermer la zone des sous-résolutions'
            }
            place="top"
            delayShow={undefined}
            delayHide={undefined}
            effect={undefined}
            zIndex={undefined}
          >
            <button
              style={{ padding: '5px' }}
              onClick={() => {
                setOpenDragZone(!openDragZone);
              }}
            >
              {!openDragZone ? (
                <PlusSquareIcon color="rgba(0, 0, 0, 0.5)" size="lg" />
              ) : (
                <MinusSquareIcon color="rgba(0, 0, 0, 0.5)" size="lg" />
              )}
            </button>
          </RTooltip>
        </div>
      )}
    </li>
  );
};
const DraggableList = ({
  items: _items,
  renderItem,
  pagination,
  className,
  getIsDragDisabled,
  getDraggableId,
  onDrop,
  filtered = true,
  nested = false,
  openDragZone = false,
  parentId,
}) => {
  const [renderKey, setRenderKey] = useState(0);
  const sortableRef = useRef(null);
  const items = filtered
    ? _items.filter((item) => !item._fields?.initialResolution)
    : _items;

  const handleDragEnd = useCallback(
    (evt) => {
      try {
        const hasChangeLevel =
          evt.from.getAttribute('data-type') !==
          evt.to.getAttribute('data-type');
        const sourceIndex = evt.oldIndex;
        const source = items[sourceIndex];
        const destinationIndex = evt.newIndex;
        const destination = !hasChangeLevel
          ? items[destinationIndex]
          : items?.find((i) => i.id === evt.to.getAttribute('data-parent-id'));
        if (
          !hasChangeLevel &&
          (sourceIndex === destinationIndex || !destination)
        ) {
          return;
        }
        if (hasChangeLevel && !!source.subResolutions?.length) {
          setRenderKey((prevKey) => prevKey + 1);
          return;
        }
        let newItems = items;
        if (
          !hasChangeLevel ||
          (hasChangeLevel && evt.from.getAttribute('data-type') === 'sub')
        ) {
          let [removedItem] = newItems.splice(sourceIndex, 1);
          if (removedItem) newItems.splice(destinationIndex, 0, removedItem);
        } else {
          newItems = destination?._fields?.subResolutions
            ? [...destination?._fields?.subResolutions]
            : [source];
          if (destination?._fields?.subResolutions)
            newItems?.splice(destinationIndex, 0, source?._fields);
        }
        onDrop({
          source,
          destination: { ...destination, index: destinationIndex },
          resolutions: newItems?.map((r, i) => ({
            resolution: { id: r.id },
            position: i + 1,
          })),
          nested,
          hasChangeLevel,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [onDrop, items, nested],
  );

  useEffect(() => {
    const sortable = new Sortable(sortableRef.current, {
      group: 'nested',
      ghostClass: 'ghostClass',
      dragClass: 'dragClass',
      forceFallback: true,
      invertSwap: true,
      swapThreshold: 1,
      animation: 150,
      scrollSpeed: 25,
      onEnd: handleDragEnd,
    });

    return () => {
      if (sortable) {
        sortable.destroy();
      }
    };
  }, [renderKey, items?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        key={`${renderKey}_${items?.length}`}
        style={
          openDragZone
            ? {
                minHeight: '100px',
                position: 'relative',
                padding: '5px 0',
                border: '1px solid rgba(0, 0, 0, 0.175)',
                borderRadius: '0.375rem',
              }
            : undefined
        }
      >
        {openDragZone && (
          <p
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              margin: 0,
              opacity: 0.7,
              fontSize: '13px',
              textAlign: 'center',
            }}
          >
            <PlusSquareIcon color="rgba(0, 0, 0, 0.5)" />
            &nbsp;Déposer une sous-résolution
            <br />
            Attention, la résolution parente deviendra informative
          </p>
        )}
        <ul
          ref={sortableRef}
          className={`list-unstyled ${className}`}
          style={
            nested
              ? { paddingLeft: '48px', minHeight: openDragZone ? '90px' : 0 }
              : { paddingBottom: '30px' }
          }
          data-type={nested ? 'sub' : 'main'}
          data-parent-id={parentId}
        >
          {items.map((item, index) => {
            const commonProps = {
              item,
              index,
              isDragDisabled: getIsDragDisabled({ item, index }),
            };
            return (
              <Fragment
                key={`draggableg_item_${getDraggableId(commonProps)}_${index}`}
              >
                <DraggableItem
                  commonProps={commonProps}
                  getDraggableId={getDraggableId}
                  getIsDragDisabled={getIsDragDisabled}
                  renderItem={renderItem}
                  nested={nested}
                  item={item}
                  _items={_items}
                  onDrop={onDrop}
                />
              </Fragment>
            );
          })}
        </ul>
      </div>

      {/* If pagination is necessary */}
      {pagination &&
        pagination.currentPage * pagination.itemsPerPage <
          pagination.totalItems && (
          <nav
            ref={ref}
            className="d-flex align-items-center justify-content-center"
            onClick={pagination.next}
          >
            {pagination.isLoading ? (
              <Loader size="xs" />
            ) : (
              <span className="btn btn-xs btn-link mb-4">Charger plus</span>
            )}
          </nav>
        )}
    </>
  );
};

DraggableList.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.array.isRequired,
  nested: PropTypes.bool,
  filtered: PropTypes.bool,
  openDragZone: PropTypes.bool,
  parentId: PropTypes.string,
  /**
   * As for the dataTable, pagination object to trigger pagination
   */
  pagination: PropTypes.shape({
    isLoading: PropTypes.bool,
    next: PropTypes.func.isRequired,
  }),
  /**
   * Render a given item ({ item, index, isDragDisabled }) => <> ... </>
   */
  renderItem: PropTypes.elementType.isRequired,
  /**
   * Should return true if the drag is disabled for the given item ({ item, index, isDragDisabled }) => true|false
   */
  getIsDragDisabled: PropTypes.func.isRequired,
  /**
   * Should return a uniq id for the given item ({ item, index, isDragDisabled }) => any
   */
  getDraggableId: PropTypes.func.isRequired,
  /**
   * Will be fired with ({ source: {index, item}, destination: {item, index} })
   * If this function throws, the list will rollback to the previous state
   */
  onDrop: PropTypes.func,
};

DraggableList.defaultProps = {
  onDrop: () => {},
};

export default DraggableList;
