import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { login, logout, reload } from './auth.actions';
import { authUserSelector, isLoggedSelector } from './auth.selectors';

const AuthContainer = ({ children, ...rest }) => {
  return children(rest);
};

function mapStateToProps(store) {
  return {
    isLogged: isLoggedSelector(store),
    user: authUserSelector(store),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: ({ username, password }) => dispatch(login({ username, password })),
    logout: () => dispatch(logout()),
    reload: () => dispatch(reload()),
  };
}

AuthContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
