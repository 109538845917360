import { ASSEMBLY_MINUTES_STATUS } from 'pills/assemblies/assembly.constants';

export const MinutesStep = {
  editMinutes: 0,
  minutesDocxGenerated: 1,
  minutesPDFGenerated: 2,
  minutesSigning: 3,
  minutesSigned: 4,
  checkAddress: 5,
  minutesGenerating: 6,
  minutesGenerated: 7,
  minutesSent: 8,
  minutesUploadDocuments: 9,
};

export type Step = {
  step: number;
};

export type MinutesStepsById = Array<Step>;

export const getCurrentStep = (status: number) => {
  switch (status) {
    case ASSEMBLY_MINUTES_STATUS.NONE:
      return MinutesStep.editMinutes;
    case ASSEMBLY_MINUTES_STATUS.DOCX_GENERATED:
      return MinutesStep.minutesDocxGenerated;
    case ASSEMBLY_MINUTES_STATUS.PDF_GENERATED:
      return MinutesStep.minutesPDFGenerated;
    case ASSEMBLY_MINUTES_STATUS.REQUEST_SENT:
      return MinutesStep.minutesSigning;
    case ASSEMBLY_MINUTES_STATUS.SIGNED:
      return MinutesStep.minutesSigned;
    case ASSEMBLY_MINUTES_STATUS.GENERATING:
      return MinutesStep.minutesGenerating;
    case ASSEMBLY_MINUTES_STATUS.GENERATED:
      return MinutesStep.minutesGenerated;
    case ASSEMBLY_MINUTES_STATUS.WAIT_SENDING:
    case ASSEMBLY_MINUTES_STATUS.SENT:
      return MinutesStep.minutesSent;
    case ASSEMBLY_MINUTES_STATUS.UPLOAD_DOCUMENTS:
      return MinutesStep.minutesUploadDocuments;
    default:
      return MinutesStep.editMinutes;
  }
};
