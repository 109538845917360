import get from 'lodash.get';
import template from 'lodash.template';
import React from 'react';

const texts = {
  pills: {},
  form: {},
  pages: {},
  buttons: {},
  modals: {},
  menu: {},
  notification: {
    plaint: {
      accept: "L'incident '${plaintDescription}' a été accepté",
      accepted_by_owner:
        "L'incident '${plaintDescription}' a été accepté par le propriétaire du lot ${parcelName}",
      accepted_by_provider:
        "L'intervenant '${providerName}' a accepté d'intervenir sur l'incident '${plaintDescription}'",
      accepted_by_syndic:
        "L'incident '${plaintDescription}' a été accepté par le syndic",
      archived: "${userName} a archivé l'incident '${plaintDescription}'",
      archive: 'Un incident a été automatiquement archivé',
      assigned_to_me:
        'Un incident vient de vous être assigné sur la résidence %residencyName%',
      assigned_to_provider:
        "L'incident '${plaintDescription}' a été assigné à l'intervenant '${providerName}'",
      assigned_to_syndic:
        "L'incident '${plaintDescription}' va être pris en charge par le syndic",
      cancel: "${userName} a rejeté l'incident '${plaintDescription}'",
      cancelled: "${userName} a rejeté l'incident '${plaintDescription}'",
      comment: {
        added: 'Le commentaire a bien été ajouté',
      },
      create: '${userName} a déclaré un incident',
      created: '${userName} a déclaré un incident',
      created_on_parcel:
        '${userName} a déclaré un incident sur votre lot ${parcelName}',
      delete: "${userName} a supprimé l'incident '${plaintDescription}'",
      deleted: "${userName} a supprimé l'incident '${plaintDescription}'",
      reject: "L'incident '${plaintDescription}' a été rejeté",
      finished_by_provider:
        "L'intervenant ${providerName} a terminé son intervention",
      job_rejected_by_syndic:
        "L'intervention sur l'incident '${plaintDescription}' a été rejetée par le syndic",
      planned: 'Une intervention a été planifiée',
      planned_by_provider:
        "L'intervenant '${providerName}' a planifié une intervention pour l'incident ${plaintDescription}'",
      planned_by_syndic:
        "Le syndic a planifié une intervention pour l'incident '${plaintDescription}'",
      rejected_by_provider:
        "L'incident '${plaintDescription}' a été rejeté par l'intervenant '${providerName}'",
      rejected_by_syndic:
        "L'incident '${plaintDescription}' a été rejeté par le syndic",
      reminder: "Rappel : '${plaintDescription}'",
      reminder_accepted: {
        planned_by_provider:
          "L'incident '${plaintDescription}' a été automatiquement marqué comme terminé",
      },
      reminder_archived: 'Un incident a été automatiquement archivé',
      reminder_rejected: {
        accepted_by_provider:
          'Un incident vous a été retiré, faute de planification de votre part',
        assigned_to_provider:
          'Un incident vous a été retiré, faute de réponse de votre part',
      },
      reminder_rejected_syndic: {
        accepted_by_provider:
          "Un incident vous est revenu, faute de planification par l'intervenant",
        assigned_to_provider:
          "Un incident vous est revenu, faute d'acceptation par l'intervenant",
      },
      resolve:
        "${userName} a marqué l'incident '${plaintDescription}' comme résolu",
      resolved:
        "${userName} a marqué l'incident '${plaintDescription}' comme résolu",
    },
    googlecalendar: {
      sync: 'Votre calendrier a été synchronisé avec votre compte google calendar.',
      unsync:
        'Votre calendrier Syment a été suprimé de votre compte google calendar, et la connexion à celui-ci a été supprimée.',
    },
    user: {
      register: "${userName} s'est créé un compte Syment",
    },
    member: {
      create: '${userName} vous a ajouté à la structure ${companyName}',
      update: 'todo',
      delete: '${userName} vous a retiré à la structure ${companyName}',
      create_detail:
        "Vous pouvez basculer d'une structure à l'autre en cliquant sur l'engrenage en haut du menu. Cliquez ici pour l'ouvrir",
    },
    provider: {
      create: '${userName} vous a ajouté à la structure ${companyName}',
      update: 'todo',
      delete: '${userName} vous a retiré à la structure ${companyName}',
      create_detail:
        "Vous pouvez basculer d'une structure à l'autre en cliquant sur l'engrenage en haut du menu. Cliquez ici pour l'ouvrir",
    },
    news: {
      create: '${userName} a créé une actualité',
      update: '${userName} a modifié une actualité',
      delete: '${userName} a supprimé une actualité',
    },
    assembly: {
      create: '${userName} a créé une nouvelle assemblée',
      update: '${userName} a modifié une nouvelle assemblée',
      delete: '${userName} a supprimé une nouvelle assemblée',
      end: "L'assemblée de la résidence ${residencyName} vient de se terminer",
      start:
        "L'assemblée de la résidence ${residencyName} vient de commencer !",
      suggestion: {
        create:
          "${userName} a ajouté une suggestion à l'assemblée #${assemblyInternalId}",
        reject:
          "${userName} a rejeté une suggestion à l'assemblée #${assemblyInternalId}",
        accept:
          "${userName} a accepté une suggestion à l'assemblée #${assemblyInternalId}",
      },
    },
    residency_poll: {
      new: "De nouveaux sondages sont disponibles aujourd'hui",
      create: "De nouveaux sondages sont disponibles aujourd'hui",
      ending: "Des sondages se terminent aujourd'hui",
      ended: 'Des sondages se sont terminés hier',
    },
    poll: {
      create: '${userName} a créé un nouveau sondage',
      update: '${userName} a modifié un sondage',
      delete: '${userName} a supprimé un sondage',
    },
    event: {
      create: '${userName} a créé un nouvel événement',
      update: '${userName} a modifié un événement',
      delete: '${userName} a supprimé un événement',
    },
    document: {
      create: '${userName} a déposé un nouveau document',
      update: '${userName} a modifié un document',
      delete: '${userName} a supprimé un document',
    },
    new: 'TODO',
    waiting: 'TODO',
    working: 'TODO',
    resolved: 'TODO',
  },
  api: {
    invalid_credentials: 'Identifiants incorrects',
    email_already_in_use: "L'email existe deja",
    api_security_missing_email: "L'email n'existe pas",
    api_security_missing_callbackurl: "L'url de call back n'est pas specifié",
    api_security_confirmation_token_not_found:
      "Le compte à activer n'existe pas",
    expired_jwt_token: 'La session a expirée. Reconnectez vous.',
    defaultError:
      'Une erreur inattendue est survenue. Veuillez recharger la page et réessayer.',
  },
  lambdaPDFServiceApi: {
    defaultError:
      'Une erreur inattendue est survenue. Veuillez recharger la page et réessayer.',
  },
  utils: {
    from_now: {
      wait: 'en attente',
      today: "aujourd'hui",
      yesterday: 'hier',
      twoDays: 'il y a 2 jours',
      thisWeek: 'cette semaine',
      lastWeek: 'la semaine dernière',
      lastLastWeek: 'il y a 2 semaines',
      lastLastLastWeek: 'il y a 3 semaines',
      month: 'il y a un mois',
      more: "il y a plus d'un mois",
    },
  },
};

const TError = ({ text }) => (
  <div style={{ color: 'red', border: '1px solid gray', background: 'black' }}>
    i18n error: {text} doesn't exist
  </div>
);

const t =
  (texts) =>
  (key, context = {}) => {
    let value = get(texts, key);
    if (typeof value === 'string') {
      return template(value)(context);
    }
    return <TError text={key} />;
  };

export default t(texts);
