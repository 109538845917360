import React, { useRef, useState } from 'react';

import { LS_KEY } from '../redux-utils/localState';
import {
  Accordions,
  BetaBadge,
  Col,
  Container,
  DelayedButton,
  Row,
} from '../ui-kit';
import MainLayout from './layouts/main.layout';

const ErrorPage = ({ error, back, retry }) => {
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();
  const [isSent] = useState(false);
  const textArea = useRef();
  return (
    <MainLayout withMenu={false}>
      <Container>
        <h3>Une erreur interne est survenue.</h3>
        <p>Veuillez choisir une option.</p>
        <Accordions
          onChange={setCurrentSelectedIndex}
          openedItemIndex={currentSelectedIndex}
          secondary
          items={[
            {
              title: 'Option 1: Réparation automatique',
              body: (
                <Row className="m-4">
                  <Col className="col-12 col-md-4">
                    <DelayedButton
                      label="Lancer la réparation"
                      className="btn btn-primary w-100"
                      onClick={retry}
                    />
                    <small className="text-muted">
                      Les données de l'application vont être mises à jour.
                    </small>
                  </Col>
                  <Col>
                    <p className="text-sm text-muted">
                      Si l'erreur persiste, veuillez contacter le support en
                      utilisant le formulaire disponible{' '}
                      <span
                        onClick={() => setCurrentSelectedIndex(2)}
                        className="cursor-pointer link-info"
                      >
                        ici
                      </span>
                    </p>
                  </Col>
                </Row>
              ),
            },
            {
              title: 'Option 2: Retour arrière',
              body: (
                <p className="m-4 d-block">
                  Revenir à la page précedente en cliquant{' '}
                  <span
                    className="cursor-pointer link-info"
                    onClick={() => {
                      back();
                    }}
                  >
                    ici
                  </span>
                </p>
              ),
            },
            {
              title: 'Option 3: Effacer le cache',
              body: (
                <p className="m-4 d-block">
                  Effacer le cache en cliquant{' '}
                  <span
                    className="cursor-pointer link-info"
                    onClick={() => {
                      localStorage.clear();
                      window.location.reload();
                    }}
                  >
                    ici
                  </span>
                </p>
              ),
            },
            {
              title: 'Option 4: Contacter le support',
              body: (
                <>
                  <p className="p-4 m-4 text-center border d-block border-1 border-primary">
                    <strong>
                      Merci pour votre sollicitude et votre patience ❤️
                    </strong>
                    <br />
                  </p>
                  <p className="m-4 d-block">
                    Rassurez vous, pas de formulaire ennuyant ici. <br />
                    Nous sommes déjà au courant de la situation et travaillons à
                    résoudre le problème.
                    <br />
                    Vous n'avez rien d'autre à faire.
                    <br />
                    <br />
                    Cordialement,
                    <br />
                    <br />
                    L'équipe Syment.
                  </p>
                  <BetaBadge soon>
                    {isSent ? (
                      <div className="w-100 ms-4 me-4 text-success">
                        Merci !
                      </div>
                    ) : (
                      <div className="w-100 ms-4 me-4">
                        <hr />
                        <label className="w-100">
                          <small className="text-muted">
                            Toutefois, si vous souhaitez nous laisser un mot sur
                            votre expérience, on se fera un plaisir de vous lire
                            : <br />
                          </small>
                          <textarea
                            disabled
                            className="form-control w-100 disabled"
                            placeholder="Votre message ici"
                            maxLength={1000}
                            ref={textArea}
                          ></textarea>
                        </label>
                        <br />
                        <br />
                        <button
                          type="button"
                          className="btn btn-sm btn-primary float-end disabled"
                          disabled
                        >
                          Envoyer
                        </button>
                      </div>
                    )}{' '}
                  </BetaBadge>
                </>
              ),
            },
          ]}
        />
      </Container>
    </MainLayout>
  );
};

export default ErrorPage;
