import { createSelector } from 'reselect';

export const filtersSelector = (state) => state.session.filters;
export const activeVisiosSelector = (state) => state.session.activeVisios;

export const filterListingSelector = (state) => state.session.listing;

export const activeVisioByAssemblyId = (assemblyId) =>
  createSelector(
    (state) => state.session.activeVisios,
    (activeVisios) => {
      const activeVisio = activeVisios.filter(
        (visio) => visio.assemblyId === assemblyId,
      );
      return activeVisio ? activeVisio[0] : undefined;
    },
  );

export const convocationStepsByAssemblyId = (assemblyId) =>
  createSelector(
    (state) => state.session.convocationSteps,
    (convocationSteps) => {
      if (!convocationSteps[assemblyId]) {
        return null;
      }

      return convocationSteps[assemblyId];
    },
  );

export const minutesStepsByAssemblyId = (assemblyId) =>
  createSelector(
    (state) => state.session.minutesSteps,
    (minutesSteps) => {
      if (!minutesSteps[assemblyId]) {
        return null;
      }

      return minutesSteps[assemblyId];
    },
  );

export const activeVisio = createSelector(
  (state) => state.session.activeVisios,
  (activeVisios) => {
    if (activeVisios.length > 0) {
      return activeVisios[0];
    }
    return null;
  },
);
