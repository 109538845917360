import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { authTokenSelector } from '../../pills/auth/auth.selectors';
import assembly from './assembly.svg';
import assemblyTemplate from './assembly-template.svg';
import dashboard from './dashboard.svg';
import external from './external.png';
import { buildLinkStr } from './helper';
import { A, ImageWrapper, MenuLabel, MenuLinkWrapper } from './menu-link.style';
import residency from './residency.svg';
import tags from './tags.svg';
import users from './users.svg';

type MenuLinkProps = {
  className?: string;
  name: MenuName;
  label: string;
  customPath?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const MENU_DEF = {
  dashboard: { icon: dashboard, path: '', type: 'v4', target: null },
  residency: { icon: residency, path: 'residencies', type: 'v4', target: null },
  users: { icon: users, path: 'members', type: 'v4', target: null },
  dometech: { icon: external, path: null, type: 'v2', target: null },
  assembly: { icon: assembly, path: 'assemblies', type: 'v3', target: null },
  assemblyTemplate: {
    icon: assemblyTemplate,
    path: 'assemblies/templates/assembly',
    type: 'v4',
    target: null,
  },
  tags: { icon: tags, path: 'tags', type: 'v4', target: null },
  external: { icon: external, path: null, type: 'v2', target: null },
  profile: { icon: null, path: 'account', type: 'v4', target: null },
  logout: { icon: null, path: 'logout', type: 'v2', target: null },
  v4: { icon: ['fas', 'rocket'], path: '', type: 'v4', target: '_blank' },
};

export type MenuName = keyof typeof MENU_DEF;

const MenuLink = ({
  name,
  label,
  customPath,
  onClick,
  className,
}: MenuLinkProps) => {
  const token = authTokenSelector(useStore().getState());
  const icon = MENU_DEF[name].icon;
  const target = MENU_DEF[name].target ?? '_self';

  const path = MENU_DEF[name].path;
  const type = MENU_DEF[name].type;
  const location = useLocation();

  const link = buildLinkStr(type, path, customPath, token);

  if (type === 'v2') {
    return (
      <A
        href={link}
        className={`${className || ''} ${
          location.pathname.slice(1).startsWith(path || customPath || '')
            ? 'active'
            : ''
        }`}
        target={path === null ? '_blank' : undefined}
        rel={path === null ? 'noopener' : undefined}
        $hasIcon={!!icon}
        onClick={(e) => onClick && onClick(e)}
      >
        {icon && (
          <ImageWrapper>
            <img src={icon.toString()} alt="" />
          </ImageWrapper>
        )}

        <MenuLabel>{label}</MenuLabel>
      </A>
    );
  }

  let v4Icon = null;

  if (icon) {
    if (typeof icon === 'string' || icon instanceof String) {
      v4Icon = (
        <ImageWrapper>
          <img src={icon.toString()} alt="" />
        </ImageWrapper>
      );
    } else {
      v4Icon = (
        <div className="tw-block tw-text-red-500">
          <FontAwesomeIcon icon={['fas', 'rocket']} />
        </div>
      );
    }
  }

  if (type === 'v4') {
    return (
      <A href={link} $hasIcon={!!icon} target={target}>
        {v4Icon}

        <MenuLabel>{label}</MenuLabel>
      </A>
    );
  }

  return (
    <MenuLinkWrapper
      className={`${className || ''} ${
        location.pathname.slice(1).startsWith(path || customPath || '')
          ? 'active'
          : ''
      }`}
      to={link}
      $hasIcon={!!icon}
      onClick={onClick}
    >
      {icon && (
        <ImageWrapper>
          <img src={icon.toString()} alt="" />
        </ImageWrapper>
      )}

      {label && <MenuLabel>{label}</MenuLabel>}
    </MenuLinkWrapper>
  );
};

export default MenuLink;
