import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideVisio } from '../pills/session/session.actions';
import { activeVisio } from '../pills/session/session.selectors';
import { DropDownMenu, EllipsisHIcon } from '.';

function ThreeDotsDropDownMenu({
  position = 'end',
  bgLight,
  hideVisioOnOpen = false,
  className = '',
  ...rest
}) {
  const currentVisio = useSelector(activeVisio);
  const dispatch = useDispatch();

  const hideVisioOnClick = () => {
    if (!currentVisio || !currentVisio.show) {
      return;
    }
    dispatch(hideVisio({ assemblyId: currentVisio.assemblyId }));
    return;
  };
  const onClick = hideVisioOnOpen ? hideVisioOnClick : undefined;
  return (
    <DropDownMenu
      position={position}
      triggerRender={
        <button
          className={`btn ${
            bgLight ? 'btn-light' : 'hover-bg-light'
          } ${className}`}
          onClick={onClick}
        >
          <EllipsisHIcon />
        </button>
      }
      {...rest}
    />
  );
}

ThreeDotsDropDownMenu.propTypes = {
  position: PropTypes.oneOf(['end', 'start']).isRequired,
};

ThreeDotsDropDownMenu.defaultProps = {
  position: 'end',
};

export default ThreeDotsDropDownMenu;
