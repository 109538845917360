import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import config from '../../api/config';
import { loginWithCookiesThenRedirect } from '../../pills/auth/auth.actions';
import { Loader } from '../../ui-kit';

/**
 * log the user using cookies
 * then redirect to location.referrer url
 * TODO
 *  - test
 *  - i18n
 */
function V2RedirectionPage({ location }) {
  console.log('V2RedirectionPage, location:', location);
  const dispatch = useDispatch();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [cookies, _, removeCookie] = useCookies(['_sabt']);
  const hasV2cookies = cookies !== undefined && Object.keys(cookies).length > 0;
  const tokenUrl = location.state?.tokenParam || undefined;
  const requestedUrl = location.state?.requestedUrl;
  const [redirectionError, setRedirectionError] = useState(false);
  const leaveUrl = location.state?.leaveUrl;
  const finishUrl = location.state?.finishUrl;
  const loginErrorCallback = () => {
    console.log('Login with cookies error callback called');
    removeCookie('_sabt');
    setRedirectionError(true);
  };

  useEffect(() => {
    // no user in store but we got a cookies ?
    if (tokenUrl || hasV2cookies) {
      dispatch(
        loginWithCookiesThenRedirect(
          requestedUrl,
          leaveUrl,
          finishUrl,
          tokenUrl,
          history,
          loginErrorCallback,
        ),
      ); // let's fetch profile & update store
    } else {
      setRedirectionError(true);
    }
    // eslint-disable-next-line
  }, []);

  // TODO this is dirty
  // should we handle with redux ? LOGIN_ERROR ?
  if (redirectionError) {
    window.location = config.v4Url;
    return null;
  }
  // here we are fetching profile (loginWithCookiesThenRedirect)
  return <Loader size="sm" />;
}

export default V2RedirectionPage;
