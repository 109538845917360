import { DATE_FORMATS, localizedFormat } from 'date.utils';
import { AssemblyType } from 'pages/assemblies/components/hooks/type';
import React from 'react';
import { DARK_TEXT } from 'styles/constant';
import { AssemblyIcon, Span } from 'ui-kit';

import {
  AssembliesByResidencyWrapper,
  AssemblyWrapper,
} from './assemblies-by-residency.style';

type AssembliesByResidencyProps = {
  className?: string;
  assemblies: AssemblyType[];
  residencyId: string;
};

const AssembliesByResidency: React.FC<AssembliesByResidencyProps> = ({
  className,
  assemblies,
  residencyId,
}) => {
  const filteredAssemblies = assemblies.filter(
    (assembly) => assembly.residency.id === residencyId,
  );

  if (filteredAssemblies.length === 0) {
    return null;
  }

  const getAssemblyDateTime = (assembly: AssemblyType) => {
    if (!assembly.date) {
      return `Assemblée, date à définir`;
    }

    return `Assemblée le ${localizedFormat(
      assembly.date,
      DATE_FORMATS.dayMonthYearHour,
    )}`;
  };

  return (
    <AssembliesByResidencyWrapper className={`${className || ''}`}>
      {filteredAssemblies.map((assembly) => {
        return (
          <AssemblyWrapper key={assembly.id}>
            <AssemblyIcon />{' '}
            <Span color={DARK_TEXT} fontSize="14px">
              {getAssemblyDateTime(assembly)}
            </Span>
          </AssemblyWrapper>
        );
      })}
    </AssembliesByResidencyWrapper>
  );
};

export default AssembliesByResidency;
