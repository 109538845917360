import {
  ManagedResidency,
  Member,
  MembersAPI,
} from 'pills/members/member.type';

import { InvitableUser, InvitableUserItem } from '../hooks/type';

export const getTitle = (userId: string | undefined) => {
  if (userId) {
    return 'Confirmation';
  }
  return "Envoi des emails d'activation de compte";
};
export const getSendMailLabel = (
  usersData: InvitableUser[] | undefined,
  userId: string | undefined,
): string => {
  if (!usersData || usersData.length === 0) {
    return '';
  }

  if (userId || usersData.length === 1) {
    return 'Envoyer';
  }
  return `Envoyer les ${usersData.length} mails`;
};
export const getActivationMessage = (
  usersData: InvitableUser[] | undefined,
  userId: string | undefined,
): string => {
  if (!usersData || usersData.length === 0) {
    return '';
  }

  if (userId || usersData.length === 1) {
    return `Vous allez envoyer l’email d’activation de compte à 1 usager.`;
  }
  return `Vous allez envoyer l’email d’activation de compte à ${usersData?.length} usagers.`;
};
export const getWarningMessage = (
  membersWithoutEmail: Member[] | undefined,
  userId: string | undefined,
): string => {
  if (userId || !membersWithoutEmail || membersWithoutEmail.length === 0) {
    return ``;
  }

  if (membersWithoutEmail.length === 1) {
    return `À noter : 1 usager sans email ne peut pas bénéficier de Syment
    pour le moment.`;
  }
  return `À noter : ${membersWithoutEmail?.length} usagers sans emails ne peuvent pas bénéficier de Syment
  pour le moment.`;
};

export const getInvitableItems = (
  usersData: InvitableUser[] | undefined,
  userId: string | undefined,
): InvitableUserItem[] => {
  if (!usersData) {
    return [];
  }
  const invitableUsers = usersData.map((user) => {
    return {
      file: user.file,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      passwordRequestedAt: user.passwordRequestedAt,
      companyName: user.companyName || '',
      councilMember: user.councilMember,
      id: user.id,
    };
  });
  if (!userId) {
    return invitableUsers;
  }

  const invitableUser = invitableUsers.find((user) => user.id === userId);

  return invitableUser ? [invitableUser] : [];
};

export const getNonInvitableItems = (
  membersWithoutEmail: Member[] | undefined,
): InvitableUserItem[] => {
  if (!membersWithoutEmail) {
    return [];
  }

  return membersWithoutEmail.map((member) => {
    return {
      file: member.avatar,
      firstName: member.firstName,
      lastName: member.lastName,
      email: 'non renseigné',
      companyName: member.companyName || '',
      councilMember: member.councilMember,
      passwordRequestedAt: '',
    };
  });
};

export const getMembersWithoutEmail = (
  membersData: MembersAPI | undefined,
): Member[] | undefined => {
  return membersData
    ?.filter((member) => member.rights.is_tenant || member.rights.is_owner)
    .filter((member) => !member.email);
};

export const getCurrentResidency = (
  residencyId: string,
  membersData: MembersAPI | undefined,
): ManagedResidency | null => {
  if (membersData && membersData?.length > 0) {
    return membersData[0].residencies.filter(
      (res) => res.id === residencyId,
    )[0];
  }
  return null;
};

export const getToastMessage = (numberOfInvitedUsers: number): string => {
  if (numberOfInvitedUsers === 1) {
    return "L'email d'activation de compte a bien été envoyé.";
  }
  return 'Les emails d’activation de compte ont bien été envoyés.';
};

export const getToastTitle = (numberOfInvitedUsers: number): string => {
  if (numberOfInvitedUsers === 1) {
    return 'Email envoyé !';
  }
  return 'Emails envoyés !';
};
