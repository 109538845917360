const config = {
  apiEndpoint:
    process.env.REACT_APP_API_ENDPOINT || 'https://api-dev.syment.com',
  socketEndpoint:
    process.env.REACT_APP_SOCKET_ENDPOINT || 'https://socket-dev.syment.com',
  // socketEndpoint: 'http://localhost:9000',
  socketPath: process.env.REACT_APP_SOCKET_PATH || '/socket.io',
  v4Url: process.env.REACT_APP_V4_URL || 'https://app-dev.syment.com',
};

export default config;
