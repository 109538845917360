import styled from 'styled-components';

import baseDivFactory from './baseDiv.factory';

export default baseDivFactory('container');

export const BoxContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1400px) {
    max-width: 1320px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => props.size || '16px'};
  height: ${(props) => props.size || '16px'};
  & img {
    width: 100%;
    display: block;
  }

  & svg {
    width: 100%;
    display: block;
  }
`;
