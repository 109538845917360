import styled from 'styled-components';

import { DARK_TEXT } from '../styles/constant';

export const P = styled.p<{ fontSize?: string }>`
  font-size: ${(props) => props.fontSize || '0.9rem'};
`;

interface SpanProps {
  fontSize?: string;
  fontWeight?: string;
  mr?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  pl?: string;
  pr?: string;
  pt?: string;
  pb?: string;
  color?: string;
  height?: string;
}
export const Span = styled.span<SpanProps>`
  font-size: ${(props) => props.fontSize || '14px'};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  margin-right: ${(props) => props.mr || '0px'};
  margin-top: ${(props) => props.mt || '0px'};
  margin-bottom: ${(props) => props.mb || '0px'};
  margin-left: ${(props) => props.ml || '0px'};
  padding-left: ${(props) => props.pl || '0px'};
  padding-right: ${(props) => props.pr || '0px'};
  padding-top: ${(props) => props.pt || '0px'};
  padding-bottom: ${(props) => props.pb || '0px'};

  color: ${(props) => props.color || `${DARK_TEXT}`};
  height: ${(props) => props.height || undefined};
`;

export const Capitalize = styled(Span)`
  text-transform: capitalize;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
`;

export const BreakLine = styled(Span)<{ width?: string }>`
  display: block;
  word-wrap: break-word;
  width: ${(props) => props.width || '100px'};
  white-space: normal;
`;

interface BoxProps {
  mr?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  pl?: string;
  pr?: string;
  pt?: string;
  pb?: string;
  color?: string;
  height?: string;
  width?: string;
  gap?: string;
  display?: string;
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  backgroundColor?: string;
  borderRadius?: string;
  borderColor?: string;
  maxWidth?: string;
}
export const Box = styled.div<BoxProps>`
  gap: ${(props) => props.gap || undefined};
  display: ${(props) => props.display || 'block'};
  align-items: ${(props) => props.alignItems || undefined};
  justify-content: ${(props) => props.justifyContent || undefined};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  margin-right: ${(props) => props.mr || '0px'};
  margin-top: ${(props) => props.mt || '0px'};
  margin-bottom: ${(props) => props.mb || '0px'};
  margin-left: ${(props) => props.ml || '0px'};
  padding-left: ${(props) => props.pl || '0px'};
  padding-right: ${(props) => props.pr || '0px'};
  padding-top: ${(props) => props.pt || '0px'};
  padding-bottom: ${(props) => props.pb || '0px'};
  width: ${(props) => props.width || undefined};
  height: ${(props) => props.height || undefined};
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  border-radius: ${(props) => props.borderRadius || '16px'};
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : undefined};
  max-width: ${(props) => props.maxWidth || undefined};
  color: ${(props) => props.color || `${DARK_TEXT}`};
`;

export const StyledBadge = styled(Span)<{
  backgroundColor?: string;
  width?: string;
  borderRadius?: string;
  gap?: string;
  borderColor?: string;
}>`
  background-color: ${(props) => props.backgroundColor || 'transparent'};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.gap || undefined};
  width: ${(props) => props.width || 'fit-content'};
  height: ${(props) => props.height || '100%'};
  border-radius: ${(props) => props.borderRadius || '16px'};
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : undefined};
`;

export const TruncableSpan = styled(Span)<{
  maxWidth?: string;
  lineHeight?: string;
}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  width: ${(props) => props.maxWidth || '500px'};
  line-height: ${(props) => props.lineHeight || undefined};
`;

export const PageTitle = styled.h1`
  color: ${DARK_TEXT};
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 0;
`;

export const PageSubTitle = styled.h2`
  color: ${DARK_TEXT};
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
`;
