import { useEffect, useRef, useState } from 'react';

function useIntersection(onVisible) {
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);

  const observer = useRef(
    new window.IntersectionObserver(([entry]) => updateEntry(entry)),
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) currentObserver.observe(node);

    return () => currentObserver.disconnect();
  }, [node]);

  useEffect(() => {
    if (entry.intersectionRatio === 1) {
      onVisible();
    }
    // eslint-disable-next-line
  }, [entry.intersectionRatio]);

  return setNode;
}

export default useIntersection;
