import { attr, Model, oneToOne } from 'redux-orm';

import User from './user.model';

class Auth extends Model {
  toString() {
    return `User: ${this.token}`;
  }
}
Auth.modelName = 'Auth';
Auth.fields = {
  token: attr({ getDefault: () => null }),
  error: attr({ getDefault: () => null }),
  referrer: attr({ getDefault: () => null }),
  leaveUrl: attr({ getDefault: () => null }),
  finishUrl: attr({ getDefault: () => null }),
  refreshToken: attr({ getDefault: () => null }),
  userId: oneToOne({
    to: User.modelName,
    as: 'user',
    relatedName: '_user',
  }),
};
export default Auth;
