import cs from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FlexBox } from '.';
import classes from './loader.module.css';

const Loader = ({ size, fullHeight = true, ...props }) => {
  const { loader, loaderSm, loaderXs, loaderXXs } = classes;
  return (
    <FlexBox
      data-testid="loader"
      className={`justify-content-center align-items-center w-100 ${
        fullHeight ? 'h-100' : ''
      }`}
      {...props}
    >
      <div
        className={cs({
          [loaderSm]: size === 'sm',
          [loaderXs]: size === 'xs',
          [loaderXXs]: size === 'xxs',
          [loader]: size === null,
        })}
      />
    </FlexBox>
  );
};

Loader.propTypes = {
  size: PropTypes.oneOf(['sm', 'xs', 'xxs']),
};

Loader.defaultProps = {
  size: null,
};

export default Loader;
