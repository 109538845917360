import styled from 'styled-components';
import { GREY_100, GREY_400, LIGHTER_GREY } from 'styles/constant';

import { OptionResidencyWrapper } from '../filter-bar.style';

export const DropDownContainer = styled('div')`
  border: 1px solid #e2e6e9;
  border-radius: 6px;
  height: 100%;
  display: flex;
  position: relative;
  padding: 4px 10px 5px 5px;
`;

export const DropDownHeader = styled('div')`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1px 10px;
  grid-column-gap: 8px;
  align-items: center;
  & svg {
    cursor: pointer;
  }
`;

export const DropDownListContainer = styled('div')`
  position: absolute;
  transform: translateY(32px);
  background: #fff;
  left: 0;
  right: 0;

  width: 100%;
`;

export const DropDownList = styled('ul')`
  border: 1px solid ${LIGHTER_GREY};
  border-radius: 6px;
  padding-top: 6px;
  padding-bottom: 5px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0px;
  border-radius: 6px;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.166821);

  & li:not(:last-child) {
    margin-bottom: 3px;
  }
`;

export const ListItem = styled('li')`
  list-style: none;
  cursor: pointer;

  & ${OptionResidencyWrapper} {
    padding: 5px;
  }
  & ${OptionResidencyWrapper}:hover {
    padding: 5px;
    background-color: #afd2f2;
    border-radius: 0px;
  }
  &:hover {
    background-color: #afd2f2;
    border-radius: 0px;
  }
`;

export const SelectionOptionWrapper = styled.div`
  cursor: pointer;
`;
export const Separator = styled.div`
  height: 80%;
  background-color: ${GREY_400};
`;

export const SearchItemWrapper = styled.div`
  margin-left: 5px;
  margin-right: 6px;
  margin-bottom: 5px;
  padding-left: 13px;
  padding-top: 8px;
  padding-bottom: 8px;

  border-radius: 6px;
  background-color: ${GREY_100};
  display: grid;
  grid-template-columns: 13px 1fr;
  grid-column-gap: 8px;
  align-items: center;
  & input {
    width: 100%;
    border-radius: 6px;
  }
`;
