import React from 'react';
import { DARK_TEXT } from 'styles/constant';
import { Span, SpinnerIcon } from 'ui-kit';

import {
  A,
  DropdownButtonItemWrapper,
  IconWrapper,
  LabelWrapper,
  SpinnerWrapper,
} from './dropdown-button.style';
import { ItemType } from './index';

type DropdownButtonItemProps = {
  className?: string;
} & ItemType;

const DropdownButtonItem: React.FC<DropdownButtonItemProps> = ({
  className,
  label,
  onClick,
  icon,
  type,
  target,
  loading,
  fallback,
}) => {
  if (loading) {
    return (
      <DropdownButtonItemWrapper className={`${className || ''}`}>
        <SpinnerWrapper>
          <SpinnerIcon />
        </SpinnerWrapper>
      </DropdownButtonItemWrapper>
    );
  }
  return (
    <DropdownButtonItemWrapper className={`${className || ''}`}>
      {type === 'directDownloadHandler' ? (
        <>
          {target ? (
            <A href={target} className="tw-w-full">
              {icon && <IconWrapper>{icon}</IconWrapper>}
              <LabelWrapper>{label}</LabelWrapper>
            </A>
          ) : (
            <Span color="#929CA9" fontSize="14px" className="">
              {fallback}
            </Span>
          )}
        </>
      ) : (
        <Span
          color={DARK_TEXT}
          fontSize="14px"
          fontWeight="400"
          onClick={onClick}
          className="dropdown-item"
        >
          {icon && <IconWrapper>{icon}</IconWrapper>}
          {label}
        </Span>
      )}
    </DropdownButtonItemWrapper>
  );
};

export default DropdownButtonItem;
