import { Residency } from 'pages/members/index/components';
import React from 'react';
import styled from 'styled-components';
import { Box } from 'ui-kit';

import { NormalizedParcels } from '../helper';
import ParcelsListing from '../parcels-listing';

type ParcelsByResidencyProps = {
  className?: string;
  parcels: NormalizedParcels[];
};

const ParcelsByResidencyWrapper = styled.div``;

const ParcelsByResidency: React.FC<ParcelsByResidencyProps> = ({
  className,
  parcels,
}) => {
  if (parcels.length === 0) {
    return null;
  }

  const residencyName = parcels[0].residency;
  const residencyId = parcels[0].residencyId;

  return (
    <ParcelsByResidencyWrapper className={`${className || ''}`}>
      <Box display="flex" flexDirection="column" gap="7px">
        <Residency
          name={residencyName}
          key={residencyId}
          councilMember={null}
          variant="detailed"
        />
        <ParcelsListing parcels={parcels} />
      </Box>
    </ParcelsByResidencyWrapper>
  );
};

export default ParcelsByResidency;
