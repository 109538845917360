import { authApi, usersApi } from 'api/base.api';
import { QUERY_KEYS } from 'api/queryKeys';
import { useQuery } from 'react-query';

const getUsersById = (userId: string): Promise<any> => {
  if (!userId) {
    return Promise.resolve(null);
  }
  return usersApi.getById({ id: userId });
};

const getUser = (): Promise<any> => {
  return authApi.me();
};

export function useUsers({ userId }: { userId: string }) {
  const { data, isLoading, isFetching } = useQuery(QUERY_KEYS.users, () =>
    getUsersById(userId),
  );

  return { data, isLoading, isFetching };
}

export function useUser() {
  const { data, isLoading, isFetching } = useQuery(QUERY_KEYS.user, () =>
    getUser(),
  );

  return { data, isLoading, isFetching };
}
