import { residenciesApi } from 'api/base.api';
import { QUERY_KEYS } from 'api/queryKeys';
import { useQuery } from 'react-query';

type UseExportResidencyProps = {
  residencyId: string;
};

const getResidentsListById = (residencyId: string) => {
  return residenciesApi.getResidentsListById({ id: residencyId });
};

export const getExportResidencyKey = (residencyId: string) => {
  return [QUERY_KEYS.exportResidentsList, residencyId];
};
export function useExportResidency({ residencyId }: UseExportResidencyProps) {
  const { data, isLoading, refetch } = useQuery(
    getExportResidencyKey(residencyId),
    () => getResidentsListById(residencyId),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: false, // disable this query from automatically running
    },
  );

  return { data, isLoading, refetch };
}
