import styled, { css } from 'styled-components';
import { DARK_TEXT, GREY_200, GREY_600 } from 'styles/constant';
import { Span } from 'ui-kit';

export const TitleWrapper = styled.div`
  display: flex;
  gap: 15px;
  transform: translateY(9px);
`;

export const ActivationUsersModalWrapper = styled.div`
  & > div.modal-header {
    border-bottom: 0px;
    padding-bottom: 8px;
    & > h5 {
      font-weight: bold;
    }

    & button.btn-close {
      opacity: 1;
      transform: scale(0.8) translate(18px, -10px);
    }

    & button.btn-close:hover {
      opacity: 0.7;
    }
  }
  & > div.modal-body {
    padding-bottom: 0px;
  }
  & > div.modal-footer {
    border-top: 0px;
    padding-top: 8px;
  }
`;

const gridStyle = css`
  display: grid;
  grid-template-columns: 50px 2fr 3fr 2fr;
  grid-column-gap: 20px;
  align-items: center;
`;
export const GridHeader = styled.div`
  width: 100%;

  ${gridStyle}
  & ${Span} {
    color: ${GREY_600};

    font-size: 10px;
    font-weight: 700;

    text-transform: uppercase;
  }
`;

export const GridWrapper = styled.div`
  max-height: 320px;
  overflow-y: auto;
`;

export const Row = styled.div`
  ${gridStyle}
  padding: 8px 0px;

  border-bottom: 1px solid ${GREY_200};
  &:last-child {
    border-bottom: none;
  }
`;
export const Col = styled.div`
  color: ${DARK_TEXT};

  font-size: 14px;
  font-weight: 400;
  &.notinvitable {
    color: ${GREY_600};
    font-style: italic;
  }
`;
