import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import { IconProps } from './at.icon';
import Icon from './icon';

const BriefcaseIcon: React.FC<IconProps> = ({
  size = '1x',
  color,
  disabled = false,
  className,
}) => (
  <Icon
    icon={faBriefcase}
    color={color}
    className={className}
    disabled={disabled}
    size={size}
  />
);
export default BriefcaseIcon;
