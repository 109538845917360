export const ASSEMBLY_MODE = {
  ONLINE: 'online',
  MIXED: 'mixed',
  PHYSICAL: 'physical',
  VPC_ONLY: 'vpc_only',
};

export const TITLES = {
  1: 'Assemblée ordinaire',
  2: 'Assemblée en seconde convocation',
  3: 'Assemblée convoquée en urgence',
  4: 'Assemblée extraordinaire',
  5: 'Assemblée informelle',
};

export const STATUS_LEGACY = {
  FUTURE: 0,
  STARTED: 1,
  FINISHED: 2,
  INVALID: 3,
};

export const STATUS_NEW = {
  FUTURE: 'not_started',
  STARTED: 'started', // started without a startDate
  IN_PROGRESS: 'in_progress', // started with a startDate (and the 1st resolution passed)
  FINISHED: 'finished',
};

export const ASSEMBLY_MINUTES_STATUS = {
  NONE: 0,
  DOCX_GENERATED: 1, // Docx generated, doc being edited
  PDF_GENERATED: 2, // PDF generated
  REQUEST_SENT: 3, // Document being signed (sign request running)
  SIGNED: 4, // Document signed (sign request over)
  GENERATING: 5,
  GENERATED: 6,
  WAIT_SENDING: 8,
  SENT: 7,
  UPLOAD_DOCUMENTS: 9,
};
