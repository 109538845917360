import React, { useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { TextAreaInput } from 'redux-form-utils/fieldComponents';
import { DARK_TEXT } from 'styles/constant';
import { Span, SpinnerIcon, StyledButton } from 'ui-kit';

import { useMemberUpdate } from '..';
import { FieldWrapper, FormWrapper, NotesWrapper } from './notes.style';

interface Values {
  info?: string;
}
interface NotesProps extends Values {
  className?: string;

  memberId: string;
}

const Notes: React.FC<NotesProps> = ({ className, info, memberId }) => {
  const [dirty, setIsDirty] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { mutate } = useMemberUpdate({ memberId });

  return (
    <NotesWrapper className={`${className || ''}`}>
      <Span color={DARK_TEXT} fontSize="24px">
        Notes
      </Span>

      <Form
        onSubmit={async (values: Values) => {
          setSubmitting(true);
          await mutate({ id: memberId, info: values.info });
          await new Promise((r) => setTimeout(r, 1000));
          setSubmitting(false);
        }}
        initialValues={{ info }}
        keepDirtyOnReinitialize={false}
      >
        {({ handleSubmit }) => {
          return (
            <>
              <FormSpy
                onChange={(formState) => {
                  formState.dirty !== undefined && setIsDirty(formState.dirty);
                }}
              />

              <FormWrapper onSubmit={handleSubmit} className={className}>
                <FieldWrapper>
                  <Field<string>
                    name="info"
                    component={TextAreaInput as any}
                    label=""
                    autoComplete="off"
                    initialHeight="125px"
                  />
                </FieldWrapper>
                <StyledButton light disabled={!dirty} type="submit">
                  {submitting ? <SpinnerIcon /> : 'Enregistrer'}
                </StyledButton>
              </FormWrapper>
            </>
          );
        }}
      </Form>
    </NotesWrapper>
  );
};

export default Notes;
