import { DATE_FORMATS, localizedFormat } from 'date.utils';
import React from 'react';
import { Avatar } from 'ui-kit';

import { InvitableUserItem } from '../hooks/type';
import { AvatarRendererWrapper } from '../listing/renderer/cell/cell-renderer.style';
import { getFirstLine } from '../listing/renderer/cell/name-renderer';
import { Col, Row } from './activation-users-modal.style';

type GridInvitableItemProps = {
  className?: string;
  type: 'invitable' | 'notinvitable';
  items: InvitableUserItem[];
};

const GridInvitableItem: React.FC<GridInvitableItemProps> = ({
  type,
  items,
}) => {
  return (
    <>
      {items.map((item, i) => {
        return (
          <Row key={i}>
            <Col>
              <AvatarRendererWrapper className={`avatar-renderer`}>
                <Avatar
                  src={item?.file?.url}
                  firstName={item.firstName}
                  lastName={item.lastName}
                  companyName={item.companyName}
                  councilMember={item.councilMember}
                  variant="base"
                />
              </AvatarRendererWrapper>
            </Col>
            <Col className="name">
              {getFirstLine(
                item.companyName,
                item.lastName,
                item.firstName,
                item.email,
              )}
            </Col>
            <Col className={type}>{item.email}</Col>
            <Col>
              {item.passwordRequestedAt &&
                localizedFormat(
                  item.passwordRequestedAt,
                  DATE_FORMATS.dayMonthYear,
                )}
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default GridInvitableItem;
