import React from 'react';
import ReactDOM from 'react-dom';

import {
  ButtonWrapper,
  FooterWrapper,
  ModalWrapper,
} from './simpleModal.style';
import { SimpleModalProps } from './simpleModal.type';

export const HAS_SIMPLE_MODAL = '10000';

const SimpleModal = ({
  title,
  message,
  isOpen,
  cancel,
  confirm,
  cancelLabel,
  confirmLabel,
  wrapper: Wrapper,
  ...rest
}: SimpleModalProps) => {
  const modalContent = (
    <div className="modal-content" {...rest}>
      <div className="modal-header">
        <h5 className="modal-title pt-3">{title}</h5>
      </div>
      <div className="modal-body">
        <p>{message}</p>
      </div>
      <FooterWrapper className="modal-footer">
        <ButtonWrapper>
          <button type="button" className="btn btn-light" onClick={confirm}>
            {confirmLabel || 'Fermer sans enregistrer'}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            data-dismiss="modal"
            data-testid="cancel-modal-btn"
            onClick={cancel}
          >
            {cancelLabel || 'Annuler'}
          </button>
        </ButtonWrapper>
      </FooterWrapper>
    </div>
  );
  if (!isOpen) return null;

  const modal = Wrapper ? (
    <Wrapper>{modalContent}</Wrapper>
  ) : (
    <ModalWrapper>{modalContent}</ModalWrapper>
  );

  return ReactDOM.createPortal(
    <div className="modal-backdrop simple-modal" tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        {modal}
      </div>
    </div>,
    document.body,
  );
};

export default SimpleModal;
