import React from 'react';
import styled from 'styled-components';
import { DARK_TEXT, GREY_700 } from 'styles/constant';
import { PhoneIcon, Span } from 'ui-kit';

type PhoneNumberProps = {
  className?: string;
  phone: string;
};

const PhoneNumberWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const PhoneNumber: React.FC<PhoneNumberProps> = ({ className, phone }) => {
  return (
    <PhoneNumberWrapper className={`${className || ''}`}>
      <PhoneIcon color={GREY_700} />
      <Span color={DARK_TEXT} fontSize="14px">
        {phone || 'Non renseigné'}
      </Span>
    </PhoneNumberWrapper>
  );
};

export default PhoneNumber;
