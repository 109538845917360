import { FormApi } from 'final-form';
import useOutsideClick from 'hooks/useOutsideClick';
import React, { useEffect, useRef, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { GREY_500 } from 'styles/constant';
import { MapMarkerAltIcon } from 'ui-kit';

import {
  ContactFieldWrapper,
  DropDownSuggestions,
  DropDownSuggestionsContainer,
  Suggestion,
} from './address.style';
import { getPlacesPostCodeById } from './helper';
import { AddressVariant } from './type';

type AutoCompleteFieldProps = {
  className?: string;
  line1: string;
  form: FormApi<any, Partial<any>>;
  setIsAddressValid: React.Dispatch<React.SetStateAction<boolean>>;
  variant: AddressVariant;
  showErrorOnLoad?: boolean;
  isValidating: boolean;
  setIsValidating: React.Dispatch<React.SetStateAction<boolean>>;
  required: boolean;
};

const AutoCompleteField: React.FC<AutoCompleteFieldProps> = ({
  className,
  line1,
  form,
  setIsAddressValid,
  variant,
  showErrorOnLoad = false,
  isValidating,
  setIsValidating,
  required,
}) => {
  const [address, setAddress] = useState(line1);

  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);
  useOutsideClick(ref, () => setIsOpen(false));
  const handleChange = (address: string) => {
    setAddress(address);
  };

  const handleSelect = async (address: string, placeId?: string) => {
    if (!placeId) {
      return;
    }
    const { line1, zipcode, city, country } = await getPlacesPostCodeById(
      placeId,
    );

    setAddress(line1);

    form.mutators.setValue('line1', line1);
    form.mutators.setValue('zipcode', zipcode);
    form.mutators.setValue('city', city);
    form.mutators.setValue('country', country);
    form.mutators.setValue('placeId', placeId);
  };
  const searchOptions = {
    types: ['address'],
  };

  useEffect(() => {
    if (!line1) {
      setIsAddressValid(false);
      return;
    }
    setIsAddressValid(true);
  }, [address, line1, setIsAddressValid]);

  return (
    <ContactFieldWrapper className={`${className || ''}`} variant={variant}>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          setIsOpen(suggestions.length > 0);
          return (
            <div className="tw-w-full">
              <div className="form-label-group">
                <input
                  name="autocomplete"
                  id="autocomplete"
                  placeholder="Adresse"
                  {...getInputProps({
                    className: `form-control location-search-input ${
                      (showErrorOnLoad || isValidating) &&
                      !address &&
                      line1 !== null
                        ? 'is-invalid'
                        : ''
                    }`,
                  })}
                  onBlur={() => setIsValidating(true)}
                  autoComplete="off"
                />
                <label htmlFor="autocomplete">
                  Adresse{required ? '*' : ''}
                </label>
              </div>
              {isOpen && (
                <DropDownSuggestionsContainer ref={ref}>
                  <DropDownSuggestions className="dropdown-list">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      return (
                        <Suggestion {...getSuggestionItemProps(suggestion)}>
                          <div>
                            <MapMarkerAltIcon color={GREY_500} size="lg" />
                          </div>
                          <span>{suggestion.description}</span>
                        </Suggestion>
                      );
                    })}
                  </DropDownSuggestions>
                </DropDownSuggestionsContainer>
              )}
            </div>
          );
        }}
      </PlacesAutocomplete>
    </ContactFieldWrapper>
  );
};

export default AutoCompleteField;
