import React from 'react';
import styled from 'styled-components';

export const CrossCircleIcon = () => {
  return (
    <Wrapper>
      <svg
        id="Icons_General_Check_circle_Copy_2"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
      >
        <rect
          id="Icons_General_Cross_circle_background"
          width="25"
          height="25"
          fill="none"
        />
        <circle
          id="Oval"
          cx="7.143"
          cy="7.143"
          r="7.143"
          transform="translate(5.357 5.357)"
          fill="#ff5f3e"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <g id="Group" transform="translate(8.916 9.028)">
          <rect
            id="Rectangle_15"
            width="1.786"
            height="8.036"
            rx="0.893"
            transform="translate(0 1.263) rotate(-45)"
            fill="#fff"
          />
          <rect
            id="Rectangle_15-2"
            width="1.786"
            height="8.036"
            rx="0.893"
            transform="translate(1.263 6.945) rotate(-135)"
            fill="#fff"
          />
        </g>
      </svg>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  margin-left: -5px;
  margin-top: -2px;
`;
