import { useMutation, UseMutationResult, useQuery } from 'react-query';

import { assembliesApi } from '../../api/base.api';
import { QUERY_KEYS } from '../../api/queryKeys';

const getAssemblyById = (assemblyId: string): Promise<any> => {
  return assembliesApi.getById({ id: assemblyId });
};

export function useAssembly({ assemblyId }: { assemblyId: string }) {
  const { data, isLoading, isFetching } = useQuery(
    [QUERY_KEYS.assembly, assemblyId],
    async () => getAssemblyById(assemblyId),
    { enabled: !!assemblyId },
  );

  return { data, isLoading, isFetching };
}

export async function startAssembly(assemblyId: string) {
  await assembliesApi.startV3({
    id: assemblyId,
  });
}

export function useStartAssembly(): UseMutationResult<
  void,
  unknown,
  string,
  unknown
> {
  return useMutation(startAssembly);
}
