import {
  format as fnsFormat,
  formatDistanceToNow as fnsFormatDistanceToNow,
} from 'date-fns';
import { fr } from 'date-fns/locale';

let currentLocal = fr;

export function localizedFormat(date, formatStr) {
  return fnsFormat(new Date(date), formatStr, {
    locale: currentLocal,
  });
}

export function localizedFormatDistanceToNow(date, opts) {
  return fnsFormatDistanceToNow(new Date(date), {
    ...opts,
    locale: currentLocal,
  });
}

export const DATE_FORMATS = {
  fullDayMonthWithHour: "EEEE d MMMM yyyy HH'h'mm",
  dayMonthYearWithHour: 'dd/MM/yyyy à HH:mm',
  fullDayMonthYearWithHour: 'EEEE d MMMM yyyy à HH:mm',
  dayMonthYearSlash: 'dd/MM/yyyy',
  fullDayMonth: 'EEEE d MMMM',
  dayMonthYear: 'd MMMM yyyy',
  dayMonthYearHour: 'd MMMM yyyy, HH:mm',
  fullDayMonthYear: 'EEEE d MMMM yyyy',
  Hour: 'HH:mm',
  DayMonthYear: 'dd.MM.yyyy',
  YearMonthDay: 'yyyy-MM-dd',

  hoursMinutesSecondes: 'HH:mm:ss',
};
