import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { updateApiHeaderToken } from './api/base.api';
import AuthLayout from './pages/layouts/auth.layout';
import MainLayout from './pages/layouts/main.layout';
import {
  authTokenSelector,
  authUserSelector,
} from './pills/auth/auth.selectors';
import Router from './router/router';
import { PROFILE_MANAGER } from 'redux-orm-models/user.type';

function App({ userToken, user, init }) {
  const [initialized, setInitialized] = useState(false);

  useEffect(
    () => {
      updateApiHeaderToken(userToken);
      init();
      setInitialized(true);
    },
    // check https://github.com/facebook/react/issues/14920
    // eslint-disable-next-line
    [userToken],
  );

  useEffect(() => {
    const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;
    if (
      PROFILE_MANAGER === user?.currentProfile &&
      intercomAppId?.length > 0 &&
      window === window.top
    ) {
      try {
        const scriptTag = document.createElement('script');
        const scriptText = document.createTextNode(`
        var APP_ID = "${intercomAppId}";
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `);
        scriptTag.appendChild(scriptText);
        document.body.appendChild(scriptTag);

        window.Intercom('boot', {
          app_id: intercomAppId,
          ...(user
            ? {
                name:
                  `${user.firstName || ''} ${user.lastName || ''}`.trim() || '',
                user_id: user.id,
                email: user.email,
                phone: user.phone,
                companyName: user.companyName,
                currentCompany: user.currentCompany?.name,
              }
            : {}),
        });

        return () => {
          document.body.removeChild(scriptTag);
        };
      } catch (err) {
        console.error(`Error init intercom: `, err);
      }
    }
  }, [user?.currentProfile]);

  return (
    initialized && (
      <Router
        userToken={userToken}
        layout={userToken ? MainLayout : AuthLayout}
      />
    )
  );
}

function mapStateToProps(state) {
  return {
    userToken: authTokenSelector(state),
    user: authUserSelector(state),
  };
}
export default connect(mapStateToProps, (dispatch) => ({
  init: () => dispatch({ type: 'init' }),
}))(App);
