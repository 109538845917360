import React from 'react';
import { GREY_200 } from 'styles/constant';
import { SVG_SIZE, SVGIconProps, SVGIconWrapper } from 'ui-kit/assembly.icon';

import { ReactComponent as PropertySVG } from './property.svg';

const PropertyIcon: React.FC<SVGIconProps> = ({
  color = GREY_200,
  size = 'sm',
  w,
  h,
  className,
}) => {
  const { width, height } = SVG_SIZE[size];
  return (
    <SVGIconWrapper
      className={`${className || ''}`}
      width={w || width}
      height={h || height}
      color={color}
      $fill
    >
      <PropertySVG />
    </SVGIconWrapper>
  );
};

export default PropertyIcon;
