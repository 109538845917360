import {
  Address,
  Email,
  EREConsent,
  LastConnection,
  PhoneNumber,
} from 'pages/members/index/components';
import { Address as AddressType } from 'pills/members/member.type';
import React from 'react';
import { DARK_TEXT, GREY_700 } from 'styles/constant';
import { AtIcon, PaperPlaneIcon, Span } from 'ui-kit';

import {
  ContactWrapper,
  LastConnectionWrapper,
  MailWrapper,
} from './contact.style';

type ContactProps = {
  className?: string;
  address: AddressType;
  email: string | null;
  phone: string;
  registeredEmailConsent: boolean;
  userEnabled: boolean;
  lastLogin: string | null;
  passwordRequestedAt: string | null;
};

const Contact: React.FC<ContactProps> = ({
  className,
  address,
  email,
  phone,
  registeredEmailConsent,
  userEnabled,
  lastLogin,
  passwordRequestedAt,
}) => {
  return (
    <ContactWrapper className={`${className || ''}`}>
      <Span color={DARK_TEXT} fontSize="24px">
        Contact
      </Span>
      <PhoneNumber phone={phone} />
      <MailWrapper>
        <Email email={email} />
        <EREConsent registeredEmailConsent={registeredEmailConsent} />
      </MailWrapper>
      <Address address={address} size="lg" />
      <LastConnectionWrapper>
        {userEnabled ? (
          <AtIcon color={GREY_700} />
        ) : (
          <PaperPlaneIcon color={GREY_700} />
        )}
        <LastConnection
          userEnabled={userEnabled}
          lastLogin={lastLogin}
          lastActivationEmail={passwordRequestedAt}
        />
      </LastConnectionWrapper>
    </ContactWrapper>
  );
};

export default Contact;
