export const YELLOW = '#FFCC00';
export const BG_YELLOW = 'rgba(255, 204, 0, 0.2);';
export const BG_BLUE = '#D5E5F3';
export const BG_GREEN = '#F1FBF3';
export const BG_RED = '#FFE2DC';
export const BG_GREY = '#DEE2E6';
export const BG_COLOR2 = '#FFBB62';

export const BLACK = '#1a1e21';
export const BLUE = '#2C89DB';
export const GREEN = '#2BC952';
export const RED = '#FF2C00';

export const GREY_LIGHT = '#F7F8FA';
export const GREY_100 = '#F8F9FA';
export const GREY_200 = '#E9ECEF';
export const GREY_400 = '#CED4DA';
export const GREY_500 = '#ADB5BD';
export const GREY_600 = '#6C757D';
export const GREY_700 = '#495057';
export const GREY_900 = '#212529';
export const LG_GREY = '#6E747A';
export const RED_500 = '#FF2F03';
export const LIGHT_GREY = '#9B9C9D';
export const LIGHTER_GREY = '#CED4DA';
export const DARK_TEXT = '#36383A';
export const BLUE_700 = '#1E5E97';
export const ORANGE = '#FF9000';
export const RED_200 = '#FFA28E';
export const RED_400 = '#FF5F3E';
export const GREEN_400 = '#2EAA4A';
export const BOX_SHADOW_15P = '#36383B';
export const YELLOW_500 = '#FFD014';

export const BREAKPOINTS = {
  laptopMax: 1399,
  desktopMax: 1180,
};

export const QUERIES = {
  laptopAndless: `(max-width: ${BREAKPOINTS.laptopMax / 16}rem)`,
  desktopAndless: `(max-width: ${BREAKPOINTS.desktopMax / 16}rem)`,
};

export const DEVICE_TYPE = {
  laptopAndless: 'laptopAndless',
  desktop: 'desktop',
};
