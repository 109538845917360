import { useInvitableUsers } from 'pages/members/index/components/hooks/useInvitableUsers';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-table';
import { GREY_600 } from 'styles/constant';
import { Span, StyledButton } from 'ui-kit';

import { ColumnNames } from '../../../column';
import ActivationButton from './activation-button';
import MemberCoordinate from './member-coordinate';
import MemberResidencies from './member-residencies';
import MemberStatus from './member-status';
import {
  ButtonsWrapper,
  MemberSummaryRendererWrapper,
} from './member-summary-renderer.style';

type MemberSummaryRendererProps = {
  className?: string;
  row: Row<ColumnNames>;
  residencyId: string | null;
};

const MemberSummaryRenderer = ({
  className,
  row,
  residencyId,
}: MemberSummaryRendererProps) => {
  const history = useHistory();
  const {
    address,
    email,
    phone,
    residencies,
    userEnabled,
    registeredEmailConsent,
    id,
    users,
  } = row.original;

  const consentERE = !!registeredEmailConsent?.enabled;
  const onClick = () => {
    history.push(`/member/details/${id}`);
  };

  const lastLogin = users?.[0]?.lastLogin;
  const { data: usersData } = useInvitableUsers({
    residencyId,
  });
  const passwordRequestedAt = usersData?.find(
    (user) => user?.id === users?.[0]?.id,
  )?.passwordRequestedAt;

  return (
    <MemberSummaryRendererWrapper className={`${className || ''}`}>
      <MemberCoordinate address={address} email={email} phone={phone} />
      <MemberResidencies residencies={residencies} />
      <MemberStatus
        userEnabled={userEnabled}
        registeredEmailConsent={consentERE}
        lastLogin={lastLogin || ''}
        passwordRequestedAt={passwordRequestedAt}
      />
      <ButtonsWrapper>
        {users.length > 0 && residencyId && !userEnabled && (
          <ActivationButton residencyId={residencyId} userId={users[0].id} />
        )}
        <StyledButton light px="22px" onClick={onClick}>
          <Span fontWeight="700" fontSize="14px" color={GREY_600}>
            Voir le profil
          </Span>
        </StyledButton>
      </ButtonsWrapper>
    </MemberSummaryRendererWrapper>
  );
};

export default MemberSummaryRenderer;
