import React, { PropsWithChildren } from 'react';
import { HeaderGroup } from 'react-table';
import styled from 'styled-components';

import SortIcon from '../../sort-icon';

interface HeaderRendererProps<ColumnsNames extends Record<any, any>> {
  column: HeaderGroup<ColumnsNames>;
}
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
`;
const HeaderLabel = styled.span`
  margin-right: 5px;
`;

const THRenderer = <ColumnsNames extends Record<any, any>>(
  props: PropsWithChildren<HeaderRendererProps<ColumnsNames>>,
): JSX.Element => {
  const { column } = props;

  const key = column.render('Header') as string;
  return (
    <div
      {...column.getHeaderProps(
        column.getSortByToggleProps({ title: undefined }),
      )}
    >
      <Wrapper
        className={`${
          column.isSorted && !column.disableSortBy ? 'sorted' : ''
        }`}
      >
        <HeaderLabel>{key}</HeaderLabel>
        {!column.disableSortBy && (
          <SortIcon
            isSorted={column.isSorted}
            isSortedDesc={column.isSortedDesc || false}
          />
        )}
      </Wrapper>
    </div>
  );
};

export default THRenderer;
