import { faCog } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import Icon from './icon';

export type IconProps = {
  size?: '1x' | '10x' | '7x' | '5x' | '3x' | '2x' | '1x' | 'lg' | 'sm' | 'xs';
  className?: string;
  disabled?: boolean;
  color?: string;
};
const SettingsIcon: React.FC<IconProps> = ({
  size = '1x',
  color,
  disabled = false,
  className,
}) => (
  <Icon
    icon={faCog}
    color={color}
    className={className}
    disabled={disabled}
    size={size}
  />
);
export default SettingsIcon;
