import styled from 'styled-components';
import { BoxContainer } from 'ui-kit';

export const COLUMNS_GAP = '34px';
export const MemberDetailsWrapper = styled(BoxContainer)`
  width: 100%;
`;

export const InformationWrapper = styled.div`
  margin-top: 32px;
  display: flex;

  gap: ${COLUMNS_GAP};
`;

export const ActivationButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
`;
