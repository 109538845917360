import { queryClient } from 'api/queryClient';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

export const applySelectorsToState = (selectors) => (state) =>
  Object.keys(selectors).reduce(
    (acc, selectorKey) => ({
      ...acc,
      [selectorKey]: selectors[selectorKey](state),
    }),
    {},
  );

function CacheProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools position="bottom-left" />
      {children}
    </QueryClientProvider>
  );
}

// clear react-query cache (will force all RequestEntities launch GET request)
export const clearCache = () => {
  // see https://react-query.tanstack.com/reference/QueryClient#queryclientclear
  queryClient.clear();
};

export const invalidationFactory = (queryKey) => () =>
  queryClient.invalidateQueries(queryKey);

export default CacheProvider;
