import { fk, Model } from 'redux-orm';

import { getId } from '../utils';
import AssemblyLawArticle from './assemblyLawArticle.model';
import AssemblyMajorityMode from './assemblyMajorityMode.model';
import { ASSEMBLY_LAW_ARTICLE_FREE_INPUT_ID } from './assemblyResolution.model';

class AssemblyResolutionTemplate extends Model {
  constructor(data) {
    super({
      ...data,
      assemblyMajorityModeId: data.assemblyMajorityMode
        ? getId(data.assemblyMajorityMode)
        : null,
      assemblyLawArticleId: data.assemblyLawArticle
        ? getId(data.assemblyLawArticle)
        : ASSEMBLY_LAW_ARTICLE_FREE_INPUT_ID,
    });
  }
}

AssemblyResolutionTemplate.modelName = 'AssemblyResolutionTemplate';

AssemblyResolutionTemplate.fields = {
  assemblyLawArticleId: fk({
    to: AssemblyLawArticle.modelName,
    as: 'assemblyLawArticle',
    relatedName: '_assemblieResolutionTemplatesSet',
  }),
  assemblyMajorityModeId: fk({
    to: AssemblyMajorityMode.modelName,
    as: 'assemblyMajorityMode',
    relatedName: '_assemblieResolutionTemplatesSet',
  }),
};

export default AssemblyResolutionTemplate;
