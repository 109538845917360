export const getClassNameFromLocation = () => {
  const pathname = window.location.pathname;
  if (pathname.endsWith('/details')) {
    return 'ag-detail';
  }

  if (pathname.includes('live')) {
    return 'live';
  }

  return 'full-page-layout';
};
