import { PER_PAGE } from 'api/base.api';
import { QUERY_KEYS } from 'api/queryKeys';
import { getMembers } from 'pills/members/members.api';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { SortingRule } from 'react-table';
import { ASC, DESC, isLastPage } from 'ui-kit';

import { ColumnId, ColumnNames } from '../listing/column';
import { mapSortKey } from '../listing/mapper';

type UseMemberProps = {
  currentPage: number;
  match: string | null;
  residencyId: string | null;
  sort: SortingRule<ColumnNames>;
  membersPerPage?: number;
};

type GetWhereProps = {
  match: string | null;
  residencyId: string | null;
  sort: SortingRule<ColumnNames>;
};

export const getWhere = ({ match, residencyId, sort }: GetWhereProps) => {
  const where = { targets: ['tenant', 'owner'] };
  if (residencyId) {
    Object.assign(where, { residencyId });
  }
  if (match) {
    Object.assign(where, { match });
  }

  Object.assign(where, {
    order: {
      key: mapSortKey(sort.id as ColumnId),
      type: sort.desc ? DESC : ASC,
    },
  });

  return where;
};

const getMembersKey = (
  currentPage: number,
  match: string | null,
  residencyId: string | null,
  sort: SortingRule<ColumnNames>,
  membersPerPage: number,
) => {
  return [
    QUERY_KEYS.members,
    currentPage,
    match,
    residencyId,
    JSON.stringify(sort),
    membersPerPage,
  ];
};

export function useMembers({
  currentPage,
  match,
  residencyId,
  sort,
  membersPerPage = PER_PAGE.MEMBERS,
}: UseMemberProps) {
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching } = useQuery(
    getMembersKey(currentPage, match, residencyId, sort, membersPerPage),

    () =>
      getMembers(
        currentPage,
        getWhere({ match, residencyId, sort }),
        membersPerPage,
      ),
  );

  const sortStr = JSON.stringify(sort);
  const dataStr = JSON.stringify(data);
  useEffect(() => {
    if (!data) {
      return;
    }
    if (!isLastPage(currentPage, data.length, data?.totalItems)) {
      const nextPage = currentPage + 1;
      queryClient.prefetchQuery(
        [
          QUERY_KEYS.members,
          nextPage,
          match,
          residencyId,
          JSON.stringify(sort),
          membersPerPage,
        ],
        () => getMembers(nextPage, getWhere({ match, residencyId, sort })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, match, residencyId, sortStr, dataStr]);

  return { data, isLoading, isFetching };
}

export const useAllMembers = ({ residencyId }: { residencyId: string }) => {
  const { data, isLoading } = useMembers({
    currentPage: 1,
    match: null,
    residencyId,
    sort: { id: ColumnId.NAME, desc: true },
    membersPerPage: PER_PAGE.ALL,
  });

  return { data, isLoading };
};
export const usePrefetchAllMembers = ({
  residencyId,
}: {
  residencyId: string;
}) => {
  const queryClient = useQueryClient();

  queryClient.prefetchQuery(
    getMembersKey(
      1,
      null,
      residencyId,
      { id: ColumnId.NAME, desc: true },
      PER_PAGE.ALL,
    ),

    () =>
      getMembers(
        1,
        getWhere({
          match: null,
          residencyId,
          sort: { id: ColumnId.NAME, desc: true },
        }),
        PER_PAGE.ALL,
      ),
  );
};
