import React from 'react';

import RTooltip from '../../rTooltip';
import {
  Bar,
  BarWrapper,
  EndBar,
  Legend,
  LegendWrapper,
  StartBar,
  VpcProgressionVoteWrapper,
  Wrapper,
} from './vpcProgressionVote.style';

const VOTEFOR_COLOR = '#2BC952';
const ABS_COLOR = '#ADB5BD';
const NOTYET_COLOR = '#FFFFFF';
const AGAINST_COLOR = '#FF5F3E';

const VpcProgressionVote = ({
  votefor = 20,
  abs = 20,
  notyet = 20,
  against = 40,
  absIgnored = false,
  notyetIgnored = false,
}) => {
  const absTooltip = `Abstention : ${
    absIgnored ? `non pris en compte` : `${abs} %`
  } `;
  const notyetTooltip = `Pas encore voté : ${
    notyetIgnored ? `non pris en compte` : `${notyet} %`
  } `;

  return (
    <VpcProgressionVoteWrapper>
      <Wrapper>
        <BarWrapper>
          <StartBar value={votefor} color={VOTEFOR_COLOR} />
          <RTooltip place="bottom" title={absTooltip}>
            <Bar value={abs} color={ABS_COLOR} />
          </RTooltip>
          <RTooltip place="bottom" title={notyetTooltip}>
            <Bar value={notyet} color={NOTYET_COLOR} />
          </RTooltip>
          <EndBar value={against} color={AGAINST_COLOR} />
        </BarWrapper>
      </Wrapper>
      <LegendWrapper>
        <Legend>Pour: {`${votefor} %`}</Legend>
        <Legend>Contre: {`${against} %`}</Legend>
      </LegendWrapper>
    </VpcProgressionVoteWrapper>
  );
};

export default VpcProgressionVote;
