import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';

import useKeypress from '../../hooks/useKeypress';

export default function ModalLayout({
  children,
  parentPath,
  history,
  match,
  location,
  size,
  zIndex = '99999',
  minWidth,
  className,
  style,
  onClose,
}) {
  let close = (e, confirmed) => {
    if (confirmed !== undefined && confirmed === false) {
      return;
    }

    // if called without dom event (as a callback) e is undefined
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    const lastPath =
      match.url.split('/').slice(0, parentPath.split('/').length).join('/') +
      location.search;
    history.push(lastPath);
  };
  close = onClose ? onClose(close) : close;
  useLayoutEffect(() => {
    // Remove scroll on the body when modal is open
    const oldCs = document.querySelector('body').className;
    document.querySelector('body').className = 'modal-open';
    return () => {
      document.querySelector('body').className = oldCs;
    };
  }, []);
  useKeypress('Escape', close);
  return (
    <div
      className="d-block modal tw-overflow-hidden"
      style={{ backgroundColor: 'rgba(0,0,0,0.5)', zIndex }}
      onClick={close}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.25 }}
        className={`modal-dialog modal-dialog-scrollable ${
          size && 'modal-'
        }${size}`}
        onClick={(e) => e.stopPropagation()}
        style={{ minWidth: minWidth || undefined }}
      >
        <div
          className={`modal-content ${className}`}
          style={style || { minWidth: minWidth || undefined }}
        >
          {React.cloneElement(children, {
            modal: { close },
          })}
        </div>
      </motion.div>
    </div>
  );
}

ModalLayout.propTypes = {
  children: PropTypes.element.isRequired,
  size: PropTypes.oneOf(['', 'sm', 'lg', 'xl', 'fullscreen']),
  /**
   * The function will receive a 'close callback' and should call this callback to effectively close the modal
   * Js callback old school way ;)
   */
  onClose: PropTypes.func,
};

ModalLayout.defaultProps = {
  size: '',
};
