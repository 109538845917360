import { documentTypesApi } from '../../api/base.api';

const flatDocumenTypes = (documentTypes) =>
  documentTypes.reduce((acc, documentType) => {
    if (Array.isArray(documentType.children) && documentType.children.length) {
      return acc.concat(flatDocumenTypes(documentType.children));
    } else {
      return acc.concat(documentType);
    }
  }, []);

export async function getDocumentTypes() {
  const documentTypes = await documentTypesApi.list();
  return flatDocumenTypes(documentTypes);
}

window.getDocumentTypes = getDocumentTypes;
