export const buildLinkStr = (
  type: string,
  path: string | null,
  customPath?: string,
  token?: string,
  queryParams?: string,
): string => {
  if (path === null && customPath) {
    return customPath;
  }

  if (type === 'v4' || type === 'v2') {
    const urlSearchParams = new URLSearchParams(queryParams ?? '');

    if (token) {
      urlSearchParams.append('token', token);
    }

    let url = `${path !== '' ? '/' : ''}${path}`;
    if (urlSearchParams.toString().length > 0) {
      url += `?${urlSearchParams.toString()}`;
    }

    return `${process.env.REACT_APP_V4_URL}${url}`;
  }
  return `/${path}` || '';
};
