// #region UTILS
export const or =
  (...accesses) =>
  (...args) =>
  ({ user }) => {
    for (let index = 0; index < accesses.length; index++) {
      const access = accesses[index];
      if (args?.length) {
        if (access(...args)({ user })) {
          return true;
        }
      } else {
        if (access({ user })) {
          return true;
        }
      }
    }
    return false;
  };

export const and =
  (...accesses) =>
  (...args) => {
    for (let index = 0; index < accesses.length; index++) {
      const access = accesses[index];
      if (!access(...args)) {
        return false;
      }
    }
    return true;
  };

// #endregion
// #region User
export function accessPulic() {
  return true;
}
// --- Assemblies
export const accessUserAssemblies = ({ user }) =>
  user.isGrantedToAccessAssemblies();
export const accessUserAssemblyAdd = ({ user }) =>
  user.isGrantedToAddAssembly();
// --- Polls
export const accessUserPolls = ({ user }) => user.isGrantedToAccessPolls();
export const accessUserPollAdd = ({ user }) => user.isGrantedToAddPoll();
// --- Residencies
export const accessUserResidencyAdd = ({ user }) =>
  user.isGrantedToAddResidency();
// --- Providers
export const accessUserProviders = ({ user }) =>
  user.isGrantedToAccessProviders();
export const accessUserProvidersAdd = ({ user }) =>
  user.isGrantedToAddProvider();
// --- Plaints
export const accessUserPlaints = ({ user }) => user.isGrantedToAccessPlaints();
export const accessUserPlaintAdd = ({ user }) => user.isGrantedToAddPlaint();
// --- Resolutions
export const accessUserAssemblyResolutionView = ({ user }) =>
  user.isGrantedToAddAssembly();

// --- Publications
export function accessUserPublications({ user }) {
  /** SYM-1370 : Hide residency discussion for an Individual account / company, or a user without company */
  return (
    user.hasDiscussionActivated() &&
    user.hasCompany() &&
    !user.isIndividualCompany()
  );
}
// --- Notifications
export function accessUserNotifications({ user }) {
  return user.isSomething();
}
// --- Inbox
export function accessUserInbox({ user }) {
  /** SYM-1370 : Hide inbox  for an Individual account / company, or a user without company */
  return user.hasCompany() && !user.isIndividualCompany();
}
// --- Roles
export const accessUserSomething = ({ user }) => user.isSomething();
export const accessUserTenant = ({ user }) => user.isTenant();
export const accessUserOwner = ({ user }) => user.isOwner();
export const accessUserProvider = ({ user }) => user.isProvider(); // it is a ROLE !
export const accessUserModerator = ({ user }) => user.isModerator();
export const accessUserManager = ({ user }) => user.isManager();
export function accessUserManagerOrOwner({ user }) {
  return user.isManager() || user.isOwner();
}
export function accessUserAdminOrManager({ user }) {
  return user.isAdmin() || user.isManager();
}
export function accessUserProfessionalCompany({ user }) {
  return user.isProfessionalCompany();
}
export const accessCompanyRentalManagement = ({ user }) =>
  user.currentCompany?.settings?.rentalManagement;
// #endregion
// #region Member
export const accessMemberEdit =
  (member) =>
  ({ user }) =>
    member?.rights?.edit;
// #endregion
// #region Residency
// TO DELETE
export const accessUserResidencyEdit = ({ user }) =>
  user.isGrantedToEditResidency();
// TO DELETE
export const accessUserResidencyView = ({ user }) =>
  user.isGrantedToViewResidency();
export const accessResidencyOwner =
  (residency) =>
  ({ user }) =>
    residency.rights?.is_owner;
export const accessResidencyManager =
  (residency) =>
  ({ user }) =>
    residency.rights?.is_manager;
export const accessResidencyEdit =
  (residency) =>
  ({ user }) =>
    residency.rights?.edit;
export const accessResidencyParcelAdd =
  (residency) =>
  ({ user }) =>
    residency.rights?.parcel_add;
export const accessResidencyDistributionKeyAdd =
  (residency) =>
  ({ user }) =>
    residency.rights?.add;
// #endregion
// #region Ditribituion Key
export const accessDistributionKeyDelete =
  (distributionKey) =>
  ({ user }) =>
    distributionKey.rights?.delete;
// #endregion
// #region News
export const accessNewItemEdit =
  (newItem) =>
  ({ user }) =>
    newItem.rights?.edit;
export const accessNewItemDelete =
  (newItem) =>
  ({ user }) =>
    newItem.rights?.delete;
export const accessNewItemEditOrDelete = (newItem) => (auth) =>
  accessNewItemEdit(newItem)(auth) || accessNewItemDelete(newItem)(auth);
// #endregion
// #region Parcel
export const accessParcelOwnerView =
  (parcel) =>
  ({ user }) =>
    parcel.rights?.owner_view;
// #endregion
// #region Building
export const accessBuildingDelete =
  (building) =>
  ({ user }) =>
    building.rights?.delete;
// #endregion
// #region Comment
export const accessCommentDelete =
  (comment) =>
  ({ user }) =>
    comment.rights?.delete;
export const accessCommentEdit =
  (comment) =>
  ({ user }) =>
    comment.rights?.edit;
export const accessCommentModerate =
  (comment) =>
  ({ user }) =>
    comment.rights?.moderate;
export const accessCommentReport =
  (comment) =>
  ({ user }) =>
    comment.rights?.report;
export const accessCommentUnmoderate =
  (comment) =>
  ({ user }) =>
    comment.rights?.unmoderate;
export const accessCommentUnreport =
  (comment) =>
  ({ user }) =>
    comment.rights?.unreport;
// #endregion
// #region Plaints
export const accessPlaintView =
  (plaint) =>
  ({ user }) =>
    plaint.rights?.view;
export const accessPlaintEdit =
  (plaint) =>
  ({ user }) =>
    plaint.rights?.edit;
export const accessPlaintAccept =
  (plaint) =>
  ({ user }) =>
    plaint.rights?.accept;
export const accessPlaintReject =
  (plaint) =>
  ({ user }) =>
    plaint.rights?.reject;
export const accessPlaintResolve =
  (plaint) =>
  ({ user }) =>
    plaint.rights?.resolve;
export const accessPlaintArchive =
  (plaint) =>
  ({ user }) =>
    plaint.rights?.archive;
export const accessPlaintCancel =
  (plaint) =>
  ({ user }) =>
    plaint.rights?.cancel;
export const accessPlaintDelete =
  (plaint) =>
  ({ user }) =>
    plaint?.rights?.delete;
// #endregion
// #region Providers
export const accessProviderDelete =
  (provider) =>
  ({ user }) =>
    provider.rights?.delete;
export const accessProviderEdit =
  (provider) =>
  ({ user }) =>
    provider.rights?.edit;
export const accessUserProvidersEdit = ({ user }) =>
  user.isGrantedToAddProvider(); // TO DELETE
// #endregion
// #region Polls
export const accessPollEdit = (poll) => () => poll.rights?.edit;
export const accessPollDelete = (poll) => () => poll.rights?.delete;
export const accessPollView = (poll) => () => poll.rights?.view;
export const accessPollViewAnswers = (poll) => () => poll.rights?.view_answers;
export const accessPollRemind = (poll) => () => poll.rights?.remind;
export const accessPollVote = (poll) => () => poll.rights?.vote;
export const accessPollVoted = (poll) => () => poll.rights?.voted;
// #endregion

// #region Assemblies
export const accessAssemblyView =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.view;
export const accessAssemblyEdit =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.edit;
export const accessAssemblyLiveEdit =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.manage;
// export const accessAssemblyLiveEdit = (assembly) => ({ user }) =>
//   assembly.rights?.ongoing_edit;
export const accessAssemblyStart =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.start;
export const accessAssemblyFinish =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.finish;
export const accessAssemblyArchive =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.archive;
export const accessAssemblyResolutionAdd =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.resolution_add;
export const accessAssemblySuggestionAdd =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.suggestion_add;
export const accessAssemblyParticipate =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.participate;
export const accessAssemblyVote =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.vote;
export const accessAssemblyDuplicate =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.duplicate;
export const accessAssemblyManage = (assembly) => (user) => {
  return assembly.rights?.manage;
};
// export const accessAssemblyManage = (assembly) => (user) => {
//   return assembly.started
//     ? assembly.rights?.ongoing_edit
//     : assembly.rights?.manage;
// };

export const accessAssemblyManageOrOngoingEdit = (assembly) => (user) => {
  return assembly.started
    ? assembly.rights?.ongoing_edit || assembly.rights?.manage
    : assembly.rights?.manage;
};

// export const accessAssemblyManageOrOngoingEdit = (assembly) => (user) => {
//   return assembly.started
//     ? assembly.rights?.ongoing_edit || assembly.rights?.manage
//     : assembly.rights?.manage;
// };
export const accessAssemblyLiveManage =
  (assembly) =>
  ({ user }) => {
    return assembly.rights?.manage;
  };
// export const accessAssemblyLiveManage = (assembly) => ({ user }) => {
//   return assembly.rights?.ongoing_edit;
// };
/**
 * TODO: check user is ongoinManager
 * when removing 'Syment-Assembly-Ongoing-Version' API client header
 * somehow assembly.ongoingManagerUserId get lost
 *
 * I keep here what was accessAssemblyLiveManage conditions
 assembly.rights?.manage && assembly.ongoingManagerUserId === user.id;
 */
export const accessAssemblyMinute =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.minutes_edit;
export const accessAssemblyOpenEarlyVoting =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.early_voting_open;
export const accessAssemblyCloseEarlyVoting =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.early_voting_close;
export const accessAssemblyConvocationEdit =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.invitation_edit;
export const accessAssemblyConvocationGenerate =
  (assemblyConvocation) =>
  ({ user }) =>
    assemblyConvocation.rights?.generate;
export const accessAssemblyPublishedToOwners =
  (assembly) =>
  ({ user }) =>
    assembly.rights?.publish;
// #endregion

// #region AssembliyResolutions
// NOTE: [API_CURIOSITY#6] -- the api do know if we are in live or not. Therefore, why should I do assembly.isLive() ? ...

export const accessAssemblyResolutionEdit =
  (assemblyResolution) =>
  ({ user }) =>
    assemblyResolution.rights?.edit;
export const accessAssemblyResolutionDelete =
  (assemblyResolution) =>
  ({ user }) =>
    assemblyResolution.rights?.delete;
export const accessAssemblyResolutionDuplicate =
  (assemblyResolution) =>
  ({ user }) =>
    assemblyResolution.rights?.duplicate;
export const accessAssemblyResolutionAccept =
  (assemblyResolution) =>
  ({ user }) =>
    assemblyResolution.rights?.accept;
export const accessAssemblyResolutionReject =
  (assemblyResolution) =>
  ({ user }) =>
    assemblyResolution.rights?.reject;
export const accessAssemblyResolutionVote =
  (assemblyResolution) =>
  ({ user }) =>
    assemblyResolution.rights?.vote;
export const accessAssemblyResolutionVotable =
  (assemblyResolution) =>
  ({ user }) =>
    assemblyResolution.rights?.votable;
export const accessAssemblyResolutionEarlyVote =
  (assemblyResolution) =>
  ({ user }) =>
    assemblyResolution.rights?.early_vote;
// #endregion

// #region Documents
export const accessDocumentEdit =
  (document) =>
  ({ user }) =>
    document.rights?.edit;
export const accessDocumentDelete =
  (document) =>
  ({ user }) =>
    document.rights?.delete;
export const accessDocumentView =
  (document) =>
  ({ user }) =>
    document.rights?.view;
