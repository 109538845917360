import React from 'react';
import RTooltip from 'ui-kit/rTooltip';

import Button, { ButtonProps } from '../button';
import { StyledButtonWrapper } from './styled-button.style';

interface StyledButtonProps extends ButtonProps {
  gap?: string;
  px?: string;
  py?: string;
  onClick?: () => void;
  tooltipTitle?: string;
}

const StyledButton: React.FC<StyledButtonProps> = ({
  className,
  light,
  primary,
  secondary,
  block,
  gap,
  px,
  py,
  type,
  onClick,
  disabled,
  children,
  tooltipTitle,
}) => {
  return (
    <StyledButtonWrapper
      className={`${className || ''} ${
        disabled ? 'tw-cursor-not-allowed' : ''
      }`}
      gap={gap}
      px={px}
      py={py}
      onClick={disabled ? undefined : onClick}
    >
      <RTooltip
        title={tooltipTitle}
        delayHide={undefined}
        delayShow={undefined}
        place="top"
        effect={undefined}
        zIndex={undefined}
      >
        <Button
          light={light}
          primary={primary}
          secondary={secondary}
          block={block}
          disabled={disabled}
          type={type}
        >
          {children}
        </Button>
      </RTooltip>
    </StyledButtonWrapper>
  );
};

export default StyledButton;
