import { fk, Model } from 'redux-orm';

import { getId } from '../utils';
import Member from './member.model';
import User from './user.model';

/**
 * Comment model - Usede to handle the residency comments ressource
 * @see GET /comments?residency={residencyId}
 * @class CommentModel
 */
class Comment extends Model {
  constructor(data) {
    super({
      ...data,
      postedById: getId(data.user),
      reportedById: getId(data.reportedBy),
      moderatedById: getId(data.moderatedBy),
      residencyId: getId(data.residency),
    });
  }
  get isReported() {
    return this.ref.reported;
  }
  get isModerated() {
    return this.ref.moderated;
  }
  toString() {
    return `Comment: ${this.id}`;
  }
}

Comment.modelName = 'Comment';
Comment.fields = {
  reportedById: fk({
    to: Member.modelName,
    as: 'reportedBy',
    relatedName: '_reportedBySet',
  }),
  moderatedById: fk({
    to: Member.modelName,
    as: 'moderatedBy',
    relatedName: '_moderatedBySet',
  }),
  postedById: fk({
    to: User.modelName,
    as: 'postedBy',
    relatedName: '_postedBySet',
  }),
};
export default Comment;
