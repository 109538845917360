import { createAction } from 'redux-actions';

import { addPrefixToActionTypes } from '../../redux-utils/utils';

export const ACTION_TYPES = addPrefixToActionTypes(
  {
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    CREATE: 'CREATE',
    SYNC: 'SYNC',
    FLUSH: 'FLUSH',
    FLUSH_ALL: 'FLUSH_ALL',
    UPSERT: 'UPSERT',
  },
  'entities',
);

export const flushEntities = createAction(
  ACTION_TYPES.FLUSH,
  ({ models, filter }) => ({
    models,
    filter,
  }),
);

export const flushAllEntities = createAction(ACTION_TYPES.FLUSH_ALL);

export const updateEntity = createAction(
  ACTION_TYPES.UPDATE,
  (entity, id, attributes) => ({
    entity,
    id,
    attributes,
  }),
);

export const deleteEntity = createAction(ACTION_TYPES.DELETE, (entity, id) => ({
  entity,
  id,
}));

export const createEntity = createAction(
  ACTION_TYPES.CREATE,
  (entity, attributes) => ({
    entity,
    attributes,
  }),
);

/**
 * @argument {Boolean} append - If true, the entites will be added to the current stored attenties. False will flush the model before.
 */
export const syncEntities = createAction(
  ACTION_TYPES.SYNC,
  (entity, attributes, append) => ({
    entity,
    attributes,
    append,
  }),
);

export const upsertEntity = createAction(
  ACTION_TYPES.UPSERT,
  (entity, attributes) => ({
    entity,
    attributes,
  }),
);
