import { AutoCompleteAddress, PlaceType, PlaceTypeType } from './type';

export const defaultAutocompleteAddress: AutoCompleteAddress = {
  line1: '',
  city: '',
  country: '',
  zipcode: '',
  placeId: '',
};
export const getPlacesPostCodeById = async (
  placeId: string,
): Promise<AutoCompleteAddress> =>
  new Promise((resolve, reject) => {
    if (!placeId) {
      reject('placeId not provided');
    }

    try {
      new window.google.maps.places.PlacesService(
        document.createElement('div'),
      ).getDetails(
        {
          placeId,
          fields: ['address_components'],
        },
        (details) => {
          const address = { ...defaultAutocompleteAddress, placeId };
          let addressStreetNumber = '';
          let addressRoute = '';
          details?.address_components?.forEach((entry) => {
            if (entry.types?.[0] === 'postal_code') {
              address.zipcode = entry.long_name;
            }
            if (entry.types?.[0] === 'locality') {
              address.city = entry.long_name;
            }
            if (entry.types?.[0] === 'country') {
              address.country = entry.long_name;
            }
            if (entry.types?.[0] === 'street_number') {
              addressStreetNumber = entry.long_name;
            }
            if (entry.types?.[0] === 'route') {
              addressRoute = entry.long_name;
            }
          });
          address.line1 = `${addressStreetNumber} ${addressRoute}`;
          return resolve(address);
        },
      );
    } catch (e) {
      reject(e);
    }
  });

export const getNewAddress = (values: any) => {
  const placeType = values.placeType;
  if (placeType === PlaceType.google && values.placeId) {
    return {
      line2: values.line2 || null,
      place_id: values.placeId,
      place_type: 'google',
    };
  }
  return {
    line1: values.line1,
    line2: values.line2 || null,
    zipcode: values.zipcode,
    city: values.city,
    country: values.country,
    place_type: 'custom',
  };
};

export const getInitialPlaceType = (
  placeType: string | undefined,
): PlaceTypeType => {
  if (!placeType) {
    return PlaceType.google;
  }
  return placeType;
};
