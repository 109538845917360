import React from 'react';

import classes from './draggableIndicator.module.css';

function DraggableIndicator(props) {
  return (
    <div className={classes.draggableIndicator} {...props}>
      <svg className="d-block" viewBox="0 0 32 32">
        <rect x="4" y="4" width="4" height="4"></rect>
        <rect x="4" y="12" width="4" height="4"></rect>
        <rect x="4" y="20" width="4" height="4"></rect>
        <rect x="4" y="28" width="4" height="4"></rect>
        <rect x="12" y="4" width="4" height="4"></rect>
        <rect x="12" y="12" width="4" height="4"></rect>
        <rect x="12" y="20" width="4" height="4"></rect>
        <rect x="12" y="28" width="4" height="4"></rect>
        <rect x="20" y="4" width="4" height="4"></rect>
        <rect x="20" y="12" width="4" height="4"></rect>
        <rect x="20" y="20" width="4" height="4"></rect>
        <rect x="20" y="28" width="4" height="4"></rect>
        <rect x="28" y="4" width="4" height="4"></rect>
        <rect x="28" y="12" width="4" height="4"></rect>
        <rect x="28" y="20" width="4" height="4"></rect>
        <rect x="28" y="28" width="4" height="4"></rect>
      </svg>
    </div>
  );
}

export default DraggableIndicator;
