import cs from 'classnames';
import React from 'react';

export interface ButtonProps {
  className?: string;
  lg?: boolean;
  sm?: boolean;
  primary?: boolean;
  secondary?: boolean;
  success?: boolean;
  light?: boolean;
  danger?: boolean;
  block?: boolean;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}
const Button: React.FC<ButtonProps> = ({
  className,
  lg,
  sm,
  primary,
  secondary,
  success,
  light,
  danger,
  block,
  active,
  onClick,
  disabled,
  type = 'button',
  ...rest
}) => (
  <button
    onClick={onClick}
    type={type}
    disabled={disabled}
    className={cs('btn', className, {
      'btn-lg': lg,
      'btn-sm': sm,
      'btn-primary font-weight-semi-bold': primary,
      'btn-secondary font-weight-semi-bold': secondary,
      'btn-success font-weight-semi-bold': success,
      'btn-light text-gray font-weight-semi-bold': light,
      'btn-danger font-weight-semi-bold': danger,
      'w-100': block,
      active,
    })}
    {...rest}
  />
);

export default Button;
