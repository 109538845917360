import {
  minLength,
  required,
  withMinLower,
  withMinNumeric,
  withMinUpper,
} from '../../redux-form-utils/fieldValidators';

export const REGISTER_FORM_KEY = 'RegisterForm';
export const USER_STATUS = {
  INDIVIDUAL: 'individual',
  PROFESSIONAL: 'professional',
};
export const USER_STATUS_FIELD_NAME = 'status';

export function validateSamePassword({ password, passwordConfirm }) {
  const errors = {};
  if (password && passwordConfirm && password !== passwordConfirm) {
    errors.passwordConfirm = 'Should be the same as password';
  }
  return errors;
}
export const passwordValidate = [
  required,
  minLength(10),
  withMinNumeric(1),
  withMinLower(1),
  withMinUpper(1),
];
// Final form field does not accept array for validate. So to be able to use we have to reduce it as a unique error
export const passwordValidateAsAfunction = (values) =>
  [minLength(10), withMinNumeric(1), withMinLower(1), withMinUpper(1)].reduce(
    (acc, p) => p(values) || acc,
    undefined,
  );
