import React from 'react';
import { useHistory } from 'react-router-dom';
import { GREY_600 } from 'styles/constant';
import { DropdownButton, PaperPlaneIcon } from 'ui-kit';
import { DropdownButtonItemType, ItemType } from 'ui-kit/dropdown-button';

import { usePrefetchInvitableUsers } from '../hooks/useInvitableUsers';
import { usePrefetchAllMembers } from '../hooks/useMembers';

type EmailsButtonProps = {
  className?: string;
  residencyId: string;
};

const EmailsButton: React.FC<EmailsButtonProps> = ({ residencyId }) => {
  const [closeDropdown, setCloseDropdown] = React.useState(false);
  usePrefetchAllMembers({ residencyId });
  usePrefetchInvitableUsers({ residencyId });

  const history = useHistory();

  const showConfirmationModal = () => {
    history.push(`/member/${residencyId}/activation`);
    setCloseDropdown(true);
  };

  const items: Array<ItemType> = [
    {
      label: 'Email d’activation de compte',
      type: DropdownButtonItemType.onClickHandler,
      icon: <PaperPlaneIcon color={GREY_600} />,
      onClick: showConfirmationModal,
    },
  ];
  return (
    <DropdownButton
      label="Emails"
      items={items}
      closeDropdown={closeDropdown}
    />
  );
};

export default EmailsButton;
