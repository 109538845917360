import {
  CompanyName,
  CompanyType,
  SyndicNameWrapper,
} from 'pills/navigation/select-syndic/select-syndic.style';
import styled from 'styled-components';
import { GREY_200, GREY_700 } from 'styles/constant';

export const ResidencyName = styled(CompanyName)`
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
`;
export const ResidencyLocation = styled(CompanyType)``;
export const LogoWrapper = styled.div`
  & svg {
    width: 100%;
  }
`;
export const NameWrapper = styled(SyndicNameWrapper)``;

const SelectResidencyWrapper = styled.div`
  background-color: #fff;
  border-radius: 6px;
  opacity: 1;
`;

export const OptionResidencyWrapper = styled(SelectResidencyWrapper)`
  display: grid;
  grid-template-columns: 26px 1fr;
  align-items: center;
  grid-column-gap: 6px;
  grid-template-rows: 26px;
`;

export const FilterBarWrapper = styled.div``;

export const IconWrapper = styled.div`
  border-radius: 4px;

  background-color: ${GREY_200};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 14px;
    height: 14px;
    display: block;
    & g {
      fill: ${GREY_700};
    }
  }
`;
