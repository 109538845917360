import { usersSelector } from 'pills/users/users.selectors';
import { useSelector } from 'react-redux';

const useLoggedInUser = () => {
  const currentUser = useSelector(usersSelector)[1];
  if (!currentUser) {
    throw new Error(`No user logged in`);
  }
  return currentUser;
};

export default useLoggedInUser;
