import { membersApi, PER_PAGE } from '../../api/base.api';
import { Member, MembersAPI } from './member.type';

export async function getMembers(
  page = 1,
  where = {},
  membersPerPage = PER_PAGE.MEMBERS,
): Promise<MembersAPI> {
  return await membersApi.list({ page, where, membersPerPage });
}

export async function getAllMembers() {
  return await membersApi.listAll();
}

export async function updateMember(member: Member) {
  return await membersApi.update(member);
}

export async function createMember(member: Member) {
  return await membersApi.create(member);
}

export async function getMemberById(id: string) {
  return await membersApi.getById({ id });
}
