import React from 'react';
import { SVG_SIZE, SVGIconProps, SVGIconWrapper } from 'ui-kit/assembly.icon';

import { ReactComponent as CloseSVG } from './close.svg';

const CloseIcon: React.FC<SVGIconProps> = ({
  size = 'sm',
  w,
  h,
  className,
}) => {
  const { width, height } = SVG_SIZE[size];
  return (
    <SVGIconWrapper
      className={`${className || ''}`}
      width={w || width}
      height={h || height}
      $fill
    >
      <CloseSVG />
    </SVGIconWrapper>
  );
};

export default CloseIcon;
