import { faPen } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import Icon from './icon';

const PenCircleIcon = (props) => (
  <span
    className="border rounded-circle text-center border-dark p-1"
    style={{ width: '26px', height: '26px', color: 'var(--bs-gray)' }}
  >
    <span className="fa-layers fa-fw">
      <Icon
        icon={faPen}
        size="xs"
        style={{ color: 'var(--bs-gray)' }}
        {...props}
      />
    </span>
  </span>
);

export default PenCircleIcon;
