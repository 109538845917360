import styled from 'styled-components';

import { GREY_200 } from '../../../styles/constant';

const SelectSyndicWrapper = styled.div`
  padding: 10px 15px 10px 8px;
  background-color: #fff;
`;

export const SyndicLogoWrapper = styled.div`
  & img {
    width: 100%;
  }
`;
export const SyndicNameWrapper = styled.div``;
export const CompanyName = styled.div`
  opacity: 1;
  color: rgba(54, 56, 58, 1);

  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  width: max-content;

  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const CompanyType = styled.div`
  opacity: 1;
  color: rgba(108, 117, 125, 1);

  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  width: max-content;
`;

export const SingleSyndicWrapper = styled(SelectSyndicWrapper)`
  display: grid;
  grid-template-columns: 33px 1fr;

  grid-column-gap: 12px;
`;

export const MultipleSyndicWrapper = styled(SelectSyndicWrapper)`
  align-items: center;
  width: 215px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 3px;
  padding-bottom: 5px;

  & div.react-select__menu-list {
    padding: 0px;
    overflow-x: hidden;
    border-radius: 4px;
    background-color: #fff !important;
  }
  & div.react-select__menu-list > div {
    background-color: #fff !important;
    padding: 0px !important;
  }

  div.single-syndic {
    margin-bottom: 3px;
    align-items: center;
  }

  & div.single-syndic:last-child {
    margin-bottom: 0px;
  }

  .react-select__option {
    background-color: #fff !important;
  }
  .react-select__option--is-focused {
    padding: 0px;
  }

  &.react-select__option {
  }

  & ${SingleSyndicWrapper}:hover {
    &.single-syndic {
      background-color: ${GREY_200};
    }
  }

  & div.react-select__control--is-focused,
  & div.react-select__control--menu-is-open {
    border: none;
    box-shadow: none;
  }

  .react-select__control--is-focused,
  .react-select__control--menu-is-open {
    border: none;
    box-shadow: none;
  }
  .react-select__control--is-focused .react-select__control--menu-is-open {
    border: none !important;
    box-shadow: none !important;
  }
  & div.react-select__control--is-focused {
    border: none;
  }
  & label {
    padding: 0px;
    & ${SyndicNameWrapper} {
      transform: translateY(-2px);
    }

    & > div {
      padding-bottom: 7px;
    }

    & ${CompanyName} {
      width: 130px;
    }
  }
  & div.react-select__indicators {
    z-index: 1000;
  }
  & span.react-select__indicator-separator {
    display: none;
  }

  & input {
  }

  & div.react-select__control {
    justify-content: right;
  }

  & div.form-select-group,
  & div.react-select-container {
    margin: 0;
  }
  & div.react-select-container {
    margin-bottom: 0 !important ;
  }

  & div.react-select__control {
    border: none;
    box-shadow: none !important;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;
