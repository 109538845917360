import { Model } from 'redux-orm';

import { getId } from '../utils';

/**
 * Create a 'Comment Model' with the given modelName
 * @param {String} modelName the model name to give to the 'comment entity' (ex: assemblyResolutionComment)
 * @param {Function} getResourceId function that retriv in the raw data the given resourceId (ex: (c) => getId(c.resolution))
 * @returns Decorated class dedicated to the comment
 */
export const createCommentEntity = (modelName, getResourceId) =>
  class CommentEntity extends Model {
    static modelName = modelName;
    constructor(data) {
      super({
        ...data,
        postedById: getId(data.user),
        reportedById: getId(data.reportedBy),
        moderatedById: getId(data.moderatedBy),
        resourceId: getResourceId(data),
      });
    }

    get isReported() {
      return this.ref.reported;
    }

    get isModerated() {
      return this.ref.moderated;
    }

    get postedBy() {
      return this.ref.user;
    }

    get date() {
      return this.ref.createdAt;
    }

    toString() {
      return `${modelName}: ${this.id}`;
    }
  };
