import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useIntersection from '../hooks/useIntersection';
import ArrowDownIcon from './arrowDown.icon';
import ArrowUpIcon from './arrowUp.icon';
import Loader from './loader';

/**
 * TODO: test it
 * @example
 * const columns = [
  {
    headerRender: ({ items, item, currentSortIndex, sort }) => (
      <MembersListHeaderItem label="Nom" onSort={sort} asc={currentSortIndex} />
    ),
    cellRender: ({ items, item }) => (
      <td>
        {item.firstName} {item.lastName}
      </td>
    ),
    sorts: [sortByFirstNameAsc, sortByFirstNameDesc],
  },
  {
    headerRender: ({ items, currentSortIndex, sort }) => (
      <MembersListHeaderItem
        label="Roles"
        onSort={sort}
        asc={currentSortIndex}
      />
    ),
    cellRender: ({ items, item }) => (
      <td>
        <MemberRoles
          {...pickBy(
            mapKeys(item.rights, (_, k) => camelCase(k)),
            (v) => v,
          )}
        />
      </td>
    ),
  },
];

const opts = {
  columns,
  cellsRender: ({ items, currentSortIndex, children }) => <tr>{children}</tr>,
  headersWrapperRender: ({ items, currentSortIndex, children }) => (
    <thead>
      <tr>{children}</tr>
    </thead>
  ),
  cellsWrapperRender: ({ items, currentSortIndex, children }) => (
    <tbody>{children}</tbody>
  ),
  wrapperRender: ({ items, currentSortIndex, children }) => (
    <table>{children}</table>
  ),
  initialSort: sortByFirstNameAsc,
};
 */
/**
 * DefaultColumnHeaderWrapperRender --> <th>{children}</th>
 *   - DefaultColumnHeaderRender --> DefaultColumnHeaderRender
 * DefaultColumnCellWrapperRender --> <td>{children}</td>
 *   - DefaultColumnCellRender --> NONE
 * DefaultCellsRender --> <tr>{children}</tr>
 * DefaultCellsWrapperRender -->  <tbody>{children}</tbody>
 * DefaultHeadersWrapperRender -->
 *  <thead>
      <tr>{children}</tr>
    </thead>
 * DefaultWrapperRender -->
 *  <table className="table">
      {children}
    </table>
 */
function DefaultColumnHeaderWrapperRender({ children }) {
  return <th>{children}</th>;
}
function DefaultColumnCellWrapperRender({ children }) {
  return <td>{children}</td>;
}
function DefaultCellsRender({ children }) {
  return <tr>{children}</tr>;
}
function DefaultCellsWrapperRender({ children }) {
  return <tbody>{children}</tbody>;
}
function DefaultHeadersWrapperRender({ children }) {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
}
/**
 *
 * @param {Object} props.pagination - object to controle the pagination @see requestEntitiesContainer 'usePagination'
 */
function DefaultWrapperRender({ children, pagination, className }) {
  const ref = useIntersection(() => pagination?.next());
  return (
    <>
      <table className={`table custom-table ${className}`}>{children}</table>

      {/* If pagination is necessary */}
      {pagination &&
        pagination.currentPage * pagination.itemsPerPage <
          pagination.totalItems && (
          <nav
            ref={ref}
            className="d-flex align-items-center justify-content-center"
            onClick={pagination.next}
          >
            {pagination.isLoading ? (
              <Loader size="xs" />
            ) : (
              <span className="btn btn-xs btn-link mb-4">Charger plus</span>
            )}
          </nav>
        )}
    </>
  );
}
function DefaultHeaderRender() {
  return <></>;
}

export const DefaultColumnHeaderRenderWrapper = styled.div`
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;
export function DefaultColumnHeaderRender({ onSort, label, asc }) {
  const onClick = onSort || undefined;
  return (
    <DefaultColumnHeaderRenderWrapper onClick={onClick} tabindex="0">
      {label}
      {onSort && (
        <span className="ms-2 table-sorting" onClick={onSort}>
          {asc === 0 ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </span>
      )}
    </DefaultColumnHeaderRenderWrapper>
  );
}

DefaultColumnHeaderRender.propTypes = {
  label: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element])
    .isRequired,
  onSort: PropTypes.func,
  asc: PropTypes.oneOf([0, 1]),
};

const DataTable = ({
  pagination,
  items,
  columns,
  CellsRender: Cells,
  WrapperRender: Wrapper,
  HeadersWrapperRender: HeadersWrapper,
  CellsWrapperRender: CellsWrapper,
  initialSort,
  filter,
  className,
  ...extraProps
}) => {
  const [currentSortIndex, setCurrentSortIndex] = useState(1);
  const [{ currentSort }, setCurrentSort] = useState({
    currentSort: initialSort,
  });
  const [currentFilteredItems, setCurrentFilteredItems] = useState(() =>
    items.filter(filter),
  );
  const [currentSortedItems, setCurrentSortedItems] = useState(() =>
    currentSort(currentFilteredItems),
  );

  useEffect(
    function () {
      const filtered = items.filter(filter);
      setCurrentFilteredItems(filtered);
      setCurrentSortedItems(currentSort(filtered));
    },
    [filter, items, currentSort],
  );
  useEffect(
    function () {
      setCurrentSortedItems(currentSort(currentFilteredItems));
    },
    [currentSortIndex, currentSort, currentFilteredItems],
  );
  const sortBy = (transform) => () => {
    setCurrentSortIndex(currentSortIndex === 0 ? 1 : 0);
    setCurrentSort({ currentSort: transform });
  };
  const commonProps = {
    items: currentSortedItems,
    currentSortIndex,
    pagination,
    ...extraProps,
  };

  return (
    <Wrapper {...commonProps} className={className}>
      <HeadersWrapper {...commonProps}>
        {columns.map(
          (
            {
              HeaderWrapperRender:
                HeaderWrapper = DefaultColumnHeaderWrapperRender,
              HeaderRender: Header = DefaultHeaderRender,
              sorts,
            },
            i,
          ) => (
            <HeaderWrapper key={i} index={i} {...commonProps}>
              <Header
                index={i}
                {...commonProps}
                sort={sorts && sortBy(sorts[currentSortIndex])}
              />
            </HeaderWrapper>
          ),
        )}
      </HeadersWrapper>
      <CellsWrapper {...commonProps}>
        {currentSortedItems.map((item, i) => (
          <Cells key={i} index={i} {...commonProps} item={item}>
            {columns.map(
              (
                {
                  CellWrapperRender:
                    CellWrapper = DefaultColumnCellWrapperRender,
                  CellRender: Cell,
                },
                j,
              ) =>
                CellWrapper && Cell ? (
                  <CellWrapper key={j} index={i} {...commonProps} item={item}>
                    <Cell index={i} {...commonProps} item={item} />
                  </CellWrapper>
                ) : null,
            )}
          </Cells>
        ))}
      </CellsWrapper>
    </Wrapper>
  );
};

/**
 * commonProps = { items, currentSortIndex } - each 'render' will receive that props
 * columns.HeaderRender's props : ({...commonProps, sort }) - sort is the function to call to trigger a sort (based on the current index, it will sot then toogle the currentIndex)
 * columns.CellRender's props: ({...commmonProps, item }) - item is the current item
 * columns.sorts is a two element array that will be used to sort the data (currentSortIndex give the current used function)
 * CellsRender's props : ({...commonProps, item, children}) - item is the current item. children are the rendered Cell.
 * CellsWrapperRender's props: ({...commonProps, children}) - children is the result of cellsRender
 * HeadersWrapperRender's props : ({...commonProps, children}) - children is the result of header render
 * WrapperRender's props : ({...commonProps, children}) - children is the result of headers and rows
 * initialSort: function that sort the items initially
 */
DataTable.propTypes = {
  items: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      HeaderWrapperRender: PropTypes.elementType,
      HeaderRender: PropTypes.elementType,
      CellWrapperRender: PropTypes.elementType,
      CellRender: PropTypes.elementType,
      sorts: PropTypes.arrayOf(PropTypes.func),
    }).isRequired,
  ).isRequired,
  CellsRender: PropTypes.elementType,
  CellsWrapperRender: PropTypes.elementType,
  HeadersWrapperRender: PropTypes.elementType,
  WrapperRender: PropTypes.elementType,
  initialSort: PropTypes.func,
  filter: PropTypes.func,
};

DataTable.defaultProps = {
  filter: (item) => item,
  initialSort: (items) => items,
  WrapperRender: DefaultWrapperRender,
  HeadersWrapperRender: DefaultHeadersWrapperRender,
  CellsWrapperRender: DefaultCellsWrapperRender,
  CellsRender: DefaultCellsRender,
};

export default DataTable;
