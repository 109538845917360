import React from 'react';
import Linkify from 'react-linkify';

const Linkable = ({ children }) => {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a href={decoratedHref} key={key} target="_blank" rel="noreferrer">
          {decoratedText}
        </a>
      )}
    >
      {children}
    </Linkify>
  );
};

export default Linkable;
