import React from 'react';
import styled from 'styled-components/macro';

import { GREY_600 } from '../styles/constant';
import UnstyledButton from './unstyledButton';

type ShowMoreProps = {
  showMoreText: string;
  showLessText: string;
  setShowMore: React.Dispatch<React.SetStateAction<boolean>>;
  showMore: boolean;
};
const ShowMore: React.FC<ShowMoreProps> = ({
  showMoreText,
  showLessText,
  showMore,
  setShowMore,
}) => {
  return (
    <ShowMoreWrapper>
      <UnstyledButton onClick={() => setShowMore(!showMore)}>
        {showMore ? showLessText : showMoreText}
      </UnstyledButton>
    </ShowMoreWrapper>
  );
};

const ShowMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: ${GREY_600};
  & button {
    text-decoration: underline;
  }
`;

export default ShowMore;
