import styled from 'styled-components';
import { DARK_TEXT } from 'styles/constant';

export const AvatarRendererWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 2px;
`;

export const DefaultRendererWrapper = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  color: ${DARK_TEXT};
`;
