import { useSelector } from 'react-redux';
import { PROFILE_MANAGER } from 'redux-orm-models/user.type';

import { usersSelector } from '../pills/users/users.selectors';

const useIsManager = () => {
  const currentUser = useSelector(usersSelector)[1];
  if (!currentUser) {
    throw new Error(`No user logged in`);
  }
  return currentUser.currentProfile === PROFILE_MANAGER;
};

export default useIsManager;
