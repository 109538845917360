import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { retry } from '../pills/dataLoader/dataLoader.actions';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };

    this.back = this.back.bind(this);
    this.retry = this.retry.bind(this);
  }

  static getDerivedStateFromError(error) {
    if (error?.toString() === 'TypeError: branch is undefined') {
      console.error(
        `Cette erreur est dû a un problème avec le local storage et redux orm. Probablement l'ajout d'un nouveau model.
        En effet, lors de l'ajout d'un nouveu model, le localStorage n'est plus en phase avec redux orm et crée cette erreur.
        Si vous venez d'ajouter un model, pensez à bump la version (avec le fix 1), sinon essayez le fix 2. Sinon, bonne chance ;)
        Fix 1: npm version patch --no-git-tag-version && npm start
        Fix 2: vider le localStorage`,
      );
    }
    return { hasError: true, error };
  }

  back() {
    this.setState({ hasError: false, error: null });
  }

  retry() {
    this.props.retry();
    this.back();
  }

  render() {
    const { renderError: Error } = this.props;
    const { hasError } = this.state;

    return hasError ? (
      <Error error={this.state.error} back={this.back} retry={this.retry} />
    ) : (
      this.props.children
    );
  }
}

ErrorBoundary.propTypes = {
  renderError: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    retry: () => dispatch(retry()),
  };
}

export default connect(null, mapDispatchToProps)(ErrorBoundary);
