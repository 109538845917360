import { Column, SortingRule } from 'react-table';

import { Member } from '../../../../../pills/members/member.type';
import {
  avatarRenderer,
  defaultRenderer,
  enabledRenderer,
  nameRenderer,
  profileRenderer,
} from './renderer/cell/CellRenderer';

type CustomColumnType = {
  profile: string;
  richavatar: string;
};
export type ColumnNames = Member & CustomColumnType;

export enum ColumnId {
  AVATAR = 'richavatar',
  NAME = 'id',
  PROFILE = 'profile',
  EMAIL = 'email',
  USER_ENABLED = 'userEnabled',
}

export type ColumnsType = Array<Column<ColumnNames>>;

export const COLUMNS: ColumnsType = [
  {
    Header: ' ',
    accessor: ColumnId.AVATAR,
    Cell: avatarRenderer,
    disableSortBy: true,
  },
  {
    Header: 'Nom',
    accessor: ColumnId.NAME,
    Cell: nameRenderer,
    sortDescFirst: true,
  },
  {
    Header: 'Profil',
    accessor: ColumnId.PROFILE,
    Cell: profileRenderer,
    sortDescFirst: true,
  },

  {
    Header: 'Email',
    accessor: ColumnId.EMAIL,
    Cell: defaultRenderer,
    sortDescFirst: true,
  },

  {
    Header: 'Compte Syment',
    accessor: ColumnId.USER_ENABLED,
    Cell: enabledRenderer,
    sortDescFirst: true,
  },
];

export const initialSortState: SortingRule<ColumnNames> = {
  id: ColumnId.NAME,
  desc: true,
};
