import { membersApi } from 'api/base.api';
import { QUERY_KEYS } from 'api/queryKeys';
import { useQuery } from 'react-query';

type UseExportEREProps = {
  residencyId: string;
};

const getResidentsEREById = (residencyId: string) => {
  return membersApi.getEmailConsentsByResendyId({ id: residencyId });
};

export const getExportEREKey = (residencyId: string) => {
  return [QUERY_KEYS.exportResidentsEre, residencyId];
};

export function useExportERE({ residencyId }: UseExportEREProps) {
  const { data, isLoading, refetch } = useQuery(
    getExportEREKey(residencyId),
    () => getResidentsEREById(residencyId),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: false, // disable this query from automatically running
    },
  );

  return { data, isLoading, refetch };
}
