import React from 'react';
import styled from 'styled-components';

import { ReactComponent as AssemblyLogo } from './menu-link/assembly.svg';

export type SVGIconProps = {
  className?: string;
  size?: 'lg' | 'sm' | 'xs';
  color?: string;
  w?: string;
  h?: string;
};

export const SVGIconWrapper = styled.div<{
  width: string;
  height: string;
  color?: string;
  $fill: boolean;
}>`
  display: flex;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: ${(props) => props.color || undefined};

  & svg {
    width: 100%;
    height: 100%;
    & g {
      fill: ${(props) => (props.$fill ? `currentColor` : undefined)};
    }
  }
`;

export const SVG_SIZE = {
  xs: {
    width: '12rem',
    height: '12rem',
  },
  sm: {
    width: '16px',
    height: '16px',
  },
  lg: {
    width: '20px',
    height: '20px',
  },
};
const AssemblyIcon: React.FC<SVGIconProps> = ({
  color = '#000',
  size = 'sm',
  className,
}) => {
  const { width, height } = SVG_SIZE[size];
  return (
    <SVGIconWrapper
      className={`${className || ''}`}
      width={width}
      height={height}
      color={color}
      $fill
    >
      <AssemblyLogo />
    </SVGIconWrapper>
  );
};

export default AssemblyIcon;
