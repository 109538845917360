import PropTypes from 'prop-types';
import React from 'react';

import bg1 from '../../images/background/01.jpg';
import bg2 from '../../images/background/02.jpg';
import bg3 from '../../images/background/03.jpg';
import bg4 from '../../images/background/04.jpg';
import bg5 from '../../images/background/05.jpg';
import bg6 from '../../images/background/06.jpg';
import bg7 from '../../images/background/07.jpg';
import bg8 from '../../images/background/08.jpg';
import bg9 from '../../images/background/09.jpg';
import { Container, FlexBox, Row } from '../../ui-kit';
import { randomIntFromInterval } from '../../utils';
import classes from './authLayout.module.css';

const { background, boxLeft, boxRight, signLogo, box } = classes;

const BGS = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9];

const currentYear = new Date().getUTCFullYear();
const BoxLeft = () => (
  <div className={boxLeft}>
    <div className={signLogo} />
    <h3>Suivez ce qu'il se passe dans votre résidence depuis n'importe où.</h3>
    <small>Syment © {currentYear} - Tous droits réservés</small>
  </div>
);

const BoxRight = ({ children }) => <div className={boxRight}>{children}</div>;

const Box = ({ children }) => <Row className={box}>{children}</Row>;

export default function AuthLayout({ children }) {
  return (
    <div
      className={background}
      style={{
        backgroundImage: `url(${
          BGS[randomIntFromInterval(0, BGS.length - 1)]
        })`,
      }}
    >
      <Container>
        <FlexBox className="justify-content-center align-items-center py-5">
          <Box>
            <BoxLeft />
            <BoxRight>{children}</BoxRight>
          </Box>
        </FlexBox>
      </Container>
    </div>
  );
}
AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
